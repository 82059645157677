<template>
	<v-card v-if="elements.length"
		elevation="0">
		<v-card-title class="text-h6">{{ title }}</v-card-title>
		<v-card-subtitle class="text-subtitle-2">{{ subtitle }}</v-card-subtitle>

		<v-card-text>
			<v-simple-table dense>
				<template v-slot:default>
					<thead>
						<tr>
							<th width="80%" class="text-left">Найменування елементів, складових частин КТЗ</th>
							<th width="20%" class="text-left">Дз, %</th>
							<!-- <th width="30%" class="text-left">Опис</th> -->
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in elements" :key="index" >
							<td>{{ item.name }}</td>
							<td>{{ item.type.val }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	data: () => ({
		title: 'Фактори додаткового зменшення вартості КТЗ з дефектами кузова, кабіни, рами',
		subtitle: 'Чинники, що використовуються у розрахунку вартості:',
	}),

	props: {
		item: { type: Object, default: () => {}},
	},

	computed: {
		elements() {
			return this.item.hasOwnProperty('uses') ? this.item.uses.filter((item) => !item.type.growing && item.type.element ): [];
		},
	},

	methods: {
		
	},
}
</script>