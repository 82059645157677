export default {
	namespaced: true,
	state: {
		endpoint: '/document/object',
		name: 'DocumentObject',
		title: {
			single: 'DocumentObject',
			plural: 'DocumentObjects'
		},
		icon: 'mdi-gift',
		items: [],
		headers: [
			{ text: 'ID', value: 'id', sortable: true, width: '50' },
			{ text: 'Код', value: 'code', sortable: true },
			{ text: 'Назва', value: 'name', sortable: true },
			{ text: 'number', value: 'number', sortable: true},
			{ text: 'type', value: 'type.name', sortable: true},
			{ text: 'category', value: 'category.name', sortable: true},
			{ text: 'datebegin', value: 'datebegin', sortable: true},
			{ text: 'dateend', value: 'dateend', sortable: true},
			{ text: 'members', value: 'members.length', sortable: true},
			{ text: 'prices', value: 'prices.length', sortable: true},
			{ text: 'Опис', value: 'description', sortable: true },
			{ text: 'actions', value: 'actions', sortable: false, width: '10%' },
		],
		item: {
			type: {},
			category: {},
			product: {},
			user: {},
			members: [],
			prices: [],
		},
	},
	actions: {
		clearItem(context) {
			context.commit('item', {
				type: {},
				category: {},
				product: {},
				user: {},
				members: [],
				prices: [],
			})
		},
	},
}
