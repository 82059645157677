<template>
	<div>
		<v-edit-dialog
			:return-value.sync="item.status"
			large
			persistent
			save-text="Зберегти"
			cancel-text="Відмінити"
			@save="onOK"
		>
			<div>{{ text }}</div>
			<template v-slot:input>
				<div class="mt-4 text-h6">{{ title }}</div>
				<v-combobox
					v-model="item.status"
					:items="items"
					label="Статус"
					item-text="name"
					item-value="id"
				></v-combobox>
			</template>
		</v-edit-dialog>

		<v-snackbar v-model="snack.visible"
			:timeout="snack.timeout"
			:color="snack.color"
		>
			<v-list-item three-line v-if="snack.subtitle">
				<v-list-item-content>
					<v-list-item-title>{{ snack.title }}</v-list-item-title>
					<v-list-item-subtitle>{{ snack.subtitle }}</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
			<div v-else>{{ snack.title }}</div>
			<template v-slot:action="{ attrs }">
				<v-btn icon v-bind="attrs" @click="snack.visible = false">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'DocumentStatusChange',
	description: 'Форма для встановлення статусу',
	components: {
		
	},
	data: () => ({
		title: 'Встановити статус',
		loading: false,

		snack: {
			visible: false,
			timeout: 3000,
			color: 'succcess',
			title: '',
			subtitle: null,
		},
	}),

	props: {
		value: { type: Object, default: () => {}},
	},

	computed: {
		...mapGetters('DocumentStatusType', [ 'items' ] ),

		item: {
			get() 		{ return this.value },
			set(val) 	{ this.$emit('input', val) }
		},

		text() {
			return this.item?.status?.name ? this.item.status.name : ''
		},
	},

	created() {
		//console.log(this.$options.name, 'created')
		this.loadDocumentStatusType()
	},

	methods: {
		...mapActions('Document', [ 'setStatus' ]),
		...mapActions('DocumentStatusType', { 'loadDocumentStatusType': 'load' }),

		onOK() {
			//console.log(this.$options.name, 'onOK', 'items', this.items, 'selected', this.selected, 'item', this.item)
			this.loading = true
			return this.setStatus(this.item).then((response) => {
				//console.log(this.$options.name, 'onOK', 'item', response.data)
				this.item = response.data

				this.snack = {
					visible: true,
					color: 'success',
					title: 'Статус змінено',
					subtitle: null
				}
			}, error => {
				console.log(this.$options.name, 'onOK', 'error', error)
				this.snack = {
					visible: true,
					color: 'error',
					title: 'Статус не змінено',
					subtitle: error,
				}
			}).finally(() => {
				this.loading = false
			});
		},
	},
}
</script>