<template>
	<v-main>
		<v-navigation-drawer fixed clipped permanent style="padding: 64px 0px 0px 0px;">
			<v-card elevation="0">
				<v-card-text>
					<z-vehicle-parameters-number></z-vehicle-parameters-number>
					<z-vehicle-parameters></z-vehicle-parameters>
				</v-card-text>
			</v-card>
			<template v-slot:append>
				<v-card-actions class="px-4">
					<v-spacer></v-spacer>
					<v-btn text color="primary" @click="onClear">{{ $vuetify.lang.t('$vuetify.clear') }}</v-btn>
					<v-btn color="primary" @click="onOK">{{ $vuetify.lang.t('$vuetify.ok') }}</v-btn>
				</v-card-actions>
			</template>
		</v-navigation-drawer>

		<v-navigation-drawer fixed clipped permanent right
			mini-variant
			mini-variant-width="56"
			style="padding: 64px 0px 0px 0px;"
        >
			<v-list dense nav>
				<v-list-item>
					<v-list-item-action>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<download-excel 
									:data="excel.data"
									:name="excel.name"
									:type="excel.type"
								>
									<v-icon large color="green darken-2" :disabled="excel.disabled" v-bind="attrs" v-on="on">mdi-microsoft-excel</v-icon>
								</download-excel>
							</template>
							<span>Експорт Excel</span>
						</v-tooltip>
					</v-list-item-action>
				</v-list-item>
			</v-list>
        </v-navigation-drawer>

		<v-container style="padding: 64px 0px 0px 256px;">
			<v-card elevation="0">
				<v-card-title class="font-weight-light">
					{{ title }}
				</v-card-title>

				<!-- <v-divider></v-divider> -->
	
				<v-row v-if="graphData.length">
					<v-col>
						<v-card class="mx-auto" color="grey lighten-4">
							<v-card-title>
								<div class="text-caption grey--text text-uppercase">
									Середня ціна за періоди
								</div>
								<v-spacer></v-spacer>
								<strong>UAH</strong>
							</v-card-title>

							<v-sheet color="transparent">
								<v-sparkline
									:labels="labels"
									:value="valueUAH"
									color="blue"
									line-width="1"
									padding="16"
									label-size="3"
								></v-sparkline>
							</v-sheet>
						</v-card>
					</v-col>

					<v-col>
						<v-card class="mx-auto" color="grey lighten-4">
							<v-card-title>
								<div class="text-caption grey--text text-uppercase">
									Середня ціна за періоди
								</div>
								<v-spacer></v-spacer>
								<strong>USD</strong>
							</v-card-title>

							<v-sheet color="transparent">
								<v-sparkline
									:labels="labels"
									:value="valueUSD"
									color="blue"
									line-width="1"
									padding="16"
									label-size="3"
								></v-sparkline>
							</v-sheet>
						</v-card>
					</v-col>

					<v-col cols="12">
						<v-card elevation="0">
							<!-- <v-card-title class="text-subtitle-2 font-weight-bold">Процентілі</v-card-title> -->
							<v-simple-table dense>
								<template v-slot:default>
									<thead>
										<tr>
											<th>Дата</th>
											<th>Вартість авто в грн.</th>
											<th>Вартість авто в USD</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, index) in graphData" :key="index">
											<td >{{ item.date }}</td>
											<td >{{ item.price.UAH }}</td>
											<td >{{ item.price.USD }}</td>
											<!-- <td >{{ parseInt(percentile.price) }}</td> -->
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-card>
					</v-col>
				</v-row>
			</v-card>
		</v-container>
		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64" ></v-progress-circular>
		</v-overlay>
		<v-snackbar v-model="snackbar.visible" :color="snackbar.color">
			{{ snackbar.text }}
			<template v-slot:action="{ attrs }">
			<v-btn icon v-bind="attrs" @click="snackbar.visible = false" >
				<v-icon>mdi-close</v-icon>
			</v-btn>
			</template>
		</v-snackbar>
	</v-main>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import axios from 'axios'
import zVehicleParametersNumber from '@/views/forms/vehicle.parameters.number'
import zVehicleParameters from '@/views/forms/vehicle.parameters'
import zPdf from '@/views/pdf/VehicleEstimate'
import downloadExcel from "vue-json-excel";

export default {
	components: {
		zVehicleParametersNumber,
		zVehicleParameters,
		zPdf,
		downloadExcel,
	},
	data: () => ({
		title: 'Середня ціна',
		loading: false,

		graph: [],

		panel: [],
		panelCount: 2,

		excel: {
			name: '',
			type: 'xlsx',
			disabled: true,
			data: []
		},

		snackbar: {
			visible: false,
			text: null,
			color: null
		},
	}),
	props: {
		
	},

	watch: {
		
	},
	
	computed: {
		/* ...mapGetters('Vehicle', [
			'minYear', 'maxYear', 'years', 'categories', 'brands','models','engines','gearboxes','bodies'
		]), */

		/* ...mapGetters('VehicleAveragePrice', [
			'params', 'isset', 'item'
			,'points', 'value'
		]), */
		...mapGetters('VehicleEstimate', [
			'params', 'item', 'isset'
		]),

		excelName() {
			return this.title + (this.params.number ? ' ' + this.params.number : (this.params.vin ? ' ' + this.params.vin : '') + '.' +  this.excel.type)
		},

		id() {
			return this.item.hasOwnProperty('id') ? this.item.id: null;
		},
		brand() {
			return this.params.hasOwnProperty('brand') ? this.params.brand.name : '';
		},
		model() {
			return this.params.hasOwnProperty('model') ? this.params.model.name : '';
		},
		estimates() {
			return this.item.hasOwnProperty('estimates') ? this.item.estimates: [];
		},

		

		registrations() {
			return this.item.hasOwnProperty('registrations') ? this.item.registrations: [];
		},

		/* excelName() {
			return this.title + (this.vin ? ' ' + this.vin : '') + '.' +  this.excelType
		}, */

		

		elements() {
			return this?.graph?.periodSelectorData?.elements ? this?.graph?.periodSelectorData?.elements : []
		},

		graphData() {
			return this?.graph?.graphData ? this?.graph?.graphData : []
		},

		labels() {
			return this?.graph?.graphData?.map(element=>element.date);
		},

		valueUAH() {
			return this?.graph?.graphData?.map(element=>element.price.UAH);
		},

		valueUSD() {
			return this?.graph?.graphData?.map(element=>element.price.USD);
		},
	},

	created () {
		//this.clear()
		//this.load()
	},

	mounted() {
		
	},

	methods: {
		...mapActions('VehicleEstimate', [
			'setItem', 'clear'
		]),

		adverts(items) {
			const sorted = [...items].sort((a, b) => parseInt(a.price) - parseInt(b.price));
			return sorted
		},

		getAdvertUrl(item) {
			//console.log('VehicleEstimateMarket', 'getAdvertUrl', 'item', item)

			return `https://auto.ria.com/uk/auto_${this.brand}_${this.model}_${item.code}.html`
		},

		onClear() {
			//this.$refs.parameters.clear()
			this.clear()
		},

		ExpandAll () {
			this.panel = [...Array(this.panelCount).keys()].map((k, i) => i)
		},
		
		ColapsAll () {
			this.panel = []
		},

		validate() {
			//return true
			return this.params.number 
				|| this.params.vin
				|| this.params.brand
				|| this.params.model
		},

		onOK() {
			var that = this

			console.log('VehicleEstimateMarket', 'onOK', 'params', this.params)
			//return

			if(this.validate()) {
				this.loading = true
				//axios.post('/vehicle/averageprice', this.params).then(response => {
				//axios.post('/vehicle/estimate/averageprice', this.params).then(response => {
				axios.post('/vehicle/estimate/averageprice/statistic', this.params).then(response => {
					//console.log('VehicleAveragePrice', 'onOK','response.data', response.data)
					
					that.graph = response.data
					//that.setItem(response.data)
					that.ExpandAll()
					that.loading = false
				}, error => {
					console.log('error', error)
					that.loading = false

					that.snackbar.visible 	= true;
					that.snackbar.text 		= error.response.data.message;
					that.snackbar.color 	= null;
				});
			} else {
				this.snackbar.visible 	= true;
				this.snackbar.text 		= 'Відсутні дані для пошуку';
				this.snackbar.color 	= null;
			}
		},
	},
}
</script>