<template>
	<v-dialog 
		v-model="dialog"
		scrollable
		persistent
		max-width="700px"
	>
		<!-- <template v-slot:activator="{ on, attrs }">
			<v-btn icon v-bind="attrs" v-on="on">
				<v-icon>mdi-plus</v-icon>
			</v-btn>
		</template> -->
		<template v-slot:activator="{ on: dialog, attrs }">
			<v-tooltip bottom>
				<template v-slot:activator="{ on: tooltip, attrs }">
					<slot name="activator" :attrs="attrs" :dialog="dialog" :tooltip="tooltip">
						<v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...dialog }">
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</slot>
				</template>
				<span>{{ $vuetify.lang.t(`$vuetify.${tooltip}`) | capitalize }}</span>
			</v-tooltip>
		</template>

		<v-card>
			<v-toolbar flat extended>
				<v-avatar>
					<v-icon>{{ icon }}</v-icon>
				</v-avatar>
				<v-toolbar-title>{{ title }}</v-toolbar-title>

				<v-spacer></v-spacer>

				<v-btn icon @click="dialog=false" >
					<v-icon>mdi-close</v-icon>
				</v-btn>

				<template v-slot:extension>
					<v-text-field
						v-model="search"
						append-icon="mdi-magnify"
						label="Пошук"
						dense
						single-line
						hide-details
						outlined
						rounded
						@click:append="onSearch"
						@keyup.enter="onSearch"
						@keyup.ctrl.90="search='Нурофен'"
					></v-text-field>
				</template>

			</v-toolbar>

			<v-card-text>
				<v-list three-line>
					<v-list-item-group
						v-model="selected"
						active-class="success--text"
						:multiple="multiple"
					>
						<template v-for="(item, index) in items">
							<v-list-item :key="index">
								<template v-slot:default="{ active }">
									<v-list-item-icon>
										<v-icon @click.stop="onAdd(item)">mdi-arrow-left</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title v-text="item.name" class="text-wrap"></v-list-item-title>
										<v-list-item-subtitle v-text="item.maker.name" class="text-wrap"></v-list-item-subtitle>
									</v-list-item-content>

									<!-- <v-list-item-action>
										<v-icon color="grey lighten-1">mdi-delete</v-icon>
									</v-list-item-action> -->
								</template>
							</v-list-item>

							<!-- <v-divider v-if="index < items.length - 1" :key="item.name"></v-divider> -->
						</template>
					</v-list-item-group>
				</v-list>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary" text  :disabled="disabledOk" @click="onOK">OK</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import axios from 'axios'
export default {
	name: 'DrugSearchDialog',
	data: () => ({
		dialog: false,
		loading: false,
		items: [],
		search: null,
		selected: [],
	}),
	props: {
		icon:   		{ type: String, default: 'mdi-magnify' },
		title:   		{ type: String, default: 'Пошук лікарського засобу' },
		tooltip:  		{ type: String, default: 'add' },
		disabled:   	{ type: Boolean, default: false }, //Ознака неактивного елементу
		multiple:   	{ type: Boolean, default: false }, //Ознака вилідення декількох елементів
	},
	
	computed: {
		disabledOk() {
			return !this.selected.length
		}
	},

	methods: {
		onSearch() {
			this.loading = true

			this.items = []

			let params = {
				name: this.search
			}

			var that = this
			axios.get('/products/dic', { params: params }).then(response => {
				//console.log(that.$options.name, 'onSearch','response.data', response.data)
				this.items = response.data
			}, error => {
				console.log('error', error)
			}).finally(() => (this.loading = false));
		},

		onAdd(item) {
			//this.$emit('add', item)
			this.$emit('add', {
				id: item.id,
				idm: item.idm,
				name: item.name,
				type: { code: 'drug' },
				product: { 
					id: item.id
					,idm: item.idm
					,maker: {
						name: item.hasOwnProperty('maker') ? item.maker.name : null
					}
				},
			})

			const index = this.items.indexOf(item)
			this.items.splice(index, 1);
		},

		onCancel () {
			this.dialog = false
		},

		onOK () {
			//console.log(this.$options.name, 'onOK','selected', this.selected)

			//let items = this.selected.map(element => this.items[element])
			//this.$emit('add', this.selected.map(element => this.items[element]))

			this.selected.forEach((index) => {
				let item = this.items[index]
				this.onAdd(item)
			})

			this.dialog = false
			this.selected = []
		},
	},
}
</script>