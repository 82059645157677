<template>
	<v-main>
		<v-navigation-drawer fixed clipped permanent style="padding: 64px 0px 0px 0px;">
			<v-card class="mx-auto" elevation="0">
				<v-card-text>
					<v-form ref="form">
						<v-card  elevation="0">
							<v-system-bar class="my-2">
								<span>Параметри</span>
								<v-spacer></v-spacer>
							</v-system-bar>

							<v-card elevation="0">
								<v-row dense>
									<v-col cols="12" sm="12" md="12">
										<v-text-field
											label="VIN-код"
											v-model="params.vin"
											outlined
											dense
											clearable
											@keyup.ctrl.90="params.vin='WAUC8AFC0HN089495'"
											@keyup.enter.self="OnOK"
											hide-details="auto"
										></v-text-field>
									</v-col>
								</v-row>
							</v-card>
						</v-card>
					</v-form>
				</v-card-text>
				<v-card-actions class="px-4">
					<v-spacer></v-spacer>
					<v-btn text color="primary" @click="clear">{{ $vuetify.lang.t('$vuetify.clear') }}</v-btn>
					<v-btn color="primary" @click="OnOK">{{ $vuetify.lang.t('$vuetify.ok') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-navigation-drawer>

		<v-navigation-drawer fixed clipped permanent right
			mini-variant
			mini-variant-width="56"
			style="padding: 64px 0px 0px 0px;"
        >
			<v-list dense nav>
				<v-list-item>
					<v-list-item-action>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<download-excel 
									:data="options"
									:name="excelName"
									:type="excelType"
								>
									<v-icon large color="green darken-2" :disabled="!options.length" v-bind="attrs" v-on="on">mdi-microsoft-excel</v-icon>
								</download-excel>
							</template>
							<span>Експорт Excel</span>
						</v-tooltip>
					</v-list-item-action>
				</v-list-item>
			</v-list>
        </v-navigation-drawer>

		<v-container style="padding: 64px 0px 0px 256px;">
			<v-card elevation="0">
				<v-card-title class="font-weight-light">
					{{ title }}
				</v-card-title>
				<!-- <v-card-text class="d-flex justify-space-between mb-6">
					<span>{{ brand }} {{ model }}</span> 
					<span>{{ (makeDate?'Дата виробництва ':'') }} {{ makeDate }}</span>
				</v-card-text> -->

				<v-card-text>
					<v-simple-table dense>
						<template v-slot:default>
							<tbody>
								<tr>
									<td>VIN</td>
									<td>{{ vin }}</td>
								</tr>
								<tr>
									<td>Марка</td>
									<td>{{ brand }}</td>
								</tr>
								<tr>
									<td>Модель</td>
									<td>{{ model }}</td>
								</tr>
								<tr>
									<td>Дата виробництва</td>
									<td>{{ makeDate }}</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-card-text>

				<!-- <v-divider></v-divider> -->
				<v-card-title class="text-subtitle-1 font-weight-bold">
					Комплектація
				</v-card-title>

				

				<v-card-text>
					<v-simple-table dense
						fixed-header
						height="480px"
					>
						<template v-slot:default>
						<thead>
							<tr>
								<th class="text-left">Код</th>
								<th class="text-left">Назва</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="option in options" :key="option.code">
								<td>{{ option.code }}</td>
								<td>{{ option.name }}</td>
							</tr>
						</tbody>
						</template>
					</v-simple-table>
				</v-card-text>
			</v-card>
		</v-container>
		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64" ></v-progress-circular>
		</v-overlay>
		<v-snackbar v-model="snackbar.visible" :color="snackbar.color">
			{{ snackbar.text }}
			<template v-slot:action="{ attrs }">
			<v-btn icon v-bind="attrs" @click="snackbar.visible = false" >
				<v-icon>mdi-close</v-icon>
			</v-btn>
			</template>
		</v-snackbar>
	</v-main>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import axios from 'axios'
import downloadExcel from "vue-json-excel";

export default {
	components: {
		downloadExcel
	},
	data: () => ({
		title: 'Інформація по vin-коду',
		loading: false,

		excelType: 'xlsx',

		snackbar: {
			visible: false,
			text: null,
			color: null
		},
	}),
	props: {
		
	},

	watch: {
		
	},
	
	computed: {
		...mapGetters('VehicleVerifyVin', [
			'params', 'vin', 'brand', 'model', 'makeDate','options'
		]),

		excelName() {
			return this.title + (this.vin ? ' ' + this.vin : '') + '.' +  this.excelType
		},
	},

	methods: {
		...mapMutations('VehicleVerifyVin',{
			setVin: 'vin',
			setBrand: 'brand',
			setModel: 'model',
			setMakeDate: 'makeDate',
			setOptions: 'options',
		}),

		...mapActions('VehicleVerifyVin', [
			'clear'
		]),

		OnOK() {
			var that = this

			if(this.params.vin) {
				this.loading = true
				axios.get('/vehicle/verify/vin/' + this.params.vin).then(response => {
					console.log('VehicleVerifyVin', 'search','response.data', response.data)
					//that.item = response.data;
					
					if(response.data.hasOwnProperty('vin')) {
						that.setVin(response.data.vin)
					}

					if(response.data.hasOwnProperty('brand')) {
						that.setBrand(response.data.brand)
					}

					if(response.data.hasOwnProperty('model')) {
						that.setModel(response.data.model)
					}

					if(response.data.hasOwnProperty('makeDate')) {
						that.setMakeDate(response.data.makeDate)
					}

					if(response.data.hasOwnProperty('options')) {
						that.setOptions(response.data.options)
					}

					that.loading = false
				}, error => {
					//console.log(error)
					that.loading = false

					that.snackbar.visible 	= true;
					that.snackbar.text 		= error.response.data.message;
					that.snackbar.color 	= null;
				});
			} else {
				that.snackbar.visible 	= true;
				that.snackbar.text 		= 'Відсутні дані для пошуку';
				that.snackbar.color 	= null;
			}
		},
	},
}
</script>