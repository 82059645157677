<template>
	<v-form ref="form">
		<v-tabs-items v-model="form.tab">
			<!-- Головна -->
			<v-tab-item>
				<v-card>
					<v-card-text>
					<v-row>
						<v-col cols="12">
							<v-text-field
								label="Id"
								v-model="item.id"
								outlined
								dense
								clearable
								hide-details="auto"
							></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-text-field
								:label="$vuetify.lang.t('$vuetify.name') | capitalize"
								v-model="item.name"
								outlined
								dense
								clearable
								hide-details="auto"
							></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-textarea
								v-model="item.description"
								:label="$vuetify.lang.t('$vuetify.comment') | capitalize"
								outlined
								hint=""
								rows="2"
							></v-textarea>
						</v-col>
					</v-row>
					</v-card-text>
				</v-card>
			</v-tab-item>
			<!-- Користувачі -->
			<v-tab-item>
				<z-table module="users" :items="item.users"></z-table>
			</v-tab-item>
			<!-- Сервіси -->
			<v-tab-item>
				<z-table module="services" :items="item.services"></z-table>
			</v-tab-item>

		</v-tabs-items>
	</v-form>
</template>

<script>
//import { mapGetters, mapActions } from 'vuex'
import zTable from '@/views/components/table.dictionary'

export default {
	components: {
		zTable
	},
	data () {
		return {
			//item: {},
		}
	},
	props: {
		module: 		{ type: String, default: null }, // users | roles | ....
		item: 			{ type: Object, default: () => {} },
	},

	watch: {
		/* 'item.brand': function (val) {
			if(val) {
				this.Models({brand: val.id})
			}
		}, */
	},
	computed: {
		form: function () {
            return this.$store.getters[this.module + '/form']
        },
		/* ...mapGetters('vehicles',[
			'categories', 'brands', 'models', 'engines', 'gearboxes', 'bodies', 'years',
		]), */
	},
	created () {
		/* if(this.brands.length == 0) 	this.Brands({});
		if(this.engines.length == 0) 	this.Engines({});
		if(this.gearboxes.length == 0) 	this.Gearboxes({}); */
	},
	methods: {
		/* ...mapActions('vehicles', [
			'Categories', 'Brands', 'Models', 'Engines', 'Gearboxes', 'Bodies',
		]),

		Clear () {
			this.$refs.form.reset()
			//this.$set(this.vehicle,'racenorm', 19000)
		}, */

		/* Set (item) {
			if(item) {
				this.item = Object.assign({}, item)
			}
		}, */

	},
}
</script>