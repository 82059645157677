import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAutocomplete,{attrs:{"items":_vm.items,"loading":_vm.loading,"search-input":_vm.search,"clearable":"","hide-details":"","hide-selected":"","item-text":"name","item-value":"id","label":"Пошук лікарського засобу...","outlined":_vm.outlined,"dense":_vm.dense,"return-object":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"keyup":[function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==90){ return null; }if(!$event.ctrlKey){ return null; }return _vm.onDefault.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSearch.apply(null, arguments)}]},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c(VListItem,[_c(VListItemTitle,[_vm._v(" Search for your favorite "),_c('strong',[_vm._v("Cryptocurrency")])])],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.name)}}),_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(item.symbol)}})],1)]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})}
var staticRenderFns = []

export { render, staticRenderFns }