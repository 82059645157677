export default {
    namespaced: true,
    state: {
		endpoint: 'users',
		item: {
			id: null,
			usersroles: [],
		},
        items: [],
    },
	actions: {
		clearItem(context) {
			context.commit('item', {
				id: null,
				usersroles: [],
			})
		},
	},
}