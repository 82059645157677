import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"fullscreen":_vm.fullscreen,"transition":_vm.transition,"scrollable":_vm.scrollable,"width":_vm.width,"height":_vm.height,"max-width":_vm.maxWidth},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [(_vm.activator=='icon')?_c(VTooltip,{attrs:{"bottom":"","disabled":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"small":_vm.small,"disabled":_vm.disabled}},'v-icon',attrs,false),Object.assign({}, tooltip, dialog)),[_vm._v(_vm._s(_vm.activatorIcon))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.' + _vm.mode))))])]):_c(VTooltip,{attrs:{"bottom":"","disabled":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"outlined":"","color":"primary"}},'v-btn',attrs,false),Object.assign({}, tooltip, dialog)),[_c(VIcon,{attrs:{"left":"","dark":"","color":"primary"}},[_vm._v(_vm._s(_vm.activatorIcon))]),_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.' + _vm.mode)))+" ")],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.title)))])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"elevation":"4","color":_vm.color}},[_c(VToolbar,{attrs:{"elevation":"0","dark":"","color":"primary"},scopedSlots:_vm._u([(_vm.form.hasOwnProperty('tabs'))?{key:"extension",fn:function(){return [_c(VTabs,{attrs:{"align-with-title":""},model:{value:(_vm.form.tab),callback:function ($$v) {_vm.$set(_vm.form, "tab", $$v)},expression:"form.tab"}},[_c(VTabsSlider,{attrs:{"color":"yellow"}}),_vm._l((_vm.form.tabs),function(item){return _c(VTab,{key:item},[_vm._v(" "+_vm._s(item)+" ")])})],2)]},proxy:true}:null],null,true)},[_c(VAvatar,[_c(VIcon,[_vm._v(_vm._s(_vm.icon))])],1),_c(VToolbarTitle,[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.' + _vm.title.single))))]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.OnClose}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticStyle:{"height":"500px"}},[_c(_vm.currentComponent,_vm._b({ref:"component",tag:"component",attrs:{"module":_vm.module,"item":_vm.item},on:{"click":function($event){return _vm.$emit('click', $event)},"input":function($event){return _vm.$emit('input', $event)}}},'component',_vm.$attrs,false))],1),(!_vm.fullscreen)?_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":_vm.OnCancel}},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.cancel')))]),_c(VBtn,{attrs:{"color":"success","dark":"","disabled":_vm.loading},nativeOn:{"click":function($event){return _vm.OnOK.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.ok')))])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }