<template>
	<v-main>
        <v-container>
            <!-- <v-toolbar elevation="0">
				<v-btn icon color="primary" @click="$router.push('/')">
					<v-icon>mdi-home</v-icon>
				</v-btn>
				<v-toolbar-title class="font-weight-light">{{ $vuetify.lang.t('$vuetify.' + title) | capitalize }}</v-toolbar-title>
				<v-spacer></v-spacer>
			</v-toolbar> -->

			<v-data-table
				v-model="selected"
				:headers="headers"
				:items="items"
				item-key="id"
				show-select
				:loading="loading"
				:search="search"
				loading-text="Завантаження даних... зачекайте, будь ласка." 
			>
			<!-- :items-per-page="-1"
				hide-default-footer -->
				<template v-slot:top>
					<v-toolbar flat>
						<v-app-bar-nav-icon>
							<v-icon>{{ icon }}</v-icon>
						</v-app-bar-nav-icon>
						<v-toolbar-title>{{ $vuetify.lang.t(`$vuetify.${title}`) | capitalize }}</v-toolbar-title>
						<v-spacer></v-spacer>

						<v-spacer></v-spacer>
						<v-text-field
							v-model="search"
							append-icon="mdi-magnify"
							label="Пошук"
							dense
							single-line
							hide-details
							outlined
							rounded
						></v-text-field>
						<!-- Оновити -->
						<v-btn icon @click="onRefresh">
							<v-icon>mdi-refresh</v-icon>
						</v-btn>

						<!-- Пошук -->
						<!-- <v-btn icon @click="OnSearch">
							<v-icon>mdi-magnify</v-icon>
						</v-btn> -->

						<!-- Створити -->
						<!-- <z-detail
							activator-type="button"
							activator-outlined
							:activator-title="$vuetify.lang.t('$vuetify.create')"
							activator-tooltip-disabled
						></z-detail> -->

						<!-- <z-detail
							tooltip="create"
						>
							<template v-slot:activator="props">
								<v-btn icon fab v-bind="props.attrs" v-on="{ ...props.dialog, ...props.tooltip}">
									<v-icon>mdi-plus</v-icon>
								</v-btn>
							</template>
						</z-detail> -->
					</v-toolbar>
				</template>

				
				<template v-slot:[`item.active`]="{ item }">
					<v-tooltip bottom v-if="item.active">
						<template v-slot:activator="{ on, attrs }">
							<v-icon v-bind="attrs" v-on="on" color="success">mdi-circle</v-icon>
						</template>
						<span>{{ item.dateactive | formatDateTime }}</span>
					</v-tooltip>
				</template>

				<template v-slot:[`item.dateactive`]="{ item }">
					<span>{{ item.dateactive | formatDateTime }}</span>
				</template>

				<template v-slot:[`item.disabled`]="{ item }">
					<v-icon v-if="item.disabled" color="error">mdi-cancel</v-icon>
				</template>

				<template v-slot:[`item.actions`]="{ item }">
					<v-toolbar elevation="0" dense color="transparent">
						<z-detail v-if="permitionUpdate"
							:selected="item"
							@close="onClose"
							@update="edit(item, $event)"
						></z-detail>
						<!-- <z-detail
							mode="update"
							:selected="item"
							activator-type="icon"
							activator-icon="mdi-pencil"
							:activator-tooltip-text="$vuetify.lang.t('$vuetify.update')"
						></z-detail> -->
						<!-- <z-confirm-delete 
							:activator-tooltip-text="$vuetify.lang.t('$vuetify.delete')"
							@ok="onDelete(item)"
						></z-confirm-delete> -->

						<z-statistic :selected="item"></z-statistic>

						<v-menu offset-y ref="menu" v-if="permitionLock">
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon v-bind="attrs" v-on="on">
									<v-icon>mdi-dots-vertical</v-icon>
								</v-btn>
							</template>
							<v-list v-if="permitionLock">

								<z-detail v-if="permitionUpdate"
									:selected="item"
									@close="onClose"
									@update="edit(item, $event)"
								>
									<template v-slot:activator="props">
										<v-list-item v-bind="props.attrs" v-on="{ ...props.dialog, ...props.tooltip}">
											<v-list-item-icon>
												<v-icon>mdi-pencil</v-icon>
											</v-list-item-icon>
											<v-list-item-content>
												<v-list-item-title>Редагувати</v-list-item-title>
											</v-list-item-content>
										</v-list-item>
									</template>
								</z-detail>
								<z-confirm-delete @ok="onDelete(item)" activator-type="list-item">
								</z-confirm-delete>
								<!-- <v-list-item @click="onDelete(item)" v-if="permitionDelete">
									<v-list-item-icon>
										<v-icon>mdi-delete</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title>Видалити</v-list-item-title>
									</v-list-item-content>
								</v-list-item> -->

								<v-divider v-if="permitionLock"></v-divider>
								
								<v-list-item v-if="item.disabled" @click="onUnlock(item)">
									<v-list-item-icon>
										<v-icon>mdi-account-check</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title>Розблокувати</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-list-item v-else @click="onLock(item)">
									<v-list-item-icon>
										<v-icon>mdi-account-cancel</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title>Заблокувати</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								
								<v-divider v-if="permitionPasswordRenew"></v-divider>
								<v-list-item v-if="permitionPasswordRenew" @click="onPasswordRenew(item)">
									<v-list-item-icon>
										<v-icon>mdi-key-arrow-right</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title>Відновити пароль</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-toolbar>
				</template>
			</v-data-table>

			
		</v-container>

		<z-detail v-if="permitionCreate"
			tooltip="create"
			@create="add"
		>
			<template v-slot:activator="props">
				<v-fab-transition>
					<v-btn
						v-bind="props.attrs" v-on="{ ...props.dialog, ...props.tooltip}"
						color="primary"
						dark
						fixed
						bottom
						right
						fab
						class="mb-8 mr-8"
						@create="add"
					>
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</v-fab-transition>
			</template>
		</z-detail>

		<!-- <v-footer app padless>
			<v-card flat tile width="100%" class="text-center">
				<v-card-text>
					<v-pagination
						v-model="page"
						:length="pageCount"
						:total-visible="7"
						
					></v-pagination>
				</v-card-text>
			</v-card>
		</v-footer> -->

		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>

		<!-- <v-snackbar v-model="snackbar.visible">
			{{ snackbar.text }}
			<template v-slot:actions>
				<v-btn icon="mdi-window-close" @click="snackbar.visible = false"></v-btn>
			</template>
		</v-snackbar> -->
    </v-main>
</template>

<script>
import axios from 'axios'
import mixin from './mixin/main'
import { mapGetters, mapActions } from 'vuex'
import downloadExcel from "vue-json-excel"
//import zDetail from '@/views/details/Users'
import zDetail from '@/views/UsersDetail'
//import zConfirmDelete from '@/views/components/confirm-delete'
import zConfirmDelete from '@/views/components/dialogs/ConfirmDelete'
import zStatistic from '@/views/UsersStatistic'

export default {
	name: 'Users',
	mixins: [mixin],
	components: {
		downloadExcel,
		zDetail,
		zConfirmDelete,
		zStatistic,
	},
	data: () => ({
		title: 'users',
		icon: 'mdi-account',
		search: '',
		headers: [
			//{ text: 'ID', value: 'id', align: ' d-none' },
			{ text: 'ID', value: 'id', sortable: true},
			{ text: 'Назва', value: 'name', sortable: true},
			{ text: 'Логін', value: 'login', sortable: true},
			{ text: 'Активний', value: 'active', sortable: true},
			{ text: 'Дата входу', value: 'dateactive', sortable: true},
			{ text: 'Блокування', value: 'disabled', sortable: true},
			{ text: 'Дії', value: 'actions', sortable: false },
		],

		page: 1,
		pageCount: 2,
		itemsPerPage: 15,

		showMenu: false,

		/* snackbar: {
			visible: false,
			text: null
		}, */
	}),
	
	computed: {
		...mapGetters(['roles']),
		...mapGetters('Users', [ 'endpoint', 'items' ]),

		permitionCreate() {
			return this.roles.some(element => ['ADMIN', 'ORG_ADMIN'].includes(element))
		},
		permitionUpdate() {
			return this.roles.some(element => ['ADMIN', 'ORG_ADMIN'].includes(element))
		},
		permitionDelete() {
			return this.roles.some(element => ['ADMIN', 'ORG_ADMIN'].includes(element))
		},
		permitionLock() {
			return this.roles.some(element => ['ADMIN', 'ORG_ADMIN'].includes(element))
		},
		permitionPasswordRenew() {
			return this.roles.some(element => ['ADMIN', 'ORG_ADMIN'].includes(element))
		},
	},

	methods: {
		...mapActions(['showMessage']),
		...mapActions('Users', [ 'setItem', 'setItems', 'createItem', 'updateItem', 'deleteItem' ]),

		onClose() {
			//console.log(this.$options.name, 'onClose', 'refs', this.$refs)
			//console.log(this.$options.name, 'onClose', 'refs.menu', this.$refs.menu)
			//console.log(this.$options.name, 'onClose', 'isActive', this.$refs.menu.isActive)
			//this.$refs.menu.isActive = false
			//this.$refs.menu.deactivate()
			//this.showMenu = false
		},

		onRefresh() {
			this.loading = true
			var that = this
			axios.get(`/users`).then(response => {
				//console.log(this.$options.name, 'load','response.data', response.data)
				that.setItems()
				that.setItems(response.data)
			}, error => {
				//console.log(error)
				that.showMessage(error)
			}).finally(() => (this.loading = false));
		},

		onLock(item) {
			if(item) {
				this.loading = true
				var that = this
				axios.put(`/users/${item.id}/lock`).then(response => {
					//console.log(that.$options.name, 'onLock','response.data', response.data)
					that.edit(item, response.data)
				}, error => {
					console.log(error)
					that.showMessage(error)
				}).finally(() => (this.loading = false));
			}
		},

		onUnlock(item) {
			if(item) {
				this.loading = true
				var that = this
				axios.put(`/users/${item.id}/unlock`).then(response => {
					//console.log(that.$options.name, 'onLock','response.data', response.data)
					that.edit(item, response.data)
				}, error => {
					console.log(error)
					that.showMessage(error)
				}).finally(() => (this.loading = false));
			}
		},

		onDelete(item) {
			if(item) {
				this.loading = true
				var that = this
				axios.delete(`/users/${item.id}`).then(response => {
					//console.log(that.$options.name, 'onLock','response.data', response.data)
					//that.edit(item, response.data)
					const index = that.items.indexOf(item)
					that.items.splice(index, 1);
				}, error => {
					console.log(error)
					that.showMessage(error)
				}).finally(() => (this.loading = false));
			}
		},

		onPasswordRenew(item) {
			if(item) {
				this.loading = true
				var that = this
				axios.put(`/users/${item.id}/password/renew`).then(response => {
					//console.log(that.$options.name, 'onLock','response.data', response.data)
					this.showMessage({
						type: 'success',
						text: "Пароль відновлено. Інформацію відправлено на електронну пошту користувача",
					})
				}, error => {
					console.log(error)
					that.showMessage(error)
				}).finally(() => (this.loading = false));
			}
		},
		
	},
}
</script>