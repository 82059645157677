import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFabTransition } from 'vuetify/lib/components/transitions';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","show-select":"","search":_vm.searchText,"loading":_vm.loading,"loading-text":"Завантаження даних... зачекайте, будь ласка.","sort-by":"id","sort-desc":"","items-per-page":_vm.filter.itemsPerPage,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":""}},[_c(VAvatar,[_c(VIcon,[_vm._v(_vm._s(_vm.icon))])],1),_c(VToolbarTitle,[_vm._v(" "+_vm._s(_vm._f("capitalize")((_vm.title.plural in _vm.$vuetify.lang.locales.en ? _vm.$vuetify.lang.t(("$vuetify." + (_vm.title.plural))) : _vm.title.plural )))+" ")]),_c(VSpacer),_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-text-box-plus")])],1)]}}])},[_c(VList,[_c('z-document-edit-pricelist',{scopedSlots:_vm._u([{key:"activator",fn:function(props){return [_c(VListItem,_vm._g(_vm._b({},'v-list-item',props.attrs,false),Object.assign({}, props.dialog)),[_c(VListItemIcon,[_c(VIcon,{domProps:{"textContent":_vm._s(props.icon)}})],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(props.title))])],1)],1)]}}])})],1)],1),_c('z-filter',{on:{"ok":_vm.onSearch}}),_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Пошук","dense":"","single-line":"","hide-details":"","outlined":"","rounded":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onRefresh.apply(null, arguments)}}},[_c(VIcon,[_vm._v("mdi-refresh")])],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VToolbar,{attrs:{"flat":"","dense":"","color":"transparent"}},[_c('z-edit',{attrs:{"selected":item}}),_c(_vm.componentDialog(item),{tag:"component",attrs:{"selected":item}})],1)]}},_vm._l((_vm.headers),function(item,index){return {key:("header." + (item.value)),fn:function(ref){return [_c('span',{key:index},[_vm._v(_vm._s(_vm._f("capitalize")((item.text in _vm.$vuetify.lang.locales.en ? _vm.$vuetify.lang.t('$vuetify.' + item.text) : item.text ))))])]}}}),{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDateTime")(item.date)))])]}},{key:"item.datebegin",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDateTime")(item.datebegin)))])]}},{key:"item.dateend",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDateTime")(item.dateend)))])]}},{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c(VIcon,[_vm._v(_vm._s(item.icon))])]}},{key:"item.status.name",fn:function(ref){
var item = ref.item;
return [_c('z-document-status-change',{model:{value:(item),callback:function ($$v) {item=$$v},expression:"item"}})]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('z-edit',{attrs:{"tooltip":"create"},scopedSlots:_vm._u([{key:"activator",fn:function(props){return [_c(VFabTransition,[_c(VBtn,_vm._g(_vm._b({staticClass:"mb-8 mr-8",attrs:{"color":"primary","dark":"","fixed":"","bottom":"","right":"","fab":""}},'v-btn',props.attrs,false),Object.assign({}, props.dialog, props.tooltip)),[_c(VIcon,[_vm._v("mdi-plus")])],1)],1)]}}])}),_c(VFooter,{attrs:{"app":"","padless":""}},[_c(VToolbar,{attrs:{"flat":""}},[_c(VSpacer),_c(VPagination,{attrs:{"length":_vm.pageCount,"total-visible":7},model:{value:(_vm.filter.page),callback:function ($$v) {_vm.$set(_vm.filter, "page", $$v)},expression:"filter.page"}}),_c(VSpacer)],1)],1),_c(VOverlay,{attrs:{"value":_vm.loading}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }