<template>
	<v-dialog  
	 	v-model="dialog"
		transition="dialog-bottom-transition"
		width="800"
		max-width="800"
		scrollable
	>
		<template v-slot:activator="{  on, attrs }">
			<slot name="activator" v-bind="attrs" v-on="on">
				<v-btn icon v-bind="attrs" v-on="on">
					<v-icon>{{ icon }}</v-icon>
				</v-btn>
			</slot>
		</template>

		<v-card>
			<!-- Ttoolbar -->
			<v-toolbar flat dark color="primary" style="flex: 0 1 auto;">
				<v-avatar>
					<v-icon>{{ icon }}</v-icon>
				</v-avatar>
				<v-toolbar-title>{{ $vuetify.lang.t(`$vuetify.${title}`) | capitalize }}</v-toolbar-title>
				
				<v-spacer></v-spacer>
				
				<!-- <v-btn icon @click="fullscreen = !fullscreen" >
					<v-icon v-if="fullscreen">mdi-arrow-collapse</v-icon>
					<v-icon v-else>mdi-arrow-expand</v-icon>
				</v-btn> -->

				<v-btn icon @click="dialog=false" >
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>

			<!-- <v-card-text style="height: 380px;"> -->
			<v-card-text>
				<v-form ref="form">
					<v-row>
						<v-col cols="12">
							<v-file-input 
								v-model="file"
								accept=".xlsx"
								@change="OnChangeFile"
								:rules="[rules.required]"
							></v-file-input>
						</v-col>

						<!-- <v-divider></v-divider> -->
						<!-- <v-col cols="12">
							<div class="text-body-2">Область даних</div>
						</v-col> -->

						<v-col cols="12">
							<v-combobox
								v-model="item.sheet"
								:items="SheetNames"
								label="Аркуші"
								:rules="[rules.required]"
							></v-combobox>
						</v-col>
						

						<v-col cols="12" md="6">
							<v-text-field
								label="Номер рядка початку області даних"
								v-model="item.row.start"
								type="number"
								min="1"
								:rules="[rules.required]"
							></v-text-field>
						</v-col>
						<v-col cols="12" md="6">
							<v-text-field
								label="Номер рядка кінця області даних"
								v-model="item.row.end"
								clearable
								type="number"
								min="1"
							></v-text-field>
						</v-col>

						<v-col cols="12" v-for="(element, index) in item.columns" :key="index">
							<v-combobox
								v-model="element.column"
								:items="ColumnNames"
								:label="element.label"
								:rules="[rules.required]"
							></v-combobox>
						</v-col>

						<!-- <v-col cols="12">
							<v-combobox
								v-model="item.column.code"
								:items="ColumnNames"
								label="Код Моріон"
							></v-combobox>
						</v-col>

						<v-col cols="12">
							<v-combobox
								v-model="item.column.name"
								:items="ColumnNames"
								label="Назва"
							></v-combobox>
						</v-col>

						<v-col cols="12">
							<v-combobox
								v-model="item.column.price"
								:items="ColumnNames"
								label="Сума"
							>
								<template v-slot:append>
									<v-menu offset-y>
										<template v-slot:activator="{ on, attrs }">
											<v-btn text v-bind="attrs" v-on="on" :color="item.column.currency.color">
												{{ item.column.currency.code }}
											</v-btn>
										</template>
										<v-list>
											<v-list-item v-for="(itm, index) in currencies" :key="index" @click="item.column.currency=itm">
												<v-list-item-icon>
													<v-icon v-text="itm.icon" :color="itm.color"></v-icon>
												</v-list-item-icon>
												<v-list-item-title>{{ itm.name }}</v-list-item-title>
											</v-list-item>
										</v-list>
									</v-menu>
								</template>
							</v-combobox>
						</v-col> -->
					</v-row>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn text @click.native="dialog=false">{{ $vuetify.lang.t('$vuetify.cancel') }}</v-btn>
				<!-- <v-btn color="success" dark @click.native="onOK" :disabled="loading">{{ $vuetify.lang.t('$vuetify.ok') }}</v-btn> -->
				<v-dialog v-model="mode.dialog" width="500">
					<template v-slot:activator="{ on, attrs }">
						<v-btn color="success" dark v-bind="attrs" v-on="on">{{ $vuetify.lang.t('$vuetify.ok') }}</v-btn>
					</template>

					<v-card>
						<v-card-title class="text-h5 grey lighten-2">Режим додавання даних до таблиці</v-card-title>
						<v-card-text>
							<v-radio-group v-model="mode.value" column>
								<v-radio label="Замінити існуючі дані таблиці" value="replace" ></v-radio>
								<v-radio label="Додати до даних таблиці" value="add"></v-radio>
							</v-radio-group>
						</v-card-text>

						<v-divider></v-divider>

						<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn text @click.native="mode.dialog=false">{{ $vuetify.lang.t('$vuetify.cancel') }}</v-btn>
						<v-btn color="success" dark @click.native="onOK" :disabled="loading">{{ $vuetify.lang.t('$vuetify.ok') }}</v-btn>
						<!-- <v-btn
							color="primary"
							text
							@click="dialog = false"
						>
							I accept
						</v-btn> -->
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-card-actions>
		</v-card>

		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
	</v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as XLSX from "xlsx"; //https://www.npmjs.com/package/xlsx
//import mixin from './details/mixin/main'

export default {
	name: 'ExcelImport',
	//mixins: [mixin],
	components: {
		
	},
	data: () => ({
		dialog: false,
		loading: false,
		file: null,
		workbook: null,
		
		item: {
			sheet: null,
			row: {
				start: 2,
				end: 10,
				header: 1,
			},
			currency: {
				code: 'UAH'
			},
			columns: [
				{ code: 'code', label: 'Код Моріон', column: {value: 8, title: "H", text: "8 : H"}},
				{ code: 'name', label: 'Назва', column: {value: 2, title: "B", text: "2 : B"}},
				{ code: 'price', label: 'Ціна за одиницю', column: {value: 6, title: "F", text: "6 : F"}},
			],
		},

		mode: { 
			dialog: false,
			value: 'replace', // replace | add
		}
	}),

	props: {
		icon:   		{ type: String, default: 'mdi-import' },
		title:   		{ type: String, default: 'import' },
	},

	computed: {
		//...mapGetters('CurrencyType', { 'currencies': 'items' } ),
		
		rules() {
			return {
				required: (value) => !!value || 'Поле обов\'язкове для заповнення',
				mustBeSelected: (value) => typeof(value) === "undefined" || value === null || value?.id>0 || this.$vuetify.locale.t('$vuetify.rule.mustBeSelected'),
			}
		},

		SheetNames() {
			return this.workbook ? this.workbook.SheetNames: []
		},

		ColumnNames() {
			let items = []
			for (let i = 1; i <= 26*2; i++) {
				let ColumnTitle = this.ColumnNumberToTitle(i)
				items.push({
					value: i,
					title: ColumnTitle,
					text: `${i} : ${ColumnTitle}`
				})
			}

			return items
		},
	},

	methods: {
		/**
		 * Converting Excel Column Numbers to Alphabetic Titles
		 * https://medium.com/thecodingway/converting-excel-column-numbers-to-alphabetic-titles-a-javascript-approach-in-simple-9-steps-6bfe91f7364b
		 */
		ColumnNumberToTitle(columnNumber) {
			const numberToCharacterMap = {};
			for (let i = 1; i <= 26; i++) {
				const character = String.fromCharCode(i + 64);
				numberToCharacterMap[i] = character;
			}
			let resp = "";
			while(columnNumber > 0) {
				let remainder = columnNumber % 26;
				if (remainder === 0) {
					resp = 'Z' + resp;
					columnNumber = Math.floor(columnNumber / 26) - 1;
				} else {
					resp = numberToCharacterMap[remainder] + resp;
					columnNumber = Math.floor(columnNumber / 26);
				}
			}
			return resp;
		},

		OnChangeFile(files) {
			let file = Array.isArray(files) ? files[0] : files;
			if (file) {
				const reader = new FileReader();

				reader.onload = (e) => {
					//console.log(this.$options.name, 'changeImport', 'XLSX', XLSX)

					const bstr = e.target.result;
					//const wb = XLSX.read(bstr, { type: 'binary' });
					this.workbook = XLSX.read(bstr, { type: 'binary' });

					//console.log(this.$options.name, 'OnChangeFile', 'workbook', this.workbook)

					this.item.sheet = this.workbook.SheetNames[0];
				}

				reader.readAsBinaryString(this.file);
			}
		},

		onOK() {
			//console.log(this.$options.name, 'onOK', 'item', this.item)

			this.mode.dialog = false

			if (this.$refs.form.validate()) {
				let result = []
				if(this.workbook) {
					if(this.item.sheet) {
						const worksheet = this.workbook.Sheets[this.item.sheet]
						if(worksheet) {
							let data_array 	= XLSX.utils.sheet_to_json(worksheet, { header: 1 });
							//const data_array 	= XLSX.utils.sheet_to_json(worksheet, { header: this.item.row.header });
							//const data_array 	= XLSX.utils.sheet_to_json(worksheet);

							//console.log(this.$options.name, 'onOK', 'data_array', data_array)

							if(this.item.row.hasOwnProperty('end') && this.item.row.end > 0) {
								//console.log(this.$options.name, 'onOK', 'row.end', this.item.row.end)
								data_array = data_array.slice(0, this.item.row.end);
							}
							data_array.forEach((row, r) => {
								if(r >= this.item.row.start-1) {
									let obj = {
										r: r+1
									}

									this.item.columns.forEach((element, c) => {
										obj[element.code] = row[element.column.value - 1]
									})

									result.push(obj)
								}
							})
						}
					}
				}

				//console.log(this.$options.name, 'onOK', 'result', result)

				this.dialog = false
				this.$emit(this.mode.value, result)

				//this.$emit('ok', result)
			}
		},
	},
}
</script>