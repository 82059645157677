<template>
	<v-dialog  
	 	v-model="dialog"
	 	fullscreen
		scrollable
		transition_="dialog-bottom-transition"
	>
		<template v-slot:activator="{ on: dialog, attrs }">
			<v-tooltip bottom>
				<template v-slot:activator="{ on: tooltip, attrs }">
					<slot name="activator" :attrs="attrs" :dialog="dialog" :tooltip="tooltip">
						<v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...dialog }">
							<v-icon>mdi-file-edit</v-icon>
						</v-btn>
					</slot>
				</template>
				<span>{{ $vuetify.lang.t(`$vuetify.${tooltip}`) | capitalize }}</span>
			</v-tooltip>
		</template>

		<v-card color="#EEEEEE">
			<v-toolbar flat color="#f5f5f5" style="flex: 0 1 auto;">
				<v-avatar>
					<v-icon>{{ icon }}</v-icon>
				</v-avatar>
				<v-toolbar-title v-if="item.id">{{ title.single }} № {{ item.id }}</v-toolbar-title>
				<v-toolbar-title v-else>{{ title.single }}</v-toolbar-title>
				
				<v-spacer></v-spacer>

				<v-btn icon @click="onSave" >
					<v-icon>mdi-content-save</v-icon>
				</v-btn>

				<v-btn icon @click="dialog=false" >
					<v-icon>mdi-close</v-icon>
				</v-btn>

				<template v-slot:extension>
					<v-tabs v-model="tab" align-with-title>
						<v-tabs-slider color="yellow"></v-tabs-slider>
						<v-tab v-for="item in tabs" :key="item">
							{{ item }}
						</v-tab>
					</v-tabs>
				</template>
			</v-toolbar>

			<v-card-text>
				<v-tabs-items v-model="tab">
					<v-tab-item>
						<v-container>
							<v-sheet color="white" elevation="1">
								<v-card flat>
									<v-card-text>
										<v-container>
											<v-form ref="form">
												<v-row dense>
													<v-col cols="12" md="8">
														<v-card flat>
															<v-card-text>
																<v-text-field
																	v-model="item.name"
																	label="Назва"
																	:rules="[rules.required]"
																></v-text-field>
																<v-combobox
																	v-model="item.type"
																	:items="types"
																	label="Тип"
																	item-text="name"
																	item-value="id"
																></v-combobox>
																<v-textarea
																	v-model="item.description"
																	label="Опис"
																	rows="1"
																></v-textarea>
																<v-text-field
																	v-model="item.code"
																	label="Код"
																></v-text-field>
															</v-card-text>
														</v-card>
													</v-col>
													<!-- <v-col cols="12" md="4">
														<v-text-field
															v-model="item.code"
															label="Код"
														></v-text-field>
													</v-col> -->
													<v-col cols="12" md="4">
														<v-card flat>
															<v-card-text>
																<v-combobox
																	v-model="item.status"
																	:items="statuses"
																	label="Статус"
																	item-text="name"
																	item-value="id"
																></v-combobox>
																<z-date 
																	v-model="item.date"
																	label="Дата"
																></z-date>
																<z-date
																	v-model="item.datebegin"
																	label="Дата початку періоду"
																	clirable
																></z-date>
																<z-date
																	v-model="item.dateend"
																	label="Дата завершення періоду"
																	clirable
																></z-date>
															</v-card-text>
														</v-card>
													</v-col>
												</v-row>
												<!-- <v-row dense>
													<v-col cols="12" sm="6" md="4">
														<v-text-field
															v-model="item.name"
															label="Назва"
															:rules="[rules.required]"
														></v-text-field>
													</v-col>
												</v-row> -->
												<!-- <v-row dense>
													<v-col cols="12" sm="6" md="4">
														
													</v-col>
												</v-row> -->
												<!-- <v-row dense>
													<v-col cols="12" sm="6" md="4">
														<z-date 
															v-model="item.date"
															label="Дата"
														></z-date>
													</v-col>
												</v-row>
												<v-row dense>
													<v-col cols="12" sm="6" md="4">
														<z-date
															v-model="item.datebegin"
															label="Дата початку періоду"
														></z-date>
													</v-col>
													<v-col cols="12" sm="6" md="4">
														<z-date
															v-model="item.dateend"
															label="Дата завершення періоду"
														></z-date>
													</v-col>
												</v-row> -->
												<!-- <v-row dense>
													<v-col cols="12" sm="6" md="4">
														<v-combobox
															v-model="item.status"
															:items="statuses"
															label="Статус"
															item-text="name"
															item-value="id"
														></v-combobox>
													</v-col>
												</v-row> -->
												<!-- <v-row dense>
													<v-col cols="12">
														<v-textarea
															v-model="item.description"
															label="Опис"
														></v-textarea>
													</v-col>
												</v-row> -->
											</v-form>
										</v-container>
									</v-card-text>
								</v-card>
							</v-sheet>
						</v-container>
					</v-tab-item>

					<!-- Members -->
					<v-tab-item>
						<v-container>
							<v-sheet color="white" elevation="1">
								<v-card flat>
									<v-card-text>
										<v-container>
											<v-data-table
												:headers="headersMember"
												:items="item.members"
												item-key="id"
												loading-text="Завантаження даних... зачекайте, будь ласка."
												hide-default-footer
											>
												<template v-slot:top>
													<v-toolbar flat>
														<v-spacer></v-spacer>
														<z-document-member-edit tooltip="create" @ok="item.members.push($event)">
															<template v-slot:activator="props">
																<v-btn icon v-bind="props.attrs" v-on="{ ...props.dialog, ...props.tooltip }">
																	<v-icon>mdi-plus</v-icon>
																</v-btn>
															</template>
														</z-document-member-edit>
													</v-toolbar>
												</template>

												<template v-slot:[`item.actions`]="{ item, index }">
													<v-toolbar flat dense color="transparent">
														<z-confirm-delete 
															:activator-tooltip-text="$vuetify.lang.t('$vuetify.delete')"
															@ok="document.members.splice(index, 1)"
														></z-confirm-delete>
														<z-document-member-edit 
															:selected="item"
															@ok="document.members.splice(index, 1, $event)"
														></z-document-member-edit>
													</v-toolbar>
												</template>
											</v-data-table>
										</v-container>
									</v-card-text>
								</v-card>
							</v-sheet>
						</v-container>

						<!-- <z-document-member-edit tooltip="create" @ok="item.members.push($event)">
							<template v-slot:activator="props">
								<v-btn
									v-bind="props.attrs" v-on="{ ...props.dialog, ...props.tooltip}"
									color="indigo" dark bottom right fab fixed
									class="mb-16"
								>
									<v-icon>mdi-plus</v-icon>
								</v-btn>
							</template>
						</z-document-member-edit> -->
					</v-tab-item>

					<!-- Objects -->
					<v-tab-item>
						<v-container>
							<v-sheet color="white" elevation="1">
								<v-card flat>
									<v-card-text>
										<v-container>
											<v-data-table
												:headers="headersObject"
												:items="item.objects"
												item-key="id"
												loading-text="Завантаження даних... зачекайте, будь ласка."
												hide-default-footer
											>
												<template v-slot:top>
													<v-toolbar flat>
														<v-spacer></v-spacer>
														<z-document-object-edit @ok="item.objects.push($event)" tooltip="create">
															<template v-slot:activator="props">
																<v-btn icon v-bind="props.attrs" v-on="{ ...props.dialog, ...props.tooltip }">
																	<v-icon>mdi-plus</v-icon>
																</v-btn>
															</template>
														</z-document-object-edit>
													</v-toolbar>
												</template>

												<template v-slot:[`item.actions`]="{ item, index }">
													<v-toolbar flat dense color="transparent">
														<z-confirm-delete 
															:activator-tooltip-text="$vuetify.lang.t('$vuetify.delete')"
															@ok="document.objects.splice(index, 1)"
														></z-confirm-delete>
														<z-document-object-edit 
															:selected="item"
															@ok="document.objects.splice(index, 1, $event)"
														></z-document-object-edit>
													</v-toolbar>
												</template>
											</v-data-table>
										</v-container>
									</v-card-text>
								</v-card>
							</v-sheet>
						</v-container>
					</v-tab-item>

					<!-- Properties -->
					<v-tab-item>
						<v-container>
							<v-sheet color="white" elevation="1">
								<v-card flat>
									<v-card-text>
										<v-container>
											<v-data-table
												:headers="headersProperty"
												:items="item.properties"
												item-key="id"
												loading-text="Завантаження даних... зачекайте, будь ласка."
												hide-default-footer
											>
												<template v-slot:top>
													<v-toolbar flat>
														<v-spacer></v-spacer>
														<z-document-property-edit @ok="item.properties.push($event)" tooltip="create">
															<template v-slot:activator="props">
																<v-btn icon v-bind="props.attrs" v-on="{ ...props.dialog, ...props.tooltip }">
																	<v-icon>mdi-plus</v-icon>
																</v-btn>
															</template>
														</z-document-property-edit>
													</v-toolbar>
												</template>

												<template v-slot:[`item.date`]="{ item }">
													<span>{{ item.date | formatDateTime }}</span>
												</template>
												
												<template v-slot:[`item.actions`]="{ item, index }">
													<v-toolbar flat dense color="transparent">
														<z-confirm-delete 
															:activator-tooltip-text="$vuetify.lang.t('$vuetify.delete')"
															@ok="document.properties.splice(index, 1)"
														></z-confirm-delete>
														<z-document-property-edit 
															:selected="item"
															@ok="document.properties.splice(index, 1, $event)"
														></z-document-property-edit>
													</v-toolbar>
												</template>
											</v-data-table>
										</v-container>
									</v-card-text>
								</v-card>
							</v-sheet>
						</v-container>
					</v-tab-item>

					<!-- Statuses -->
					<v-tab-item>
						<v-container>
							<v-sheet color="white" elevation="1">
								<v-card flat>
									<v-card-text>
										<v-container>
											<v-data-table
												:headers="headersStatus"
												:items="item.statuses"
												item-key="id"
												loading-text="Завантаження даних... зачекайте, будь ласка."
												hide-default-footer
											>
												<template v-slot:top>
													<v-toolbar flat>
														<v-spacer></v-spacer>
														<z-document-status-edit @ok="item.statuses.push($event)" tooltip="create">
															<template v-slot:activator="props">
																<v-btn icon v-bind="props.attrs" v-on="{ ...props.dialog, ...props.tooltip }">
																	<v-icon>mdi-plus</v-icon>
																</v-btn>
															</template>
														</z-document-status-edit>
													</v-toolbar>
												</template>

												<template v-slot:[`item.date`]="{ item }">
													<span>{{ item.date | formatDateTime }}</span>
												</template>

												<template v-slot:[`item.actions`]="{ item, index }">
													<v-toolbar flat dense color="transparent">
														<z-confirm-delete 
															:activator-tooltip-text="$vuetify.lang.t('$vuetify.delete')"
															@ok="document.statuses.splice(index, 1)"
														></z-confirm-delete>
														<!-- <z-document-status-edit 
															:selected="item"
															@ok="document.statuses.splice(index, 1, $event)"
														></z-document-status-edit> -->
													</v-toolbar>
												</template>
											</v-data-table>
										</v-container>
									</v-card-text>
								</v-card>
							</v-sheet>
						</v-container>
					</v-tab-item>

				</v-tabs-items>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>

				<z-document-member-edit v-if="tab==1"
					@ok="item.members.push($event)"
				>
					<template v-slot:activator="props">
						<v-btn v-bind="props.attrs" v-on="{ ...props.dialog}" color="indigo" outlined>
							<v-icon left> mdi-plus </v-icon>
							Додати
						</v-btn>
					</template>
				</z-document-member-edit>

				<z-document-object-edit v-if="tab==2"
					@ok="item.members.push($event)"
				>
					<template v-slot:activator="props">
						<v-btn v-bind="props.attrs" v-on="{ ...props.dialog}" color="indigo" outlined>
							<v-icon left> mdi-plus </v-icon>
							Додати
						</v-btn>
					</template>
				</z-document-object-edit>

				<z-document-property-edit v-if="tab==3"
					@ok="item.members.push($event)"
				>
					<template v-slot:activator="props">
						<v-btn v-bind="props.attrs" v-on="{ ...props.dialog}" color="indigo" outlined>
							<v-icon left> mdi-plus </v-icon>
							Додати
						</v-btn>
					</template>
				</z-document-property-edit>

				<z-document-status-edit v-if="tab==4"
					@ok="item.members.push($event)"
				>
					<template v-slot:activator="props">
						<v-btn v-bind="props.attrs" v-on="{ ...props.dialog}" color="indigo" outlined>
							<v-icon left> mdi-plus </v-icon>
							Додати
						</v-btn>
					</template>
				</z-document-status-edit>

				<v-spacer></v-spacer>
				<!-- <v-btn text @click.native="onCancel">{{ $vuetify.lang.t('$vuetify.cancel') }}</v-btn> -->
				<v-btn color="success" dark @click.native="onOK" :disabled="loading">{{ $vuetify.lang.t('$vuetify.ok') }}</v-btn>
			</v-card-actions>
		</v-card>

		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>

		<v-snackbar v-model="snack.visible"
			:timeout="snack.timeout"
			:color="snack.color"
		>
			<v-list-item three-line v-if="snack.subtitle">
				<v-list-item-content>
					<v-list-item-title>{{ snack.title }}</v-list-item-title>
					<v-list-item-subtitle>{{ snack.subtitle }}</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
			<div v-else>{{ snack.title }}</div>
			<template v-slot:action="{ attrs }">
				<v-btn icon v-bind="attrs" @click="snack.visible = false">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
	</v-dialog>
</template>

<script>
import mixin from '@/views/mixin/edit'
import { mapGetters, mapActions } from 'vuex'
import zDate from '@/views/components/control/Date'
import zConfirmDelete from '@/views/components/confirm-delete'
import zDocumentMemberEdit from '@/views/DocumentMemberEdit'
import zDocumentObjectEdit from '@/views/DocumentObjectEdit'
import zDocumentPropertyEdit from '@/views/DocumentPropertyEdit'
import zDocumentStatusEdit from '@/views/DocumentStatusEdit'


export default {
	name: 'DocumentEdit',
	description: 'Форма для редагування інформації оцінки',
	mixins: [mixin],
	components: {
		zDate,
		zConfirmDelete,
		zDocumentMemberEdit,
		zDocumentObjectEdit,
		zDocumentPropertyEdit,
		zDocumentStatusEdit,
	},
	data: () => ({
		
	}),

	computed: {
		...mapGetters('Document', [ 'icon', 'title', 'item' ]),
		...mapGetters('DocumentType', { 'types': 'items' }),
		...mapGetters('DocumentStatusType', { 'statuses': 'items' }),
		...mapGetters('DocumentMember', { 'iconMember':'icon', 'titleMember': 'title', 'headersMember': 'headers' }),
		...mapGetters('DocumentObject', { 'iconObject':'icon', 'titleObject': 'title', 'headersObject': 'headers' }),
		...mapGetters('DocumentProperty', { 'iconProperty':'icon', 'titleProperty': 'title', 'headersProperty': 'headers' }),
		...mapGetters('DocumentStatus', { 'iconStatus':'icon', 'titleStatus': 'title', 'headersStatus': 'headers' }),

		tabs() {
			//return ['Головна', this.titleMember.plural, this.titleObject.plural, this.titleProperty.plural, this.titleStatus.plural ]
			return ['Головна', 'Учасники', "Об'єкти", 'Властивості', 'Статуси' ]
		},

		document() {
			return this.item ? this.item: {};
		},

		/* estimate() {
			return this.item ? this.item: {};
		}, */
	},

	created() {
		//console.log(this.$options.name, 'created')
		this.loadDocumentType()
		this.loadDocumentStatusType()
	},

	methods: {
		...mapActions('Document', [ 'find', 'create', 'update', 'setItem' ]),
		...mapActions('DocumentType', { 'loadDocumentType': 'load' }),
		...mapActions('DocumentStatusType', { 'loadDocumentStatusType': 'load' }),

		init() {
			this.item.type = this.types[0]
			this.item.status = this.statuses[0]
		},

		/* sumField(key) {
			return this.item.objects.reduce((a, b) => a + (b[key] || 0), 0)
		}, */
	},
}
</script>

<style scoped>
	.theme--dark.v-tabs-items {
		background-color: transparent !important;
	}
	.theme--light.v-tabs-items {
		background-color: transparent !important;
	}
</style>