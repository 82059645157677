export default {
    namespaced: true,
    state: {
		params: {
            type: { code: 'metodica' },
		    number: null,
			vin: null,
			mileage: [],
			engineVolume: [],

            /* year: null,
            odometer: null,
            racenorm: null,
            cd: null,
            marketregion: null, */
		},

        item: {
            percentiles: [],
            adverts: [],
            registrations: [],
            adjustment: [],
		    conclusion: [],
        },

        //Dictionaries
		brands: [],
		models: [],
		generations: [],
		modifications: [],
		bodystyles: [],
		drivers: [],
		gearboxes: [],
		options: [],
		cities: [],

		vehicle: [],
		market: [],
		percentiles: {},
		offers: [],
    },
    getters: {
		params(state) {
			return state.params
		},
		
		brands(state) {
			return state.brands
		},
		models(state) {
			return state.models
		},
		generations(state) {
			return state.generations
		},
		modifications(state) {
			return state.modifications
		},
		bodystyles(state) {
			return state.bodystyles
		},
		drivers(state) {
			return state.drivers
		},
		gearboxes(state) {
			return state.gearboxes
		},
		options(state) {
			return state.options
		},
		cities(state) {
			return state.cities
		},
		
		isset(state) {
			return state.params.number 
				|| state.params.vin 
				|| state.params.brand
				|| state.params.model
				//|| state.params.engine
				//|| state.params.gearbox
		},
		vehicle(state) {
			return state.vehicle
		},
		market(state) {
			return state.market
		},
		percentiles(state) {
			return state.percentiles
		},
		offers(state) {
			return state.offers
		},
    },
    mutations: {
		params (state, data) {
			state.params = data
		},

		brands (state, data) {
			state.brands = data?data:[]
		},
		models (state, data) {
			state.models = data?data:[]
		},
		generations (state, data) {
			state.generations = data?data:[]
		},
		modifications (state, data) {
			state.modifications = data?data:[]
		},
		bodystyles (state, data) {
			state.bodystyles = data?data:[]
		},
		drivers (state, data) {
			state.drivers = data?data:[]
		},
		gearboxes (state, data) {
			state.gearboxes = data?data:[]
		},
		options (state, data) {
			state.options = data?data:[]
		},
		cities (state, data) {
			state.cities = data?data:[]
		},

		vehicle (state, data) {
			if(data) {
				state.vehicle = []
				for (let key in data) {
					state.vehicle.push(data[key])
				}
			} else {
				state.vehicle = []
			}
			//state.vehicle = data?data:[]
		},
		market (state, data) {
			state.market = data?data:[]
		},
		percentiles (state, data) {
			state.percentiles = data?data:{}
		},
		offers (state, data) {
			let arr = data.slice().sort(function(a, b) {
				return a.price - b.price;
			})

			state.offers = arr?arr:[]
		},
    },
    actions: {
		clear (context) {
			let d = new Date()
			context.commit('params', {
                type: { code: 'metodica' },
                number: null,
			    vin: null,
				mileage: [],
				engineVolume: [],

                /* year: null,
                odometer: null,
                racenorm: null,
                cd: null,
                marketregion: null, */
			})

            context.commit('item', {
				percentiles: [],
                adverts: [],
                registrations: [],
                adjustment: [],
		        conclusion: [],
			})

			context.commit('vehicle', [])
			context.commit('market', [])
			context.commit('percentiles', [])
		},

		setBrands (context, data) {
			context.commit('brands', data)
		},
		setModels (context, data) {
			context.commit('models', data)
		},
		setGenerations (context, data) {
			context.commit('generations', data)
		},
		setModifications (context, data) {
			context.commit('modifications', data)
		},
		setBodystyles (context, data) {
			context.commit('bodystyles', data)
		},
		setDrivers (context, data) {
			context.commit('drivers', data)
		},
		setGearboxes (context, data) {
			context.commit('gearboxes', data)
		},
		setOptions (context, data) {
			context.commit('options', data)
		},
		setCities (context, data) {
			context.commit('cities', data)
		},
    },
}

/*
{
    "vehicle": {
        "category": {
            "code": "category",
            "name": "Категорія",
            "value": "Легкові автомобілі"
        },
        "brand": {
            "code": "brand",
            "name": "Марка",
            "value": "Ford",
            "description": "FORD MUSTANG"
        },
        "model": {
            "code": "model",
            "name": "Модель",
            "value": "Mustang",
            "description": "MUSTANG"
        },
        "year": {
            "code": "year",
            "name": "Рік виробництва",
            "value": "2016"
        },
        "body": {
            "code": "body",
            "name": "Тип кузова",
            "value": "КУПЕ-B"
        },
        "engine": {
            "code": "engine",
            "name": "Тип двигуна",
            "value": "Бензиновий"
        },
        "capacity": {
            "code": "capacity",
            "name": "Об'єм двигуна",
            "value": "2264"
        },
        "color": {
            "code": "color",
            "name": "Колір",
            "value": "БІЛИЙ"
        },
        "purpose": {
            "code": "purpose",
            "name": "Призначення",
            "value": "ЗАГАЛЬНИЙ"
        },
        "own_weight": {
            "code": "own_weight",
            "name": "Власна вага",
            "value": "1540"
        },
        "total_weight": {
            "code": "total_weight",
            "name": "Загальна вага",
            "value": "2041"
        },
        "regnum": {
            "code": "regnum",
            "name": "Державний номер",
            "value": "АВ9999ІТ"
        },
        "vin": {
            "code": "vin",
            "name": "VIN-код",
            "value": ""
        },
        "inn": {
            "code": "inn",
            "name": "ІНН власника",
            "value": "2656019963"
        },
        "birthday": {
            "code": "birthday",
            "name": "День народження власника",
            "value": "1972-09-19"
        }
    },
    "ria": {
        "marka_id": 24,
        "model_id": 2047,
        "yers": "2016",
        "fuel_id": 1,
        "engineVolumeTo": "2264",
        "engineVolumeFrom": "2264"
    },
    "params": {
        "brand": {
            "id": "4",
            "name": "Ford"
        },
        "model": {
            "id": "367",
            "name": "Mustang"
        },
        "yearto": "2016",
        "yearfrom": "2016",
        "engine": {
            "id": "1",
            "name": "Бензиновий"
        },
        "capacityto": "2264",
        "capacityfrom": "2264"
    },
    "market": {
        "total": {
            "code": "total",
            "name": "Загальна кількість цін",
            "value": 51,
            "description": null
        },
        "arithmeticMean": {
            "code": "arithmeticMean",
            "name": "Середня арифметична",
            "value": 20019,
            "description": null
        },
        "arithmeticMeanQuartile": {
            "code": "arithmeticMeanQuartile",
            "name": "Середня арифметична міжквантильна",
            "value": 19523,
            "description": "Середня арифметична без 25% самих малих та самих великих значень"
        },
        "percentiles": {
            "1.0": 15004.01,
            "5.0": 15810,
            "25.0": 17499.25,
            "50.0": 19500,
            "75.0": 21500,
            "95.0": 28449.999999999996,
            "99.0": 32969.990000000005
        },
        "offers": [
            {
                "id": "31015188",
                "price": "27500",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_31015188.html"
            },
            {
                "id": "34698608",
                "price": "21500",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_34698608.html"
            },
            {
                "id": "35483260",
                "price": "18900",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_35483260.html"
            },
            {
                "id": "29534452",
                "price": "19800",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_29534452.html"
            },
            {
                "id": "34654780",
                "price": "25000",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_34654780.html"
            },
            {
                "id": "35543474",
                "price": "18999",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_35543474.html"
            },
            {
                "id": "34320255",
                "price": "14999",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_34320255.html"
            },
            {
                "id": "34512717",
                "price": "15800",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_34512717.html"
            },
            {
                "id": "35213372",
                "price": "21000",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_35213372.html"
            },
            {
                "id": "35305311",
                "price": "16900",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_35305311.html"
            },
            {
                "id": "35639760",
                "price": "22000",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_35639760.html"
            },
            {
                "id": "35266462",
                "price": "16900",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_35266462.html"
            },
            {
                "id": "35069494",
                "price": "16500",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_35069494.html"
            },
            {
                "id": "35233803",
                "price": "18500",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_35233803.html"
            },
            {
                "id": "34557836",
                "price": "20000",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_34557836.html"
            },
            {
                "id": "35324875",
                "price": "20000",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_35324875.html"
            },
            {
                "id": "35176683",
                "price": "19999",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_35176683.html"
            },
            {
                "id": "30577624",
                "price": "19700",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_30577624.html"
            },
            {
                "id": "35566624",
                "price": "29999",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_35566624.html"
            },
            {
                "id": "35390087",
                "price": "17499",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_35390087.html"
            },
            {
                "id": "28388240",
                "price": "18000",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_28388240.html"
            },
            {
                "id": "35119474",
                "price": "17600",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_35119474.html"
            },
            {
                "id": "35506542",
                "price": "16400",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_35506542.html"
            },
            {
                "id": "34456344",
                "price": "16900",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_34456344.html"
            },
            {
                "id": "35260238",
                "price": "22000",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_35260238.html"
            },
            {
                "id": "33340111",
                "price": "22000",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_33340111.html"
            },
            {
                "id": "35518269",
                "price": "19490",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_35518269.html"
            },
            {
                "id": "27711169",
                "price": "21500",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_27711169.html"
            },
            {
                "id": "33956973",
                "price": "17000",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_33956973.html"
            },
            {
                "id": "35576555",
                "price": "19500",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_35576555.html"
            },
            {
                "id": "33917403",
                "price": "19200",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_33917403.html"
            },
            {
                "id": "30878105",
                "price": "20000",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_30878105.html"
            },
            {
                "id": "35382380",
                "price": "28500",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_35382380.html"
            },
            {
                "id": "34222034",
                "price": "22900",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_34222034.html"
            },
            {
                "id": "33118061",
                "price": "18200",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_33118061.html"
            },
            {
                "id": "31910739",
                "price": "22500",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_31910739.html"
            },
            {
                "id": "35471813",
                "price": "22000",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_35471813.html"
            },
            {
                "id": "33977466",
                "price": "21000",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_33977466.html"
            },
            {
                "id": "35605662",
                "price": "16600",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_35605662.html"
            },
            {
                "id": "34608341",
                "price": "19000",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_34608341.html"
            },
            {
                "id": "34784216",
                "price": "20500",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_34784216.html"
            },
            {
                "id": "34380134",
                "price": "17500",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_34380134.html"
            },
            {
                "id": "35369693",
                "price": "15500",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_35369693.html"
            },
            {
                "id": "29397068",
                "price": "22900",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_29397068.html"
            },
            {
                "id": "35545575",
                "price": "20800",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_35545575.html"
            },
            {
                "id": "34941767",
                "price": "16000",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_34941767.html"
            },
            {
                "id": "34963281",
                "price": "21000",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_34963281.html"
            },
            {
                "id": "34761532",
                "price": "16100",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_34761532.html"
            },
            {
                "id": "35427059",
                "price": "18000",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_35427059.html"
            },
            {
                "id": "35604320",
                "price": "33000",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_35604320.html"
            },
            {
                "id": "33334106",
                "price": "17900",
                "url": "https://auto.ria.com/uk/auto_Ford_Mustang_33334106.html"
            }
        ]
    },
    "prices": {
        "total": 51,
        "arithmeticMean": 20019.313725490196,
        "interQuartileMean": 19522.615384615383,
        "percentiles": {
            "1.0": 15004.01,
            "5.0": 15810,
            "25.0": 17499.25,
            "50.0": 19500,
            "75.0": 21500,
            "95.0": 28449.999999999996,
            "99.0": 32969.990000000005
        },
        "prices": [
            27500,
            21500,
            18900,
            19800,
            25000,
            18999,
            14999,
            15800,
            21000,
            16900,
            22000,
            16900,
            16500,
            18500,
            20000,
            20000,
            19999,
            19700,
            29999,
            17499,
            18000,
            17600,
            16400,
            16900,
            22000,
            22000,
            19490,
            21500,
            17000,
            19500,
            19200,
            20000,
            28500,
            22900,
            18200,
            22500,
            22000,
            21000,
            16600,
            19000,
            20500,
            17500,
            15500,
            22900,
            20800,
            16000,
            21000,
            16100,
            18000,
            33000,
            17900
        ],
        "classifieds": [
            31015188,
            34698608,
            35483260,
            29534452,
            34654780,
            35543474,
            34320255,
            34512717,
            35213372,
            35305311,
            35639760,
            35266462,
            35069494,
            35233803,
            34557836,
            35324875,
            35176683,
            30577624,
            35566624,
            35390087,
            28388240,
            35119474,
            35506542,
            34456344,
            35260238,
            33340111,
            35518269,
            27711169,
            33956973,
            35576555,
            33917403,
            30878105,
            35382380,
            34222034,
            33118061,
            31910739,
            35471813,
            33977466,
            35605662,
            34608341,
            34784216,
            34380134,
            35369693,
            29397068,
            35545575,
            34941767,
            34963281,
            34761532,
            35427059,
            35604320,
            33334106
        ]
    }
}
*/