import { mapActions } from 'vuex'
import mixin from '@/views/mixin/api'

export default {
	mixins: [mixin],
	data: () => ({
		title: 'role',
		icon: 'mdi-account-tie',
		dialog: false,
		fullscreen: false,
		loading: false,

		tab: null,
        tabs: ['Головна'],
	}),
	props: {
		selected: 					{ type: Object, default: null },
		disabled:   				{ type: Boolean, default: false }, //Ознака неактивного елементу
		activatorType:  			{ type: String, default: 'button-icon' }, // Тип активатора вікна: button | icon | menu-item | list-item
		activatorIcon:  			{ type: String, default: 'mdi-plus' },
		activatorTitle:  			{ type: String, default: null },
		activatorOutlined:     		{ type: Boolean, default: false },
		activatorTooltipText:  		{ type: String, default: null },
		activatorTooltipDisabled:	{ type: Boolean, default: false },
	},

	computed: {
		rules() {
			return {
				required: (value) => !!value || 'Поле обов\'язкове для заповнення',
				requiredArray: (value) => value instanceof Array && value.length == 0 || "Обов'язково для заповнення",
				mustBeSelected: (value) => typeof(value) === "undefined" || value === null || value?.id>0 || this.$vuetify.locale.t('$vuetify.rule.mustBeSelected'),
				
				code: (value) => {
					const pattern = /^([a-zA-Z'\-]+)/
					return (value === null || value.length == 0 || value.length > 32 || pattern.test(value)) || this.$vuetify.locale.t('$vuetify.rule.code', [128])
				},
				name: (value) => {
					const pattern = /^([a-zA-Zа-яА-ЯЇї'\-]+)/
					return (value && value.length <= 10 && pattern.test(value)) || 'І\'мя некоректне'
					//return value === null || ( value.length <= 10 && pattern.test(value)) || 'Name is not correct'
				},
				pib: (value) => {
					const pattern = /^([a-zA-Zа-яА-ЯЇї' \-]+)/
					return (value && value.length <= 10 && pattern.test(value)) || 'І\'мя некоректне'
					//return value === null || ( value.length <= 10 && pattern.test(value)) || 'Name is not correct'
				},
				date: (value) => {
					//console.log('form.mixin', 'rules.date', 'value', value)
					return (value === null || value?.length == 0 || moment(value, "DD.MM.YYYY", true).isValid() || moment(value, "YYYY-MM-DD", true).isValid()) || 'Date is not correct'
				},

				datetime: (value) => {
					return (value === null || value.length == 0 || moment(value, "YYYY-MM-DDTHH:mm", true).isValid()) || 'Date is not correct'
				},

				email: (value) => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					//return pattern.test(value) || this.$vuetify.locale.t('$vuetify.rule.email')//'Некоректний e-mail.'
					return pattern.test(value) || 'Некоректний e-mail.'
				},

				phone: (value) => {
					const pattern = /^\+?3?8?(0\d{9})$/
					//return (value === null || value?.length == 0 || pattern.test(value)) || 'Номер телефону не вірний (+380961112233)'
					return !value || pattern.test(value) || 'Номер телефону не відповідає формату (+380961112233)'
				},

				personsname: (value) => {
					const pattern = /^([a-zA-Zа-яА-ЯЇї'\- ]+)/
					//return (value && value.length <= 10 && pattern.test(value)) || this.$vuetify.locale.t('$vuetify.rule.personsname')//'Name is not correct'
					return (value && value.length <= 10 && pattern.test(value)) || 'Name is not correct'//'Name is not correct'
				},
			}
		},
	},

	watch: {
		dialog: function (val) {
			if(val) {
				this.onOpen()
			} else {
				this.onClose()
			}
		},
	},

	methods: {
		...mapActions([ 'showMessage' ]),

		required(value) {
			if (value instanceof Array && value.length == 0) {
				return "Обов'язково заповнити";
			}
			return !!value || "Обов'язково заповнити";
		},

		addItems (items, data) {
			for(let i in data) {
				items.push(data[i]);
			}
		},

		removeItem (items, item) {
			const index = items.indexOf(item)
			items.splice(index, 1);
		},

		removeItems(items, filtered, indexes) {
			let itms = []
			indexes.forEach((i) => itms.push(filtered[i]));
			itms.forEach((item) => this.removeItem(items, item));
		},

		onOpen () {
			//console.log('details.mixin.main', 'onOpen','selected', this.selected)

			this.find(this.selected)
		},

		onClose () {
			//this.setItem()
		},

		onCancel () {
			this.dialog = false
		},

		onOK () {
			//var that = this
			//this.loading = true

			/* let promise = this.item.id ? this.update() : this.create()
			promise.then(response => {
				//console.log('Roles', 'load','response.data', response.data)
				that.item = response.data

				that.loading 	= false
				this.dialog 	= false

				//that.showMessage({text: 'OK', status: '200'})
			}, error => {
				//console.log(error)
				that.loading = false
				that.showMessage(error)
			}) */
		},
	},
}