import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"fullscreen":_vm.fullscreen,"transition":"dialog-bottom-transition","scrollable":"","max-width":_vm.fullscreen? null: _vm.maxWidth},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.onDialog;
var attrs = ref.attrsDialog;
return [_vm._t("activator",function(){return [_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(_vm._s(_vm.icon))])],1)]}}],null,true)},[_c(VList,_vm._l((_vm.scripts),function(script,index){return _c(VListItem,{key:index,on:{"click":function($event){return _vm.onOpen(script)}}},[_c(VListItemTitle,[_vm._v(_vm._s(script.name))])],1)}),1)],1)]})]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VToolbar,{staticStyle:{"flex":"0 1 auto"},attrs:{"elevation":"0","dark":"","color":"primary"}},[_c(VAvatar,[_c(VIcon,[_vm._v(_vm._s(_vm.icon))])],1),_c(VToolbarTitle,[_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.title))]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.script.code)+" №"+_vm._s(_vm.item.id)+" "+_vm._s(_vm._f("formatDateTime")(_vm.item.datecreate)))])],1)],1)],1),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.copyText}},[_c(VIcon,[_vm._v("mdi-content-copy")])],1),_c(VDivider,{attrs:{"vertical":""}}),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.fullscreen = !_vm.fullscreen}}},[(_vm.fullscreen)?_c(VIcon,[_vm._v("mdi-arrow-collapse")]):_c(VIcon,[_vm._v("mdi-arrow-expand")])],1),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.dialog=false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c('pre',{ref:"script"},[_vm._v(_vm._s(_vm.json))])])],1),_c(VOverlay,{attrs:{"value":_vm.loading}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }