import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import moment from 'moment';

//Vue.config.devtools = process.env.NODE_ENV === 'development'

Vue.config.productionTip = true

//https://ru.vuejs.org/v2/guide/filters.html
Vue.filter('capitalize', function (value) {
	if (!value) return ''
	value = value.toString()
	return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
    }
});

Vue.filter('formatDateTime', function(value) {
    if (value) {
        return moment(String(value)).format('DD.MM.YYYY HH:mm:ss')
    }
});

Vue.filter('formatDateSQL', function(value) {
    if (value) {
        return moment(String(value)).format('YYYY-MM-DD')
    }
});

Vue.filter('formatDateTimeSQL', function(value) {
    if (value) {
        return moment(String(value)).format('YYYY-MM-DD hh:mm')
    }
});

Vue.filter('toCurrency', function (value) {
	if (typeof value !== "number") {
		return value;
	}
	var formatter = new Intl.NumberFormat('en-US', {
		maximumSignificantDigits: 2
	});
	return formatter.format(value);
});

Vue.filter('toCurrencyUSD', function (value) {
	if (typeof value !== "number") {
		return value;
	}
	var formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD'
	});
	return formatter.format(value);
});

Vue.filter('toCurrencyUAH', function (value) {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat('uk-UK', {
        style: 'currency',
        currency: 'UAH'
    });
    return formatter.format(value);
});

Vue.filter('prettyFSON', function (value) {
    return JSON.stringify(JSON.parse(value), null, 2);
});

new Vue({
	router,
	store,
	vuetify,
	icons: {
		iconfont: 'mdi', // default - only for display purposes
	},
	render: h => h(App),
	mounted() {
		//console.log('navigator.language', navigator.language.slice(0, 2))

		this.$vuetify.lang.defaultLocale = 'en';
		this.$vuetify.lang.current = navigator.language.slice(0, 2)

		let lang = localStorage.getItem("lang")
		if(lang) {
			this.$vuetify.lang.current = lang;
		}
	},
	methods: {
		translate (str) {
			/* let result = str
			try {
				result = this.$options.filters.capitalize(this.$vuetify.lang.t('$vuetify.' + str))
			} catch(error) {
				console.log('translate', 'error', error)
			}
			return result */
			return this.$options.filters.capitalize(this.$vuetify.lang.t('$vuetify.' + str))
		},
	}

}).$mount('#app')
