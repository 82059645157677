import axios from 'axios'
import {HTTP} from '../http'

export default {
    namespaced: true,
    state: {
        name: 'estimate',
        api_key: 'pM8p23DsYIoRsEbEjFfU9sYSfHWngww1SipqsM9t',

        types: [
            {name: 'Всі', value: 'all'},
            {name: 'Вживані', value: 'bu'},
            {name: 'Нові', value: 'na'},
            {name: 'Під пригон', value: 'order'},
        ],

        categories: [],
        brands: [],
        models: [],
        engines: [],
        gearboxes: [],
        bodies: [],

        regions: [],
        states: [],
        cities: [],
        countries: [],
        driverTypes: [],
        options: [],
        colors: [],
        
        prices: [],
        classifieds: [],
        arithmeticMean: null, //среднее арифметическое
        interQuartileMean: null, // межквартильное среднее
        percentiles: {},
        pricestotal: 0,

        // результат оцінки
        //estimate: {},
        estimate: null,

        /* vehicle: {
                "regnum": null,
                "vin": null,
                "category": {
                    "id": "13",
                    "name": "Легкові автомобілі"
                },
                "brand": {
                    "id": "1",
                    "name": "Audi"
                },
                "model": {
                    "id": "6",
                    "name": "80"
                },
                "engine": [],
                "year": 1988,
                "race": 250
            },
            prices: {
                "total": 14,
                "arithmeticMean": 2377.85714285714266225113533437252044677734375,
                "interQuartileMean": 2344.4444444444443433894775807857513427734375,
                "percentiles": {},
                "prices": [],
                "classifieds": []
            } */
        announcements: [],  // объявления 
    },
    getters: {
        name(state) {
            return state.name;
        },
        api_key(state) {
            return state.api_key;
        },
        /* module(state) {
            return state.name;
        }, */

        types(state) {
            return state.types;
        },
        categories(state) {
            return state.categories;
        },
        brands(state) {
            return state.brands;
        },
        models(state) {
            return state.models;
        },
        regions(state) {
            return state.regions;
        },
        bodies(state) {
            return state.bodies;
        },
        states(state) {
            return state.states;
        },
        cities(state) {
            return state.cities;
        },
        driverTypes(state) {
            return state.driverTypes;
        },
        engines(state) {
            return state.engines;
        },
        gearboxes(state) {
            return state.gearboxes;
        },
        options(state) {
            return state.options;
        },
        colors(state) {
            return state.colors;
        },
        countries(state) {
            return state.countries;
        },

        years(state) {
            var d = new Date()
            var items = []
            for(var i = d.getFullYear(); i > 1900; i-- ){
                items.push(i)
            }
            return items;
        },

        prices(state) {
            return state.prices;
        },
        classifieds(state) {
            return state.classifieds;
        },

        pricesMin(state) {
            return Math.min(...state.prices);
        },

        pricesMax(state) {
            return Math.max(...state.prices);
        },

        arithmeticMean(state) {
            return state.arithmeticMean;
        },
        interQuartileMean(state) {
            return state.interQuartileMean;
        },
        percentiles(state) {
            return state.percentiles;
        },
        pricestotal(state) {
            return state.pricestotal;
        },

        estimate(state) {
            return state.estimate;
        },
        announcements(state) {
            return state.announcements;
        },
    },
    mutations: {
        categories (state, data) {
            state.categories = data?data:[];
        },
        brands (state, data) {
            state.brands = data?data:[];
        },
        models (state, data) {
            state.models = data?data:[];
        },
        regions (state, data) {
            state.regions = data?data:[];
        },
        bodies (state, data) {
            state.bodies = data?data:[];
        },
        states (state, data) {
            state.states = data?data:[];
        },
        cities (state, data) {
            state.cities = data?data:[];
        },
        driverTypes (state, data) {
            state.driverTypes = data?data:[];
        },
        engines (state, data) {
            state.engines = data?data:[];
        },
        gearboxes (state, data) {
            state.gearboxes = data?data:[];
        },
        options (state, data) {
            state.options = data?data:[];
        },
        colors (state, data) {
            state.colors = data?data:[];
        },
        countries (state, data) {
            state.countries = data?data:[];
        },
        prices (state, data) {
            state.prices = data?data:[];
        },
        classifieds (state, data) {
            state.classifieds = data?data:[];
        },

        arithmeticMean (state, data) {
            state.arithmeticMean = data;
        },
        interQuartileMean (state, data) {
            state.interQuartileMean = data;
        },
        percentiles (state, data) {
            state.percentiles = data?data:{};
        },
        pricestotal (state, data) {
            state.pricestotal = data;
        },

        estimate (state, data) {
            state.estimate = data;
        },
        announcements (state, data) {
            if(data) {
                state.announcements.push(data)
            }
            //state.announcements = data?data:[];
        },

        clear (state) {
            state.prices  = [],
            state.classifieds  = [],
            state.arithmeticMean = null, //среднее арифметическое
            state.interQuartileMean = null, // межквартильное среднее
            state.percentiles = {},
            state.pricestotal = 0,

            state.estimate = null//[]
            state.announcements = []
        }

        /*
        insert (state, data) {
            if(Array.isArray(data)) {
                for(var i in data) {
                    data[i]['count'] = 1
                    state.items.push(data[i]);
                }
            } else {
                data['count'] = 1
                state.items.push(data);
            }
        },

        delete (state, data) {
            if(Array.isArray(data)) {
                for(let i in data) {
                    const index = state.items.indexOf(data[i])
                    state.items.splice(index, 1);
                }
            } else {
                const index = state.items.indexOf(data)
                state.items.splice(index, 1);
            }
        },
        */
    },
    actions: {
         
		Clear (context){
			context.commit('clear');
		},

        load (context) {
            var promise = new Promise((resolve, reject) => {

            setTimeout(() => {
                reject(new Error("время вышло!"));
            }, 1000);
            
            });
        },

        categories (context, data) {
            //console.log('Brands', 'data', data)
            axios.get('vehicle/categories').then(response => {
                context.commit('categories', response.data)
            }, error => {
                //context.dispatch('Message', error);
                //if(data.hasOwnProperty('error')) data.error(error);
            });
        },

        // Марки автотранспорнтих засобів
        brands (context, data) {
            //console.log('Brands', 'data', data)
            axios.get('vehicle/brands').then(response => {
                context.commit('brands', response.data)
            }, error => {
                //context.dispatch('Message', error);
                //if(data.hasOwnProperty('error')) data.error(error);
            });
        },

        // Моделі автотранспорнтих засобів
        models (context, data) {
            //console.log('Models', 'data', data)
            axios.get('vehicle/brand/'+data.brand+'/models').then(response => {
                context.commit('models', response.data)
            }, error => {
                //context.dispatch('Message', error);
                //if(data.hasOwnProperty('error')) data.error(error);
            });
        },

        // Типи двигунів
        engines (context, data) {
            //console.log('Models', 'data', data)
            axios.get('vehicle/engines').then(response => {
                context.commit('engines', response.data)
            }, error => {
                //context.dispatch('Message', error);
                //if(data.hasOwnProperty('error')) data.error(error);
            });
        },

        // Типи коробок передач
        gearboxes (context, data) {
            //console.log('Models', 'data', data)
            axios.get('vehicle/gearboxes').then(response => {
                context.commit('gearboxes', response.data)
            }, error => {
                //context.dispatch('Message', error);
                //if(data.hasOwnProperty('error')) data.error(error);
            });
        },

        // Типи кузовів
        bodies (context, data) {
            //console.log('Models', 'data', data)
            axios.get('vehicle/categories/'+data.category.id+'/bodies').then(response => {
                context.commit('bodies', response.data)
            }, error => {
                //context.dispatch('Message', error);
                //if(data.hasOwnProperty('error')) data.error(error);
            });
        },

        /*
        //Типы транспорта
        Categories (context, data) {
            //console.log('Brands', 'data', data)
            HTTP.get('vehicle/categories').then(response => {
                context.commit('categories', response.data)
                if(data.hasOwnProperty('success')) data.success(response.data);
            }, error => {
                //context.dispatch('Message', error);
                if(data.hasOwnProperty('error')) data.error(error);
            });
        },

        // Марки автотранспорнтих засобів
        Brands (context, data) {
            //console.log('Brands', 'data', data)
            HTTP.get('vehicle/brands').then(response => {
                context.commit('brands', response.data)
                if(data.hasOwnProperty('success')) data.success(response.data);
            }, error => {
                //context.dispatch('Message', error);
                if(data.hasOwnProperty('error')) data.error(error);
            });
        },

        // Моделі автотранспорнтих засобів
        Models (context, data) {
            //console.log('Models', 'data', data)
            HTTP.get('vehicle/brand/'+data.brand+'/models').then(response => {
                context.commit('models', response.data)
                if(data.hasOwnProperty('success')) data.success(response.data);
            }, error => {
                //context.dispatch('Message', error);
                if(data.hasOwnProperty('error')) data.error(error);
            });
        },

        // Типи двигунів
        Engines (context, data) {
            //console.log('Models', 'data', data)
            HTTP.get('vehicle/engines').then(response => {
                context.commit('engines', response.data)
                if(data.hasOwnProperty('success')) data.success(response.data);
            }, error => {
                //context.dispatch('Message', error);
                if(data.hasOwnProperty('error')) data.error(error);
            });
        },

        // Типи коробок передач
        Gearboxes (context, data) {
            //console.log('Models', 'data', data)
            HTTP.get('vehicle/gearboxes').then(response => {
                context.commit('gearboxes', response.data)
                if(data.hasOwnProperty('success')) data.success(response.data);
            }, error => {
                //context.dispatch('Message', error);
                if(data.hasOwnProperty('error')) data.error(error);
            });
        },

        // Типи кузовів
        Bodies (context, data) {
            //console.log('Models', 'data', data)
            HTTP.get('vehicle/category/'+data.category.id+'/bodies').then(response => {
                context.commit('bodies', response.data)
                if(data.hasOwnProperty('success')) data.success(response.data);
            }, error => {
                //context.dispatch('Message', error);
                if(data.hasOwnProperty('error')) data.error(error);
            });
        },

        Estimate (context, data) {
            //console.log('Estimate', 'data', data)
            //let url = 'vehicles/estimate/'+data.type
            //console.log('Estimate', 'url', url)
            //context.commit('clear');
            //HTTP.post('vehicles/estimate/'+data.type, data.params).then(response => {
            HTTP.post('vehicle/estimate/'+data.type, data.params).then(response => {
                console.log('Estimate', 'response.data', response.data)
                //context.commit('estimate', response.data)
                //if(data.hasOwnProperty('success')) data.success(response.data, data.params)
                if(data.hasOwnProperty('success')) data.success(response.data)
            }, error => {
                console.log('Estimate', 'error', error)
                //context.dispatch('Message', error);
                //if(data.hasOwnProperty('error')) data.error(error, data.params)
                if(data.hasOwnProperty('error')) data.error(error.response)
            });
        },

        VehicleAveragePrice (context, data) {
            //console.log('VehicleAveragePrice', 'data', data);
            console.log('VehicleAveragePrice', 'params', data.params);
            //console.log('Estimate', 'url', url)
            //context.commit('clear');

            HTTP.get('vehicle/averageprice', { params: data.params } ).then(response => {
                console.log('VehicleAveragePrice', 'response.data', response.data)
                //context.commit('estimate', response.data)
                //if(data.hasOwnProperty('success')) data.success(response.data, data.params)
                if(data.hasOwnProperty('success')) data.success(response.data)
            }, error => {
                console.log('VehicleAveragePrice', 'error', error)
                //context.dispatch('Message', error);
                //if(data.hasOwnProperty('error')) data.error(error, data.params)
                if(data.hasOwnProperty('error')) data.error(error.response)
            });
        },

        */

    }
   
}