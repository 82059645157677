export default {
    namespaced: true,
    state: {
		endpoint: 'realty/request',
		item: {},
        items: [],
    },
	actions: {
		clearItem(context) {
			context.commit('item', {
				id: null,
				//users: [],
				//services: [],
			})
		},
	}
}