import {HTTP} from '../http'

export default {
	namespaced: true,
	state: {
		//name: 'app',
		//title: 'Ortica Systems',

		//token: null,

		authorized: false, //Ознака авторизації
		profile: {},
		usersroles: [],
		services: [],
	},
	getters: {
		authorized(state) {
			return state.authorized
		},

		profile(state) {
			return state.profile
		},

		usermenu(state) {
			var items = []
			for(let i in state.usersroles) {
				let role = state.usersroles[i]
				if(role.hasOwnProperty('menu')) {
					for(let j in role.menu) {
						let menu = role.menu[j]
						items.push({
							id: menu.id,
							title: menu.name,
							subtitle: menu.description,
							icon: menu.image,
							to: menu.path,
						})
					}
				}
			}
			
			// DISTINCT
			const unique  = [...new Map(items.map(item => [item['id'], item])).values()];

			return unique;
		},

		services(state) {
			var items = []
			// Сервіси користувача
			for(let i in state.services) {
				let item = state.services[i]
				items.push({
					id: item.id,
					odr: item.odr,
					overline: item.type.name,
					title: item.name,
					subtitle: item.description,
					icon: item.image,
					router: item.router,
				})
			}
			// Сервіси ролей
			for(let i in state.usersroles) {
				let role = state.usersroles[i]

				if(role.hasOwnProperty('services')) {
					for(let j in role.services) {
						let item = role.services[j]
						items.push({
							id: item.id,
							odr: item.odr,
							overline: item.type.name,
							title: item.name,
							subtitle: item.description,
							icon: item.image,
							router: item.router,
						})
					}
				}
			}

			// DISTINCT
			const unique  = [...new Map(items.map(item => [item['id'], item])).values()];

			return unique.sort(function (a, b) {return parseInt(a.odr) - parseInt(b.odr)});
		},
	},
	mutations: {
		authorized(state, value) {
			state.authorized = value
		},
		
		profile(state, value) {
			state.profile = value
		},

		usersroles(state, value) {
			state.usersroles = value
		},

		services(state, value) {
			state.services = value
		},
	},
	actions: {
		Clear (context) {
			context.commit('authorized', false)
			context.commit('profile', {})
			context.commit('usersroles', [])
			context.commit('services', [])
		},

		Authorization (context, data) {
			context.commit('authorized', data != null);
			
			if(data.hasOwnProperty('profiles')) {
				context.commit('profile', data.profiles);
			}

			if(data.hasOwnProperty('usersroles')) {
				context.commit('usersroles', data.usersroles);
			}

			if(data.hasOwnProperty('services')) {
				context.commit('services', data.services);
			}
		},

		// Очищення даних
		/* Clear (context){
			//console.log(context.getters.name,'Clear')

			context.commit('token', null);
			//context.dispatch('profile/Clear', null, {root: true});
			//context.dispatch('menu/Clear', null, {root: true});
		}, */

		// Ініціалізація
		/* async Init (context, data) {
			
			// Очищення даних
			context.dispatch('Clear');

			// Зчитування токена з localStorage
			let token = localStorage.getItem('token')
			if(token) {
				// Перевірка токена на валідність
				//await context.dispatch('Token', token) // Чекаємо завершення валідації токена

				// Ініціалізація профайлу
				//await context.dispatch('profile/Init', null, {root: true})

				//commit('gotOtherData', await getOtherData())
				if(data.hasOwnProperty('success')) data.success();

			} else {
				if(data.hasOwnProperty('error')) data.error()
			}
		}, */

		/**
		 * Перевірка токена на валідність
		 * 
		 * @param {*} context 
		 * @param {*} data 
		 */
		/* Token (context, data) {
			//console.log(context.getters.name,'Token', 'data', data)
			//console.log(context.getters.name,'Token', 'Authorization', HTTP.defaults.headers.common['Authorization'])
			//return new Promise((resolve, reject) => {
				// setTimeout(() => { context.commit('token', data);  resolve() }, 1000) 

			let token = localStorage.getItem('token')
			if(token) {
				context.commit('token', token)

				HTTP.get('/token').then(response => {
					//console.log(context.getters.name,'Login', 'response', response)

					// Авторизація користувача
					context.dispatch('Authorization', {});

					if(data.hasOwnProperty('success')) data.success(response);
				}, error => {
					//console.log(context.getters.name,'Login: error', 'error', error)
					context.commit('token', null);
					localStorage.removeItem("token");
					
					if(data.hasOwnProperty('error')) data.error(error);
				});
			} else {
				if(data.hasOwnProperty('error')) data.error();
			}
		},
 */
		
		/**
		 * Вхід користувача до системи (ідентифікація)
		 * 
		 * @param {*} context 
		 * @param {*} data 
		 */
		/* Login (context, data) {
			//console.log(context.getters.name, 'Login', 'data', data)

			HTTP.post('/login', data.item).then(response => {
				//console.log(context.getters.name,'Login', 'response', response)
				context.commit('token', response.data.token)
				localStorage.setItem('token',  response.data.token)

				// Завантаження провілю
				//context.dispatch('MyProfile', {});
				// Авторизація користувача
				context.dispatch('Authorization', {});

				if(data.hasOwnProperty('success')) data.success(response);
			}, error => {
				if(data.hasOwnProperty('error')) data.error(error.response);
				//console.log(context.getters.name,'Login: error', 'error', error)
			});
		}, */

		/**
		 * Вихід користувача з системи
		 * 
		 * @param {*} context 
		 * @param {*} data 
		 */
		/* Logout (context, data) {
			//console.log(context.getters.name, 'logout', 'data', data)
			HTTP.post('/logout', data.params).then(response => {
				//Обнулення змінної з токеном
				context.commit('token', null);
				//Видалення токену з localStorage
				localStorage.removeItem("token");

				if(data.hasOwnProperty('success')) data.success(response);
			}, error => {
				if(data.hasOwnProperty('error')) data.error(error.response);
			});
		}, */

		/**
		 * Авторизація користувача (отримання профілю, ролей, меню)
		 * 
		 * @param {*} context 
		 * @param {*} data 
		 */
		 /* Authorization (context, data) {
			//console.log(context.getters.name, 'Login', 'data', data)
			HTTP.get('authorization').then(response => {
				//console.log(context.getters.name, 'Authorization', 'response', response.data)
				context.commit('authorization', response.data);
				if(data.hasOwnProperty('success')) data.success(response);
			}, error => {
				if(data.hasOwnProperty('error')) data.error(error.response);
			});
		}, */


		// Реєстрація користувача
		Singup (context, data) {
			//console.log(context.getters.name, 'logout', 'data', data)

			//context.dispatch('Clear');
			//localStorage.removeItem("token");

			if(data) {
				setTimeout( function() {
					var response = {
						data: {
							token: '1234'
						}
					}
					if(data.hasOwnProperty('success')) data.success(response);
				}, 1000);
				/* HTTP.post('/singup', data.item).then(response => {
					console.log(context.getters.name, 'logout', 'response', response)
					context.dispatch('Close');
					if(data.hasOwnProperty('success')) data.success(response);
				}, error => {
					if(data.hasOwnProperty('error')) data.error(response);
				}); */
			}
		},

		/**
		 * Заявка на підключення
		 * 
		 * @param {*} context 
		 * @param {*} data 
		 */
		Connection (context, data) {
			//console.log(context.getters.name, 'logout', 'data', data)
			HTTP.post('/connection', data.item).then(response => {
				//console.log(context.getters.name, 'Connection', 'response', response)
				if(data.hasOwnProperty('success')) data.success(response);
			}, error => {
				//console.log(context.getters.name, 'Connection', 'error', error.response)
				if(data.hasOwnProperty('error')) data.error(error.response);
			});
		},

		/**
		 * Зміна паролю
		 * 
		 * @param {*} context 
		 * @param {*} data 
		 */
		PasswordChange (context, data) {
			//console.log(context.getters.name, 'Change', 'data', data)
			HTTP.post('password/change', data.item, data.config).then(response => {
				if(data.hasOwnProperty('success')) data.success(response);
			}, error => {
				if(data.hasOwnProperty('error')) data.error(error.response);
			});
		},
		
		/**
		 * Відновлення паролю
		 * 
		 * @param {*} context 
		 * @param {*} data 
		 */
		PasswordRecovery (context, data) {
			HTTP.post('password/recovery', data.item, data.config).then(response => {
				if(data.hasOwnProperty('success')) data.success(response);
			}, error => {
				if(data.hasOwnProperty('error')) data.error(error.response);
			});
		},

		/**
		 * Завантаження власного профілю
		 * 
		 * @param {*} context
		 * @param {*} data
		 */
		 /* MyProfile (context, data) {
			//console.log(context.getters.name, 'Profile', 'data', data)
			HTTP.get('myprofile').then(response => {
				//console.log(context.getters.name, 'Profile', 'response', response.data)
				context.commit('profile', response.data);
				if(data.hasOwnProperty('success')) data.success(response);
			}, error => {
				if(data.hasOwnProperty('error')) data.error(error.response);
			});
		}, */



		/**
		 * Завантаження власного профілю
		 * 
		 * @param {*} context
		 * @param {*} data
		 */
		/* MyProfile (context, data) {
			//console.log(context.getters.name, 'Profile', 'data', data)
			HTTP.get('myprofile').then(response => {
				//console.log(context.getters.name, 'Profile', 'response', response.data)
				context.commit('profile', response.data);
				if(data.hasOwnProperty('success')) data.success(response);
			}, error => {
				if(data.hasOwnProperty('error')) data.error(error.response);
			});
		}, */

		/**
		 * Зміни власного профілю
		 * 
		 * @param {*} context
		 * @param {*} data
		 */
		 Profile (context, data) {
			//console.log(context.getters.name, 'Profile', 'data', data)
			HTTP.put('profiles', data.item).then(response => {
				//console.log(context.getters.name, 'Profile', 'response', response)
                context.commit('profile', response.data);
                if(data.hasOwnProperty('success')) data.success(response);
            }, error => {
                if(data.hasOwnProperty('error')) data.error(error.response);
            });
		},
	}	
}