<template>
	<v-dialog  
	 	v-model="dialog"
	 	fullscreen
		transition="dialog-bottom-transition"
		scrollable
	>
		<!-- Актіватор -->
		<template v-slot:activator="{  on, attrs }">
			<slot name="activator">
				<v-btn icon v-bind="attrs" v-on="on">
					<v-icon>{{ icon }}</v-icon>
				</v-btn>
			</slot>
		</template>

		<v-card>
			<!-- Ttoolbar -->
			<v-toolbar elevation="0" dark color="primary" style="flex: 0 1 auto;">
				<v-avatar>
					<v-icon>{{ icon }}</v-icon>
				</v-avatar>
				<!-- <v-toolbar-title>{{ title }}</v-toolbar-title>
				<v-toolbar-subtitle>{{ title }}</v-toolbar-subtitle> -->
				<v-toolbar-title>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>{{ title }}</v-list-item-title>
							<v-list-item-subtitle>{{ subtitle }}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-toolbar-title>
				

				<v-spacer></v-spacer>

				<v-btn icon @click="dialog=false" >
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>

			<v-card-text>
				<v-container>
					<v-banner single-line>
					<!-- We can't save your edits while you are in offline mode. -->
						<v-row dense>
							<v-col cols="12" md="3" class="d-flex align-center">
								<v-subheader class="text-wrap">Період</v-subheader>
							</v-col>
							<v-col cols="12" md="9">
								<v-row dense>
									<v-col cols="12" md="6">
										<z-date v-model="item.datebegin" outlined dense clirable></z-date>
									</v-col>
									<v-col cols="12" md="6">
										<z-date v-model="item.dateend" outlined dense clirable></z-date>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="12" md="3" class="d-flex align-center">
								<v-subheader class="text-wrap">Організація</v-subheader>
							</v-col>
							<v-col cols="12" md="9">
								<v-combobox
									v-model="item.organization"
									:items="organizations"
									label="Організація"
									outlined
									dense
									clirable
									item-text="name"
									item-value="id"
									hide-details="auto"
									@focus="loadOrganization"
								></v-combobox>

								<!-- @change="onorganization" -->

								<!-- <v-row dense>
									<v-col cols="12" md="6">
										<z-date v-model="datebegin" outlined dense clirable></z-date>
									</v-col>
									<v-col cols="12" md="6">
										<z-date v-model="dateend" outlined dense clirable></z-date>
									</v-col>
								</v-row> -->
							</v-col>
						</v-row>

					<template v-slot:actions>
						<v-btn icon @click.stop="onRefresh">
							<v-icon>mdi-refresh</v-icon>
						</v-btn>
					</template>
					</v-banner>
				</v-container>
				<!-- <v-data-table
					:headers="headers"
					:items="items"
					item-key="name"
					:loading="loading"
					loading-text="Завантаження даних... зачекайте, будь ласка." 
					hide-default-footer
					:items-per-page="-1"
				>
					<template v-slot:[`item.equal`]="{ item }">
						<v-icon color="green" v-if="item.equal===true">mdi-thumb-up</v-icon>
						<v-icon color="red" v-else-if="item.equal===false">mdi-thumb-down</v-icon>
					</template>
				</v-data-table> -->
				
			</v-card-text>
		</v-card>

		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
	</v-dialog>
</template>

<script>
import axios from 'axios'
import zDate from '@/views/components/control/Date'

export default {
	name: 'RealtyRequestStatistic',
	components: { zDate },
	data: () => ({
		title: 'Статистика',
		subtitle: 'Статистичні показники запитів',
		icon: 'mdi-chart-scatter-plot-hexbin',
		dialog: false,
		loading: false,
		
		item: {
			/* datebegin: null,
			dateend: null, */
		},

		/* params: [
			{ code: 'type', path: 'type.name', name: 'Тип запиту' },
			{ code: 'number', path: 'number', name: 'Реєстраційний номер' },
			{ code: 'vin', path: 'vin', name: 'VIN-код' },
			{ code: 'category', path: 'category.name', name: 'Категорія' },
			{ code: 'brand', path: 'brand.name', name: 'Марка' },
			{ code: 'model', path: 'model.name', name: 'Модель' },
			{ code: 'yearProdFrom', path: 'yearProdFrom', name: 'Період випуску авто, Від' },
			{ code: 'yearProdTo', path: 'yearProdTo', name: 'Період випуску авто, До' },
			{ code: 'yearProd', path: 'yearProd', name: 'Рік випуску авто' },
			{ code: 'generation', path: 'generation.name', name: 'Покоління' },
			//{ code: 'modification', path: 'modification.name', name: 'Модифікація' },
			{ code: 'bodystyle', path: 'bodystyle.name', name: 'Тип кузова' },
			{ code: 'driver', path: 'driver.name', name: 'Тип приводу' },
			{ code: 'engineVolume', path: 'engineVolume', name: "Об''єм двигуна, л." },
			{ code: 'fuel', path: 'fuel.name', name: 'Тип палива' },
			{ code: 'gearbox', path: 'gearbox.name', name: 'Коробка передач' },
			//{ code: 'options', path: 'options.name', name: 'Опції' },
			{ code: 'color', path: 'color.name', name: 'Колір' },
			{ code: 'country', path: 'country.name', name: 'Країна виробник' },
			{ code: 'state', path: 'state.name', name: 'Область' },
			{ code: 'city', path: 'city.name', name: 'Місто' },
			{ code: 'mileageFrom', path: 'mileageFrom', name: 'Пробіг Від, тис.км' },
			{ code: 'mileageTo', path: 'mileageTo', name: 'Пробіг До, тис.км' },
			{ code: 'damage', path: 'damage', name: 'ДТП' },
			{ code: 'period', path: 'period', name: 'Період оцінки' },
		], */
	}),

	props: {
		selected: 					{ type: Array, default: [] },
	},

	computed: {
		organizations: function () {
			return this.$store.getters[`Organization/items`]
		},
	},

	watch: {
		/* dialog: function (val) {
			if(val) {
				this.onOpen()
			} else {
				this.onClose()
			}
		}, */
	},

	created() {
		this.loadOrganization()
	},

	methods: {
		loadOrganization() {
			if(this.organizations.length == 0) {
				var that = this
				axios.get('/organization').then(response => {
					//console.log(that.$options.name, 'loadOrganization','response.data', response.data)
					this.$store.commit(`Organization/items`, response.data);

					if(that.organizations.length && !that.item.hasOwnProperty('organization')) {
						that.item['organization'] = that.organizations[0]
					}
				}, error => {
					console.log('error', error)
				});
			}
		},

		onRefresh() {

		},
	},
}
</script>