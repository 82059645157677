<template>
	<!-- <v-dialog v-model="dialog" v-bind="$props"> -->
	<v-dialog v-model="dialog" 
		fullscreen
		hide-overlay
		transition="dialog-bottom-transition"
	>
		<!-- :fullscreen="fullscreen"
		:hide-overlay=""
		:transition="transition"
		:scrollable="scrollable"
		:width="width"
		:height="height" -->

		<!-- Актіватор -->
		<template v-slot:activator="{ on: dialog, attrs }">
            <!-- activator - icon -->
            <v-tooltip bottom v-if="activator=='icon'" disabled>
                <template v-slot:activator="{ on: tooltip }">
                    <v-icon :small="small" v-bind="attrs" v-on="{ ...tooltip, ...dialog }" :disabled="disabled">{{ icon }}</v-icon>
                </template>
                <span>{{title}}</span>
            </v-tooltip>
            
            <!-- activator - list-item -->
            <v-list-item v-else-if="activator=='list-item'" v-bind="attrs" v-on="{...dialog }" :disabled="disabled"> 
                <v-list-item-icon>
                    <v-icon color="primary" :disabled="disabled">{{icon}}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{title}}</v-list-item-title>
            </v-list-item>

            <!-- activator - button -->
            <v-tooltip bottom v-else disabled>
                <template v-slot:activator="{ on: tooltip }">
                    <!-- <v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...dialog }" :disabled="disabled" v-text="activatorName">
                        <v-icon color="primary">{{acvicon}}</v-icon>
                    </v-btn> -->
					<v-btn outlined color="primary" v-bind="attrs" v-on="{ ...tooltip, ...dialog }">
						<v-icon left dark color="primary">{{ icon }}</v-icon>
						{{ activatorName }}
					</v-btn>
                </template>
                <span>{{title}}</span>
            </v-tooltip>
        </template>
		
		<v-card>
			<v-toolbar dark color="primary">
				
				<v-toolbar-title>{{ title }}</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-toolbar-items>
					<!-- <v-btn dark text @click="dialog = false" >Save</v-btn> -->
				</v-toolbar-items>
				<v-btn icon dark @click="dialog = false" >
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>
			<v-container :fluid="fluid">
				<z-form :item="item"></z-form>
			</v-container>
			<!-- <v-list three-line subheader >
				<v-subheader>User Controls</v-subheader>
				<v-list-item>
					<v-list-item-content>
					<v-list-item-title>Content filtering</v-list-item-title>
					<v-list-item-subtitle>Set the content filtering level to restrict apps that can be downloaded</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
				<v-list-item>
					<v-list-item-content>
					<v-list-item-title>Password</v-list-item-title>
					<v-list-item-subtitle>Require password for purchase or use password to restrict purchase</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
			</v-list>
				<v-divider></v-divider>
				<v-list
				three-line
				subheader
				>
				<v-subheader>General</v-subheader>
				<v-list-item>
					<v-list-item-action>
					<v-checkbox v-model="notifications"></v-checkbox>
					</v-list-item-action>
					<v-list-item-content>
					<v-list-item-title>Notifications</v-list-item-title>
					<v-list-item-subtitle>Notify me about updates to apps or games that I downloaded</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
				<v-list-item>
					<v-list-item-action>
					<v-checkbox v-model="sound"></v-checkbox>
					</v-list-item-action>
					<v-list-item-content>
					<v-list-item-title>Sound</v-list-item-title>
					<v-list-item-subtitle>Auto-update apps at any time. Data charges may apply</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
				<v-list-item>
					<v-list-item-action>
					<v-checkbox v-model="widgets"></v-checkbox>
					</v-list-item-action>
					<v-list-item-content>
					<v-list-item-title>Auto-add widgets</v-list-item-title>
					<v-list-item-subtitle>Automatically add home screen widgets</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
			</v-list> -->
		</v-card>
	</v-dialog>
</template>

<script>
import zForm from './vehicle.estimate.result.vue'

export default {
	components: {
		zForm
	},
	data: () => ({
		dialog: false,
		loading: false,
		
		fluid: false,

		snackbar: false,
		message: null,
		messagetype: null, // success | info | warning | error
	}),
	props: {
		type: {
			type: String,
			default: 'metodica' // check | market | metodica | exploration
		},
		item: { 
			type: Object,
			default: () => {}
		},
		mode: { 
			type: String,
			default: 'edit' // add | edit | preview
		}, 
		
		title:      	{ type: String, default: 'Висновок' },
		hint:       	{ type: String, default: null },
		icon:       	{ type: String, default: 'mdi-file-chart-outline' },
		fullscreen: 	{ type: Boolean, default: false },
		scrollable: 	{ type: Boolean, default: false },
		hideOverlay: 	{ type: Boolean, default: false },
		width:      	{ type: Number, default: 1024 },
		height:     	{ type: Number, default: 800 },
		transition: 	{ type: String, default: 'dialog-bottom-transition' },
		color:			{ type: String, default: null }, 		// Колір фону (card)
		

		//activator:			{ type: Object, default: () => {} },	// Параметри активатора

		small:      	{ type: Boolean, default: false },
		disabled:   	{ type: Boolean, default: false }, //Ознака неактивного елементу
		activator:  	{ type: String, default: 'button'}, // Тип активатора вікна: button | icon | menu-item | list-item

	},
	watch: {
		item: function (val) {
			if(val) {
				
			}
		},
	},
	computed: {
		activatorName: function () {
			let result = 'створити'
			switch(this.mode) {
				case 'edit': result = 'редагувати'; break;
				case 'preview': result = 'перегляд'; break;
			}
			return result
		},

		activatorIcon: function () {
			let result = 'mdi-plus'
			switch(this.mode) {
				case 'edit': result = 'mdi-pencil'; break;
				case 'preview': result = 'mdi-printer-eye'; break;
			}
			return result
		},

		/* tooltip: function () {
			let result = 'Створити'
			switch(this.mode) {
				case 'edit': result = 'Редагувати'; break;
				case 'preview': result = 'Перегляд'; break;
			}
			return result
		}, */
	},
	methods: {
		OnOpen (e) {
			this.dialog = true
		},

		OnClear (e) {
			this.$refs.detail.Clear()
			//this.item = {}
			//this.dialog = false
			//this.$emit('close', e)
		},
		OnClose (e) {
			this.dialog = false
			//this.OnClear()
			this.$emit('close', e)
		},
		OnCancel (e) {
			this.OnClose()
			this.$emit('cancel', e)
		},
		OnOK (e) {
			//console.log('OnOK', 'item', this.item)
			//console.log('OnOK', 'detail.item', this.$refs.detail.item)
			
			//this.$emit('ok', e)
			this.$emit(this.mode, this.$refs.detail.item)

			if(this.mode == 'add') this.OnClear()

			this.OnClose()
		},

		Message (text, type) {
			//console.log(this.modulename, 'Message', 'text', text)

			this.message = text
			this.snackbar = true
			this.messagetype = type
		},
	},
}
</script>