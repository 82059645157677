<template>
	<v-dialog  
	 	v-model="dialog"
	 	fullscreen
		transition="dialog-bottom-transition"
		scrollable
	>
		<!-- Актіватор -->
		<template v-slot:activator="{  on, attrs }">
			<slot name="activator">
				<v-btn icon v-bind="attrs" v-on="on">
					<v-icon>{{ icon }}</v-icon>
				</v-btn>
			</slot>
		</template>

		<v-card color="#EEEEEE">
			<!-- Ttoolbar -->
			<v-toolbar flat color="#f5f5f5"  style="flex: 0 1 auto;">
				<v-avatar>
					<v-icon>{{ icon }}</v-icon>
				</v-avatar>
				<!-- <v-toolbar-title>{{ title }}</v-toolbar-title>
				<v-toolbar-subtitle>{{ title }}</v-toolbar-subtitle> -->
				<v-toolbar-title>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>{{ title }}</v-list-item-title>
							<v-list-item-subtitle>{{ subtitle }}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-toolbar-title>
				
				<v-spacer></v-spacer>

				<v-btn icon @click="dialog=false" >
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>

			<v-card-text>
				<v-container>
					<v-sheet color="white" elevation="1">
						<v-card flat>
							<v-list-item three-line>
								<v-list-item-content>
									<v-list-item-title class="text-h6">{{ drug.name }}</v-list-item-title>
									<v-list-item-subtitle class="font-weight-bold">{{ drug.medform }}</v-list-item-subtitle>
									<v-list-item-subtitle>{{ maker.name }}</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-action>
									<v-list-item-action-text>{{ product.number }}</v-list-item-action-text>
									<v-list-item-action-text>{{ product.datebegin | formatDate }} - {{ product.dateend | formatDate }}</v-list-item-action-text>
									<v-list-item-action-text>Код Моріон {{ drug.idm }}</v-list-item-action-text>
								</v-list-item-action>
							</v-list-item>
						</v-card>

						<v-card flat>
							<GChart v-if="chartData.length"
								type_="ColumnChart"
								type="LineChart"
								:data="chartData"
								:options="chartOptions"
							/>
						</v-card>

						<v-data-table
							:items="prices"
							:headers="headers"
							item-key="id"
							loading-text="Завантаження даних... зачекайте, будь ласка."
							:items-per-page="-1"
							hide-default-footer
						>
							<template v-slot:top>
								<v-toolbar flat>
									<v-toolbar-title>Ціни</v-toolbar-title>
								</v-toolbar>
							</template>

							<template v-slot:[`item.datebegin`]="{ item }">
								<span>{{ item.datebegin | formatDate }}</span>
							</template>
						</v-data-table>
					</v-sheet>
				</v-container>
			</v-card-text>
		</v-card>

		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
	</v-dialog>
</template>

<script>
import axios from 'axios'
import moment from 'moment';
import { GChart } from 'vue-google-charts/legacy' //https://www.npmjs.com/package/vue-google-charts

export default {
	name: 'EstimateObjectStatistic',
	components: { GChart },
	data: () => ({
		dialog: false,
		loading: false,
		
		prices: [],
		headers: [
			//{ text: 'ID', value: 'id', sortable: true, width: '50' },
			{ text: 'Продавець', value: 'seller_name', sortable: true },
			{ text: 'Тип ціни', value: 'type_name', sortable: true },
			{ text: 'Ціна', value: 'price', sortable: true },
			{ text: 'Дата', value: 'datebegin', sortable: true },
		],

		chartOptions: {
			chart: {
				title: 'Company Performance',
				subtitle: 'Sales, Expenses, and Profit: 2014-2017',
			}
		}
	}),

	props: {
		icon:   		{ type: String, default: 'mdi-chart-timeline-variant-shimmer' },
		title:   		{ type: String, default: "Статистика цін об'єкту оцінки" },
		item: 			{ type: Object, default: () => {} }, //EstimateObject
	},

	computed: {
		name() {
			return this.item?.name ? this.item.name : ''
		},

		subtitle() {
			return this.item?.name ? this.item.name : ''
		},

		product() {
			return this.item.hasOwnProperty('product') ? this.item.product: {}
		},

		drug() {
			//return this.product && this.product.hasOwnProperty('drugs') ? this.product.drugs[0]: {}
			return this.product.hasOwnProperty('drugs') ? this.product.drugs[0]: {}
		},

		maker() {
			//return this.product && this.product.hasOwnProperty('maker') ? this.product.maker: {}
			return this.product.hasOwnProperty('maker') ? this.product.maker: {}
		},

		chartData() {
			const sellers 	= [...new Set(this.prices.map(item => item.seller_name))];
			const dates 	= [...new Set(this.prices.map(item => item.datebegin))];

			let result = []
			result.push(['Дата'].concat(sellers))

			dates.forEach((item) => { result.push([item].concat(new Array( sellers.length ).fill().map((e, i) => null))) })

			this.prices.forEach((item) => {
				const indexX = dates.indexOf(item.datebegin)+1;
				const indexY = sellers.indexOf(item.seller_name)+1;
				
				//console.log(this.$options.name, 'chartData', 'indexX', indexX,'indexY', indexY)

				result[indexX][indexY] = parseFloat(item.price)
			})

			result.forEach((item, index) => {
				if(index > 0) {
					item[0] = moment(String(item[0])).format('DD.MM.YYYY')
				}
			})

			//console.log(this.$options.name, 'chartData','result', result)

			return result
		}
	},

	watch: {
		dialog(val) {
			if(val) {
				this.onRefresh()
			}
		},
	},

	/* created() {
		this.params.datebegin = this.datebegin
		this.params.dateend = this.dateend

		this.onRefresh()
	}, */

	methods: {
		onRefresh() {
			//console.log(this.$options.name, 'onRefresh','params', this.params)
			//console.log(this.$options.name, 'onRefresh','item', this.item)

			this.loading = true;
			var that = this;
			axios.get(`/estimate/object/${this.item.id}/statistic`).then(response => {
				//console.log(this.$options.name, 'onRefresh','response.data', response.data)
				that.prices = response.data
			}, error => {
				console.log(error)
			}).finally(() => (this.loading = false));
		},
	},
}
</script>