<template>
	<v-form ref="form">
		<v-row dense>
			<!-- <v-col cols="12">
				<v-combobox
					v-model="item.yearProd"
					:items="years"
					label="Рік виробництва"
					outlined
					dense
					hide-details="auto"
				></v-combobox>
			</v-col> -->
			<v-col cols="12">
				<v-text-field
					label="Показник одометра, км"
					v-model="item.odometer"
					outlined
					dense
					type="number"
					hide-details="auto"
					min="0"
				></v-text-field>
			</v-col>
			<v-col cols="12">
				<v-text-field
					label="Нормативний середньорічний пробіг, км"
					v-model="item.mileageAvgNorm"
					outlined
					dense
					type="number"
					hide-details="auto"
					min="0"
				></v-text-field>
			</v-col>
			<v-col cols="12">
				<v-text-field
					label="Середня ринкова ціна"
					v-model="item.avgPriceUAH"
					outlined
					dense
					type="number"
					hide-details="auto"
					min="0"
				></v-text-field>
			</v-col>
			<v-col cols="12">
				<v-combobox
					label="Ринок регіону"
					v-model="item.marketregion"
					:items="marketregions"
					outlined 
					dense
					item-text="name"
					item-value="value"
					hide-details="auto"
				></v-combobox>
			</v-col>
			<v-col cols="12">
				<v-text-field
					label="Коефіцієнт торгу"
					v-model="item.bargain"
					outlined
					dense
					type="number"
					hide-details="auto"
					min="0.01"
					max="1"
					step="0.01"
				></v-text-field>
			</v-col>
		</v-row>
	</v-form>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
export default {
	data () {
		return {
			/* minYear: 1900,
			maxYear: (new Date()).getFullYear(), */
		}
	},
	props: {
		module:	{ type: String, default: 'VehicleEstimate'}, // check | market | metodica | exploration | averageprice
	},

	computed: {
		...mapGetters([
			'marketregions'
		]),

		item: function () {
			return this.$store.getters[`${this.module}/params`]
		},

		/* years() {
			const arrayRange = (start, stop, step) =>
			Array.from(
				{ length: (stop - start) / step + 1 },
				(value, index) => start + index * step
			);
			return arrayRange(this.minYear, this.maxYear, 1).reverse();
		}, */
	},

	created () {
		var that = this

		// Ринки регіону
		if(this.marketregions.length == 0) {
			axios.get('/vehicle/marketregion').then(response => {
				//console.log('\\forms\\vehicle.parameters.vue', 'created','response.data', response.data)
				that.setMarketregions(response.data)
			}, error => {
				console.log('error', error)
			});
		}
	},
	methods: {
		...mapActions([
			'setMarketregions'
		]),
	},
}
</script>