<template>
	<v-tooltip bottom :disabled="tooltipDisabled">
		<template v-slot:activator="{ on: tooltip, attrs }">
			<v-btn v-bind="attrs" v-on="{ ...tooltip, ...dialog }"
				:icon="icon"
				:text="text"
				:disabled="disabled"
				:color="color"
				:large="large" 
				:small="small"
				:dark="dark"
				:block="block"
				:outlined="outlined"
			>
				<v-icon>{{ iconName }}</v-icon>
				{{ title }}
			</v-btn>
		</template>
		<span>{{ tooltipText | capitalize }}</span>
	</v-tooltip>
</template>

<script>
export default {
	data: () => ({

	}),
	props: {
		//value:				{ type: Boolean, default: false }, //dialog
		value:				{ type: Object, default: () => {} }, //dialog
		title:				{ type: String, default: null },
        icon:				{ type: Boolean, default: false },
        text:				{ type: Boolean, default: false },
		iconName:			{ type: String, default: 'mdi-delete' },
        color:				{ type: String, default: null },
		large:     			{ type: Boolean, default: false },
        small:     			{ type: Boolean, default: false },
        dark:     			{ type: Boolean, default: false },
        block:     			{ type: Boolean, default: false },
        outlined:     		{ type: Boolean, default: false },
		disabled:  			{ type: Boolean, default: false }, //Ознака неактивного елементу

        tooltipText:		{ type: String, default: null },
		tooltipDisabled:	{ type: Boolean, default: false },
	},
	computed: {
		dialog: {
			get() 		{ return this.value },
			set(val) 	{ this.$emit('input', val) }
		},
	},
}

</script>