import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VBtn,{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.$router.push('/')}}},[_c(VIcon,[_vm._v("mdi-home")])],1)],1),_c(VCol,{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c(VCard,{attrs:{"elevation":"0","width":"600px"}},[_c(VToolbar,{staticClass:"elevation-0"},[_c(VIcon,{staticClass:"mr-6"},[_vm._v(_vm._s(_vm.icon))]),_c(VToolbarTitle,[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.'+_vm.title))))]),_c(VSpacer)],1),_c(VCardText,[_c(VForm,{ref:"form"},[_c(VTextField,{attrs:{"label":_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.email')),"prepend-icon":"mdi-email","type":"text","rules":[_vm.rules.required, _vm.rules.email],"required":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==90){ return null; }if(!$event.ctrlKey){ return null; }return _vm.OnDefault.apply(null, arguments)}},model:{value:(_vm.item.email),callback:function ($$v) {_vm.$set(_vm.item, "email", $$v)},expression:"item.email"}})],1),_c('z-alert',{ref:"alert"})],1),_c(VCardActions,[_c(VBtn,{attrs:{"large":"","block":"","loading":_vm.loading,"color":"success","dark":""},nativeOn:{"click":function($event){return _vm.OnOk.apply(null, arguments)}}},[_c(VIcon,{attrs:{"left":"","dark":""}},[_vm._v("mdi-check-bold")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.ok'))+" ")],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }