export default {
	namespaced: true,
	state: {
		name: 'menu',
		title: {
			list: 'menu',
			detail: 'menu',
		},
		icon: 'mdi-purse',

		items: [],
		items1: [],
		items2: [],
		items3: [],
	},
	getters: {
		items(state) {
			return state.items
		},
		items1(state) {
			return state.items1
		},
		items2(state) {
			return state.items2
		},
		items3(state) {
			return state.items3
		},
	},
	mutations: {
        items(state, value) {
            state.items = value
        },
		items1(state, value) {
            state.items1 = value
        },
		items2(state, value) {
            state.items2 = value
        },
		items3(state, value) {
            state.items3 = value
        },
    },
	actions: {
		Clear (context){
			context.commit('items')
		},
		Load (context, data) {

			/* 
				Повідомлення
				Справи
				Пошта
				Документи
				Банківські реквізити
				Об'єкти страхування
				Затраховані особи
				Договори страхування
				Виплати
				Довірені особи
				Адреси
				Контакти
				Учасники
				Оцінка якості
				Налаштування (мова, геолокація, виджети)
				Інтернет-магазин
				*/

			/* context.commit('items', [
				{ code: 'documents', to: {name: 'documents'}, icon: 'mdi-bookshelf',
					children: [
						{ code: 'policies', to: {name: 'policies'}, icon: 'mdi-bookshelf' },
						{ code: 'notifications', to: {name: 'notifications'}, icon: 'mdi-bullhorn' },
						{ code: 'cases', to: {name: 'cases'}, icon: 'mdi-bookshelf' },
						{ code: 'payments', to: {name: 'payments'}, icon: 'mdi-bookshelf' },
					],
				},
				{ code: 'calendar', to: {name: 'calendar'}, icon: 'mdi-bookshelf' },
			])
			context.commit('items1', [
				{ code: 'policies', to: {name: 'policies'}, icon: 'mdi-file-document-multiple-outline' },
				{ code: 'notifications', to: {name: 'notifications'}, icon: 'mdi-bullhorn' },
				{ code: 'cases', to: {name: 'cases'}, icon: 'mdi-bookshelf' },
				{ code: 'payments', to: {name: 'payments'}, icon: 'mdi-cash-multiple' },
			])
			context.commit('items2', [
				{ code: 'calendar', to: {name: 'calendar'}, icon: 'mdi-calendar' },
			])
			context.commit('items3', [

			]) */

			context.commit('items', [
				//Documents
				{
					items: [
						{ code: 'documents', to: {name: 'documents'}, icon: 'mdi-bookshelf' },
					]
				},
				//Templates
				{
					items: [
						{ code: 'constructors', to: {name: 'constructors'}, icon: 'mdi-tools',
							children: [
								{ code: 'templates', to: {name: 'templates'}, icon: 'mdi-bookshelf' },
								//{ code: 'notifications', to: {name: 'notifications'}, icon: 'mdi-bullhorn' },
							],
						},
					]
				},	
				//Calendar
				{
					items: [
						{ code: 'calendar', to: {name: 'calendar'}, icon: 'mdi-calendar' },
					]
				},
			])

		},
	},
}