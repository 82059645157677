<template>
	<!-- <v-dialog v-model="dialog" v-bind="$props"> -->
	<v-dialog v-model="dialog" 
		persistent 
		max-width="500px" 
		scrollable
	>
		<!-- :fullscreen="fullscreen"
		:hide-overlay=""
		:transition="transition"
		:scrollable="scrollable"
		:width="width"
		:height="height" -->

		<!-- Актіватор -->
		<template v-slot:activator="{ on: dialog, attrs }">
            <!-- activator - icon -->
            <v-tooltip bottom v-if="activator=='icon'" disabled>
                <template v-slot:activator="{ on: tooltip }">
                    <v-icon :small="small" v-bind="attrs" v-on="{ ...tooltip, ...dialog }" :disabled="disabled">{{activatorIcon}}</v-icon>
                </template>
                <span>{{title}}</span>
            </v-tooltip>
            
            <!-- activator - list-item -->
            <v-list-item v-else-if="activator=='list-item'" v-bind="attrs" v-on="{...dialog }" :disabled="disabled"> 
                <v-list-item-icon>
                    <v-icon color="primary" :disabled="disabled">{{activatorIcon}}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{title}}</v-list-item-title>
            </v-list-item>

            <!-- activator - button -->
            <v-tooltip bottom v-else disabled>
                <template v-slot:activator="{ on: tooltip }">
                    <!-- <v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...dialog }" :disabled="disabled" v-text="activatorName">
                        <v-icon color="primary">{{acvicon}}</v-icon>
                    </v-btn> -->
					<v-btn outlined color="primary" v-bind="attrs" v-on="{ ...tooltip, ...dialog }">
						<v-icon left dark color="primary">{{ activatorIcon }}</v-icon>
						{{ activatorName }}
					</v-btn>
                </template>
                <span>{{title}}</span>
            </v-tooltip>
        </template>

		<v-card elevation="4" :color="color">
			<slot name="toolbar">
				<v-toolbar elevation="0">
					<v-avatar>
						<v-icon>{{ icon }}</v-icon>
					</v-avatar>
					<v-toolbar-title>{{ title | capitalize }}</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-btn icon @click="OnClose" >
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<!-- <template v-slot:extension>
						<slot name="toolbarextension"></slot>
					</template> -->
				</v-toolbar>
			</slot>
			<v-card-text>
				<slot name="context">
					<FormDetail ref="detail" :type="type" :item="item"></FormDetail>
				</slot>
			</v-card-text>
			<v-card-actions v-if="!fullscreen">
				<v-btn text color="primary" @click="OnClear">Очистити</v-btn>
				<v-spacer></v-spacer>
				<v-btn text @click="OnCancel">{{ $vuetify.lang.t('$vuetify.cancel') }}</v-btn>
				<v-btn color="primary" dark @click.native="OnOK" :disabled="loading">{{ $vuetify.lang.t('$vuetify.ok') }}</v-btn>
			</v-card-actions>
		</v-card>

		<!-- <v-snackbar 
			v-model="snackbar" 
			vertical
			:timeout="3000"
			shaped
			:color="messagetype"
		>
			{{ message }}
			<template v-slot:action="{ attrs }">
				<v-btn color="indigo" text
					v-bind="attrs"
					@click="snackbar = false"
				>Close</v-btn>
			</template>
		</v-snackbar> -->
	</v-dialog>
</template>

<script>
import FormDetail from './vehicle.estimate.detail.vue'

export default {
	components: {
		FormDetail
	},
	data: () => ({
		dialog: false,
		loading: false,

		snackbar: false,
		message: null,
		messagetype: null, // success | info | warning | error
	}),
	props: {
		type: {
			type: String,
			default: 'metodica' // check | market | metodica | exploration | averageprice
		},
		item: { 
			type: Object,
			default: function () {
				return {}
			}
		},
		mode: { 
			type: String,
			default: 'edit' // add | edit | preview
		}, 
		
		//title:      	{ type: String, default: null },
		hint:       	{ type: String, default: null },
		//icon:       	{ type: String, default: null },
		fullscreen: 	{ type: Boolean, default: false },
		scrollable: 	{ type: Boolean, default: false },
		hideOverlay: 	{ type: Boolean, default: false },
		width:      	{ type: Number, default: 1024 },
		height:     	{ type: Number, default: 800 },
		transition: 	{ type: String, default: 'dialog-bottom-transition' },
		color:			{ type: String, default: null }, 		// Колір фону (card)
		

		//activator:			{ type: Object, default: () => {} },	// Параметри активатора

		small:      	{ type: Boolean, default: false },
		disabled:   	{ type: Boolean, default: false }, //Ознака неактивного елементу
		activator:  	{ type: String, default: 'button'}, // Тип активатора вікна: button | icon | menu-item | list-item

	},
	watch: {
		/* item: function (val) {
			if(val) {
				console.log('vehicle.estimate.dialog', 'watch', 'item', val)
			}
		}, */
		/* dialog: function (val) {
			if(val) {
				console.log('vehicle.estimate.dialog', 'watch:dialog', 'item', this.item)
			}
		}, */
	},
	computed: {
		title: function () {
			let result = 'Створити'
			switch(this.mode) {
				case 'edit': result = 'Редагувати'; break;
				case 'preview': result = 'Перегляд'; break;
			}
			return result
		},
		icon: function () {
			let result = 'mdi-plus'
			switch(this.mode) {
				case 'edit': result = 'mdi-pencil'; break;
				case 'preview': result = 'mdi-printer-eye'; break;
			}
			return result
		},

		activatorName: function () {
			let result = 'створити'
			switch(this.mode) {
				case 'edit': result = 'редагувати'; break;
				case 'preview': result = 'перегляд'; break;
			}
			return result
		},

		activatorIcon: function () {
			let result = 'mdi-plus'
			switch(this.mode) {
				case 'edit': result = 'mdi-pencil'; break;
				case 'preview': result = 'mdi-printer-eye'; break;
			}
			return result
		},

		/* tooltip: function () {
			let result = 'Створити'
			switch(this.mode) {
				case 'edit': result = 'Редагувати'; break;
				case 'preview': result = 'Перегляд'; break;
			}
			return result
		}, */
	},
	methods: {
		OnOpen (e) {
			this.dialog = true
		},

		OnClear (e) {
			this.$refs.detail.Clear()
			//this.item = {}
			//this.dialog = false
			//this.$emit('close', e)
		},
		OnClose (e) {
			this.dialog = false
			//this.OnClear()
			this.$emit('close', e)
		},
		OnCancel (e) {
			this.OnClose()
			this.$emit('cancel', e)
		},
		OnOK (e) {
			//console.log('OnOK', 'item', this.item)
			//console.log('OnOK', 'detail.item', this.$refs.detail.item)
			
			//this.$emit('ok', e)
			this.$emit(this.mode, this.$refs.detail.item)

			if(this.mode == 'add') this.OnClear()

			this.OnClose()
		},

		Message (text, type) {
			//console.log(this.modulename, 'Message', 'text', text)

			this.message = text
			this.snackbar = true
			this.messagetype = type
		},
	},
}
</script>