<template>
	<v-dialog  
	 	v-model="dialog"
	 	:fullscreen="fullscreen"
		transition="dialog-bottom-transition"
		width="800"
		height="1024"
		max-width="1024"
		scrollable
	>
		<!-- Актіватор -->
		<!-- <template v-slot:activator="{ on: dialog, attrs }">
			<z-activator v-bind="attrs" v-model="dialog"
				:type="activatorType"
				:icon-name="activatorIcon"
				:title="activatorTitle"
				:disabled="disabled"
				:outlined="activatorOutlined"
				:tooltip-text="activatorTooltipText"
				:tooltip-disabled="activatorTooltipDisabled"
			></z-activator>
		</template> -->
		<template v-slot:activator="{  on, attrs }">
			<slot name="activator" v-bind="attrs" v-on="on">
				<v-btn icon v-bind="attrs" v-on="on">
					<v-icon>mdi-pencil</v-icon>
				</v-btn>
			</slot>
		</template>

		<v-card elevation="4">
			<!-- Ttoolbar -->
			<v-toolbar elevation="0" dark color="primary" style="flex: 0 1 auto;">
				<v-avatar>
					<v-icon>{{ icon }}</v-icon>
				</v-avatar>
				<v-toolbar-title>{{ $vuetify.lang.t('$vuetify.' + title) | capitalize }}</v-toolbar-title>
				
				<v-spacer></v-spacer>
				
				<v-btn icon @click="fullscreen = !fullscreen" >
					<v-icon v-if="fullscreen">mdi-arrow-collapse</v-icon>
					<v-icon v-else>mdi-arrow-expand</v-icon>
				</v-btn>

				<v-btn icon @click="dialog=false" >
					<v-icon>mdi-close</v-icon>
				</v-btn>

				<template v-slot:extension>
					<!-- Tabs -->
					<v-tabs v-model="tab" align-with-title>
						<v-tabs-slider color="yellow"></v-tabs-slider>
						<v-tab v-for="item in tabs" :key="item">
							{{ item }}
						</v-tab>
					</v-tabs>

					<v-toolbar v-if="tab==1" dense color="primary" elevation="0">
						<z-field-search v-model="searchUsers"></z-field-search>
						<z-confirm-delete @ok="onRemoveUsers()" activator-type="button-icon" :disabled="users.length==0"></z-confirm-delete>
						<z-users @ok="onAddUsers($event)" activator-icon="mdi-plus" multiple :excluded="item.users"></z-users>
					</v-toolbar>
					
					<v-toolbar v-if="tab==2" dense color="primary" elevation="0">
						<z-field-search v-model="searchServices"></z-field-search>
						<z-confirm-delete @ok="onRemoveServices()" activator-type="button-icon" :disabled="services.length==0"></z-confirm-delete>
						<z-services @ok="onAddServices($event)" activator-icon="mdi-plus" multiple :excluded="item.services"></z-services>
					</v-toolbar>
				</template>
			</v-toolbar>

			<v-card-text style="height: 380px;">
				<v-tabs-items v-model="tab">
					<v-tab-item>
						<v-card flat>
							<v-card-text>
								<v-row>
									<v-col cols="12">
										<v-text-field
											label="Id"
											v-model="item.id"
											clearable
										></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field
											:label="$vuetify.lang.t('$vuetify.name') | capitalize"
											v-model="item.name"
											clearable
										></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-textarea
											v-model="item.description"
											:label="$vuetify.lang.t('$vuetify.comment') | capitalize"
											hint=""
											rows="2"
										></v-textarea>
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
					</v-tab-item>
					<v-tab-item>
						<v-list dense>
							<v-list-item-group v-model="users" multiple>
								<template v-for="(itm, index) in filteredUsers">
									<v-list-item >
											<template v-slot:default="{ active }">
												<v-list-item-action>
													<v-checkbox :input-value="active"></v-checkbox>
												</v-list-item-action>
												<v-list-item-content>
													<v-list-item-title v-text="itm.name"></v-list-item-title>
													<!-- <v-list-item-subtitle v-text="itm.description"></v-list-item-subtitle> -->
													<!-- <v-list-item-subtitle v-text="itm.type.name"></v-list-item-subtitle> -->
												</v-list-item-content>

												<v-list-item-action>
													<z-confirm-delete @ok="onRemoveUser(itm)"></z-confirm-delete>
												</v-list-item-action>
											</template>
									</v-list-item>
									<v-divider v-if="index < filteredUsers.length - 1" :key="index"></v-divider>
								</template>
							</v-list-item-group>
						</v-list>
					</v-tab-item>
					<v-tab-item>
						<v-list dense>
							<v-list-item-group v-model="services" multiple>
								<template v-for="(itm, index) in filteredServices">
									<v-list-item >
											<template v-slot:default="{ active }">
												<v-list-item-action>
													<v-checkbox :input-value="active"></v-checkbox>
												</v-list-item-action>
												<v-list-item-content>
													<v-list-item-title v-text="itm.name"></v-list-item-title>
													<!-- <v-list-item-subtitle v-text="itm.description"></v-list-item-subtitle> -->
													<v-list-item-subtitle v-text="itm.type.name"></v-list-item-subtitle>
												</v-list-item-content>

												<v-list-item-action>
													<z-confirm-delete @ok="onRemoveService(itm)"></z-confirm-delete>
												</v-list-item-action>
											</template>
									</v-list-item>
									<v-divider v-if="index < filteredServices.length - 1" :key="index"></v-divider>
								</template>
							</v-list-item-group>
						</v-list>
					</v-tab-item>
				</v-tabs-items>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn text @click.native="onCancel">{{ $vuetify.lang.t('$vuetify.cancel') }}</v-btn>
				<v-btn color="success" dark @click.native="onOK" :disabled="loading">{{ $vuetify.lang.t('$vuetify.ok') }}</v-btn>
			</v-card-actions>
		</v-card>

		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
	</v-dialog>
</template>

<script>
import mixin from './details/mixin/main'
import { mapGetters, mapActions } from 'vuex'
import zActivator from '@/views/components/activator/activator'
import zFieldSearch from '@/views/components/field.search'
import zConfirmDelete from '@/views/components/confirm-delete'
import zUsers from '@/views/dictionaries/Users'
import zServices from '@/views/dictionaries/Services'

export default {
	mixins: [mixin],
	components: {
		zActivator,
		zFieldSearch,
		zConfirmDelete,
		zUsers,
		zServices,
	},
	data: () => ({
		title: 'role',
		icon: 'mdi-account-tie',

        tabs: ['Головна', 'Користувачі', 'Сервіси'],

		users: [],
		searchUsers: '',
		services: [],
		searchServices: '',
	}),

	computed: {
		...mapGetters('Roles', [ 'endpoint', 'item' ]),
		
		filteredUsers() {
			return this.item.users.filter(item => {
				return item.id==this.searchUsers
					|| item.name.toLowerCase().includes(this.searchUsers.toLowerCase())
			})
		},

		filteredServices() {
			return this.item.services.filter(item => {
				return item.id==this.searchServices
					|| item.name.toLowerCase().includes(this.searchServices.toLowerCase())
			})
		}
	},

	methods: {
		...mapActions('Roles', [ 'setItem', 'setItems', 'createItem', 'updateItem', 'deleteItem' ]),

		onAddUsers (items) {
			this.addItems(this.item.users, items)
		},

		onRemoveUser (item) {
			this.removeItem(this.item.users, item)
		},

		onRemoveUsers() {
			this.removeItems(this.item.users, this.filteredUsers, this.users)
			this.users = []
		},

		onAddServices (items) {
			this.addItems(this.item.services, items)
		},

		onRemoveService (item) {
			this.removeItem(this.item.services, item)
		},

		onRemoveServices() {
			this.removeItems(this.item.services, this.filteredServices, this.services)
			this.services = []
		}
	},
}
</script>