export default {
    namespaced: true,
    state: {
		endpoint: 'services',
		item: {
			id: null,
			roles: [],
		},
        items: [],
    },
	actions: {
		clearItem(context) {
			context.commit('item', {
				id: null,
				roles: [],
			})
		},
	},
}