<template>
	<v-autocomplete
		v-model="model"
		:items="items"
		:loading="loading"
		:search-input.sync="search"
		clearable
		hide-details
		hide-selected
		item-text="name"
		item-value="id"
		label="Пошук лікарського засобу..."
		:outlined="outlined"
		:dense="dense"
		return-object
		@keyup.ctrl.90="onDefault"
		@keyup.enter="onSearch"
	>
		<template v-slot:no-data>
			<v-list-item>
			<v-list-item-title>
				Search for your favorite
				<strong>Cryptocurrency</strong>
			</v-list-item-title>
			</v-list-item>
		</template>
		<!-- <template v-slot:selection="{ attr, on, item, selected }">
			<v-chip
			v-bind="attr"
			:input-value="selected"
			color="blue-grey"
			class="white--text"
			v-on="on"
			>
			<v-icon left>
				mdi-bitcoin
			</v-icon>
			<span v-text="item.name"></span>
			</v-chip>
		</template> -->
		<template v-slot:item="{ item }">
			<!-- <v-list-item-avatar
				color="indigo"
				class="text-h5 font-weight-light white--text"
			>
			{{ item.name.charAt(0) }}
			</v-list-item-avatar> -->
			<v-list-item-content>
				<v-list-item-title v-text="item.name"></v-list-item-title>
				<v-list-item-subtitle v-text="item.symbol"></v-list-item-subtitle>
			</v-list-item-content>
			<!-- <v-list-item-action>
				<v-icon>mdi-bitcoin</v-icon>
			</v-list-item-action> -->
		</template>
	</v-autocomplete>
</template>

<script>
import axios from 'axios'
export default {
	data: () => ({
		loading: false,
		items: [],
		//model: null,
		search: null,
	}),

	props: {
		value: 			{ type: Object, default: () => {} },
		//value: 			{ type: String, default: null },
		label:  		{ type: String, default: null},
		placeholder:  	{ type: String, default: null},
		hint:  			{ type: String, default: null},
		persistentHint:	{ type: Boolean, default: false },
		readonly:		{ type: Boolean, default: false },
		clirable:		{ type: Boolean, default: false },
		outlined: 		{ type: Boolean, default: false },
		dense: 			{ type: Boolean, default: false },
		prependIcon:  	{ type: String, default: null}, //"mdi-calendar
		hideDetails: 	{ type: [Boolean,String], default: null },
		rules:			{ type: Array, default: () => [] },
	},

	computed: {
		model: {
			get() 		{ return this.value },
			set(val) 	{ this.$emit('input', val) }
		},

		/* drug() {
			return Array.isArray(this.model.drugs) && this.model.drugs.length ? this.model.drugs[0] : {}  ///this.model.hasOwnProperty('drugs')
		}, */
	},

	watch: {
		search (val) {
			// Items have already been loaded
			//if (this.items.length > 0) return

			//this.onSearch()
		},
    },
	
	methods: {
		onDefault() {
			this.model = {
				"id": "6781",
				"idm": "109182",
				"name": "НУРОФЕН ДЛЯ ДЕТЕЙ сусп. оральн. 100 мг/5 мл фл. 100 мл, с клубничным вкусом, Reckitt Benckiser Healthcare (UK)",
				"barcode": null,
				"number": "UA/7914/01/01",
				"datebegin": "2017-07-04T00:00:00+02:00",
				"dateend": "3000-01-01T00:00:00+01:00",
				"image": null,
				"datecreate": "2020-05-09T13:43:06+02:00",
				"dateedit": "2020-11-03T18:30:04+01:00",
				"maker": {
					"id": "110",
					"name": "Reckitt Benckiser Healthcare (UK)"
				},
				"type": {
					"id": "MEDECINES",
					"name": "Лекарственные средства"
				},
				"user": {
					"id": "1",
					"name": "Administrator"
				},
				"drugs": [
					{
						"id": "6704",
						"name": "НУРОФЕН ДЛЯ ДЕТЕЙ",
						"num": null,
						"medform": "сусп. оральн. 100 мг/5 мл фл. 100 мл, с клубничным вкусом"
					}
				]
			}
		},

		onSearch() {
			this.loading = true

			let params = {
				name: this.search
			}

			var that = this
			axios.get('/products/dic', { params: params }).then(response => {
				console.log(that.$options.name, 'onSearch','response.data', response.data)
				this.items = response.data
			}, error => {
				console.log('error', error)
			}).finally(() => (this.loading = false));
		},
		
	},
}
</script>