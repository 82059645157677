<template>
	<v-dialog  
	 	v-model="dialog"
	 	fullscreen
		transition_="dialog-bottom-transition"
		scrollable
	>
		<!-- Актіватор -->
		<template v-slot:activator="{  on, attrs }">
			<slot name="activator">
				<v-btn icon v-bind="attrs" v-on="on">
					<v-icon>mdi-eye</v-icon>
				</v-btn>
			</slot>
		</template>

		<v-card>
			<!-- Ttoolbar -->
			<v-toolbar elevation="0" dark color="primary" style="flex: 0 1 auto;">
				<v-avatar>
					<v-icon>{{ icon }}</v-icon>
				</v-avatar>
				<!-- <v-toolbar-title>{{ title }}</v-toolbar-title>
				<v-toolbar-subtitle>{{ title }}</v-toolbar-subtitle> -->
				<v-toolbar-title>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>{{ title }}</v-list-item-title>
							<v-list-item-subtitle>№{{ item.id }} {{ item.datecreate | formatDateTime }}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-toolbar-title>
				

				<v-spacer></v-spacer>

				<z-detail-script v-if="permitionScript" :item="item"></z-detail-script>

				<v-btn icon @click="dialog=false" >
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>

			<v-navigation-drawer
				style="transform: translateX(0) !important; visibility: visible !important;"
				permanent
				absolute
				fixed
				left
				width="300"
				class='mt-16'
				height=""
				floating
				disable-resize-watcher
			>
				<!-- <v-card elevation="0"> -->
				<v-card>
					<v-card-text>
						<!-- <z-vehicle-parameters-number></z-vehicle-parameters-number>
						<z-vehicle-parameters yearProdPeriod></z-vehicle-parameters> -->
						<!-- <v-subheader>Subheader</v-subheader>
						<v-title>Application</v-title> -->
						<!-- <v-text-field v-if="item.number" -->
						<v-text-field
							:value="item.number"
							label="Реєстраційний номер"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field>
						<v-text-field v-if="item.vin"
							:value="item.vin"
							label="VIN-код"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field>
						<v-text-field v-if="item.category"
							:value="item.category.name"
							label="Категорія"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field>
						<v-text-field v-if="item.brands"
							:value="item.brands.name"
							label="Марка"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field>
						<v-text-field v-if="item.model"
							:value="item.model.name"
							label="Модель"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field>
						<!-- <v-text-field v-if="item.hasOwnProperty('model')"
							:value="item.model.name"
							label="Модель"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field> -->
		
						<v-row no-gutters v-if="item.yearProdFrom || item.yearProdTo">
							<v-col cols="12" >
								<v-system-bar>
									<span>Рік випуску авто</span>
									<v-spacer></v-spacer>
								</v-system-bar>
							</v-col>
							<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
								<v-text-field
									:value="item.yearProdFrom"
									label="Від"
									outlined dense readonly hide-details="auto"
									class="my-3"
								></v-text-field>
							</v-col>
							<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
								<v-text-field
									:value="item.yearProdTo"
									label="До"
									outlined dense readonly hide-details="auto"
									class="my-3"
								></v-text-field>
							</v-col>
						</v-row>

						<v-text-field v-if="item.yearProd"
							:value="item.yearProd"
							label="Рік випуску авто"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field>

						<v-text-field v-if="item.generation"
							:value="item.generation.name"
							label="Покоління"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field>

						<v-text-field v-if="item.modification"
							:value="item.modification.name"
							label="Модифікація"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field>

						<v-text-field v-if="item.bodystyle"
							:value="item.bodystyle.name"
							label="Тип кузова"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field>
						<v-text-field v-if="item.driver"
							:value="item.driver.name"
							label="Тип приводу"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field>
						<!-- <v-text-field v-if="item.driver"
							:value="item.driver.name"
							label="Тип приводу"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field> -->
						<v-text-field v-if="item.engineVolume"
							:value="item.engineVolume"
							label="Об'єм двигуна, л."
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field>
						<v-text-field v-if="item.fuel"
							:value="item.fuel.name"
							label="Тип палива"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field>
						<v-text-field v-if="item.gearbox"
							:value="item.gearbox.name"
							label="Коробка передач"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field>
						<div v-if="options.length">Опції</div>
						<div class="text-center" v-if="options.length">
							<v-chip v-for="(item, index) in item.options" :key="index"
								class="ma-3"
								x-small
							>
								{{ item.name }}
							</v-chip>
						</div>
						<v-text-field v-if="item.color"
							:value="item.color.name"
							label="Колір"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field>
						<v-text-field v-if="item.country"
							:value="item.country.name"
							label="Країна виробник"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field>
						<v-text-field v-if="item.state"
							:value="item.state.name"
							label="Область"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field>
						<v-text-field v-if="item.city"
							:value="item.city.name"
							label="Місто"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field>

						<div>Пробіг, тис.км</div>
						<v-row no-gutters v-if="item.mileageFrom || item.mileageTo">
							<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
								<v-text-field
									:value="item.mileageFrom"
									label="Від"
									outlined dense readonly hide-details="auto"
									class="my-3"
								></v-text-field>
							</v-col>
							<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
								<v-text-field
									:value="item.mileageTo"
									label="До"
									outlined dense readonly hide-details="auto"
									class="my-3"
								></v-text-field>
							</v-col>
						</v-row>
						<v-text-field v-if="item.damage==0"
							value="Без ДТП"
							label="ДТП"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field>
						<v-text-field v-else-if="item.damage==1"
							value="Після ДТП"
							label="ДТП"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field>
						<v-text-field v-else
							value="Без ДТП і після ДТП"
							label="ДТП"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field>

						<v-text-field v-if="item.period==30"
							value="Останній місяць"
							label="Період оцінки"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field>
						<v-text-field v-else-if="item.period==90"
							value="3 місяці"
							label="Період оцінки"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field>
						<v-text-field v-else-if="item.period==180"
							value="Останні півроку"
							label="Період оцінки"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field>
						<v-text-field v-else-if="item.period==365"
							value="Останній рік"
							label="Період оцінки"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field>
						<v-text-field v-else-if="item.period>0"
							:value="`${item.period} днів`"
							label="Період оцінки"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field>
					</v-card-text>
				</v-card>
				<!-- <template v-slot:append>
					<v-card-actions class="px-4">
						<v-spacer></v-spacer>
						<v-btn text color="primary" @click="onClear">{{ $vuetify.lang.t('$vuetify.clear') }}</v-btn>
						<v-btn color="primary" @click="onOK">{{ $vuetify.lang.t('$vuetify.ok') }}</v-btn>
					</v-card-actions>
				</template> -->
			</v-navigation-drawer>

			<v-card-text>
				<v-container style="padding: 64px 0px 0px 256px;">
					<v-card v-for="(estimate, index) in item.estimates" :key="index"
						elevation="0"
					>
						<!-- <v-card-title class="text-h5">Оцінка</v-card-title>
						<v-card-subtitle class="font-weight-light">{{ estimate.id }} - {{ estimate.code }}</v-card-subtitle> -->
						<v-toolbar elevation="0">
							<!-- <v-avatar>
								<v-icon>{{ icon }}</v-icon>
							</v-avatar> -->
							<v-toolbar-title>
								<v-list-item two-line>
									<v-list-item-content>
										<v-list-item-title class="text-h5">Оцінка</v-list-item-title>
										<v-list-item-subtitle>{{ estimate.code }} - {{ estimate.id }}</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
							</v-toolbar-title>

							<v-spacer></v-spacer>

							<!-- <v-btn icon @click="dialog=false" >
								<v-icon>mdi-close</v-icon>
							</v-btn> -->
							<!-- <z-detail-script
								:item="item"
							></z-detail-script> -->
						</v-toolbar>

						<v-divider></v-divider>

						<v-card-text>
							<v-list two-line>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title>Загальна кількість оголошень</v-list-item-title>
										<v-list-item-subtitle>Метод медіани</v-list-item-subtitle>
									</v-list-item-content>
									<v-list-item-action>
										<v-list-item-action-text class="text-right" v-text="estimate.total"></v-list-item-action-text>
									</v-list-item-action>
								</v-list-item>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title>Середнє арифметичне</v-list-item-title>
										<v-list-item-subtitle>Метод медіани</v-list-item-subtitle>
									</v-list-item-content>
									<v-list-item-action>
										<v-list-item-action-text class="text-right" v-text="estimate.arithmeticMean"></v-list-item-action-text>
									</v-list-item-action>
								</v-list-item>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title>Середнє арифметичне без урахування 25% найменших і найбільших значень</v-list-item-title>
										<v-list-item-subtitle>Метод медіани</v-list-item-subtitle>
									</v-list-item-content>
									<v-list-item-action>
										<v-list-item-action-text class="text-right" v-text="estimate.interQuartileMean"></v-list-item-action-text>
									</v-list-item-action>
								</v-list-item>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title>Середня ціна в UAH</v-list-item-title>
										<v-list-item-subtitle>Метод AI {{ estimate.minPriceUAH }} <-> {{ estimate.maxPriceUAH }}</v-list-item-subtitle>
									</v-list-item-content>
									<v-list-item-action>
										<v-list-item-action-text class="text-right" v-text="estimate.avgPriceUAH"></v-list-item-action-text>
									</v-list-item-action>
								</v-list-item>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title>Середня ціна в USD</v-list-item-title>
										<v-list-item-subtitle>Метод AI {{ estimate.minPriceUSD }} <-> {{ estimate.maxPriceUSD }}</v-list-item-subtitle>
									</v-list-item-content>
									<v-list-item-action>
										<v-list-item-action-text class="text-right" v-text="estimate.avgPriceUSD"></v-list-item-action-text>
									</v-list-item-action>
								</v-list-item>
							</v-list>
							<!-- <v-simple-table dense>
								<template v-slot:default>
									<tbody>
										<tr>
											<td class="text-left">Загальна кількість оголошень, що беруть участь у підрахунку</td>
											<td class="text-right">{{ estimate.total }}</td>
										</tr>
										<tr>
											<td class="text-left">Середнє арифметичне</td>
											<td class="text-right">{{ estimate.arithmeticMean }}</td>
										</tr>
										<tr>
											<td class="text-left">Середнє арифметичне без урахування 25% найменших і найбільших значень</td>
											<td class="text-right">{{ estimate.interQuartileMean }}</td>
										</tr>
										<tr>
											<td class="text-left">Середня ціна в UAH</td>
											<td class="text-right">{{ estimate.avgPriceUAH }}</td>
										</tr>
										<tr>
											<td class="text-left">Середня ціна в USD</td>
											<td class="text-right">{{ estimate.avgPriceUSD }}</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table> -->


							<v-card class="mx-auto" elevation="0" v-if="estimatePercentiles(estimate).length">
								<v-card-title class="text-h6">Процентілі</v-card-title>
								<v-card-subtitle>Алгоритм розрахунку середньої ціни за методом медіани</v-card-subtitle>
								<v-simple-table dense>
									<template v-slot:default>
										<thead>
											<tr>
												<th class="text-left" v-for="percentile in estimatePercentiles(estimate)" :key="percentile.id" >{{ percentile.code }}</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td v-for="percentile in estimatePercentiles(estimate)" :key="percentile.id">{{ parseInt(percentile.price) }}</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-card>
							
							<v-card v-if="estimateAdverts(estimate).length"
								class="mx-auto mt-8"
								elevation="0"
							>
								<v-card-title class="text-h6">Об'яви</v-card-title>
								<v-card-subtitle>Алгоритми розрахунку середньої ціни за методом медіани за з використанням AI</v-card-subtitle>

								<v-card-text>
									<v-chip-group active-class="primary--text" column>
										<v-chip v-for="advert in adverts(estimateAdverts(estimate))" :key="advert.id" :href="getAdvertUrl(advert)"  target="_blank">
											{{ parseInt(advert.price) }}
										</v-chip>
									</v-chip-group>
								</v-card-text>
							</v-card>

							<v-card v-if="estimatePrices(estimate).length"
								elevation="0"
							>
								<v-card-title class="text-h6">Ціни</v-card-title>
								<v-card-subtitle>Статистика за період</v-card-subtitle>

								<v-simple-table dense>
									<template v-slot:default>
										<thead>
											<tr>
												<th>Дата</th>
												<th>Вартість авто в UAH</th>
												<th>Вартість авто в USD</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(item, index) in estimatePrices(estimate)" :key="index">
												<td >{{ item.name }}</td>
												<td >{{ item.priceUAH }}</td>
												<td >{{ item.priceUSD }}</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-card>

							<v-card v-if="increas.length"
								elevation="0"
							>
								<v-card-title class="text-h6">Фактори, що впливають на процент збільшення вартості</v-card-title>
								<!-- <v-card-subtitle>Статистика за період</v-card-subtitle> -->

								<v-simple-table dense>
									<template v-slot:default>
										<thead>
											<tr>
												<th width="80%" class="text-left">Найменування факторів, які визначають умови експлуатації</th>
												<th width="20%" class="text-left">Дз, %</th>
												<!-- <th width="30%" class="text-left">Опис</th> -->
											</tr>
										</thead>
										<tbody>
											<tr v-for="(item, index) in increas" :key="index" >
												<td>{{ item.name }}</td>
												<!-- <td>{{ item.code }}</td> -->
												<td>{{ item.type.val }}</td>
												<!-- <td>{{ item.description }}</td> -->
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-card>

							<v-card v-if="reduce.length"
								elevation="0"
							>
								<v-card-title class="text-h6">Фактори, що впливають на процент зменшення вартості</v-card-title>
								<!-- <v-card-subtitle>Статистика за період</v-card-subtitle> -->

								<v-simple-table dense>
									<template v-slot:default>
										<thead>
											<tr>
												<th width="80%" class="text-left">Найменування факторів, які визначають умови експлуатації</th>
												<th width="20%" class="text-left">Дз, %</th>
												<!-- <th width="30%" class="text-left">Опис</th> -->
											</tr>
										</thead>
										<tbody>
											<tr v-for="(item, index) in reduce" :key="index" >
												<td>{{ item.name }}</td>
												<td>{{ item.type.val }}</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-card>


							<v-card v-if="elements.length"
								elevation="0"
							>
								<v-card-title class="text-h6">Проценти додаткового зменшення вартості КТЗ з дефектами кузова, кабіни, рами</v-card-title>
								<!-- <v-card-subtitle>Статистика за період</v-card-subtitle> -->

								<v-simple-table dense>
									<template v-slot:default>
										<thead>
											<tr>
												<th width="80%" class="text-left">Найменування елементів, складових частин КТЗ</th>
												<th width="20%" class="text-left">Дз, %</th>
												<!-- <th width="30%" class="text-left">Опис</th> -->
											</tr>
										</thead>
										<tbody>
											<tr v-for="(item, index) in elements" :key="index" >
												<td>{{ item.name }}</td>
												<td>{{ item.type.val }}</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-card>

							<v-card v-if="factors.length"
								elevation="0"
							>
								<v-card-title class="text-h6">На основі аналізу довідкових даних щодо даного транспортного засобу встановлені такі показники:</v-card-title>
								<!-- <v-card-subtitle>Статистика за період</v-card-subtitle> -->

								<v-simple-table dense>
									<template v-slot:default>
										<thead>
											<tr>
												<th width="50%" class="text-left">Показник</th>
												<th width="10%" class="text-left">Умовне позначення</th>
												<th width="10%" class="text-left">Значення</th>
												<th width="30%" class="text-left">Опис</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(item, index) in factors" :key="item.id" >
												<td>{{ item.name }}</td>
												<td>{{ item.code }}</td>
												<td>{{ item.val }}</td>
												<td>{{ item.description }}</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-card>
						</v-card-text>
					</v-card>
				</v-container>
				
			</v-card-text>
			<!-- <v-card-actions>
				<v-spacer></v-spacer>
				<v-btn text @click.native="onCancel">{{ $vuetify.lang.t('$vuetify.cancel') }}</v-btn>
				<v-btn color="success" dark @click.native="onOK" :disabled="loading">{{ $vuetify.lang.t('$vuetify.ok') }}</v-btn>
			</v-card-actions> -->
		</v-card>

		

		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
	</v-dialog>
</template>

<script>
import axios from 'axios'
//import mixin from './mixin/main'
import { mapGetters, mapActions } from 'vuex'
import zActivator from '@/views/components/activator/activator'
import zFieldSearch from '@/views/components/field.search'
import zConfirmDelete from '@/views/components/confirm-delete'
import zUsers from '@/views/dictionaries/Users'
import zServices from '@/views/dictionaries/Services'
import zDetailScript from '@/views/details/Script'

export default {
	name: 'VehicleRequestDetail',
	//mixins: [mixin],
	components: {
		zActivator,
		zFieldSearch,
		zConfirmDelete,
		zUsers,
		zServices,
		zDetailScript,
	},
	data: () => ({
		//title: 'role',
		icon: 'mdi-home-analytics',
		dialog: false,
		loading: false,
		drawer: true,

		item: {},
	}),

	props: {
		//item: 					{ type: Object, default: {} },
		selected: 					{ type: Object, default: {} },
	},

	computed: {
		...mapGetters(['roles']),
		//...mapGetters('Roles', [ 'endpoint', 'item' ]),
		
		filteredUsers() {
			return this.item.users.filter(item => {
				return item.id==this.searchUsers
					|| item.name.toLowerCase().includes(this.searchUsers.toLowerCase())
			})
		},

		filteredServices() {
			return this.item.services.filter(item => {
				return item.id==this.searchServices
					|| item.name.toLowerCase().includes(this.searchServices.toLowerCase())
			})
		},

		/* item() {
			return this.selected
		},

		item() {
			return this.selected
		},

		this.title */

		title() {
			return this.item?.type?.name
		},

		options() {
			return this.item.hasOwnProperty('options') ? this.item.options: [];
		},

		factors() {
			return this.item.hasOwnProperty('factors') ? this.item.factors: [];
		},

		// Фактори, що впливають на процент збільшення вартості КТЗ
		increas() {
			//return this.item.hasOwnProperty('increas') ? this.item.increas: [];
			return this.item.hasOwnProperty('uses') ? this.item.uses.filter((item) => item.type.growing && !item.type.element ): [];
		},

		// Фактори, що впливають на процент зниження вартості КТЗ
		reduce() {
			//return this.item.hasOwnProperty('reduce') ? this.item.reduce: [];
			return this.item.hasOwnProperty('uses') ? this.item.uses.filter((item) => !item.type.growing && !item.type.element ): [];
		},

		// Проценти додаткового зменшення вартості КТЗ з дефектами кузова, кабіни, рами
		elements() {
			//return this.item.hasOwnProperty('elements') ? this.item.elements: [];
			return this.item.hasOwnProperty('uses') ? this.item.uses.filter((item) => !item.type.growing && item.type.element ): [];
		},

		permitionScript() {
			return this.roles.includes('ADMIN')
		},		
	},

	watch: {
		dialog: function (val) {
			if(val) {
				this.find()
			}
		},
		/* dialog: function (val) {
			//this.drawer = this.dialog
			if(val) {
				this.onOpen()
			} else {
				this.onClose()
			}
		}, */

		drawer(val) {
			console.log(this.$options.name, 'drawer','val', val)
		},
	},

	methods: {
		/**
		 * Пошук
		 */
		 find () {
			var that = this;
			if(this.selected?.id) {

				//let params = []
				//if(this.datebegin) 	params['datebegin'] = this.datebegin
				//if(this.dateend) 	params['dateend'] = this.dateend

				//console.log(this.$options.name, 'find','params', params)

				this.loading = true;
				axios.get(`vehicle/request/${this.selected.id}`).then(response => {
					//console.log(that.$options.name, 'find','response.data', response.data)
					that.item = Object.assign({}, response.data)
				}, error => {
					console.log(error)
				}).finally(() => {
					this.loading = false
				});
			}
		},

		onOpen () {
			//console.log(this.$options.name, 'onOpen','item', this.item)

			//this.find(this.selected)
			//this.drawer = true
		},

		onClose () {
			//this.setItem()
		},

		estimatePercentiles(item) {
			return item.hasOwnProperty('percentiles') ? item.percentiles : [];
		},

		estimateAdverts(item) {
			return item.hasOwnProperty('adverts') ? item.adverts : [];
		},

		estimatePrices(item) {
			return item.hasOwnProperty('prices') ? item.prices : [];
		},

		adverts(items) {
			const sorted = [...items].sort((a, b) => parseInt(a.price) - parseInt(b.price));
			return sorted
		},

		getAdvertUrl(item) {
			//console.log('VehicleEstimateMarket', 'getAdvertUrl', 'item', item)

			return `https://auto.ria.com/uk/auto_${this.brand}_${this.model}_${item.code}.html`
		},

		labels(items) {
			return items.map(element=>element.name);
		},

		valueUAH(items) {
			return items.map(element=>element.priceUAH);
		},

		valueUSD(items) {
			return items.map(element=>element.priceUSD);
		},

		avgUAH(items) {
			const arr = this.valueUAH(items);
			const average = arr.reduce((a, b) => a + b, 0) / arr.length;
			return average
		},

		avgUSD(items) {
			const arr = this.valueUSD(items);
			const average = arr.reduce((a, b) => a + b, 0) / arr.length;
			return average
		},

		minUAH(items) {
			const arr = this.valueUAH(items);
			return Math.min(...arr)
		},
		minUSD(items) {
			const arr = this.valueUSD(items);
			return Math.min(...arr)
		},

		maxUAH(items) {
			const arr = this.valueUAH(items);
			return Math.max(...arr)
		},
		maxUSD(items) {
			const arr = this.valueUSD(items);
			return Math.max(...arr)
		},
	},
}
</script>