import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"400"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.onCancel.apply(null, arguments)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [_c('z-activator',_vm._b({attrs:{"type":_vm.activatorType,"icon-name":_vm.activatorIcon,"title":_vm.activatorTitle,"disabled":_vm.disabled,"tooltip-text":_vm.activatorTooltipText,"tooltip-disabled":_vm.activatorTooltipDisabled},model:{value:(dialog),callback:function ($$v) {dialog=$$v},expression:"dialog"}},'z-activator',attrs,false))]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.title)+" "),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.dialog=false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VDivider),_c(VCardText,[_vm._t("text",function(){return [_c('div',[_vm._v(_vm._s(_vm.text))])]})],2),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"grey","dark":"","small":""},nativeOn:{"click":function($event){return _vm.onCancel.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.not')))]),_c(VBtn,{attrs:{"color":"red","dark":"","small":""},nativeOn:{"click":function($event){return _vm.onOK.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.yes')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }