<template>
    <v-card elevation="0" v-if="visible">
        <v-card-text class="text--primary" v-show="visibleResult">
            <div class="text-overline text-center font-weight-bold">ВИСНОВОК</div>
            <div class="text-overline text-center font-weight-bold">автотоварознавчого дослідження з оцінки транспортного засобу</div>

            <!-- <p class="text-subtitle-2 font-weight-bold">1. Транспортний засіб</p>
            <p class="text-subtitle-2 font-weight-bold">2. Оцінка ринку</p>
            <p class="text-subtitle-2 font-weight-bold">3. Ринкова вартість</p> -->
            
            <v-expansion-panels v-model="panel" multiple style="position: static;">
                
                <v-expansion-panel v-if="vehicles">
                    <v-expansion-panel-header class="font-weight-bold" color="grey lighten-5">Транспортний засіб</v-expansion-panel-header>
                    <v-expansion-panel-content v-if="vehicles">
                        <v-row>
                            <v-col cols="12"  xs="4" sm="4" md="4" lg="4" xl="4">
                                <v-list dense>
                                    <v-list-item v-for="(vehicle, i) in vehicles.filter(item => item.l==0)" :key="i" style="max-height: 24px;">
                                        <v-list-item-content>
                                            <v-list-item-title v-text="vehicle.title"></v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-list-item-action-text v-text="vehicle.value"></v-list-item-action-text>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                            </v-col>

                            <v-col cols="12"  xs="4" sm="4" md="4" lg="4" xl="4">
                                <v-list dense>
                                    <v-list-item v-for="(vehicle, i) in vehicles.filter(item => item.l==1)" :key="i" style="max-height: 24px;">
                                        <v-list-item-content>
                                            <v-list-item-title v-text="vehicle.title"></v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-list-item-action-text v-text="vehicle.value"></v-list-item-action-text>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                            </v-col>

                            <v-col cols="12"  xs="4" sm="4" md="4" lg="4" xl="4">
                                <v-list dense>
                                    <v-list-item v-for="(vehicle, i) in vehicles.filter(item => item.l==2)" :key="i" style="max-height: 24px;">
                                        <v-list-item-content>
                                            <v-list-item-title v-text="vehicle.title"></v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-list-item-action-text v-text="vehicle.value"></v-list-item-action-text>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                            <v-col cols="12" v-if="item.hasOwnProperty('params')">
                                <v-btn text small color="indigo" @click="$emit('setparams', item.params)">
                                    <v-icon left>mdi-arrow-left-thin</v-icon>
                                    в параметри
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel v-if="item.hasOwnProperty('market')">
                    <v-expansion-panel-header class="font-weight-bold" color="grey lighten-5">Оцінка ринку</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-list dense >
                            <v-list-item v-for="(key) in ['total', 'arithmeticMean', 'arithmeticMeanQuartile']" :key="key"  style="max-height: 24px;">
                                <v-list-item-content>
                                    <v-list-item-title v-text="item.market[key].name"></v-list-item-title>
                                    <v-list-item-subtitle class="text-caption" v-text="item.market[key].description"></v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-list-item-action-text v-text="item.market[key].value"></v-list-item-action-text>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>

                        <v-card elevation="0">
                            <v-card-title class="text-subtitle-2 font-weight-bold">Процентілі</v-card-title>
                            <v-simple-table dense>
                                <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left" v-for="(value, key) in item.prices.percentiles" :key="key" >{{ key }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td v-for="(value, key) in item.prices.percentiles" :key="key">{{ parseInt(value) }}</td>
                                    </tr>
                                </tbody>
                                </template>
                            </v-simple-table>
                        </v-card>

                        <v-expansion-panel style="position: static;">
                            <v-expansion-panel-header class="text-subtitle-2 font-weight-bold">Ціни</v-expansion-panel-header>
                            <v-expansion-panel-content style="display: none;">
                                <v-chip-group active-class="primary--text" column>
                                    <v-chip v-for="(item, index) in sortByPrice(item.market.offers)" :key="index" :href="item.url"  target="_blank">												
                                        {{ parseInt(item.price) }}
                                    </v-chip>
                                </v-chip-group>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- Фактори, що впливають на процент збільшення вартості КТЗ -->
                <v-expansion-panel v-if="['exploration'].includes(type)">
                    <v-expansion-panel-header class="font-weight-bold" color="grey lighten-5">Фактори, що впливають на процент збільшення вартості КТЗ</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <!-- <p>
                            Чинники, що використовуються у розрахунку вартості:
                        </p> -->
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th width="80%" class="text-left">Найменування факторів, які визначають умови експлуатації</th>
                                        <th width="20%" class="text-left">Дз, %</th>
                                        <!-- <th width="30%" class="text-left">Опис</th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in item.increas" :key="index" >
                                        <td>{{ item.name }}</td>
                                        <!-- <td>{{ item.code }}</td> -->
                                        <td>{{ item.value }}</td>
                                        <!-- <td>{{ item.description }}</td> -->
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- Фактори, що впливають на процент зниження вартості КТЗ -->
                <v-expansion-panel v-if="['exploration'].includes(type)">
                    <v-expansion-panel-header class="font-weight-bold" color="grey lighten-5">Фактори, що впливають на процент зменшення вартості КТЗ</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th width="80%" class="text-left">Найменування факторів, які визначають умови експлуатації</th>
                                        <th width="20%" class="text-left">Дз, %</th>
                                        <!-- <th width="30%" class="text-left">Опис</th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in item.reduce" :key="index" >
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.value }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- Проценти додаткового зменшення вартості КТЗ з дефектами кузова, кабіни, рами -->
                <v-expansion-panel v-if="['exploration'].includes(type)">
                    <v-expansion-panel-header class="font-weight-bold" color="grey lighten-5">Проценти додаткового зменшення вартості КТЗ з дефектами кузова, кабіни, рами</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th width="80%" class="text-left">Найменування елементів, складових частин КТЗ</th>
                                        <th width="20%" class="text-left">Дз, %</th>
                                        <!-- <th width="30%" class="text-left">Опис</th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in item.elements" :key="index" >
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.value }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-expansion-panel-content>
                </v-expansion-panel>


                <!-- Вартість -->
                <v-expansion-panel v-if="item.hasOwnProperty('adjustment')">
                    <v-expansion-panel-header class="font-weight-bold" color="grey lighten-5">Вартість</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <p>
                            На основі аналізу довідкових даних щодо даного транспортного засобу встановлені такі показники:
                        </p>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th width="50%" class="text-left">Показник</th>
                                        <th width="10%" class="text-left">Умовне позначення</th>
                                        <th width="10%" class="text-left">Значення</th>
                                        <th width="30%" class="text-left">Опис</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in item.adjustment" :key="index" >
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.code }}</td>
                                        <td>{{ item.value }}</td>
                                        <td>{{ item.description }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel v-if="item.hasOwnProperty('conclusion')">
                    <v-expansion-panel-header class="font-weight-bold" color="grey lighten-5">ВИСНОВОК</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <p>
                            Дата оцінки: {{ item.conclusion.date }}
                        </p>
                        <p>
                            Ринкова вартість автомобіля <b>{{item.vehicle.brand.value}} {{item.vehicle.model.value}}</b>, {{item.vehicle.hasOwnProperty('regnum')?"реєстраційний номер " + item.vehicle.regnum.value+ ",": ""}}  на дату оцінки становить  
                        </p>
                        <v-row>
                            <v-col cols="12"  xs="6" sm="6" md="6" lg="6" xl="6" class="text-h6 text-left">
                                <!-- Дата: {{ item.conclusion.date }} -->
                            </v-col>
                            <v-col cols="12"  xs="6" sm="6" md="6" lg="6" xl="6" class="text-h5 text-right">
                                ${{ item.conclusion.value }}
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>

				<!-- Середня ціна -->
                <!-- <v-expansion-panel v-if="item.hasOwnProperty('points')"> -->
				<v-expansion-panel v-if="['averageprice'].includes(type)">
                    <v-expansion-panel-header class="font-weight-bold" color="grey lighten-5">Середня ціна</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <p>
                            Середня ціна
                        </p>
                        <!-- <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th width="50%" class="text-left">Показник</th>
                                        <th width="10%" class="text-left">Умовне позначення</th>
                                        <th width="10%" class="text-left">Значення</th>
                                        <th width="30%" class="text-left">Опис</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in item.adjustment" :key="index" >
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.code }}</td>
                                        <td>{{ item.value }}</td>
                                        <td>{{ item.description }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table> -->
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    data () {
        return {
            panel: [0,1,2,3,4,5,6,7,8,9,10]
        }
    },
    props: {
        type: {
			type: String,
			default: 'metodica' // check | market | metodica | exploration | averageprice
		},
        item: {
			type: Object,
			default() {
				return {}
			}
		},
        visible: {
			type: Boolean,
			default: true
		},
    },

    watch: {
		/* 'item.brand': function (val) {
			if(val) {
				//this.Models({category: this.category.value, brand: val.value})
				this.Models({brand: val.id})
			}
		}, */
        /* vehicle: function (val) {
			if(val) {
				this.item = Object.assign({}, val)
			}
		}, */
	},
    computed: {
        /* ...mapGetters('vehicles',[
			'categories', 'brands', 'models', 'engines', 'gearboxes', 'bodies',
			'years',
		]), */
        vehicles () {
            console.log('vehicle.estimate.result','computed.vehicles', 'item', this.item)

			var result = null
			if(this.item.hasOwnProperty('vehicle')) {
				result = []
				for(let key in this.item.vehicle) {
					let item = this.item.vehicle[key]
					//console.log('vehicles', 'item', item)
					result.push({
						title: item.name,
						value: item.value
					})
				}

				//Розподілення на два списки
				for(let i in result) {
					result[i]['l'] = i % 3
					//console.log(i, i % 3)
				}
			}
			//console.log('mono','computed.vehicles', 'result', result)
			return result
		},

        visibleResult () {
            return Object.keys(this.item).length > 0 
        },

    },
    methods: {
        sortByPrice: function(arr) {
			return arr.slice().sort(function(a, b) {
				return a.price - b.price;
			});
		},

        // OnSetParams
    },
}
</script>