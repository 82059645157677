<template>
	<v-main>
        <!-- <v-container> -->
           <!--  <v-toolbar elevation="0">
				<v-btn icon color="primary" @click="$router.push('/')">
					<v-icon>mdi-home</v-icon>
				</v-btn>
				<v-toolbar-title class="font-weight-light">{{ $vuetify.lang.t('$vuetify.' + title) | capitalize }}</v-toolbar-title>
				<v-spacer></v-spacer>
			</v-toolbar> -->

			<v-data-table
				v-model="selected"
				:headers="headers"
				:items="items"
				item-key="id"
				show-select
				:search="search"
				:loading="loading"
				loading-text="Завантаження даних... зачекайте, будь ласка."
				sort-by="id"
				sort-desc
				:items-per-page="itemsPerPage"
				hide-default-footer
			>

			<!-- 
			:items-per-page="itemsPerPage"
				hide-default-footer
				:page.sync="page" 
				@page-count="pageCount = $event"
			-->
			<!-- :search="search"
				:custom-filter="customFilter" -->
				<template v-slot:top>
					<v-toolbar flat>
						<v-toolbar-title>
							{{ (title in $vuetify.lang.locales.en ? $vuetify.lang.t('$vuetify.' + title) : title ) | capitalize }}
						</v-toolbar-title>

						<v-divider class="mx-4" inset vertical ></v-divider>

						<v-spacer></v-spacer>

						<v-menu
							v-model="menu.filter"
							:close-on-content-click="false"
							:nudge-width="200"
							offset-x
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn v-bind="attrs" v-on="on" icon>
									<v-icon>mdi-filter-variant</v-icon>
								</v-btn>
							</template>

							<v-card>
								<v-card-title>Фільтр</v-card-title>
								<v-card-text>
									<v-list>
										<v-list-item>
											<v-list-item-action>
												<v-switch 
													v-model="onlymy"
													false-value="0"
													true-value="1"
												></v-switch>
											</v-list-item-action>
											<v-list-item-title>Тільки мої запити</v-list-item-title>
										</v-list-item>
									</v-list>

									<v-combobox v-if="isAdmin"
										v-model="organization"
										:items="organizations"
										label="Організація"
										dense
										outlined
										item-text="name"
										item-value="id"
										:loading="loadings.organization"
										@focus="loadOrganizations"
									></v-combobox>

									<v-text-field
										v-model.number="itemsPerPage"
										prepend-icon_="mdi-book-open-page-variant"
										label="Кількість рядків на сторінку"
										dense
										hide-details="auto"
										outlined
										type="number"
										:min="10"
										:max="100"
									></v-text-field>
								</v-card-text>
								<v-card-actions>
									<v-spacer></v-spacer>
									<v-btn text @click="menu.filter = false">Cancel</v-btn>
									<v-btn color="primary" text @click="onFilter">OK</v-btn>
								</v-card-actions>
							</v-card>
						</v-menu>

						<v-text-field
							v-model="search"
							append-icon="mdi-magnify"
							label="Пошук"
							dense
							single-line
							hide-details
							outlined
							rounded
						></v-text-field>

						<!-- <z-dialog-statistic :selected="selected"></z-dialog-statistic> -->
						<z-dialog-compare :selected="selected"></z-dialog-compare>
						<v-btn icon @click.stop="onRefresh">
							<v-icon>mdi-refresh</v-icon>
						</v-btn>

						<!-- Пошук -->
						<!-- <z-search v-model="search"></z-search> -->
					</v-toolbar>
				</template>

				<template v-slot:[`item.actions`]="{ item }">
					<v-toolbar elevation="0" dense color="transparent">
						<!-- PDF -->
						<v-icon color="red" @click="onPDF(item)">mdi-file-pdf-box</v-icon>

						<!-- Редагувати -->
						<z-detail :selected="item"></z-detail>
						<!-- activator-type="button"
							activator-outlined
							:activator-title="$vuetify.lang.t('$vuetify.create')"
							activator-tooltip-disabled -->
						<!-- Видалити -->
						<!-- <z-confirm 
							activator-tooltip-text="delete"
							@ok="onDelete(item)"
						></z-confirm> -->
					</v-toolbar>
				</template>

				<template v-for="(item, index) in headers" v-slot:[`header.${item.value}`]="{  }">
					<span :key="index">{{ (item.text in $vuetify.lang.locales.en ? $vuetify.lang.t('$vuetify.' + item.text) : item.text ) | capitalize }}</span>
				</template>

				<template v-slot:[`item.datecreate`]="{ item }">
					<!-- <span>{{ item.datecreate | dd_mm_yyyy }}</span> -->
					<span>{{ item.datecreate | formatDateTime }}</span>
				</template>
				
				<template v-slot:[`item.icon`]="{ item }">
					<v-icon>{{ item.icon }}</v-icon>
				</template>

				<!-- <template v-slot:[`item.code`]="{ item }">
					<span>{{ avgStatisticUAH(item) }}</span>
				</template>
				<template v-slot:[`item.vin`]="{ item }">
					<span>{{ avgStatisticUSD(item) }}</span>
				</template> -->
				

				<!-- 
				valueUSD(items) {
					return items.map(element=>element.priceUSD);
				},

				
				avgUSD(items) {
					const arr = items.map(element=>element.priceUSD);
					const average = arr.reduce((a, b) => a + b, 0) / arr.length;
					return average
				}, -->

			</v-data-table>
			<!-- <div class="text-center pt-2">
				<v-pagination
					v-model="page"
					:length="pageCount"
					@next="onNext"
					@previous="onPrevious"
				></v-pagination>
			</div> -->

			<v-footer app padless>
				<v-card flat tile width="100%" class="text-center">
					<v-card-text>
						<!-- <v-btn icon @click="onPrevious">
							<v-icon>mdi-chevron-left</v-icon>
						</v-btn>
						<v-btn text rounded>
							{{ page }}
						</v-btn>
						<v-btn icon @click="onNext">
							<v-icon>mdi-chevron-right</v-icon>
						</v-btn> -->
						<v-pagination
							v-model="page"
							:length="pageCount"
							:total-visible="7"
							
						></v-pagination>
						<!-- @next="onNext"
							@previous="onPrevious" -->
					</v-card-text>
      			</v-card>
			</v-footer>

			<z-pdf ref="pdf" :item="pdfItem"></z-pdf>
		<!-- </v-container> -->
		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
    </v-main>
</template>

<script>
import axios from 'axios'
import mixin from './mixin/main'
import { mapGetters, mapActions } from 'vuex'
import downloadExcel from "vue-json-excel"
import zPdf from '@/views/pdf/RealtyEstimate'
import zDetail from '@/views/RealtyRequestDetail'
import zDialogCompare from '@/views/RealtyRequestCompare'
import zDialogStatistic from '@/views/RealtyRequestStatistic'

//import zConfirmDelete from '@/views/components/confirm-delete'

export default {
	name: 'RealtyRequest',
	mixins: [mixin],
	components: {
		zPdf,
		downloadExcel,
		zDetail,
		zDialogCompare,
		zDialogStatistic
	},
	data: () => ({
		title: 'Запити',
		//loading: 	false,
		search: '',
		onlymy: 0, // ознака відображати тільки мої запити
		organization: null,

		loadings: {
			organization: false,
		},

		menu: {
			filter: 	false,
		},

		page: 1,
		pageCount: 2,
		itemsPerPage: 15,

		headers: [
			{ text: 'ID', value: 'id', sortable: true, width: '50'},
			{ text: 'Серсіс', value: 'service.name', sortable: true},
			{ text: 'Категорія', value: 'category.name', sortable: true},
			{ text: 'Тип', value: 'type.name', sortable: true},
			{ text: 'Операція', value: 'operation.name', sortable: true},
			{ text: 'Область', value: 'state.name', sortable: true},
			{ text: 'Місто', value: 'city.name', sortable: true},
			{ text: 'Район', value: 'district.name', sortable: true},
			{ text: "Кількість пропозицій", value: 'estimates[0].total', sortable: true},
			{ text: "Середня ціна в UAH", value: 'estimates[0].arithmeticMean', sortable: true},
			{ text: 'Створив', value: 'user.name', sortable: true},
			{ text: 'Дата', value: 'datecreate', sortable: true},
			{ text: 'Actions', value: 'actions', sortable: false, width: '10%' },
		],

		pdfItem: {},
	}),

	props: {
		own: { type: Boolean, default: false },
	},
	
	computed: {
		...mapGetters(['roles']),
		...mapGetters('Organization', { organizations: 'items' }),
		...mapGetters('RealtyRequest', [ 'endpoint', 'items' ]),

		

		isAdmin() {
			return this.roles.includes('ADMIN')
		},
	},

	watch: {
		page: function (val) {
			this.onSearch()
		},
	},

	methods: {
		...mapActions('Organization', { 'loadOrganization': 'load' }),
		...mapActions('RealtyRequest', [ 'setItem', 'setItems', 'createItem', 'updateItem', 'deleteItem' ]),

		loadOrganizations(e) {
			if(this.organizations.length == 0) {
				this.loadings.organization = true
				this.loadOrganization().then(() => {
				}).finally(() => (this.loadings.organization = false));
			}
		},

		/**
		 * Оновити
		 */
		onRefresh () {
			this.onSearch()	
		},

		onFilter () {
			this.menu.filter = false
			this.onSearch()	
		},

		onPDF (item) {
			this.pdfItem = item
			this.$refs.pdf.generate()
		},

		onNext() {
			this.onSearch()
		},

		onPrevious() {
			this.onSearch()
		},

		/**
		 * Пошук
		 */
		onSearch () {
			//console.log(that.$options.name, 'search')

			let params = {
				page: this.page,
				itemsPerPage: this.itemsPerPage,
				onlymy: this.onlymy,
				organization: this.organization?.id,
			}

			//console.log(this.$options.name, 'search','params', params)

			this.loading = true
			var that = this
			/* var p = this.own ? axios.get(`realty/myrequest`, { params: params }) : axios.get(`realty/request`, { params: params })
			p.then(response => {
				//console.log(that.$options.name, 'onSearch','response', response)
				//console.log(that.$options.name, 'onSearch','response.headers', response.headers)
				//console.log(that.$options.name, 'onSearch','response.headers.pagecount', response.headers.pagecount)
				//console.log(that.$options.name, 'onSearch','response.data', response.data)
				that.setItems()
				that.setItems(response.data)

				if(that.items.length > 0 && that.pageCount < that.page + 1) {
					that.pageCount = that.page + 1
				}
			}, error => {
				console.log(error)
			}).finally(() => {
				this.loading = false
			}); */

			axios.get(`/realty/request`, { params: params }).then(response => {
				//console.log(this.$options.name, 'search','response.data', response.data)
				that.setItems(response.data)
				if(that.items.length > 0 && that.pageCount < that.page + 1) {
					that.pageCount = that.page + 1
				}
			}, error => {
				console.log(error)
				//that.showMessage(error)
			}).finally(() => (this.loading = false));
		},

		valueUSD(items) {
			return items.map(element=>element.priceUSD);
		},
	},
}
</script>