<template>
	<v-dialog  
	 	v-model="dialog"
	 	:fullscreen="fullscreen"
		transition="dialog-bottom-transition"
		width="800"
		height="1024"
		max-width="1024"
		scrollable
	>
		<!-- Актіватор -->
		<template v-slot:activator="{ on: dialog, attrs }">
			<v-tooltip bottom>
				<template v-slot:activator="{ on: tooltip, attrs }">
					<slot name="activator" :attrs="attrs" :dialog="dialog" :tooltip="tooltip">
						<v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...dialog }">
							<v-icon>mdi-pencil</v-icon>
						</v-btn>
					</slot>
				</template>
				<span>{{ $vuetify.lang.t(`$vuetify.${tooltip}`) | capitalize }}</span>
			</v-tooltip>
		</template>

		<v-card elevation="4">
			<!-- Ttoolbar -->
			<v-toolbar elevation="0" dark color="primary" style="flex: 0 1 auto;">
				<v-avatar>
					<v-icon>{{ icon }}</v-icon>
				</v-avatar>
				<v-toolbar-title>{{ $vuetify.lang.t(`$vuetify.${title}`) | capitalize }}</v-toolbar-title>
				
				<v-spacer></v-spacer>
				
				<!-- <v-chip> {{ item.id }}</v-chip> -->
				<span> {{ item.id }}</span>

				<v-spacer></v-spacer>

				<v-btn icon @click="fullscreen = !fullscreen" >
					<v-icon v-if="fullscreen">mdi-arrow-collapse</v-icon>
					<v-icon v-else>mdi-arrow-expand</v-icon>
				</v-btn>

				<v-btn icon @click="dialog=false" >
					<v-icon>mdi-close</v-icon>
				</v-btn>

				<!-- <template v-slot:extension>
					Tabs
					<v-tabs v-model="tab" align-with-title>
						<v-tabs-slider color="yellow"></v-tabs-slider>
						<v-tab v-for="item in tabs" :key="item">
							{{ item }}
						</v-tab>
					</v-tabs>

					<v-toolbar v-if="tab==1" dense color="primary" elevation="0">
						<z-field-search v-model="searchRoles"></z-field-search>
						<z-confirm-delete @ok="onRemoveRoles()" activator-type="button-icon" :disabled="roles.length==0"></z-confirm-delete>
						<z-roles @ok="onAddRoles($event)" activator-icon="mdi-plus" multiple :excluded="item.usersroles"></z-roles>
					</v-toolbar>

				</template> -->
			</v-toolbar>

			<!-- <v-card-text style="height: 380px;"> -->
			<v-card-text class="mt-6">
				<v-form ref="form">
					<v-row>
						<v-col cols="12" sm="8" md="8">
							<v-text-field
								v-model="item.name"
								:label="$vuetify.lang.t('$vuetify.name') | capitalize"
								prepend-icon="mdi-text"
								required
								:rules="[rules.required]"
								@keyup.ctrl.90="OnDefault"
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="4" md="4">
							<v-combobox
								v-model="item.type"
								:label="$vuetify.lang.t('$vuetify.type') | capitalize"
								:items="types"
								item-text="name"
								item-value="id"
								hide-details="auto"
								@focus="loadTypes"
							></v-combobox>
						</v-col>

						<v-col cols="12">
							<v-text-field
								v-model="item.legalName"
								label="Повна назва"
								prepend-icon="mdi-text-long"
								@keyup.ctrl.90="OnDefault"
							></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-text-field
								v-model="item.alternateName"
								label="Альтернативна назва"
								prepend-icon="mdi-text-short"
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="6" md="6">
							<v-text-field
								v-model="item.taxID"
								:label="$vuetify.lang.t('$vuetify.inn') | capitalize"
								prepend-icon="mdi-identifier"
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="6" md="6">
							<v-text-field
								v-model="item.domenName"
								label="Домен"
								prepend-icon="mdi-at"
							></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-textarea
								v-model="item.description"
								:label="$vuetify.lang.t('$vuetify.comment') | capitalize"
								prepend-icon="mdi-comment"
								rows="1"
							></v-textarea>
						</v-col>
					</v-row>
					
				</v-form>

				<v-alert v-model="alert" dense outlined type="error" dismissible>{{ alertText }}</v-alert>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn text @click.native="onCancel">{{ $vuetify.lang.t('$vuetify.cancel') }}</v-btn>
				<v-btn color="success" dark @click.native="onOK" :disabled="loading">{{ $vuetify.lang.t('$vuetify.ok') }}</v-btn>
			</v-card-actions>
		</v-card>

		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
	</v-dialog>
</template>

<script>
import axios from 'axios'
//import mixin from './details/mixin/main'
import mixin from './mixin/detail'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import zActivator from '@/views/components/activator/activator'
import zFieldSearch from '@/views/components/field.search'
import zConfirmDelete from '@/views/components/confirm-delete'
import zRoles from '@/views/dictionaries/Roles'

export default {
	name: 'OrganizationDetail',
	mixins: [mixin],
	components: {
		zActivator,
		zFieldSearch,
		zConfirmDelete,
		zRoles,
	},
	data: () => ({
		title: 'organization',
		icon: 'mdi-domain',


        //tabs: ['Головна', 'Ролі'],

		//roles: [],
		//searchRoles: '',

		loadings: {
			types: false,
			//organizations: false,
		},

		item: {
			profile: {}
		},

		alert: false,
		alertText: null
	}),

	props: {
		selected: 					{ type: Object, default: null },
		disabled:   				{ type: Boolean, default: false }, //Ознака неактивного елементу
		tooltip:  					{ type: String, default: 'edit' },
		
		/* activatorType:  			{ type: String, default: 'button-icon' }, // Тип активатора вікна: button | icon | menu-item | list-item
		activatorIcon:  			{ type: String, default: 'mdi-plus' },
		activatorTitle:  			{ type: String, default: null },
		activatorOutlined:     		{ type: Boolean, default: false },
		activatorTooltipText:  		{ type: String, default: null },
		activatorTooltipDisabled:	{ type: Boolean, default: false }, */
	},

	computed: {
		//...mapGetters('Users', [ 'endpoint', 'item' ]),
		//...mapGetters('Roles', {  'roles': 'items' }),
		//...mapGetters('Organization', {  'organizations': 'items',  }),
		...mapGetters('Organization', ['types']),

		/* filteredRoles() {
			return this.item.usersroles.filter(item => {
				return item.id==this.searchRoles
					|| item.name.toLowerCase().includes(this.searchRoles.toLowerCase())
			})
		}, */
	},

	watch: {
		dialog: function (val) {
			//console.log(this.$options.name, 'dialog','val', val)
			if(val) {
				this.$emit('open')
			} else {
				this.$emit('close')
			}
		},
	},

	methods: {
		//...mapActions('Users', [ 'setItem', 'setItems', 'createItem', 'updateItem', 'deleteItem' ]),
		//...mapActions('Roles', { 'setRoles': 'setItems' }),
		...mapActions('Organization', { 'setOrganizations': 'setItems' }),
		...mapMutations('Organization', { 'setTypes': 'types' }),

		find(item) {
			if(item?.id) {
				this.loading = true
				var that = this
				axios.get(`/organization/${item.id}`).then(response => {
					//console.log(this.$options.name, 'find','response.data', response.data)
					that.item = response.data
				}, error => {
					console.log(error)
					that.showAlert(error)
				}).finally(() => (this.loading = false));
			}
		},

		save() {
			console.log(this.$options.name, 'save','item', this.item)

			var that = this
			if (this.$refs.form.validate()) {
				if(this.item?.id) {
					axios.put(`/organization/${this.item.id}`, this.item).then(response => {
						//console.log(that.$options.name, 'save::update','response.data', response.data)
						//console.log(this.$options.name, 'create','response.data', response.data)
						//that.createItem(response.data)
						
						that.item = response.data
						that.$emit('update', that.item)
						that.dialog = false
					}, error => {
						console.log(error)
						that.showAlert(error)
					}).finally(() => (this.loading = false));
				} else {
					axios.post(`/organization`, this.item).then(response => {
						//console.log(that.$options.name, 'save::create','response.data', response.data)
						//console.log(this.$options.name, 'create','response.data', response.data)
						//that.createItem(response.data)
						
						that.item = response.data
						that.$emit('create', that.item)
						that.dialog = false
					}, error => {
						console.log(error)
						that.showAlert(error)
					}).finally(() => (this.loading = false));
				}
			}
		},

		loadTypes(e) {
			if(this.types.length == 0) {
				this.loadings.types = true
				var that = this
				axios.get('/organization/type').then(response => {
					//console.log(this.$options.name, 'loadTypes','data', response.data)
					that.setTypes(response.data)
				}, error => {
					console.log('error', error)
					that.showAlert(error)
				}).finally(() => (this.loadings.types = false));
			}
		},

		onOK () {
			console.log(this.$options.name, 'onOK', 'item', this.item)

			//this.save()
		},

		showAlert (text) {
			this.alert = true
			this.alertText = text
		},
	},
}
</script>