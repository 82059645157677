<template>
	<v-dialog  
	 	v-model="dialog"
	 	fullscreen
		transition="dialog-bottom-transition"
		scrollable
	>
		<!-- Актіватор -->
		<template v-slot:activator="{  on, attrs }">
			<slot name="activator">
				<v-btn icon v-bind="attrs" v-on="on" :disabled="!selected.length">
					<v-icon>{{ icon }}</v-icon>
				</v-btn>
			</slot>
		</template>

		

		<v-card>
			<!-- Ttoolbar -->
			<v-toolbar elevation="0" dark color="primary" style="flex: 0 1 auto;">
				<v-avatar>
					<v-icon>{{ icon }}</v-icon>
				</v-avatar>
				<!-- <v-toolbar-title>{{ title }}</v-toolbar-title>
				<v-toolbar-subtitle>{{ title }}</v-toolbar-subtitle> -->
				<v-toolbar-title>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>{{ title }}</v-list-item-title>
							<v-list-item-subtitle>{{ subtitle }}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-toolbar-title>
				

				<v-spacer></v-spacer>

				<v-btn icon @click="dialog=false" >
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>

			<v-card-text>
				<v-data-table
					:headers="headers"
					:items="items"
					item-key="name"
					:loading="loading"
					loading-text="Завантаження даних... зачекайте, будь ласка." 
					hide-default-footer
					:items-per-page="-1"
				>
				<template v-slot:[`item.equal`]="{ item }">
					<v-icon color="green" v-if="item.equal===true">mdi-thumb-up</v-icon>
					<v-icon color="red" v-else-if="item.equal===false">mdi-thumb-down</v-icon>
				</template>
				</v-data-table>
				
			</v-card-text>
		</v-card>

		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
	</v-dialog>
</template>

<script>

export default {
	name: 'RealtyRequestCompare',
	components: {
		
	},
	data: () => ({
		title: 'Порівняння',
		subtitle: 'Визначення різниці в значеннях параметрів запитів',

		icon: 'mdi-compare',
		dialog: false,
		loading: false,
		drawer: true,

		params: [
			{ code: 'service', path: 'service.name', name: 'Сервіс' },
			{ code: 'category', path: 'category.name', name: 'Категорія' },
			{ code: 'type', path: 'type.name', name: 'Тип' },
			{ code: 'operation', path: 'operation.name', name: 'Тип операції' },
			{ code: 'state', path: 'state.name', name: 'Область' },
			{ code: 'city', path: 'city.name', name: 'Місто' },
			{ code: 'district', path: 'district.name', name: 'Район' },
			{ code: 'dateFrom', path: 'dateFrom', name: 'Дата видачі від' },
			{ code: 'dateTo', path: 'dateTo', name: 'Дата видачі до' },
			{ code: 'area', path: 'area', name: 'Площа' },
			{ code: 'roomsCount', path: 'roomsCount', name: 'Кількість кімнат' },
		],
	}),

	props: {
		selected: 					{ type: Array, default: [] },
	},

	computed: {
		headers () { 
			let result = [
				{ text: 'Назва', value: 'name', sortable: true}
			]

			this.selected.forEach((item, index) => {
				let value = 'value' + index
					,text = item.id
				;

				result.push( { text: text, value: value, sortable: true} )
			})

			result.push( { text: '', value: 'equal', sortable: true} )

			//console.log(this.$options.name, 'headers','result', result)

			return result
		},

		items() {
			let result = []

			const getValue = (path, obj) => path.split('.').reduce((acc, c) => acc && acc[c], obj);

			this.params.forEach((param) => {
				let path = param.path

				let obj = { name: param.name }, values = []

				this.selected.forEach((item, index) => {
					let name = 'value' + index
						,val = getValue(path, item)
					;

					if( param.code == 'damage') {
						switch(val) {
							case 0: val = 'Без ДТП'; break;  
							case 1: val = 'Після ДТП'; break;
							default: val = 'Всі'
						}
					}

					obj[name] = val

					values.push(val)
				})

				//Визначення рівності всіх значень
				var unique = values.filter((value, index, array) => array.indexOf(value) === index);
				obj['equal'] = !(unique.length > 1)
				
				result.push(obj)
			});

			//console.log(this.$options.name, 'items','result', result)

			return result
		}
	},

	watch: {
		/* dialog: function (val) {
			if(val) {
				this.onOpen()
			} else {
				this.onClose()
			}
		}, */
	},

	methods: {
		//...mapActions('Roles', [ 'setItem', 'setItems', 'createItem', 'updateItem', 'deleteItem' ]),

		/* onOpen () {
			//console.log(this.$options.name, 'onOpen','item', this.item)

			//this.find(this.selected)
			//this.drawer = true
		},

		onClose () {
			//this.setItem()
		}, */
	},
}
</script>