export default {
    namespaced: true,
    state: {
		params: {
        	vin: null,
		},

		vin: null,
		brand: null,
		model: null,
		makeDate: null,
		options: [],
		//items: [],
    },
    getters: {
		params(state) {
			return state.params
		},
		vin(state) {
			return state.vin
		},
		brand(state) {
			return state.brand
		},
		model(state) {
			return state.model
		},
		makeDate(state) {
			return state.makeDate
		},
		options(state) {
			return state.options
		},
		isset() {
			return state.params.vin?true:false
		},
    },
    mutations: {
		params (state, data) {
			state.params = data
		},
		vin (state, data) {
			state.vin = data
		},
		brand (state, data) {
			state.brand = data
		},
		model (state, data) {
			state.model = data
		},
		makeDate (state, data) {
			state.makeDate = data
		},
		options (state, data) {
			state.options = data?data:[]
		},
    },
    actions: {
		clear (context) {
			context.commit('params', {
				vin: null,
			})
			context.commit('vin', null)
			context.commit('brand', null)
			context.commit('model', null)
			context.commit('makeDate', null)
			context.commit('options', [])
		},
    }
   
}