export default {
    namespaced: true,
    state: {
		params: {
        	type: { code: 'DrugAveragePrice' },
			objects: [
				/* {
					type: { code: 'drug' },
					product: {}
				} */
			]
		},

        item: {
			objects: [],
        },
    },
    getters: {
		params(state) {
			return state.params
		},
		isset(state) {
			return state.params.number 
				|| state.params.vin 
				|| state.params.brand
				|| state.params.model
				//|| state.params.engine
				//|| state.params.gearbox
		},
    },
    mutations: {
		params (state, data) {
			state.params = data
		},
    },
    actions: {
		clearItem(context) {
			context.commit('item', {
				objects: [],
			})
		},
    },
}