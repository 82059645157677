import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"fullscreen":"","transition_":"dialog-bottom-transition","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("activator",function(){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-eye")])],1)]})]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"color":"#EEEEEE"}},[_c(VToolbar,{staticStyle:{"flex":"0 1 auto"},attrs:{"flat":"","color":"#f5f5f5"}},[_c(VAvatar,[_c(VIcon,[_vm._v(_vm._s(_vm.icon))])],1),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.title))]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.dialog=false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VContainer,[_c(VSheet,{attrs:{"color":"white","elevation":"1"}},[_c(VToolbar,{attrs:{"flat":""}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.item.type.name))]),_c(VSpacer),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.item.id ? '№ ' + _vm.item.id: ''))])],1),_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c(VSimpleTable,{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',[_vm._v("Тип")]),_c('td',[_vm._v(_vm._s(_vm.item.type.name))])]),_c('tr',[_c('td',[_vm._v("Дата")]),_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(_vm.item.date)))])]),_c('tr',[_c('td',[_vm._v("Дата початку періоду")]),_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(_vm.item.datebegin)))])]),_c('tr',[_c('td',[_vm._v("Дата завершення періоду")]),_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(_vm.item.dateend)))])]),_c('tr',[_c('td',[_vm._v("Користувач")]),_c('td',[_vm._v(_vm._s(_vm.item.user.name))])])])]},proxy:true}])})],1)],1),_c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,[_vm._v("Об'єкти оцінки")]),_c(VCardText,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.item.objects,"item-key":"id","loading-text":"Завантаження даних... зачекайте, будь ласка.","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.priceMin",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toCurrencyUAH")(item.priceMin)))])]}},{key:"item.priceMax",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toCurrencyUAH")(item.priceMax)))])]}},{key:"item.priceAvg",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toCurrencyUAH")(item.priceAvg)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VToolbar,{attrs:{"elevation":"0","dense":"","color":"transparent"}},[(item.priceCount)?_c('z-statistic',{attrs:{"item":item}}):_vm._e()],1)]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',{staticClass:"title"}),_c('th',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm._f("toCurrencyUAH")(_vm.sumField('priceMin'))))]),_c('th',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm._f("toCurrencyUAH")(_vm.sumField('priceAvg'))))]),_c('th',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm._f("toCurrencyUAH")(_vm.sumField('priceMax'))))]),_c('th',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.sumField('priceCount')))])])])],2)],1)],1)],1)],1)],1)],1),_c(VOverlay,{attrs:{"value":_vm.loading}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }