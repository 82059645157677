<template>
	<v-card elevation="0">
		<v-card-title>{{ title }}</v-card-title>
		<v-simple-table dense>
			<template v-slot:default>
				<tbody>
					<tr>
						<td>Середнє арифметичне</td>
						<td>{{ parseInt(estimate.arithmeticMean) }}</td>
					</tr>
					<tr>
						<td>Середнє арифметичне без урахування 25% найменших і найбільших значень</td>
						<td>{{ parseInt(estimate.interQuartileMean) }}</td>
					</tr>
					<tr>
						<td>Загальна кількість оголошень, що беруть участь у підрахунку</td>
						<td>{{ estimate.total }}</td>
					</tr>
				</tbody>
			</template>
		</v-simple-table>
		
		<v-card elevation="0">
			<v-card-title class="text-subtitle-2 font-weight-bold">Процентілі</v-card-title>
			<v-simple-table dense>
				<template v-slot:default>
					<thead>
						<tr>
							<th class="text-left" v-for="percentile in estimate.percentiles" :key="percentile.id" >{{ percentile.code }}</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td v-for="percentile in estimate.percentiles" :key="percentile.id">{{ parseInt(percentile.price) }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</v-card>

		<v-expansion-panels v-model="panel" multiple style="position: static;">
			<v-expansion-panel>
				<v-expansion-panel-header class="text-subtitle-2 font-weight-bold">Ціни</v-expansion-panel-header>
				<v-expansion-panel-content>
					<v-chip-group active-class="primary--text" column>
						<v-chip v-for="advert in adverts(estimate.adverts)" :key="advert.id" :href="getAdvertUrl(advert)"  target="_blank">
							{{ parseInt(advert.price) }}
						</v-chip>
					</v-chip-group>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</v-card>
</template>

<script>
export default {
	name: 'VehicleAveragePriceMediana',
	data: () => ({
		title: 'Розрахунок середньої ціни за методом медіани',
		panel: [],
	}),

	props: {
		estimate: { type: Object, default: () => {}},
	},

	methods: {
		adverts(items) {
			const sorted = [...items].sort((a, b) => parseInt(a.price) - parseInt(b.price));
			return sorted
		},

		getAdvertUrl(item) {
			//console.log(this.$options.name, 'getAdvertUrl', 'item', item)

			return `https://auto.ria.com/uk/auto_${this.brand}_${this.model}_${item.code}.html`
		},
	},
}
</script>