import { mapGetters, mapMutations, mapActions } from 'vuex'
import axios from 'axios'

export default {
	data: () => ({
		loading: false,
	}),

	methods: {
		...mapActions([ 'showMessage' ]),

		find(item) {
			if(item.id) {
				var that = this
				this.loading = true

				axios.get('/'+this.endpoint+'/' + item.id).then(response => {
					//console.log(this.endpoint, 'onOpen','response.data', response.data)
					
					that.setItem(response.data)

					that.loading 	= false

					//that.showMessage({text: 'OK', status: '200'})
				}, error => {
					//console.log(error)
					that.loading = false
					that.showMessage(error)
				})
			}
		},

		findAll() {
			var that = this
			that.loading = true
			
			axios.get('/'+this.endpoint).then(response => {
				//console.log(this.endpoint, 'load','response.data', response.data)
				that.setItems()
				that.setItems(response.data)

				that.loading = false

                //that.showMessage({text: 'OK', status: '200'})
			}, error => {
				//console.log(error)
				that.loading = false
                that.showMessage(error)
			})
		},

		//'setItem', 'setItems', 'createItem', 'updateItem', 'deleteItem'

		create(item) {
			axios.post('/'+this.endpoint, item).then(response => {
				//console.log(this.endpoint, 'create','response.data', response.data)

				that.createItem(response.data)

				that.loading 	= false
				this.dialog 	= false

				//that.showMessage({text: 'OK', status: '200'})
			}, error => {
				//console.log(error)
				that.loading = false
				that.showMessage(error)
			})
		},

		update(item) {
			axios.put('/'+this.endpoint+'/' + item.id, item).then(response => {
				//console.log(this.endpoint, 'create','response.data', response.data)

				that.updateItem({
					old: that.selected,
					new: response.data,
				})

				that.loading 	= false
				this.dialog 	= false

				//that.showMessage({text: 'OK', status: '200'})
			}, error => {
				//console.log(error)
				that.loading = false
				that.showMessage(error)
			})

		},

		delete(item) {
			if(item.id) {
				var that = this
				that.loading = true

				axios.delete('/'+this.endpoint+'/' + item.id).then(response => {
					//console.log('Services', 'load','response.data', response.data)
					
					that.deleteItem(item)

					that.loading = false

					//that.showMessage({text: 'OK', status: '200'})
				}, error => {
					//console.log(error)
					that.loading = false
					that.showMessage(error)
				})
			} else {
				that.deleteItem(item)
			}
		},
	},
}