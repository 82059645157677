<template>
	<v-dialog  
	 	v-model="dialog"
		:fullscreen="fullscreen"
		transition="dialog-bottom-transition"
		scrollable
		:max-width="fullscreen? null: maxWidth"
	>
		<!-- Актіватор -->
		<template v-slot:activator="{ onDialog: on, attrsDialog: attrs }">
			<slot name="activator">
				<v-menu offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn icon v-bind="attrs" v-on="on">
							<v-icon>{{ icon }}</v-icon>
						</v-btn>
					</template>
					<v-list>
						<!-- <v-list-item v-bind="attrsDialog" v-on="onDialog"> -->
						<!-- <v-list-item @click="onAveragePriceMediana">
							<v-list-item-title>Середня ціна за методом медіани</v-list-item-title>
						</v-list-item>
						<v-list-item @click="onAveragePriceAi">
							<v-list-item-title>Середня ціна за методом AI</v-list-item-title>
						</v-list-item>
						<v-list-item @click="onAveragePriceStatistic">
							<v-list-item-title>Статистика середньої ціни за період</v-list-item-title>
						</v-list-item> -->
						<v-list-item v-for="(script, index) in scripts" :key="index" 
							@click="onOpen(script)"
						>
							<v-list-item-title>{{ script.name }}</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</slot>
		</template>

		<v-card>
			<!-- Ttoolbar -->
			<v-toolbar elevation="0" dark color="primary" style="flex: 0 1 auto;">
				<v-avatar>
					<v-icon>{{ icon }}</v-icon>
				</v-avatar>
				<!-- <v-toolbar-title>{{ title }}</v-toolbar-title>
				<v-toolbar-subtitle>{{ title }}</v-toolbar-subtitle> -->
				<v-toolbar-title>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>{{ title }}</v-list-item-title>
							<v-list-item-subtitle>{{ script.code }} №{{ item.id }} {{ item.datecreate | formatDateTime }}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-toolbar-title>
				

				<v-spacer></v-spacer>

				<v-btn icon @click="copyText" >
					<v-icon>mdi-content-copy</v-icon>
				</v-btn>

				<v-divider vertical></v-divider>

				<v-btn icon @click="fullscreen = !fullscreen">
					<v-icon v-if="fullscreen">mdi-arrow-collapse</v-icon>
					<v-icon v-else="fullscreen">mdi-arrow-expand</v-icon>
				</v-btn>
				<v-btn icon @click="dialog=false" >
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>

			<v-card-text>
				<!-- <code>{{ scripttext }}</code> -->
				<!-- <pre>{{ scripttext | prettyFSON }}</pre> -->
				<pre ref="script">{{ json }}</pre>
			</v-card-text>
			<!-- <v-card-actions>
				<v-spacer></v-spacer>
				<v-btn text @click.native="onCancel">{{ $vuetify.lang.t('$vuetify.cancel') }}</v-btn>
				<v-btn color="success" dark @click.native="onOK" :disabled="loading">{{ $vuetify.lang.t('$vuetify.ok') }}</v-btn>
			</v-card-actions> -->
		</v-card>

		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
	</v-dialog>
</template>

<script>
import axios from 'axios'
//import mixin from './mixin/main'
//import { mapGetters, mapActions } from 'vuex'
import zActivator from '@/views/components/activator/activator'
import zFieldSearch from '@/views/components/field.search'
import zConfirmDelete from '@/views/components/confirm-delete'
import zUsers from '@/views/dictionaries/Users'
import zServices from '@/views/dictionaries/Services'

export default {
	name: 'Script',
	//mixins: [mixin],
	components: {
		zActivator,
		zFieldSearch,
		zConfirmDelete,
		zUsers,
		zServices,
	},
	data: () => ({
		dialog: false,
		fullscreen: false,
		loading: false,
		log: false,

		title: 'Скрипт',
		icon: 'mdi-code-braces',

		maxWidth: 800,

		script: {},
		json: null,
	}),

	props: {
		item: 					{ type: Object, default: {} },
	},

	computed: {
		scripts() {
			return [
				{ code: 'mediana', 		name: 'Середня ціна за методом медіани' },
				{ code: 'ai', 			name: 'Середня ціна за методом AI' },
				{ code: 'statistic', 	name: 'Статистика середньої ціни за період' },
			]
		}
	},

	methods: {
		onOpen (script) {
			console.log(this.$options.name, 'onOpen','item', this.item)

			this.script = script
			this.dialog = true
			this.loading = true
			let that = this
			axios.get(`/vehicle/request/${this.item.id}/averageprice/${this.script.code}/script`).then(response => {
				console.log(that.$options.name, 'onOpen','response.data', response.data)
				that.json = response.data
			}, error => {
				console.log('error', error)
			}).finally(() => {
				this.loading = false
			});
		},

		copyText() {
			navigator.clipboard.writeText(this.$refs.script.textContent);
		}
	},
}
</script>