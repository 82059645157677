import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VTooltip,{attrs:{"bottom":"","disabled":_vm.tooltipDisabled},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":_vm.icon,"text":_vm.text,"disabled":_vm.disabled,"color":_vm.color,"large":_vm.large,"small":_vm.small,"dark":_vm.dark,"block":_vm.block,"outlined":_vm.outlined}},'v-btn',attrs,false),Object.assign({}, tooltip, _vm.dialog)),[_c(VIcon,[_vm._v(_vm._s(_vm.iconName))]),_vm._v(" "+_vm._s(_vm.title)+" ")],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.tooltipText)))])])}
var staticRenderFns = []

export { render, staticRenderFns }