<template>
	<v-dialog  
		v-model="dialog"
		max-width="400"
		@keydown.esc="onCancel"
	>
		<!-- Актіватор -->
		<template v-slot:activator="{ on: dialog, attrs }">
			<z-activator v-bind="attrs" v-model="dialog"
				:type="activatorType"
				:icon-name="activatorIcon"
				:title="activatorTitle"
				:disabled="disabled"
				:tooltip-text="activatorTooltipText"
				:tooltip-disabled="activatorTooltipDisabled"
			></z-activator>
		</template>

		<v-card>
			<v-card-title class="text-h5">
				{{ title }}
				<v-spacer></v-spacer>
				<v-btn icon @click="dialog=false" >
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-divider></v-divider>
			<v-card-text>
				<slot name="text">
					<div>{{ text }}</div>
				</slot>
			</v-card-text>
			<v-divider></v-divider>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="grey" dark small @click.native="onCancel">{{ $vuetify.lang.t('$vuetify.not') }}</v-btn>
				<v-btn color="red" dark small @click.native="onOK">{{ $vuetify.lang.t('$vuetify.yes') }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import zActivator from '@/views/components/activator/activator'

export default {
	components: {
		zActivator,
	},
	data: () => ({
		dialog: false,
	}),
	props: {
		title:       				{ type: String, default: 'Confirm delete' },
		text:       				{ type: String, default: 'Are you sure you want to delete this record?' },
		disabled:   				{ type: Boolean, default: false }, //Ознака неактивного елементу

		// Activator
		activatorType:  			{ type: String, default: 'icon' }, // Тип активатора вікна: button | icon | menu-item | list-item
		activatorIcon:  			{ type: String, default: 'mdi-trash-can' },
		activatorTitle:  			{ type: String, default: null },
		activatorTooltipText:  		{ type: String, default: 'Видалити' },
		activatorTooltipDisabled:	{ type: Boolean, default: false },
	},
	
	methods: {
		onCancel () {
			this.dialog = false
		},

		onOK () {
			this.dialog = false
			this.$emit('ok')
		},
	},
}
</script>