<template>
	<v-card v-if="estimate.avgPriceUAH >= 0 || estimate.avgPriceUSD >= 0"
		elevation="0">
		<v-card-title class="text-h6">{{ title }}</v-card-title>

		<v-card-text>
			<v-list dense>
				<!-- <v-subheader>Середня ціна</v-subheader> -->
				<v-list-item>
					<v-list-item-content>
						<v-list-item-title>UAH</v-list-item-title>
					</v-list-item-content>
					<v-list-item-action>
						<v-list-item-action-text class="font-weight-bold text-subtitle-2">{{ estimate.avgPriceUAH | toCurrencyUAH }}</v-list-item-action-text>
					</v-list-item-action>
				</v-list-item>
				<v-list-item>
					<v-list-item-content>
						<v-list-item-title>USD</v-list-item-title>
					</v-list-item-content>
					<v-list-item-action>
						<v-list-item-action-text class="font-weight-bold text-subtitle-2">{{ estimate.avgPriceUSD | toCurrencyUSD }}</v-list-item-action-text>
					</v-list-item-action>
				</v-list-item>
			</v-list>
		</v-card-text>

		<v-divider></v-divider>

		<v-card v-if="visibleAdverts && adverts.length"
			class="mx-auto"
			elevation="0"
		>
			<v-card-title class="text-h6">Об'яви</v-card-title>
			<v-card-text>
				<v-chip-group active-class="primary--text" column>
					<v-chip v-for="(advert, index) in adverts" :key="index" 
						:href="getAdvertUrl(advert)"
						target="_blank"
					>
						{{ parseInt(advert.price) }}
					</v-chip>
				</v-chip-group>
			</v-card-text>
		</v-card>
	</v-card>
</template>

<script>
export default {
	data: () => ({
		title: 'Розрахунок середньої ціни за методом AI',
	}),

	props: {
		estimate: 			{ type: Object, default: () => {}},
		visibleAdverts: 	{ type: Boolean, default: false},
	},

	computed: {
		adverts() {
			let result = []
			if(this.estimate.hasOwnProperty('adverts')) {
				const sorted = [...this.estimate.adverts].sort((a, b) => parseInt(a.price) - parseInt(b.price));
				result = sorted
			}
			return result;
		},
	},

	methods: {
		getAdvertUrl(item) {
			//console.log('VehicleEstimateMarket', 'getAdvertUrl', 'item', item)
			//$uri = item.uri ? item.uri : `/auto_${this.brand}_${this.model}_${item.code}.html`;

			//return item.uri `https://auto.ria.com/uk/auto_${this.brand}_${this.model}_${item.code}.html`
			return `https://auto.ria.com/uk${item.uri}`
		},
	},
}
</script>