import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"elevation":"0"}},[_c(VCardText,{staticClass:"text--primary"},[_c(VToolbar,{attrs:{"flat":""}},[_c(VSpacer),_c('Dialog',{attrs:{"type":_vm.type,"mode":"add","item":_vm.item},on:{"add":_vm.OnAdd,"close":_vm.OnClose}}),_c(VDivider,{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c(VBtn,{attrs:{"color":"primary","disabled":_vm.items.length<1},on:{"click":function($event){return _vm.OnExecute(_vm.items)}}},[_c(VIcon,{attrs:{"left":"","dark":""}},[_vm._v("mdi-play-box-multiple")]),_vm._v(" Оцінка ")],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VMenu,{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c(VTooltip,{attrs:{"bottom":"","disabled":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('span',[_vm._v("Меню")])])]}}],null,true)},[_c(VList,[_c('Dialog',{attrs:{"type":_vm.type,"mode":"edit","small":"","activator":"list-item","item":item}}),_c(VListItem,{on:{"click":function($event){return _vm.OnRemove(item)}}},[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-trash-can-outline")])],1),_c(VListItemTitle,[_vm._v("Видалити")])],1),_c(VDivider),_c(VListItem,{on:{"click":function($event){return _vm.OnExecute(item)}}},[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-play-box")])],1),_c(VListItemTitle,[_vm._v("Оцінка")])],1),(item.hasOwnProperty('estimate'))?_c('ResultDialog',{attrs:{"small":"","activator":"list-item","item":item.estimate}}):_vm._e()],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }