<template>
    <v-text-field
		v-bind:value="value"
      	v-on:input="$emit('input', $event)"
		append-icon="mdi-magnify"
		:label="label"
		:placeholder="placeholder"
		filled
		rounded
		dense
		single-line
		hide-details
	></v-text-field>
</template>

<script>
export default {
	data: () => ({
		
	}),
	props: {
		value: 			{ type: String, default: ''},
		label:  		{ type: String, default: 'Search'},
		placeholder:  	{ type: String, default: 'Search'},
	},
}
</script>