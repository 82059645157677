import { mapActions } from 'vuex'

export default {
	data: () => ({
		dialog: false,
		fullscreen: false,
		loading: false,

		tab: null,
        //tabs: ['Головна'],

		snack: {
			visible: false,
			timeout: 3000,
			color: 'succcess',
			title: '',
			subtitle: '',
		},
	}),

	props: {
		//icon:   		{ type: String, default: 'mdi-format-title' },
		//title:   		{ type: String, default: '< Заголовок >' },
		tooltip:  		{ type: String, default: 'edit' },
		disabled:   	{ type: Boolean, default: false }, //Ознака неактивного елементу
		//selected: 		{ type: Object, default: () => {} },
		selected: 		{ type: Object, default: null },
	},

	computed: {
		id() {
			return this.selected?.id ? this.selected.id : null
		},

		rules() {
			return {
				required: (value) => !!value || 'Поле обов\'язкове для заповнення',
				mustBeSelected: (value) => typeof(value) === "undefined" || value === null || value?.id>0 || this.$vuetify.locale.t('$vuetify.rule.mustBeSelected'),
				
				code: (value) => {
					const pattern = /^([a-zA-Z'\-]+)/
					return (value === null || value.length == 0 || value.length > 32 || pattern.test(value)) || this.$vuetify.locale.t('$vuetify.rule.code', [128])
				},
				name: (value) => {
					const pattern = /^([a-zA-Zа-яА-ЯЇї'\-]+)/
					return (value && value.length <= 10 && pattern.test(value)) || 'І\'мя некоректне'
					//return value === null || ( value.length <= 10 && pattern.test(value)) || 'Name is not correct'
				},
				date: (value) => {
					//console.log('form.mixin', 'rules.date', 'value', value)
					return (value === null || value?.length == 0 || moment(value, "DD.MM.YYYY", true).isValid() || moment(value, "YYYY-MM-DD", true).isValid()) || 'Date is not correct'
				},

				datetime: (value) => {
					return (value === null || value.length == 0 || moment(value, "YYYY-MM-DDTHH:mm", true).isValid()) || 'Date is not correct'
				},

				email: (value) => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					//return pattern.test(value) || this.$vuetify.locale.t('$vuetify.rule.email')//'Некоректний e-mail.'
					return pattern.test(value) || 'Некоректний e-mail.'
				},

				phone: (value) => {
					const pattern = /^\+?3?8?(0\d{9})$/
					//return (value === null || value?.length == 0 || pattern.test(value)) || 'Номер телефону не вірний (+380961112233)'
					return !value || pattern.test(value) || 'Номер телефону не відповідає формату (+380961112233)'
				},

				personsname: (value) => {
					const pattern = /^([a-zA-Zа-яА-ЯЇї'\- ]+)/
					//return (value && value.length <= 10 && pattern.test(value)) || this.$vuetify.locale.t('$vuetify.rule.personsname')//'Name is not correct'
					return (value && value.length <= 10 && pattern.test(value)) || 'Name is not correct'//'Name is not correct'
				},
			}
		},
	},

	watch: {
		dialog: function (val) {
			if(val) {
				this.onOpen()
			}
		},
	},

	methods: {
		...mapActions([ 'showMessage' ]),

		/**
		 * Ініціалізація елементів форми
		 */
		init() {},

		/**
		 * Завантаження даних
		 */
		load() {
			if(this.id) {
				this.loading = true
				this.find(this.id).finally(() => {
					this.loading = false
				})
			}
		},

		validate() {
			return new Promise((resolve, reject) => {
				if(this.$refs.form.validate()) {
					resolve();
				} else {
					reject(new Error('Data is not valid'));
				}
			});
		},

		/**
		 * Подія при відкритті вікна
		 */
		onOpen() {
			this.onRefreh()
		},

		/**
		 * Закриття вікна
		 */
		onClose () {
			//console.log(this.$options.name, 'onClose')

			this.dialog = false
		},

		/**
		 * Відміна
		 */
		onCancel () {
			this.onClose()
		},

		/**
		 * Очистка даних
		 */
		onClear() {
			this.setItem()
			this.init()
		},

		/**
		 * Оновлення даних
		 */
		onRefreh() {
			this.onClear()
			this.load()
		},

		/**
		 * Збереження даних
		 */
		onSave() {
			console.log(this.$options.name, 'mixin.edit.js', 'onSave', 'item', this.item)

			this.loading = true
			return this.validate().then(() => {
				if(this.id) {
					return this.update(this.selected).then(() => {
						this.snack = {
							visible: true,
							color: 'success',
							title: 'Дані змінено',
							subtitle: null
						}
					})
				} else {
					return this.create(this.item).then(() => {
						this.snack = {
							visible: true,
							color: 'success',
							title: 'Дані збережено',
							subtitle: null
						}
					})
				}
			}).catch((error) => {
				console.log(this.$options.name, 'onSave', 'error', error)
				//this.showMessage(error)
				this.snack = {
					visible: true,
					color: 'error',
					title: 'Дані не збережено',
					subtitle: error,
				}
			}).finally(() => {
				this.loading = false
			});
		},

		onOK() {
			console.log(this.$options.name, 'mixin.edit.js', 'onOK', 'item', this.item)

			this.loading = true
			return this.validate().then(() => {
				if(this.id) {
					return this.update(this.selected)
				} else {
					return this.create(this.item)
				}
			}).then(() => {
				this.onClose()
			}).catch((error) => {
				console.log(this.$options.name, 'onOK', 'error', error)
				this.showMessage(error)
			}).finally(() => {
				this.loading = false
			});
		},
		
		/* addItems (items, data) {
			for(let i in data) {
				items.push(data[i]);
			}
		},

		removeItem (items, item) {
			const index = items.indexOf(item)
			items.splice(index, 1);
		},

		removeItems(items, filtered, indexes) {
			let itms = []
			indexes.forEach((i) => itms.push(filtered[i]));
			itms.forEach((item) => this.removeItem(items, item));
		}, */

		/* onOpen () {
			//console.log('details.mixin.main', 'onOpen','selected', this.selected)

			this.find(this.selected)
		}, */		
	},
}