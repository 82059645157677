import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VForm,{ref:"form"},[_c(VTabsItems,{model:{value:(_vm.form.tab),callback:function ($$v) {_vm.$set(_vm.form, "tab", $$v)},expression:"form.tab"}},[_c(VTabItem,[_c(VCard,[_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Id","outlined":"","dense":"","clearable":"","hide-details":"auto"},model:{value:(_vm.item.id),callback:function ($$v) {_vm.$set(_vm.item, "id", $$v)},expression:"item.id"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.name')),"outlined":"","dense":"","clearable":"","hide-details":"auto"},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextarea,{attrs:{"label":_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.comment')),"outlined":"","hint":"","rows":"2"},model:{value:(_vm.item.description),callback:function ($$v) {_vm.$set(_vm.item, "description", $$v)},expression:"item.description"}})],1)],1)],1)],1)],1),_c(VTabItem,[_c('z-table',{attrs:{"module":"users","items":_vm.item.users}})],1),_c(VTabItem,[_c('z-table',{attrs:{"module":"services","items":_vm.item.services}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }