<template>
	<v-main>
        <v-container>
           <!--  <v-toolbar elevation="0">
				<v-btn icon color="primary" @click="$router.push('/')">
					<v-icon>mdi-home</v-icon>
				</v-btn>
				<v-toolbar-title class="font-weight-light">{{ $vuetify.lang.t('$vuetify.' + title) | capitalize }}</v-toolbar-title>
				<v-spacer></v-spacer>
			</v-toolbar> -->

			<v-data-table
				v-model="selected"
				:headers="headers"
				:items="items"
				item-key="id"
				show-select
				:loading="loading"
				loading-text="Завантаження даних... зачекайте, будь ласка." 
				class="mt-16"
			>
				<template v-slot:top>
					<v-toolbar flat>
						<v-toolbar-title>{{ $vuetify.lang.t('$vuetify.' + title) | capitalize }}</v-toolbar-title>
						<v-divider class="mx-4" inset vertical ></v-divider>
						<v-spacer></v-spacer>

						<!-- Пошук -->
						<!-- <v-btn icon @click="OnSearch">
							<v-icon>mdi-magnify</v-icon>
						</v-btn> -->

						<!-- Створити -->
						<!-- <z-dialog-detail
							:module="module"
							mode="create"
							:title="title"
							:icon="icon"
						></z-dialog-detail> -->

						<z-detail
							activator-type="button"
							activator-outlined
							:activator-title="$vuetify.lang.t('$vuetify.create')"
							activator-tooltip-disabled
						></z-detail>

						<!-- Оновити -->
						<v-btn icon @click="onRefresh">
							<v-icon>mdi-refresh</v-icon>
						</v-btn>

					</v-toolbar>
				</template>
				<!-- <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('type'))" v-slot:[`item.${header.value}`]="{ value }">
					<span v-if="header.type =='datetime'" :key="index">{{  DateFormat(value) }}</span>
				</template> -->

				<template v-slot:[`item.actions`]="{ item }">
					<v-toolbar elevation="0" dense color="transparent">
						<z-detail
							mode="update"
							:selected="item"
							activator-type_="icon"
							activator-icon_="mdi-pencil"
							:activator-tooltip-text_="$vuetify.lang.t('$vuetify.update')"
						></z-detail>
						<z-confirm-delete 
							:activator-tooltip-text="$vuetify.lang.t('$vuetify.delete')"
							@ok="onDelete(item)"
						></z-confirm-delete>
					</v-toolbar>

				</template>
			</v-data-table>
		</v-container>
		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
    </v-main>
</template>

<script>
import mixin from './mixin/main'
import { mapGetters, mapActions } from 'vuex'
import downloadExcel from "vue-json-excel"
import zDetail from '@/views/RolesDetail'
import zConfirmDelete from '@/views/components/confirm-delete'

export default {
	mixins: [mixin],
	components: {
		downloadExcel,
		zDetail,
		zConfirmDelete
	},
	data: () => ({
		title: 'roles',

		headers: [
            //{ text: 'ID', value: 'id', align: ' d-none' },
            { text: 'ID', value: 'id', sortable: true, width: '10%'},
            { text: 'Назва', value: 'name', sortable: true},
            { text: 'Користувачі', value: 'users.length', sortable: true, width: '15%'},
            { text: 'Сервіси', value: 'services.length', sortable: true, width: '10%'},
            { text: 'Actions', value: 'actions', sortable: false, width: '10%' },
        ],
	}),

	computed: {
		...mapGetters('Roles', [ 'endpoint', 'items' ]),
	},

	methods: {
		...mapActions('Roles', [ 'setItem', 'setItems', 'createItem', 'updateItem', 'deleteItem' ]),
	},
}
</script>