import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","show-select":"","search":_vm.search,"loading":_vm.loading,"loading-text":"Завантаження даних... зачекайте, будь ласка.","sort-by":"id","sort-desc":"","items-per-page":_vm.itemsPerPage,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":""}},[_c(VToolbarTitle,[_vm._v(" "+_vm._s(_vm._f("capitalize")((_vm.title in _vm.$vuetify.lang.locales.en ? _vm.$vuetify.lang.t('$vuetify.' + _vm.title) : _vm.title )))+" ")]),_c(VDivider,{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c(VSpacer),_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-filter-variant")])],1)]}}]),model:{value:(_vm.menu.filter),callback:function ($$v) {_vm.$set(_vm.menu, "filter", $$v)},expression:"menu.filter"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Фільтр")]),_c(VCardText,[_c(VList,[_c(VListItem,[_c(VListItemAction,[_c(VSwitch,{attrs:{"false-value":"0","true-value":"1"},model:{value:(_vm.onlymy),callback:function ($$v) {_vm.onlymy=$$v},expression:"onlymy"}})],1),_c(VListItemTitle,[_vm._v("Тільки мої запити")])],1)],1),(_vm.isAdmin)?_c(VCombobox,{attrs:{"items":_vm.organizations,"label":"Організація","dense":"","outlined":"","item-text":"name","item-value":"id","loading":_vm.loadings.organization},on:{"focus":_vm.loadOrganizations},model:{value:(_vm.organization),callback:function ($$v) {_vm.organization=$$v},expression:"organization"}}):_vm._e(),_c(VTextField,{attrs:{"prepend-icon_":"mdi-book-open-page-variant","label":"Кількість рядків на сторінку","dense":"","hide-details":"auto","outlined":"","type":"number","min":10,"max":100},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=_vm._n($$v)},expression:"itemsPerPage"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.menu.filter = false}}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.onFilter}},[_vm._v("OK")])],1)],1)],1),_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Пошук","dense":"","single-line":"","hide-details":"","outlined":"","rounded":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('z-dialog-compare',{attrs:{"selected":_vm.selected}}),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onRefresh.apply(null, arguments)}}},[_c(VIcon,[_vm._v("mdi-refresh")])],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VToolbar,{attrs:{"elevation":"0","dense":"","color":"transparent"}},[_c(VIcon,{attrs:{"color":"red"},on:{"click":function($event){return _vm.onPDF(item)}}},[_vm._v("mdi-file-pdf-box")]),_c('z-detail',{attrs:{"selected":item}})],1)]}},_vm._l((_vm.headers),function(item,index){return {key:("header." + (item.value)),fn:function(ref){return [_c('span',{key:index},[_vm._v(_vm._s(_vm._f("capitalize")((item.text in _vm.$vuetify.lang.locales.en ? _vm.$vuetify.lang.t('$vuetify.' + item.text) : item.text ))))])]}}}),{key:"item.datecreate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDateTime")(item.datecreate)))])]}},{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c(VIcon,[_vm._v(_vm._s(item.icon))])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c(VFooter,{attrs:{"app":"","padless":""}},[_c(VCard,{staticClass:"text-center",attrs:{"flat":"","tile":"","width":"100%"}},[_c(VCardText,[_c(VPagination,{attrs:{"length":_vm.pageCount,"total-visible":7},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1),_c('z-pdf',{ref:"pdf",attrs:{"item":_vm.pdfItem}}),_c(VOverlay,{attrs:{"value":_vm.loading}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }