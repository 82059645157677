import axios from 'axios'
export default {
	namespaced: true,
	state: {
		items: [],
	},
	actions: {
		load (context, data) {
			return axios.get('/document/type').then(response => {
				context.commit('items', response.data)
			}, error => {
				console.log('error', error)
			})
		},
	},
}