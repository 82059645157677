<template>
	<v-main>
		<v-navigation-drawer fixed clipped permanent style="padding: 64px 0px 0px 0px;"
			width="300"
		>
			<v-card elevation="0">
				<v-card-text>
					<!-- <z-vehicle-parameters-number></z-vehicle-parameters-number> -->
					<z-vehicle-parameters></z-vehicle-parameters>

					<v-system-bar class="my-2">
						<span>Параметри для методики</span>
						<v-spacer></v-spacer>
					</v-system-bar>

					<z-vehicle-parameters-metodica></z-vehicle-parameters-metodica>
				</v-card-text>
			</v-card>
			<template v-slot:append>
				<v-card-actions class="px-4">
					<v-spacer></v-spacer>
					<v-btn text color="primary" @click="onClear">{{ $vuetify.lang.t('$vuetify.clear') }}</v-btn>
					<v-btn color="primary" @click="onOK">{{ $vuetify.lang.t('$vuetify.ok') }}</v-btn>
				</v-card-actions>
			</template>
		</v-navigation-drawer>

		<v-navigation-drawer fixed clipped permanent right
			mini-variant
			mini-variant-width="56"
			style="padding: 64px 0px 0px 0px;"
        >
			<v-list dense nav>
				<!-- PDF -->
				<v-list-item>
					<v-list-item-action>
						<v-tooltip bottom>
							<template v-slot:activator="{ on: tooltip, attrs }">
								<v-icon large color="red" v-bind="attrs" v-on="{ ...tooltip }" @click="onPDF">mdi-file-pdf-box</v-icon>
							</template>
							<span>Експорт PDF</span>
						</v-tooltip>
					</v-list-item-action>
				</v-list-item>

				<!-- Excel -->
				<v-list-item>
					<v-list-item-action>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<download-excel 
									:data="excel.data"
									:name="excel.name"
									:type="excel.type"
								>
									<v-icon large color="green darken-2" :disabled="excel.disabled" v-bind="attrs" v-on="on">mdi-microsoft-excel</v-icon>
								</download-excel>
							</template>
							<span>Експорт Excel</span>
						</v-tooltip>
					</v-list-item-action>
				</v-list-item>
				<v-divider></v-divider>
				<v-list-item>
					<v-list-item-action>
						<v-tooltip bottom>
							<template v-slot:activator="{ on: tooltip, attrs }">
								<v-icon large v-bind="attrs" v-on="{ ...tooltip }" @click="copyParam">mdi-content-copy</v-icon>
							</template>
							<span>Копіювати параметри</span>
						</v-tooltip>
					</v-list-item-action>
				</v-list-item>
				<v-list-item>
					<v-list-item-action>
						<v-tooltip bottom>
							<template v-slot:activator="{ on: tooltip, attrs }">
								<v-icon large v-bind="attrs" v-on="{ ...tooltip }" @click="pasteParam">mdi-content-paste</v-icon>
							</template>
							<span>Вставити параметри</span>
						</v-tooltip>
					</v-list-item-action>
				</v-list-item>
			</v-list>
        </v-navigation-drawer>

		<v-container style="padding: 64px 0px 0px 300px;">
			<v-card elevation="0">
				<v-card-title class="font-weight-light">
					{{ title }} {{ id ? '№' + id: '' }}
				</v-card-title>

				<v-divider></v-divider>

				<z-form-vehicle-estimate-identify :item="item"></z-form-vehicle-estimate-identify>
				<div v-for="(estimate, index) in estimates" :key="index">
					<z-form-average-price-statistic :estimate="estimate"></z-form-average-price-statistic>
				</div>
				<z-form-vehicle-estimate-calculation :item="item"></z-form-vehicle-estimate-calculation>
				<z-form-vehicle-estimate-conclusion :item="item"></z-form-vehicle-estimate-conclusion>
			</v-card>
		</v-container>
		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
		<v-snackbar v-model="snackbar.visible" :color="snackbar.color">
			{{ snackbar.text }}
			<template v-slot:action="{ attrs }">
			<v-btn icon v-bind="attrs" @click="snackbar.visible = false" >
				<v-icon>mdi-close</v-icon>
			</v-btn>
			</template>
		</v-snackbar>

		<z-pdf ref="pdf" :item="item"></z-pdf>
	</v-main>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import downloadExcel from "vue-json-excel";
import zVehicleParametersNumber from '@/views/forms/vehicle.parameters.number'
//import zVehicleParameters from '@/views/forms/vehicle.parameters'
import zVehicleParameters from '@/views/forms/vehicle.parameters.ai'
import zVehicleParametersMetodica from '@/views/forms/vehicle.parameters.metodica'
import zPdf from '@/views/pdf/VehicleEstimate'

import zFormVehicleEstimateIdentify from '@/views/forms/VehicleEstimateIdentify'
import zFormAveragePriceMediana from '@/views/forms/VehicleAveragePriceMediana'
import zFormAveragePriceAi from '@/views/forms/VehicleAveragePriceAI'
import zFormAveragePriceStatistic from '@/views/forms/VehicleAveragePriceStatistic'
import zFormVehicleEstimateCalculation from '@/views/forms/VehicleEstimateCalculation'
import zFormVehicleEstimateConclusion from '@/views/forms/VehicleEstimateConclusion'

export default {
	name: 'VehicleEstimateMetodica',
	components: {
		zPdf,
		downloadExcel,
		zVehicleParametersNumber,
		zVehicleParameters,
		zVehicleParametersMetodica,
		zFormVehicleEstimateIdentify,
		zFormAveragePriceMediana,
		zFormAveragePriceAi,
		zFormAveragePriceStatistic,
		zFormVehicleEstimateCalculation,
		zFormVehicleEstimateConclusion,
	},
	data: () => ({
		title: 'Оцінка ринкової вартості транспортного засобу',
		loading: false,

		excel: {
			name: '',
			type: 'xlsx',
			disabled: true,
			data: []
		},

		snackbar: {
			visible: false,
			text: null,
			color: null
		},
	}),
	props: {
		
	},

	watch: {
		
	},
	
	computed: {
		...mapGetters('VehicleEstimate', [
			'params', 'item', 'isset'
		]),

		excelName() {
			return this.title + (this.params.regnum ? ' ' + this.params.regnum : (this.params.vin ? ' ' + this.params.vin : '') + '.' +  this.excel.type)
		},

		/* brand() {
			return this.params.hasOwnProperty('brand') ? this.params.brand.name : '';
		},
		model() {
			return this.params.hasOwnProperty('model') ? this.params.model.name : '';
		}, */
		id() {
			return this.item.hasOwnProperty('id') ? this.item.id: null;
		},
		
		estimates() {
			return this.item.hasOwnProperty('estimates') ? this.item.estimates: [];
		},

		
	},

	created () {
		//this.clear()
		//this.load()
	},

	methods: {
		...mapActions('VehicleEstimate', [
			'setItem', 'clear'
		]),

		/* adverts(items) {
			const sorted = [...items].sort((a, b) => parseInt(a.price) - parseInt(b.price));
			return sorted
		},

		getAdvertUrl(item) {
			//console.log('VehicleEstimateMarket', 'getAdvertUrl', 'item', item)

			return `https://auto.ria.com/uk/auto_${this.brand}_${this.model}_${item.code}.html`
		}, */

		onClear() {
			this.clear()
		},

		validate() {
			return this.params.number 
				|| this.params.vin
				|| this.params.brand
				|| this.params.model
		},

		onOK() {
			
			//console.log(that.$options.name, 'onOK', 'params', this.params)
			//return

			if(this.validate()) {
				this.loading = true
				var that = this
				axios.post('/vehicle/estimate/metodica', this.params).then(response => {
					//console.log(that.$options.name, 'onOK','response.data', response.data)
					
					that.setItem(response.data)
				}, error => {
					console.log('error', error)

					that.snackbar.visible 	= true;
					that.snackbar.text 		= error.response.data.message;
					that.snackbar.color 	= null;
				}).finally(() => {
					this.loading = false
				});
			} else {
				this.snackbar.visible 	= true;
				this.snackbar.text 		= 'Відсутні дані для пошуку';
				this.snackbar.color 	= null;
			}
		},

		onPDF () {
			this.$refs.pdf.generate()
		},

		copyParam () {
			//console.log(this.$options.name, 'copyParam','params', this.params)
			let text = JSON.stringify(this.params)
			console.log(this.$options.name, 'copyParam','text', text)
			navigator.clipboard.writeText(text);

			/* async function setClipboard(text) {
				//const type = "text/plain";
				const type = "application/json";
				const blob = new Blob([text], { type });
				const data = [new ClipboardItem({ [type]: blob })];
				await navigator.clipboard.write(data);
			}

			
			setClipboard(text) */
		},

		pasteParam () {
			//navigator.clipboard.readText().then((clipText) => (destination.innerText = clipText));
			navigator.clipboard.readText().then((clipText) => {
				console.log(this.$options.name, 'pasteParam','clipText', clipText)
				//destination.innerText = clipText
			});
		},
	},
}
</script>