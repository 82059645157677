import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSnackbar,{attrs:{"color":_vm.color,"timeout":_vm.timeout,"multi-line":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c(VLayout,{attrs:{"align-center":"","pr-4":""}},[_c(VIcon,{staticClass:"pr-3",attrs:{"dark":"","large":""}},[_vm._v(_vm._s(_vm.icon))]),_c(VLayout,{attrs:{"column":""}},[_c('div',[_vm._v(_vm._s(_vm.text))])])],1)]},proxy:true},{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onClose.apply(null, arguments)}}},'v-btn',attrs,false),[_c(VIcon,[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})}
var staticRenderFns = []

export { render, staticRenderFns }