<template>
	<v-dialog 
		v-model="dialog" 
	 	:fullscreen="fullscreen"
		scrollable
		max-width="800px"
		max-height="800px"
	>
		<!-- Актіватор -->
		<template v-slot:activator="{ on: dialog, attrs }">
			<z-activator v-bind="attrs" v-model="dialog"
				:type="activatorType"
				:icon-name="activatorIcon"
				:title="$vuetify.lang.t('$vuetify.add')"
				:tooltip-text="$vuetify.lang.t('$vuetify.add')"
			></z-activator>
		</template>

		<v-card>
			<!-- Ttoolbar -->
			<v-toolbar elevation="0" dark color="primary" style="flex: 0 1 auto;">
				<v-avatar>
					<v-icon>{{ icon }}</v-icon>
				</v-avatar>
				<v-toolbar-title>{{ $vuetify.lang.t('$vuetify.' + title) | capitalize }}</v-toolbar-title>
				
				<v-spacer></v-spacer>

				<v-btn icon @click="fullscreen = !fullscreen" >
					<v-icon v-if="fullscreen">mdi-arrow-collapse</v-icon>
					<v-icon v-else>mdi-arrow-expand</v-icon>
				</v-btn>

				<v-btn icon @click="dialog=false" >
					<v-icon>mdi-close</v-icon>
				</v-btn>

				<template v-slot:extension>
					<v-toolbar dense color="primary" elevation="0">
						<v-checkbox v-if="multiple"
							:input-value="filtered.length == selected.length"
							:indeterminate="filtered.length != selected.length && selected.length > 0"
							hide-details
							@change="onSelectAll"
						></v-checkbox>

						<z-field-search v-model="search"></z-field-search>

						<v-btn icon @click="sortAlphabeticalAscending=!sortAlphabeticalAscending">
							<v-icon v-if="sortAlphabeticalAscending">mdi-sort-alphabetical-ascending</v-icon>
							<v-icon v-else>mdi-sort-alphabetical-descending</v-icon>
						</v-btn>

						<v-btn icon @click="viewList=!viewList">
							<v-icon v-if="viewList">mdi-view-list</v-icon>
							<v-icon v-else>mdi-view-module</v-icon>
						</v-btn>
					</v-toolbar>
				</template>
			</v-toolbar>

			<v-card-text>
				<v-list dense>
					<v-list-item-group v-model="selected" :multiple="multiple">
						<template v-for="(itm, index) in filtered">
							<v-list-item>
									<template v-slot:default="{ active }">
										<v-list-item-action>
											<v-checkbox 
												:input-value="active"
												:on-icon="multiple?'mdi-checkbox-outline':'mdi-radiobox-marked'"
												:off-icon="multiple?'mdi-checkbox-blank-outline':'mdi-radiobox-blank'"
											></v-checkbox>
										</v-list-item-action>
										<v-list-item-avatar v-if="!viewList">
											<v-icon v-text="itm.image"></v-icon>
										</v-list-item-avatar>
										<v-list-item-content>
											<v-list-item-title v-text="itm.name"></v-list-item-title>
											<v-list-item-subtitle v-if="!viewList" v-text="itm.type.name"></v-list-item-subtitle>
										</v-list-item-content>

										<v-list-item-action v-if="!viewList">
											<v-list-item-action-text v-text="itm.id"></v-list-item-action-text>
										</v-list-item-action>
									</template>
							</v-list-item>
							<v-divider v-if="index < filtered.length - 1" :key="index"></v-divider>
						</template>
					</v-list-item-group>
				</v-list>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn text @click="onCancel">{{ $vuetify.lang.t('$vuetify.cancel') }}</v-btn>
				<v-btn color="success" dark @click.native="onOK" :disabled="loading">{{ $vuetify.lang.t('$vuetify.ok') }}</v-btn>
			</v-card-actions>
		</v-card>

		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
	</v-dialog>
</template>

<script>
import mixin from './mixin/main'
import { mapGetters, mapActions } from 'vuex'
import zActivator from '@/views/components/activator/activator'
import zFieldSearch from '@/views/components/field.search'

export default {
	mixins: [mixin],
	components: {
		zActivator,
		zFieldSearch,
	},
	data: () => ({
		title: 'services',
		icon: 'mdi-account-tie',
	}),

	computed: {
		...mapGetters('Services', [ 'endpoint', 'items' ]),
	},

	methods: {
		...mapActions('Services', [ 'setItem', 'setItems', 'createItem', 'updateItem', 'deleteItem' ]),
	},
}
</script>