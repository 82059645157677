<template>
	<v-main>
		<v-container style="background: linear-gradient(45deg,#ffffff,#257047);" fluid>
			<v-container>
				<v-row>
					<v-col cols="12" sm="6" md="6">
						<div class="mt-8 text-h5">Сервіси для бізнесу</div>
						<p class="mt-8 mb-16 text-body-1 font-weight-light">
							Широкий спектр сервісів в різних напрямках для сучасного бізнесу.<br>
							Доступно та зручно.
						</p>
						<!-- <v-btn outlined color="primary" :to="{name: 'connection'}">{{ $vuetify.lang.t('$vuetify.orderconnection') | capitalize }}</v-btn> -->
					</v-col>
					<v-col cols="12" sm="6" md="6" class="text-right d-flex align-stert mb-6">
						<img :src="require('../assets/honeycomb.svg')">
						<v-row>
							<v-col cols="12">
								<img :src="require('../assets/logo_tinex.jpg')">
							</v-col>
							<v-col cols="12">
								<img :src="require('../assets/logo_pvbki_ukr.png')">
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-container>
		</v-container>

		<v-main style="padding: 30px 0px 0px;">
			<v-container>
				<!-- <v-card class="d-flex justify-center mb-6 flex-wrap" flat tile>
					<ShopCard v-for="(item, j) in services" :key="j"
						:overline="item.overline"
						:title="item.title"
						:subtitle="item.subtitle"
						:icon="item.icon"
						:router="item.router"
					></ShopCard>
				</v-card> -->
				
				<v-row>
					<v-col v-for="(item, index) in services" :key="index"
						cols="12" md="6"
					>
						<ShopCard
							:overline="item.overline"
							:title="item.title"
							:subtitle="item.subtitle"
							:icon="item.icon"
							:router="item.router"
							:color="item.color"
						></ShopCard>
						<!-- :color="item.type.color" -->
					</v-col>
				</v-row>
			</v-container>
		</v-main>

		<v-overlay :value="loading">
			<v-progress-circular
				indeterminate
				size="64"
			></v-progress-circular>
		</v-overlay>

	</v-main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
//import ShopCard from '@/views/shop.card'
import ShopCard from '@/views/shop.card2'

export default {
	name: 'Home',
	components: {
		ShopCard,
	},

	data () {
		return {
			loading: false,
			browser: null,
			
			straights: [
				{
					title: 'Транспортні засоби',
					icon: '',
					services: [
						{ title: 'Оцінка ринкової вартості за параметрами', },
						{ title: 'Оцінка ринкової вартості за номером державної реєстрації та/або vin-кодом', },
						{ title: 'Оцінка за методикою товарознавчої експертизи', },
						{ title: 'Оцінка за методикою товарознавчої експертизи', },
					],
				},
				{
					title: 'Лікарські засоби',
					icon: '',
					services: [
						{ title: 'Оцінка динаміки змін вартості', },
						{ title: 'Оцінка ринкової вартості в поточний період', },
						{ title: 'Оцінка рецептів, рахунків, прайсів', },
					],
				},
				{
					title: 'Медичні послуги',
					icon: '',
					services: [
						{ title: 'Оцінка ринкової вартості', },
						{ title: 'Оцінка вартості лікування', },
					],
				},
				{
					title: 'Лабораторні дослідження',
					icon: '',
					services: [
						{ title: 'Оцінка ринкової вартості', },
					],
				},
			],

			/* services: [
				{
					title: 'Транспортні засоби',
					subtitle: '',
					icon: '',
					methods: [
						{ title: 'Аналіз ринкових цін', subtitle: '', icon: 'mdi-car', iconcontent: '1', mono:{name: 'ServiceVehicleEstimateMarketMono'}, multi:{name: 'ServiceVehicleEstimateMarketMulti'} },
						//{ title: 'Оцінка вартості', subtitle: 'Оцінка вартості транспортного засобу за методикою товарознавчої експертизи', icon: 'mdi-car', iconcontent: '1', mono:{name: 'ServiceVehicleEstimateMetodicaMono'}, multi:{name: 'ServiceVehicleEstimateMetodicaMulti'} },
						{ title: 'Оцінка вартості', subtitle: '', icon: 'mdi-car', iconcontent: '1', mono:{name: 'ServiceVehicleEstimateMetodicaMono'}, multi:{name: 'ServiceVehicleEstimateMetodicaMulti'} },
						{ title: 'Автотоварознавче дослідження', subtitle: '', icon: 'mdi-car', iconcontent: '1', mono:{name: 'ServiceVehicleEstimateExplorationMono'}, multi:{name: 'ServiceVehicleEstimateExplorationMulti'} },
						//{ title: 'Оцінка ринкової вартості', subtitle: 'для одного транспортного засобу', icon: 'mdi-car', iconcontent: '1', to:{name: 'ServiceVehicleEstimateMono'} },
						//{ title: 'Оцінка ринкової вартості ', subtitle: 'для групи транспортних засобів', icon: 'mdi-car', iconcontent: '2+', to:{name: 'ServiceVehicleEstimateMulti'} },
					],
				},
				{
					title: 'Лікарські засоби',
					subtitle: '',
					icon: '',
					methods: [
						{ title: 'Оцінка ринкової вартості', subtitle: 'для одного лікарського засобу', icon: 'mdi-pill', iconcontent: '1', to:{name: 'ServiceVehicleEstimateMono'} },
						{ title: 'Оцінка ринкової вартості', subtitle: 'для групи лікарських засобів (рецептів, разунків, прайсів)', icon: 'mdi-pill', iconcontent: '2+', to:{name: 'ServiceVehicleEstimateMono'}},
					],
				},
			], */
		}
	},
	computed: {
		...mapGetters([ 'services' ]),

		/* ...mapGetters('app', [
			'services'
		]), */

		/* gradient() {

			var colors = [
				{ id: 0, hex: "#ffffff", disabled: false },
				{ id: 1, hex: "#257047", disabled: false }
			]

			let gradient = "linear-gradient(45deg";
			colors.forEach( function(e) {
				gradient += "," + e.hex;
			});
			gradient += ")";

			console.log('gradient', gradient)
			return gradient;
		} */

	},
	created () {

		// Дата компіляції
		//console.log('build', document.documentElement.dataset.buildTimestampUtc)
		
		// інформація про браузер, який використовується та операційну систему
		var platform = require('platform');
		//console.log('platform', platform)

		this.browser = {
			name: platform.name, // 'Safari'
			version: platform.version, // '5.1'
			product: platform.product, // 'iPad'
			manufacturer: platform.manufacturer, // 'Apple'
			layout: platform.layout, // 'WebKit'
			os: platform.os, // 'iOS 5.0'
			description: platform.description, // 'Safari 5.1 on Apple iPad (iOS 5.0)'
			ua: platform.ua
		}
		//console.log('browser', this.browser)
	},
}
</script>


<style>
/* @import url("https://fonts.googleapis.com/css?family=Questrial"); */

.v-application .text-h4 {
  	font-family: "Open Sans",Sans-Serif !important;
}

/* "Roboto", sans-serif !important; */
/* font-family:"Open Sans",Sans-Serif;font-size:16px */

</style>

