<template>
	 <v-data-table
		v-model="selected"
		:headers="headers"
		:items="items"
		item-key="id"
		show-select
		:loading="loading"
		loading-text="Завантаження даних... зачекайте, будь ласка." 
	>
		<template v-slot:top>
			<v-toolbar flat>
				<!-- <v-toolbar-title>My CRUD</v-toolbar-title>
				<v-divider class="mx-4" inset vertical ></v-divider> -->
				<v-spacer></v-spacer>

				<!-- Пошук -->
				<!-- <v-btn icon @click="OnSearch">
					<v-icon>mdi-magnify</v-icon>
				</v-btn> -->

				<!-- Додати -->
				<z-dialog :module="module"></z-dialog>

			</v-toolbar>
		</template>

		<template v-slot:[`item.actions`]="{ item }">
			<v-icon color="primary"  @click="$emit('delete', item)">mdi-trash-can-outline</v-icon>
		</template>
		<!-- <template v-slot:expanded-item="{ headers, item }">
			<td :colspan="headers.length">
				<v-row dense v-for="(prop, i) in properties(item)" :key="i">
					<v-col cols="12" sm="6" md="6" lg="3" xl="3">
						<strong class="d-flex justify-end">{{prop.title}}:</strong>
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="9" xl="9">
						{{ prop.value }}
					</v-col>
				</v-row>
			</td>
		</template> -->
	</v-data-table>
</template>

<script>
//import moment from "moment";
import zDialog from '@/views/components/dialog.dictionary'

export default {
	components: {
		zDialog
	},
	data: () => ({
		//modulename: 'dialogs.dialog',

		//dialog: false,
		loading: false,
		selected: [],
		headers: [
            //{ text: 'ID', value: 'id', align: ' d-none' },
            { text: 'ID', value: 'id', sortable: true },
            { text: 'Назва', value: 'name', sortable: true},
            { text: 'Actions', value: 'actions', sortable: false },
        ],
	}),
	props: {
		module:       	{ type: String, default: null },
		items: 			{ type: Array, default: () => [] },
	},
	computed: {
		/* title: function () {
            return this.$store.getters[this.module + '/title']
        },
		icon: function () {
            return this.$store.getters[this.module + '/icon']
        },
		items: function () {
            return this.$store.getters[this.module + '/items']
        },
		headers: function () {
            return this.$store.getters[this.module + '/headers']
        }, */
	},
	methods: {
		/* DateFormat(value) {
			return (value ? moment(value).format('YYYY-MM-DD') : null);
		}, */

		/* OnSearch (data) {
            var that = this
            this.loading = true;
            this.$store.dispatch(`${this.module}/read`, {
                //params: { name: this.searchtext },
                success: function(response) {
                    that.loading = false;

					//console.log(that.module,'OnSearch', 'items', that.items)
                },
                error: function(error) {
                    that.loading = false;
                }
            })
        },
        OnDelete (item) {
            if(item) {
                var that = this
                this.loading = true;
                this.$store.dispatch(`${this.module}/delete`, {
                    item: item,
                    success: function(response) {
                        that.loading = false;
                    },
                    error: function(error) {
                        that.loading = false;
                    }
                })
            }
        },
        OnDeleteSelected () {
            for(let i in this.selected) {
                this.OnDelete(this.selected[i])
            }
            data.selected = []
        },

		OnRead (e) {
			//this.dialog = true
		}, */
	},
}
</script>