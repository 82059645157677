<template>
	<v-main>
		<v-container>
			<v-row>
				<v-col cols="12">
					<v-btn icon color="primary" @click="$router.push('/')">
						<v-icon >mdi-home</v-icon>
					</v-btn>
				</v-col>

				<v-col cols="12" class="d-flex justify-center">
					<v-card elevation="0" width="600px">
						<v-toolbar class="elevation-0">
							<v-icon class="mr-6">{{ icon }}</v-icon>
							<v-toolbar-title>{{ $vuetify.lang.t('$vuetify.'+title) | capitalize }}</v-toolbar-title>
							<v-spacer></v-spacer>
						</v-toolbar>
						<v-card-text>
							<v-form ref="form">
								<v-row>
									<v-col cols="12">
										<v-text-field
											v-model="item.name"
											:label="$vuetify.lang.t('$vuetify.pib') | capitalize"
											prepend-icon="mdi-account"
											required
											:rules="[rules.required, rules.name]"
											@keyup.ctrl.90="OnDefault"
										></v-text-field>
									</v-col>                                  
									<v-col cols="12" sm="6" md="6">
										<v-text-field
											v-model="item.email"
											:label="$vuetify.lang.t('$vuetify.email') | capitalize"
											prepend-icon="mdi-email"
											required
											:rules="[rules.required, rules.email]"
											@keyup.ctrl.90="OnDefault"
										></v-text-field>
									</v-col>
									<v-col cols="12" sm="6" md="6">
										<v-text-field 
											v-model="item.phone"
											:label="$vuetify.lang.t('$vuetify.telephone') | capitalize"
											prepend-icon="mdi-phone"
											:rules="[rules.phone]"
											@keyup.ctrl.90="OnDefault"
										></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field 
											v-model="item.company"
											:label="$vuetify.lang.t('$vuetify.company') | capitalize"
											prepend-icon="mdi-domain"
											required
											:rules="[rules.required]"
											@keyup.ctrl.90="OnDefault"
										></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-textarea
											v-model="item.description"
											:label="$vuetify.lang.t('$vuetify.comment') | capitalize"
											prepend-icon="mdi-comment"
											hint="Вкажіть, будь ласка, з якими сервісами ви бажаєте працювати"
											rows="1"
										></v-textarea>
									</v-col>
								</v-row>
							</v-form>
							<z-alert ref="alert"></z-alert>
						</v-card-text>

						<v-card-actions>
							<!-- <v-spacer></v-spacer> -->
							<v-btn large block
								:loading="loading"
								@click.native="OnOk"
								color="success"
								dark
							>
								<v-icon left dark>mdi-check-bold</v-icon>	
								{{ $vuetify.lang.t('$vuetify.ok') }}
							</v-btn>
							<!-- <v-spacer></v-spacer> -->
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</v-main>
</template>

<script>
import axios from 'axios'
import VueRecaptcha from 'vue-recaptcha'
import { mapGetters, mapActions } from 'vuex'
import zAlert from '@/views/components/alert'

export default {
	components: {
		VueRecaptcha, zAlert
	},
	data: () => ({
		title: 'orderconnection',
		icon: 'mdi-account-plus',
		loading: false,

		rules: {
			required: (value) => !!value || 'Поле обов\'язкове для заповнення',
			name: (value) => {
				const pattern = /^([a-zA-Zа-яА-ЯІіЇї'\-]+)/
				return (value && value.length > 10 && pattern.test(value)) || 'І\'мя некоректне'
			},
			email: (value) => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Некоректний e-mail.'
            },
			phone: (value) => {
				const pattern = /^\+?3?8?(0\d{9})$/
				return !value || pattern.test(value) || 'Номер телефону не вірний (+380961112233)'
			},
		},

		item: {
			name: null
			,email: null
			,phone: null
			,company: null
		},

	}),
	
	computed: {
		
	},
	
	methods: {
		...mapActions('app',[
			'Connection'
		]),

		OnDefault () {
			//ctrl+z
			this.item.name       = "Іваненко Іван Іванович"
			//this.item.email      = "qwins@i.ua"
			this.item.phone      = "+380971112233"
			this.item.company    = "Global International"
		},  

		OnOk() {
			var that = this;

			this.$refs.alert.Close();

			if (this.$refs.form.validate()) {
				var that = this;
				this.loading = true;
				axios.post('/profile/registration').then(response => {
					that.$refs.alert.Open(response.data, 'success')
				}, error => {
					that.$refs.alert.Open(error, 'error')
				}).finally(() => {
					this.loading = false
				});
			}
		},

		OnOk_() {
			var that = this;

			this.$refs.alert.Close();

			if (this.$refs.form.validate()) {
				this.loading = true;
				this.Connection({
					item: this.item,
					success: function(response) {
						that.loading = false;
						//router.push('/')
						that.$refs.alert.Open(response.data, 'success')
					},
					error: function(response) {
						that.loading = false
						//console.log('OnOk', 'error', response)
						that.$refs.alert.Open(response.data, 'error')
					},
				});
			}
		},
	},
}
</script>