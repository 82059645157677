<template>
	<v-dialog  
	 	v-model="dialog"
	 	fullscreen
		transition_="dialog-bottom-transition"
		scrollable
	>
		<!-- Актіватор -->
		<template v-slot:activator="{  on, attrs }">
			<slot name="activator">
				<v-btn icon v-bind="attrs" v-on="on">
					<v-icon>mdi-eye</v-icon>
				</v-btn>
			</slot>
		</template>

		<v-card>
			<!-- Ttoolbar -->
			<v-toolbar elevation="0" dark color="primary" style="flex: 0 1 auto;">
				<v-avatar>
					<v-icon>{{ icon }}</v-icon>
				</v-avatar>
				<!-- <v-toolbar-title>{{ title }}</v-toolbar-title>
				<v-toolbar-subtitle>{{ title }}</v-toolbar-subtitle> -->
				<v-toolbar-title>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>{{ title }}</v-list-item-title>
							<v-list-item-subtitle>№{{ item.id }} {{ item.datecreate | formatDateTime }}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-toolbar-title>
				

				<v-spacer></v-spacer>

				<!-- <z-detail-script v-if="permitionScript" :item="item"></z-detail-script> -->

				<v-btn icon @click="dialog=false" >
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>

			<v-navigation-drawer
				style="transform: translateX(0) !important; visibility: visible !important;"
				permanent
				absolute
				fixed
				left
				width="300"
				class='mt-16'
				height=""
				floating
				disable-resize-watcher
			>
				<!-- <v-card elevation="0"> -->
				<v-card>
					<v-card-text>
						<v-text-field v-if="item.category"
							:value="item.category.name"
							label="Категорія"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field>
						<v-text-field v-if="item.type"
							:value="item.type.name"
							label="Тип"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field>
						<v-text-field v-if="item.operation"
							:value="item.operation.name"
							label="Тип операції"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field>
						<v-text-field v-if="item.state"
							:value="item.state.name"
							label="Область"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field>
						<v-text-field v-if="item.city"
							:value="item.city.name"
							label="Місто"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field>
						<v-text-field v-if="item.district"
							:value="item.district.name"
							label="Район"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field>
						<v-text-field v-if="item.dateFrom"
							:value="item.dateFrom | formatDate"
							label="Дата видачі від"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field>
						<v-text-field v-if="item.dateTo"
							:value="item.dateTo | formatDate"
							label="Дата видачі до"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field>
						<v-text-field v-if="item.area"
							:value="item.area"
							label="Площа"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field>
						<v-text-field v-if="item.roomsCount"
							:value="item.roomsCount"
							label="Кількість кімнат"
							outlined dense readonly hide-details="auto"
							class="my-3"
						></v-text-field>
					</v-card-text>
				</v-card>
				<!-- <template v-slot:append>
					<v-card-actions class="px-4">
						<v-spacer></v-spacer>
						<v-btn text color="primary" @click="onClear">{{ $vuetify.lang.t('$vuetify.clear') }}</v-btn>
						<v-btn color="primary" @click="onOK">{{ $vuetify.lang.t('$vuetify.ok') }}</v-btn>
					</v-card-actions>
				</template> -->
			</v-navigation-drawer>

			<v-card-text>
				<v-container style="padding: 64px 0px 0px 256px;">
					<v-card v-for="(estimate, index) in item.estimates" :key="index"
						elevation="0"
					>
						<!-- <v-card-title class="text-h5">Оцінка</v-card-title>
						<v-card-subtitle class="font-weight-light">{{ estimate.id }} - {{ estimate.code }}</v-card-subtitle> -->
						<v-toolbar elevation="0">
							<!-- <v-avatar>
								<v-icon>{{ icon }}</v-icon>
							</v-avatar> -->
							<v-toolbar-title>
								<v-list-item two-line>
									<v-list-item-content>
										<v-list-item-title class="text-h5">Оцінка</v-list-item-title>
										<!-- <v-list-item-subtitle>{{ estimate.code }} - {{ estimate.id }}</v-list-item-subtitle> -->
									</v-list-item-content>
								</v-list-item>
							</v-toolbar-title>

							<v-spacer></v-spacer>

							<!-- <v-btn icon @click="dialog=false" >
								<v-icon>mdi-close</v-icon>
							</v-btn> -->
							<!-- <z-detail-script
								:item="item"
							></z-detail-script> -->
						</v-toolbar>

						<v-divider></v-divider>

						<v-card-text>
							<v-list two-line>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title>Загальна кількість оголошень</v-list-item-title>
										<!-- <v-list-item-subtitle>Метод медіани</v-list-item-subtitle> -->
									</v-list-item-content>
									<v-list-item-action>
										<v-list-item-action-text class="text-right" v-text="estimate.total"></v-list-item-action-text>
									</v-list-item-action>
								</v-list-item>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title>Середнє арифметичне</v-list-item-title>
										<!-- <v-list-item-subtitle>Метод медіани</v-list-item-subtitle> -->
									</v-list-item-content>
									<v-list-item-action>
										<!-- <v-list-item-action-text class="text-right" v-text="estimate.arithmeticMean | toCurrencyUAH"></v-list-item-action-text> -->
										<v-list-item-action-text class="text-right">{{ estimate.arithmeticMean | toCurrencyUAH }}</v-list-item-action-text>
									</v-list-item-action>
								</v-list-item>
							</v-list>

							<v-card v-if="estimatePrices(estimate).length"
								elevation="0"
							>
								<v-card-title class="text-h6">Ціни</v-card-title>
								<!-- <v-card-subtitle>Статистика за період</v-card-subtitle> -->

								<v-simple-table dense>
									<template v-slot:default>
										<thead>
											<tr>
												<th>Кількість об'яв</th>
												<th>Вартість авто в UAH</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(item, index) in estimatePrices(estimate)" :key="index">
												<td >{{ item.cnt }}</td>
												<td >{{ item.priceUAH | toCurrencyUAH }}</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-card>
						</v-card-text>
					</v-card>
				</v-container>
				
			</v-card-text>
			<!-- <v-card-actions>
				<v-spacer></v-spacer>
				<v-btn text @click.native="onCancel">{{ $vuetify.lang.t('$vuetify.cancel') }}</v-btn>
				<v-btn color="success" dark @click.native="onOK" :disabled="loading">{{ $vuetify.lang.t('$vuetify.ok') }}</v-btn>
			</v-card-actions> -->
		</v-card>

		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
	</v-dialog>
</template>

<script>
import axios from 'axios'
//import mixin from './mixin/main'
import { mapGetters, mapActions } from 'vuex'
import zActivator from '@/views/components/activator/activator'
import zFieldSearch from '@/views/components/field.search'
import zConfirmDelete from '@/views/components/confirm-delete'
import zUsers from '@/views/dictionaries/Users'
import zServices from '@/views/dictionaries/Services'
import zDetailScript from '@/views/details/Script'

export default {
	name: 'RealtyRequestDetail',
	//mixins: [mixin],
	components: {
		zActivator,
		zFieldSearch,
		zConfirmDelete,
		zUsers,
		zServices,
		zDetailScript,
	},
	data: () => ({
		//title: 'role',
		icon: 'mdi-home-analytics',
		dialog: false,
		loading: false,
		drawer: true,

		item: {},
	}),

	props: {
		//item: 					{ type: Object, default: {} },
		selected: 					{ type: Object, default: {} },
	},

	computed: {
		...mapGetters(['roles']),
		//...mapGetters('Roles', [ 'endpoint', 'item' ]),
		
		filteredUsers() {
			return this.item.users.filter(item => {
				return item.id==this.searchUsers
					|| item.name.toLowerCase().includes(this.searchUsers.toLowerCase())
			})
		},

		filteredServices() {
			return this.item.services.filter(item => {
				return item.id==this.searchServices
					|| item.name.toLowerCase().includes(this.searchServices.toLowerCase())
			})
		},

		/* item() {
			return this.selected
		},

		item() {
			return this.selected
		},

		this.title */

		title() {
			return this.item?.type?.name
		},

		/* options() {
			return this.item.hasOwnProperty('options') ? this.item.options: [];
		}, */

		/* factors() {
			return this.item.hasOwnProperty('factors') ? this.item.factors: [];
		}, */

		// Фактори, що впливають на процент збільшення вартості КТЗ
		/* increas() {
			//return this.item.hasOwnProperty('increas') ? this.item.increas: [];
			return this.item.hasOwnProperty('uses') ? this.item.uses.filter((item) => item.type.growing && !item.type.element ): [];
		}, */

		// Фактори, що впливають на процент зниження вартості КТЗ
		/* reduce() {
			//return this.item.hasOwnProperty('reduce') ? this.item.reduce: [];
			return this.item.hasOwnProperty('uses') ? this.item.uses.filter((item) => !item.type.growing && !item.type.element ): [];
		}, */

		// Проценти додаткового зменшення вартості КТЗ з дефектами кузова, кабіни, рами
		/* elements() {
			//return this.item.hasOwnProperty('elements') ? this.item.elements: [];
			return this.item.hasOwnProperty('uses') ? this.item.uses.filter((item) => !item.type.growing && item.type.element ): [];
		}, */

		/* permitionScript() {
			return this.roles.includes('ADMIN')
		}, */		
	},

	watch: {
		dialog: function (val) {
			if(val) {
				this.find()
			}
		},
		/* dialog: function (val) {
			//this.drawer = this.dialog
			if(val) {
				this.onOpen()
			} else {
				this.onClose()
			}
		}, */

		drawer(val) {
			console.log(this.$options.name, 'drawer','val', val)
		},
	},

	methods: {
		/**
		 * Пошук
		 */
		 find () {
			var that = this;
			if(this.selected?.id) {

				//let params = []
				//if(this.datebegin) 	params['datebegin'] = this.datebegin
				//if(this.dateend) 	params['dateend'] = this.dateend

				//console.log(this.$options.name, 'find','params', params)

				this.loading = true;
				axios.get(`realty/request/${this.selected.id}`).then(response => {
					//console.log(that.$options.name, 'find','response.data', response.data)
					that.item = Object.assign({}, response.data)
				}, error => {
					console.log(error)
				}).finally(() => {
					this.loading = false
				});
			}
		},

		onOpen () {
			//console.log(this.$options.name, 'onOpen','item', this.item)

			//this.find(this.selected)
			//this.drawer = true
		},

		onClose () {
			//this.setItem()
		},

		estimatePercentiles(item) {
			return item.hasOwnProperty('percentiles') ? item.percentiles : [];
		},

		estimateAdverts(item) {
			return item.hasOwnProperty('adverts') ? item.adverts : [];
		},

		estimatePrices(item) {
			return item.hasOwnProperty('prices') ? item.prices : [];
		},

		adverts(items) {
			const sorted = [...items].sort((a, b) => parseInt(a.price) - parseInt(b.price));
			return sorted
		},

		getAdvertUrl(item) {
			//console.log('RealtyEstimateMarket', 'getAdvertUrl', 'item', item)

			return `https://auto.ria.com/uk/auto_${this.brand}_${this.model}_${item.code}.html`
		},

		labels(items) {
			return items.map(element=>element.name);
		},

		valueUAH(items) {
			return items.map(element=>element.priceUAH);
		},

		valueUSD(items) {
			return items.map(element=>element.priceUSD);
		},

		avgUAH(items) {
			const arr = this.valueUAH(items);
			const average = arr.reduce((a, b) => a + b, 0) / arr.length;
			return average
		},

		avgUSD(items) {
			const arr = this.valueUSD(items);
			const average = arr.reduce((a, b) => a + b, 0) / arr.length;
			return average
		},

		minUAH(items) {
			const arr = this.valueUAH(items);
			return Math.min(...arr)
		},
		minUSD(items) {
			const arr = this.valueUSD(items);
			return Math.min(...arr)
		},

		maxUAH(items) {
			const arr = this.valueUAH(items);
			return Math.max(...arr)
		},
		maxUSD(items) {
			const arr = this.valueUSD(items);
			return Math.max(...arr)
		},
	},
}
</script>