<template>
	<v-dialog  
	 	v-model="dialog"
	 	:fullscreen="fullscreen"
		scrollable
		transition="dialog-bottom-transition"
		width_="500"
	>
		<template v-slot:activator="{ on: dialog, attrs }">
			<v-tooltip bottom>
				<template v-slot:activator="{ on: tooltip, attrs }">
					<slot name="activator" :attrs="attrs" :dialog="dialog" :tooltip="tooltip">
						<v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...dialog }">
							<v-icon>mdi-pencil</v-icon>
						</v-btn>
					</slot>
				</template>
				<span>{{ $vuetify.lang.t(`$vuetify.${tooltip}`) | capitalize }}</span>
			</v-tooltip>
		</template>

		<v-card>
			<v-toolbar flat color="#f5f5f5" style="flex: 0 1 auto;">
				<v-avatar>
					<v-icon>{{ icon }}</v-icon>
				</v-avatar>
				<v-toolbar-title v-if="item.id">{{ title.single }} № {{ item.id }}</v-toolbar-title>
				<v-toolbar-title v-else>{{ title.single }}</v-toolbar-title>
				
				<v-spacer></v-spacer>

				<!-- <v-btn icon @click="onSave" >
					<v-icon>mdi-content-save</v-icon>
				</v-btn> -->

				<v-btn icon @click="dialog=false" >
					<v-icon>mdi-close</v-icon>
				</v-btn>

				<template v-slot:extension>
					<v-tabs v-model="tab" align-with-title>
						<v-tabs-slider color="yellow"></v-tabs-slider>
						<v-tab v-for="item in tabs" :key="item">
							{{ item }}
						</v-tab>
					</v-tabs>
				</template>
			</v-toolbar>

			<v-card-text>
				<v-tabs-items v-model="tab">
					<v-tab-item>
						<v-form ref="form">
							<v-row dense>
								<v-col cols="12">
									<v-text-field
										v-model="item.name"
										label="Назва"
										:rules="[rules.required]"
									></v-text-field>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="12">
									<v-combobox
										v-model="item.type"
										:items="types"
										label="Тип"
										item-text="name"
										item-value="id"
									></v-combobox>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="12">
									<v-textarea
										v-model="item.description"
										label="Опис"
									></v-textarea>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="12" md="4">
									<v-text-field
										v-model="item.code"
										label="Код"
									></v-text-field>
								</v-col>
							</v-row>
						</v-form>
					</v-tab-item>


					<!-- Members -->
					<v-tab-item>
						<v-card flat>
							<v-card-text>
								<v-container>
									<v-data-table
										:headers="headersMember"
										:items="item.members"
										item-key="id"
										loading-text="Завантаження даних... зачекайте, будь ласка."
										hide-default-footer
									>
										<template v-slot:top>
											<v-toolbar flat>
												<v-spacer></v-spacer>
												<z-document-object-member-edit @ok="item.members.push($event)" tooltip="create">
													<template v-slot:activator="props">
														<v-btn icon v-bind="props.attrs" v-on="{ ...props.dialog, ...props.tooltip }">
															<v-icon>mdi-plus</v-icon>
														</v-btn>
													</template>
												</z-document-object-member-edit>
											</v-toolbar>
										</template>

										<template v-slot:[`item.actions`]="{ item, index }">
											<v-toolbar flat dense color="transparent">
												<z-confirm-delete 
													:activator-tooltip-text="$vuetify.lang.t('$vuetify.delete')"
													@ok="object.members.splice(index, 1)"
												></z-confirm-delete>
												<z-document-object-member-edit 
													:selected="item"
													@ok="object.members.splice(index, 1, $event)"
												></z-document-object-member-edit>
											</v-toolbar>
										</template>
									</v-data-table>
								</v-container>
							</v-card-text>
						</v-card>
					</v-tab-item>

					<!-- Prices -->
					<v-tab-item>
						<v-card flat>
							<v-card-text>
								<v-container>
									<v-data-table
										:headers="headersPrice"
										:items="item.prices"
										item-key="id"
										loading-text="Завантаження даних... зачекайте, будь ласка."
										hide-default-footer
									>
										<template v-slot:top>
											<v-toolbar flat>
												<v-spacer></v-spacer>
												<z-document-object-price-edit @ok="item.prices.push($event)" tooltip="create">
													<template v-slot:activator="props">
														<v-btn icon v-bind="props.attrs" v-on="{ ...props.dialog, ...props.tooltip }">
															<v-icon>mdi-plus</v-icon>
														</v-btn>
													</template>
												</z-document-object-price-edit>
											</v-toolbar>
										</template>


										<template v-slot:[`item.value`]="{ item }">
											<span>{{ item.value }} {{ item.currency.code }}</span>
										</template>
										{ text: 'Сума', value: 'value', sortable: true},

										<template v-slot:[`item.actions`]="{ item, index }">
											<v-toolbar flat dense color="transparent">
												<z-confirm-delete 
													:activator-tooltip-text="$vuetify.lang.t('$vuetify.delete')"
													@ok="object.prices.splice(index, 1)"
												></z-confirm-delete>
												<z-document-object-price-edit 
													:selected="item"
													@ok="object.prices.splice(index, 1, $event)"
												></z-document-object-price-edit>
											</v-toolbar>
										</template>
									</v-data-table>
								</v-container>
							</v-card-text>
						</v-card>
					</v-tab-item>

				</v-tabs-items>				
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<!-- <v-btn text @click.native="onCancel">{{ $vuetify.lang.t('$vuetify.cancel') }}</v-btn> -->
				<v-btn color="success" dark @click.native="onOK" :disabled="loading">{{ $vuetify.lang.t('$vuetify.ok') }}</v-btn>
			</v-card-actions>
		</v-card>

		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
	</v-dialog>
</template>

<script>
import mixin from '@/views/mixin/edit'
import { mapGetters, mapActions } from 'vuex'
import zDate from '@/views/components/control/Date'
import zConfirmDelete from '@/views/components/confirm-delete'
import zDocumentObjectMemberEdit from '@/views/DocumentObjectMemberEdit'
import zDocumentObjectPriceEdit from '@/views/DocumentObjectPriceEdit'

export default {
	name: 'DocumentObjectEdit',
	description: 'Форма для реєстрації учасника документу',
	mixins: [mixin],
	components: {
		zDate,
		zConfirmDelete,
		zDocumentObjectMemberEdit,
		zDocumentObjectPriceEdit,
	},
	data: () => ({
		fullscreen: false,
	}),

	computed: {
		...mapGetters('DocumentObject', [ 'icon', 'title', 'item' ] ),
		...mapGetters('DocumentObjectType', { 'types': 'items' } ),
		...mapGetters('DocumentObjectMember',{ 'iconMember':'icon', 'titleMember': 'title', 'headersMember': 'headers' } ),
		...mapGetters('DocumentObjectPrice',{ 'iconPrice':'icon', 'titlePrice': 'title', 'headersPrice': 'headers' } ),

		tabs() {
			//return ['Головна', this.titleMember.plural, this.titleObject.plural, this.titleProperty.plural, this.titleStatus.plural ]
			return ['Головна', 'Учасники', 'Ціни' ]
		},

		object() {
			return this.item ? this.item: {};
		},
	},

	created() {
		//console.log(this.$options.name, 'created')
		this.loadDocumentObjectType()
	},

	methods: {
		...mapActions('DocumentObject', [ 'find', 'create', 'update', 'setItem' ]),
		...mapActions('DocumentObjectType', { 'loadDocumentObjectType': 'load' }),

		init() {
			//console.log(this.$options.name, 'init', 'item', this.item)
			if(!this.item?.type?.id) {
				this.item.type = this.types[0]
			}

			console.log(this.$options.name, 'init', 'item', this.item)
		},

		onOpen() {
			//console.log(this.$options.name, 'onOpen', 'selected', this.selected)
			//console.log(this.$options.name, 'onOpen', 'items', this.items)
			if(this.selected) {
				this.setItem(Object.assign({}, this.selected))
			} else {
				this.setItem()
			}
			
			this.init()
		},

		onOK() {
			//console.log(this.$options.name, 'onOK', 'items', this.items, 'selected', this.selected, 'item', this.item)

			this.loading = true
			return this.validate().then(() => {
				//console.log(this.$options.name, 'onOK', 'item', this.item)
				this.$emit('ok', this.item)
			}).then(() => {
				this.onClose()
			}).catch((error) => {
				console.log(this.$options.name, 'onOK', 'error', error)
				//this.showMessage(error)
			}).finally(() => {
				this.loading = false
			});
		},
	},
}
</script>