<template>
	<v-form ref="form">
		<!-- <v-list three-line>
			<v-list-item v-for="(item, index) in item.objects" :key="index">
				<v-list-item-content>
					<v-list-item-title v-html="item.name" class="text-wrap"></v-list-item-title>
					<v-list-item-subtitle v-html="item.maker.name"></v-list-item-subtitle>
				</v-list-item-content>
				<v-list-item-action>
					<v-btn icon>
						<v-icon color="grey lighten-1">mdi-delete</v-icon>
					</v-btn>
				</v-list-item-action>
			</v-list-item>
		</v-list> -->
		<v-list three-line>
			<v-list-item-group
				v-model="selected"
				active-class="success--text"
				multiple_
			>
				<template v-for="(item, index) in item.objects">
					<v-list-item :key="index">
						<template v-slot:default="{ active }">
						<v-list-item-content>
							<v-list-item-title v-text="item.name" class="text-wrap"></v-list-item-title>

							<!-- <v-list-item-subtitle
								class="text--primary"
								v-text="item.headline"
							></v-list-item-subtitle> -->

							<v-list-item-subtitle v-text="item.maker.name" class="text-wrap"></v-list-item-subtitle>
						</v-list-item-content>

						<v-list-item-action>
							<!-- <v-list-item-action-text v-text="item.action"></v-list-item-action-text> -->
							<v-icon color="grey lighten-1">mdi-delete</v-icon>
						</v-list-item-action>
						</template>
					</v-list-item>

					<v-divider v-if="index < item.objects.length - 1" :key="index"></v-divider>
				</template>
			</v-list-item-group>
		</v-list>
	</v-form>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import zAutocompleteDrug from '@/views/components/control/AutocompleteDrug'


export default {
	name: 'drug.parameters.list',
	components: {
		zAutocompleteDrug,
	},
	data () {
		return {
			menu: false,
			product: {},
			selected: [],
		}
	},
	props: {
		//value: 		{ type: Object, default: () => {} },
		module:			{ type: String, default: 'Estimate'}, // check | market | metodica | exploration | averageprice
	},

	computed: {
		...mapGetters(['roles']),

		//...mapGetters('Estimate',['params']),

		//...mapGetters('app', ['profile']),

		item: function () {
			return this.$store.getters[`${this.module}/params`]
		},
	},

	watch: {
		/* 'item.brand': function (val) {
			if(val) {
				this.Models({brand: val.id})
			}
		}, */
		/* item: function (val) {
			if(val) {
				console.log('vehicle.estimate.detail', 'watch', 'item', val)
			}
		}, */
	},

	created () {
		//console.log(this.$options.name, 'created')
	},
	methods: {
		/* ...mapActions([
			'setCategories', 'setFuels', 'setColors', 'setCountries', 'setStates'
		]), */

		/* ...mapActions('VehicleEstimate', [
			'setBrands', 'setModels', 'setGenerations', 'setModifications', 'setBodystyles', 'setDrivers',
			'setGearboxes','setOptions','setCities',
		]), */


		/* clear () {
			this.$refs.form.reset()
			//this.$set(this.vehicle,'racenorm', 19000)
		}, */

		addDrug(item) {
			console.log(this.$options.name, 'addDrug','item', item)

			this.menu = false
			this.item.objects.push(item)
		},

		onDeleteModel (item) {
			//console.log(that.$options.name, 'onDeleteModel', 'item', item)
			//console.log(that.$options.name, 'onDeleteModel', 'profile', this.profile)

			if(item?.id) {
				var that = this
				axios.put(`/vehicle/models/${item.id}/datedelete`).then(response => {
					//console.log(that.$options.name, 'onDelete','response.data', response.data)
					const index = that.models.indexOf(item)
					that.models.splice(index, 1);
					that.item.model = null
				}, error => {
					console.log('error', error)
				});
			}
		},
	},
}
</script>