import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var attrs = ref.attrs;
return [_vm._t("activator",function(){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),Object.assign({}, tooltip, dialog)),[_c(VIcon,[_vm._v("mdi-filter-variant<")])],1)]},{"attrs":attrs,"dialog":dialog,"tooltip":tooltip})]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t(("$vuetify." + _vm.tooltip)))))])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"flat":""}},[_c(VAvatar,[_c(VIcon,[_vm._v("mdi-filter-variant")])],1),_c(VToolbarTitle,[_vm._v("Фільтр")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.dialog=false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VTextField,{attrs:{"label":"Ідентифікатор","dense":"","hide-details":"auto","outlined":"","type":"number","min":1,"clearable":""},model:{value:(_vm.item.id),callback:function ($$v) {_vm.$set(_vm.item, "id", _vm._n($$v))},expression:"item.id"}}),_c(VDivider,{staticClass:"mt-6"}),_c(VSwitch,{attrs:{"false-value":"0","true-value":"1","label":"Тільки мої запити"},model:{value:(_vm.item.onlymy),callback:function ($$v) {_vm.$set(_vm.item, "onlymy", $$v)},expression:"item.onlymy"}}),_c(VTextField,{attrs:{"label":"Кількість рядків на сторінку","dense":"","hide-details":"auto","outlined":"","type":"number","min":10,"max":100},model:{value:(_vm.item.itemsPerPage),callback:function ($$v) {_vm.$set(_vm.item, "itemsPerPage", _vm._n($$v))},expression:"item.itemsPerPage"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.onOK}},[_vm._v("OK")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }