import { VCard } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{staticClass:"mx-auto",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 3 : 1,"outlined":"","to":_vm.to,"shaped":""}},[_c(VListItem,{attrs:{"three-line":""}},[_c(VListItemContent,[_c('div',{staticClass:"text-overline mb-4"},[_vm._v(" "+_vm._s(_vm.overline)+" ")]),_c(VListItemTitle,{staticClass:"text-h5 mb-1"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.subtitle))])],1),_c(VListItemAvatar,{attrs:{"tile_":"","size":"80","color":_vm.color}},[_c(VIcon,{attrs:{"dark":"","x-large":""}},[_vm._v(_vm._s(_vm.icon))])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }