import axios from 'axios'

//axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export const HTTP = axios.create({
    //baseURL: process.env.VUE_APP_URL + process.env.VUE_APP_API,
    baseURL: 'https://api.ortica-systems.com/medat',
    headers: {
        'Content-Type': 'application/json',
    }
})