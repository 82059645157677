<template>
	<v-main>
        <!-- <v-container> -->
			<v-data-table
				v-model="selected"
				:headers="headers"
				:items="items"
				item-key="id"
				show-select
				:search="searchText"
				:loading="loading"
				loading-text="Завантаження даних... зачекайте, будь ласка."
				sort-by="id"
				sort-desc
				:items-per-page="filter.itemsPerPage"
				hide-default-footer
			>
				<template v-slot:top>
					<v-toolbar flat>
						<v-avatar>
							<v-icon>{{ icon }}</v-icon>
						</v-avatar>
						<v-toolbar-title>
							{{ (title in $vuetify.lang.locales.en ? $vuetify.lang.t(`$vuetify.${title}`) : title ) | capitalize }}
						</v-toolbar-title>

						<v-spacer></v-spacer>

						<z-filter @ok="onSearch"></z-filter>

						<v-text-field
							v-model="searchText"
							append-icon="mdi-magnify"
							label="Пошук"
							dense
							single-line
							hide-details
							outlined
							rounded
						></v-text-field>

						<v-btn icon @click.stop="onRefresh">
							<v-icon>mdi-refresh</v-icon>
						</v-btn>

						<!-- Пошук -->
						<!-- <z-search v-model="search"></z-search> -->
					</v-toolbar>
				</template>

				<template v-slot:[`item.actions`]="{ item }">
					<v-toolbar flat dense color="transparent">
						<!-- PDF -->
						<!-- <v-icon color="red" @click="onPDF(item)">mdi-file-pdf-box</v-icon> -->

						<!-- Перегляд -->
						<z-view :selected="item"></z-view>
						<!-- Редагувати -->
						<z-edit :selected="item"></z-edit>
						
						<!-- Видалити -->
						<!-- <z-confirm 
							activator-tooltip-text="delete"
							@ok="onDelete(item)"
						></z-confirm> -->
					</v-toolbar>
				</template>

				<template v-for="(item, index) in headers" v-slot:[`header.${item.value}`]="{  }">
					<span :key="index">{{ (item.text in $vuetify.lang.locales.en ? $vuetify.lang.t('$vuetify.' + item.text) : item.text ) | capitalize }}</span>
				</template>

				<template v-slot:[`item.date`]="{ item }">
					<span>{{ item.date | formatDateTime }}</span>
				</template>
				<template v-slot:[`item.datebegin`]="{ item }">
					<span>{{ item.datebegin | formatDateTime }}</span>
				</template>
				<template v-slot:[`item.dateend`]="{ item }">
					<span>{{ item.dateend | formatDateTime }}</span>
				</template>
				
				<template v-slot:[`item.icon`]="{ item }">
					<v-icon>{{ item.icon }}</v-icon>
				</template>

				<template v-slot:[`item.code`]="{ item }">
					<span>{{ item.objects.reduce((a, b) => a + (b['priceAvg'] || 0), 0) | toCurrencyUAH }}</span>
				</template>
			</v-data-table>

			<z-edit tooltip="create">
				<template v-slot:activator="props">
					<v-fab-transition>
						<v-btn
							v-bind="props.attrs" v-on="{ ...props.dialog, ...props.tooltip}"
							color="primary"
							dark
							fixed
							bottom
							right
							fab
							class="mb-8 mr-8"
						>
						<!-- @create="add" -->
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</v-fab-transition>
				</template>
			</z-edit>

			<v-footer app padless>
				<v-toolbar flat>
					<v-spacer></v-spacer>

					<v-pagination
						v-model="filter.page"
						:length="pageCount"
						:total-visible="7"
					></v-pagination>

					<v-spacer></v-spacer>

					<!-- <z-edit tooltip="create">
						<template v-slot:activator="props">
							<v-btn icon fab v-bind="props.attrs" v-on="{ ...props.dialog, ...props.tooltip}">
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</template>
					</z-edit> -->
				</v-toolbar>
				<!-- <v-card flat tile width="100%" class="text-center">
					<v-card-text>
						<v-pagination
							v-model="filter.page"
							:length="pageCount"
							:total-visible="7"
						></v-pagination>
					</v-card-text>
      			</v-card> -->
			</v-footer>

			<z-pdf ref="pdf" :item="pdfItem"></z-pdf>
		<!-- </v-container> -->
		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
    </v-main>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import downloadExcel from "vue-json-excel"
import zPdf from '@/views/pdf/VehicleEstimate'
import zFilter from '@/views/EstimateFilter'
import zEdit from '@/views/EstimateEdit'
import zView from '@/views/EstimateView'
//import zDialogCompare from '@/views/VehicleRequestCompare'
//import zDialogStatistic from '@/views/VehicleRequestStatistic'

//import zConfirmDelete from '@/views/components/confirm-delete'

export default {
	name: 'Estimate',
	components: {
		zPdf,
		downloadExcel,
		zFilter,
		zEdit,
		zView,
		//zDialogCompare,
		//zDialogStatistic
	},

	data: () => ({
		loading: false,
		searchText: '',
		pageCount: 2,

		selected: [],
		headers: [
			{ text: 'ID', value: 'id', sortable: true, width: '50'},
			{ text: 'Тип', value: 'type.name', sortable: true},
			{ text: 'Дата', value: 'date', sortable: true},
			{ text: 'Початок періоду', value: 'datebegin', sortable: true},
			{ text: 'Завершення періоду', value: 'dateend', sortable: true},
			{ text: "Кількість об'єктів", value: 'objects.length', sortable: true},
			{ text: "Загальна середня сума", value: 'code', sortable: true},
			{ text: 'Користувач', value: 'user.name', sortable: true},
			{ text: 'Дії', value: 'actions', sortable: false, width: '10%' },
		],

		pdfItem: {},
	}),

	props: {
		icon:   		{ type: String, default: 'mdi-google-analytics' },
		title:   		{ type: String, default: 'Оцінки' },
	},
	
	computed: {
		...mapGetters('Estimate', [ 'items', 'filter' ]),
	},

	watch: {
		'filter.page': function (val) {
			this.onSearch()
		},
	},

	created() {
		this.onSearch()
	},

	methods: {
		//...mapActions('Estimate', [ 'setItem', 'setItems' ]),
		...mapActions('Estimate', [ 'search' ]),

		onRefresh () {
			this.onSearch()
		},

		onPDF (item) {
			this.pdfItem = item
			this.$refs.pdf.generate()
		},

		onNext() {
			//console.log(this.$options.name, 'onNext','page', this.page)
			this.onSearch()
		},

		onPrevious() {
			//console.log(this.$options.name, 'onPrevious','page', this.page)
			this.onSearch()
		},

		/**
		 * Пошук
		 */
		onSearch () {
			//console.log(that.$options.name, 'search')
			//console.log(this.$options.name, 'search','filter', this.filter)
			this.loading = true
			this.search(this.filter).then(() => {
				if(this.items.length > 0 && this.pageCount < this.filter.page + 1) {
					this.pageCount = this.filter.page + 1
				}
			}).finally(() => {
				this.loading = false
			})
		},
	},
}
</script>