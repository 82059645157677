export default {
    namespaced: true,
    state: {
		endpoint: 'realty/estimate',
        item: {},
		items: [],
		params: {},
    },
	getters: {
		params(state) {
			return state.params
		},
	},
	mutations: {
		params (state, data) {
			state.params = data
		},
	},
}