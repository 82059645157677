<template>
	<v-app>
		<!-- <v-app-bar app flat height="90px"> -->
		<v-app-bar app flat>
		<!-- hide-on-scroll -->
			<!-- <v-container class="pa-0 fill-height"> -->
				<!-- Основне меню -->
				<v-app-bar-nav-icon v-if="authorized" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

				<!-- Логотип -->
				<!-- <v-avatar size="72px" @click="OnHome"> -->
				<v-avatar size="48px" @click="OnHome">
					<img :src="icon">
				</v-avatar>

				<!-- Назва -->
				<v-toolbar-title class="ml-4 text-h4" v-show="$vuetify.breakpoint.smAndUp">{{ title }} <span class="text-caption">{{ version }}</span></v-toolbar-title>

				<v-spacer></v-spacer>

				<!-- <v-spacer></v-spacer> -->
				
				<!-- Language Menu -->
				<v-menu offset-y open-on-hover>
					<template v-slot:activator="{ on, attrs }">
						<v-btn icon v-bind="attrs" v-on="on">
							{{ $vuetify.lang.current }}
						</v-btn>
					</template>
					<v-list subheader dense>
						<v-subheader>{{ $vuetify.lang.t('$vuetify.language') | capitalize }}</v-subheader>
						<v-list-item-group v-model="selectedlang">
							<template v-for="(item, name, index) in $vuetify.lang.locales">
								<v-list-item :key="index" @click="OnClickLocale(name)">
									<template v-slot:default="{ active }">
										<v-list-item-content>
											<v-list-item-title v-text="item.langname"></v-list-item-title>
										</v-list-item-content>
										<v-list-item-action>
											<v-icon v-if="active">mdi-check</v-icon>
										</v-list-item-action>
									</template>
								</v-list-item>
							</template>
						</v-list-item-group>
					</v-list>
				</v-menu>

				<!-- Увійти -->
				<v-btn v-show="!authorized"
					class="mx-3"
					outlined 
					color="primary" 
					:to="{name: 'login'}"
				>{{ $vuetify.lang.t('$vuetify.signin') | capitalize }}</v-btn>


				<!-- Створити Аккаунт -->
				<!-- <v-btn v-show="!authorized" 
					color="primary" 
					:to="{name: 'signup'}"
				>{{ $vuetify.lang.t('$vuetify.createaccount') | capitalize }}</v-btn> -->

				<!-- Меню користувача-->
				<v-menu offset-y v-model="menu" :close-on-content-click="true" :close-on-click="true">
					<template v-slot:activator="{ on: menu, attrs }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on: tooltip }">
								<v-btn v-show="authorized" icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">
									<v-icon>mdi-account</v-icon>
								</v-btn>
							</template>
							<span>{{ $vuetify.lang.t('$vuetify.user') | capitalize }}</span>
						</v-tooltip>

					</template>
					<v-list>
						<v-list-item three-line @click="$refs.profile.Open()">
							<v-list-item-action>
								<v-icon>mdi-account</v-icon>
							</v-list-item-action>
							<v-list-item-content v-if="holder.profile">
								<v-list-item-title class="text-h6">{{ holder.profile.name }}</v-list-item-title>
								<v-list-item-subtitle v-if="holder.profile.organization">{{ holder.profile.organization.name }}</v-list-item-subtitle>
								<v-list-item-subtitle>{{ holder.profile.email }}</v-list-item-subtitle>
							</v-list-item-content>
							<v-list-item-content v-else>
								<v-list-item-title class="text-h6">{{ holder.name }}</v-list-item-title>
								<v-list-item-subtitle>{{ holder.login }}</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
					</v-list>
					<v-divider></v-divider>
					<v-list dense>
						<!-- Зміна паролю -->						
						<v-list-item @click="OnPasswordChange">
							<v-list-item-avatar>
								<v-icon>mdi-key-change</v-icon>
							</v-list-item-avatar>
							<v-list-item-content>
								<v-list-item-title>{{ $vuetify.lang.t('$vuetify.passwordchange') | capitalize }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<!-- API key -->						
						<v-list-item @click="$refs.apikey.Open()">
							<v-list-item-avatar>
								<v-icon>mdi-key</v-icon>
							</v-list-item-avatar>
							<v-list-item-content>
								<v-list-item-title>API key</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<v-divider></v-divider>

						<!-- Інструкція -->						
						<v-list-item @click="onInstruction">
							<v-list-item-avatar>
								<v-icon>mdi-information-variant</v-icon>
							</v-list-item-avatar>
							<v-list-item-content>
								<v-list-item-title>Інструкція</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>

					<v-divider v-if="$vuetify.breakpoint.smAndDown"></v-divider>
					<v-list dense v-if="$vuetify.breakpoint.smAndDown">
						<!-- Вихід -->						
						<v-list-item @click="OnLogout">
							<v-list-item-avatar>
								<v-icon>mdi-exit-to-app</v-icon>
							</v-list-item-avatar>
							<v-list-item-content>
								<v-list-item-title>{{ $vuetify.lang.t('$vuetify.exit') | capitalize }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-menu>

				<!-- Вихід -->
				<v-tooltip bottom v-if="$vuetify.breakpoint.mdAndUp">
					<template v-slot:activator="{ on, attrs }">
						<v-btn v-show="authorized" icon @click="OnLogout" v-bind="attrs" v-on="on">
							<v-icon>mdi-exit-to-app</v-icon>
						</v-btn>
					</template>
					<span>{{ $vuetify.lang.t('$vuetify.exit') | capitalize }}</span>
				</v-tooltip>

			<!-- </v-container> -->
		</v-app-bar>
		
		<!-- Головне меню -->
		<v-navigation-drawer v-model="drawer"  style="position: fixed;" temporary>
			<!-- <v-list dense> -->
			<v-list dense>
				<v-subheader>Сервіси</v-subheader>
				<v-list-item v-for="item in services" :key="item.id" :to="{name: item.router}">
					<v-list-item-icon>
						<v-icon color="primary">{{ item.icon }}</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title class="text-subtitle-2" v-html="item.title"></v-list-item-title>
						<!-- <v-list-item-subtitle v-html="item.title"></v-list-item-subtitle> -->
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<v-divider></v-divider>
			<v-list dense>
				<v-subheader>Налаштування</v-subheader>
				<!-- <v-list-item v-for="item in usermenu" :key="item.id" :to="item.to"> -->
				<v-list-item v-for="item in usermenu" :key="item.id" :to="{name: item.router}">
					<v-list-item-icon>
						<v-icon color="primary">{{ item.icon }}</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title class="text-subtitle-2">{{ item.title }}</v-list-item-title>
						<!-- <v-list-item-subtitle>{{ item.subtitle }}</v-list-item-subtitle> -->
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>
		
		<v-main style="padding: 0px 0px 0px;">
			<router-view></router-view>
		</v-main>

		<!-- <z-form-profile :visible="dialogprofile" @close="dialogprofile=false" /> -->
		<z-profile ref="profile" :value="profile"></z-profile>
		<z-apikey ref="apikey"></z-apikey>

		<z-app-message></z-app-message>

		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
  	</v-app>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapMutations, mapActions } from 'vuex'
//import router from './router'
import zAppMessage from '@/views/app.message.vue'
import zProfile from '@/views/app.profile.vue'
import zApikey from '@/views/app.apikey.vue'

export default {
	name: 'App',
	components: {
		//'z-form-profile': profile,
		zAppMessage,
		zProfile, zApikey
	},

	data: () => ({
		title: 'Ortica Systems',
		icon: require('./assets/logo.svg'),
		loading: false,
		//instruction: require('./assets/Instruction.pdf'),

		drawer: false,
		menu: false,
	}),

	created () {
		var that = this;

		//axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
		axios.defaults.baseURL = 'https://api.ortica-systems.com/medat';
		axios.defaults.headers.common['Content-Type'] = 'application/json';
		//axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

		//Встановлення мови за замовченням
		let lang = localStorage.getItem('lang')
		if(!lang) {
			lang = navigator.language.substring(0,2)
		}

		if(this.$vuetify.lang.locales.hasOwnProperty(lang)){
			this.$vuetify.lang.current = lang
		}

		this.selectedlang = Object.keys(this.$vuetify.lang.locales).indexOf(this.$vuetify.lang.current)

		// Перевірка валідності токена
		this.TokenValidate()
	},
	computed: {
		...mapGetters([ 'holder', 'authorized', 'profile', 'usermenu', 'services' ]),
		/* ...mapGetters('app', [
			'authorized', 'profile', 'usermenu', 'services'
		]), */

		version() {
			return process.env.VUE_APP_VERSION;
		},
	},
	watch: {
		/* value (val) {
			console.log("value", val)
			this.Mode(val)
			
		}, */
    },
	methods: {
		/* ...mapMutations('app',{
			set: 'increment' // `this.add()` будет вызывать `this.$store.commit('increment')`
		}), */
		...mapActions([ 'setHolder', 'showMessage' ]),
		...mapActions('app', [
			'Authorization', 'Clear'
			//,'Token'
			//, 'Init', 'Logout'
		]),

		/**
		 * Перевірка валідності токена
		 */
		TokenValidate () {
			var that = this;
			
			//console.log('App','TokenValidate', 'route.name', this.$route.name)
			//this.$route.name;

			let token = localStorage.getItem('token')
			if(token) {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

				this.loading = true;
				axios.get('/token').then(response => {
					//console.log(this.$options.name,'created', 'response', response)

					// Авторизація користувача
					that.UserAuthorization()
				}, error => {
					console.log(this.$options.name,'created', 'error', error)

					that.showMessage(error)

					localStorage.removeItem("token");
					axios.defaults.headers.common['Authorization'] = null;

					that.loading = false;

					if(that.$route.name != 'login')
						that.$router.push('/login')
				});
			} else {
				if(that.$route.name != 'login')
						that.$router.push('/login')
			}
		},

		/**
		 * Авторизація користувача
		 */
		 UserAuthorization () {
			var that = this
			this.loading = true

			axios.get('/authorization').then(response => {
				//console.log(this.$options.name, 'UserAuthorization', 'data', response.data)

				that.setHolder(response.data)
				//that.Authorization(response.data);
				//that.$router.push('/')
			}, error => {
				//console.log(this.$options.name,'created', 'error', error)
				that.$router.push('/login')
			}).finally(() => (this.loading = false));
		},

		// Вихід користувача з системи
		OnLogout () {
            //this.loading = true;
			var that = this;
			axios.post('/logout', {}).then(response => {
				//console.log(this.$options.name, 'Authorization', 'data', response.data)
			}, error => {
				console.log(this.$options.name,'created', 'error', error)
			}).finally(() => {
				localStorage.removeItem("token")
				axios.defaults.headers.common['Authorization'] = null;
				//this.Clear()
				this.setHolder()
				this.$router.push('/login').catch(err => {})
			});
		},

		// Зміна паролю
		OnPasswordChange () {
			this.$router.push('/password/change').catch(err => {});
		}, 

		// Встановлення мови інтерфейсу
		OnClickLocale(name) {
			this.$vuetify.lang.current = name;
			localStorage.setItem('lang', name)
		},
		
		// Перехід на стартову сторінку
		OnHome () {
			this.$router.push('/').catch(err => {});
		},

		onInstruction() {
			window.open('https://ortica-systems.com/ortica/doc/Instruction.pdf', '_blank')
			
			/* const link = document.createElement('a');
			link.href = 'https://ortica-systems.com/ortica/doc/Instruction.pdf';
			link.target = '_blank';
			link.download = 'Instruction.pdf';

			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link); */
		},
	}
	
};
</script>

<style>
	::-webkit-scrollbar {
		width: 5px;
	}
	/* Track */
	/* ::-webkit-scrollbar-track {
		background: #ff2929;
	} */

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #FAFAFA;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #EEEEEE;
	}
</style>