<template>
	<v-form ref="form">
		<v-tabs-items v-model="form.tab">
			<!-- Головна -->
			<v-tab-item>
				<v-card>
					<v-card-text>
					<v-row>
						<v-col cols="12">
							<v-text-field
								label="Id"
								v-model="item.id"
								outlined
								dense
								clearable
								hide-details="auto"
							></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-text-field
								:label="$vuetify.lang.t('$vuetify.name') | capitalize"
								v-model="item.name"
								outlined
								dense
								clearable
								hide-details="auto"
							></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-text-field
								:label="$vuetify.lang.t('$vuetify.login') | capitalize"
								v-model="item.login"
								outlined
								dense
								clearable
								hide-details="auto"
							></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-text-field
								:label="$vuetify.lang.t('$vuetify.expiration') | capitalize"
								v-model="item.expiration"
								outlined
								dense
								clearable
								hide-details="auto"
							></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-text-field
								:label="$vuetify.lang.t('$vuetify.datecreate') | capitalize"
								v-model="item.datecreate"
								outlined
								dense
								clearable
								hide-details="auto"
							></v-text-field>
						</v-col>

						<v-col cols="12">
							<v-checkbox
								v-model="item.enable"
								label="enable"
								hide-details
							></v-checkbox>
							<v-checkbox
								v-model="item.active"
								label="active"
								hide-details
							></v-checkbox>
						</v-col>

						<v-col cols="12">
							<v-textarea
								v-model="item.description"
								:label="$vuetify.lang.t('$vuetify.comment') | capitalize"
								outlined
								hint=""
								rows="2"
							></v-textarea>
						</v-col>
					</v-row>
					</v-card-text>
				</v-card>
			</v-tab-item>

			<!-- Профіль -->
			<v-tab-item>
				<z-profiles :item="item.profiles"></z-profiles>
			</v-tab-item>
			<!-- Ролі -->
			<v-tab-item>
				<z-table module="roles" :items="item.usersroles"></z-table>
			</v-tab-item>
			<!-- Сервіси -->
			<v-tab-item>
				<z-table module="services" :items="item.services"></z-table>
			</v-tab-item>

		</v-tabs-items>
	</v-form>
</template>

<script>
//import { mapGetters, mapActions } from 'vuex'
import zTable from '@/views/components/table.dictionary'
import zProfiles from '@/views/forms/profiles'

export default {
	components: {
		zTable, zProfiles
	},
	data () {
		return {
			//item: {},

			rules: {
				required: (value) => !!value || 'Поле обов\'язкове для заповнення',
				name: (value) => {
					const pattern = /^([a-zA-Zа-яА-ЯІіЇї'\-]+)/
					return (value && value.length > 10 && pattern.test(value)) || 'І\'мя некоректне'
				},
				email: (value) => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					return pattern.test(value) || 'Некоректний e-mail.'
				},
				phone: (value) => {
					const pattern = /^\+?3?8?(0\d{9})$/
					return !value || pattern.test(value) || 'Номер телефону не вірний (+380961112233)'
				},
			},
		}
	},
	props: {
		module: 		{ type: String, default: null }, // users | roles | ....
		item: 			{ type: Object, default: () => {} },
	},

	watch: {
		/* 'item.brand': function (val) {
			if(val) {
				this.Models({brand: val.id})
			}
		}, */
	},
	computed: {
		form: function () {
            return this.$store.getters[this.module + '/form']
        },
		/* ...mapGetters('vehicles',[
			'categories', 'brands', 'models', 'engines', 'gearboxes', 'bodies', 'years',
		]), */
	},
	created () {
		/* if(this.brands.length == 0) 	this.Brands({});
		if(this.engines.length == 0) 	this.Engines({});
		if(this.gearboxes.length == 0) 	this.Gearboxes({}); */
	},
	methods: {
		/* ...mapActions('vehicles', [
			'Categories', 'Brands', 'Models', 'Engines', 'Gearboxes', 'Bodies',
		]),

		Clear () {
			this.$refs.form.reset()
			//this.$set(this.vehicle,'racenorm', 19000)
		}, */

		/* Set (item) {
			if(item) {
				this.item = Object.assign({}, item)
			}
		}, */

	},
}
</script>