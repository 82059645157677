import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"500px","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [(_vm.activator=='icon')?_c(VTooltip,{attrs:{"bottom":"","disabled":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"small":_vm.small,"disabled":_vm.disabled}},'v-icon',attrs,false),Object.assign({}, tooltip, dialog)),[_vm._v(_vm._s(_vm.activatorIcon))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.title))])]):(_vm.activator=='list-item')?_c(VListItem,_vm._g(_vm._b({attrs:{"disabled":_vm.disabled}},'v-list-item',attrs,false),Object.assign({}, dialog)),[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"primary","disabled":_vm.disabled}},[_vm._v(_vm._s(_vm.activatorIcon))])],1),_c(VListItemTitle,[_vm._v(_vm._s(_vm.title))])],1):_c(VTooltip,{attrs:{"bottom":"","disabled":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"outlined":"","color":"primary"}},'v-btn',attrs,false),Object.assign({}, tooltip, dialog)),[_c(VIcon,{attrs:{"left":"","dark":"","color":"primary"}},[_vm._v(_vm._s(_vm.activatorIcon))]),_vm._v(" "+_vm._s(_vm.activatorName)+" ")],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.title))])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"elevation":"4","color":_vm.color}},[_vm._t("toolbar",function(){return [_c(VToolbar,{attrs:{"elevation":"0"}},[_c(VAvatar,[_c(VIcon,[_vm._v(_vm._s(_vm.icon))])],1),_c(VToolbarTitle,[_vm._v(_vm._s(_vm._f("capitalize")(_vm.title)))]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.OnClose}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)]}),_c(VCardText,[_vm._t("context",function(){return [_c('FormDetail',{ref:"detail",attrs:{"type":_vm.type,"item":_vm.item}})]})],2),(!_vm.fullscreen)?_c(VCardActions,[_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":_vm.OnClear}},[_vm._v("Очистити")]),_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":_vm.OnCancel}},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.cancel')))]),_c(VBtn,{attrs:{"color":"primary","dark":"","disabled":_vm.loading},nativeOn:{"click":function($event){return _vm.OnOK.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.ok')))])],1):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }