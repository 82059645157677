<template>
	<v-form ref="form">
		<!-- <v-footer
			fixed 
		> -->
		<v-row>
			<v-col cols="12">
				<v-combobox
					v-model="item.period"
					:items="periods"
					label="Період оцінки"
					outlined
					dense
					item-text="name"
					item-value="value"
					hide-details="auto"
				></v-combobox>
			</v-col>
			<v-divider></v-divider>
			<!-- <v-col cols="12" v-for="(item, index) in item.objects" :key="index">
				<z-autocomplete-drug
					v-model="item.product"
					outlined
					dense
				></z-autocomplete-drug>
			</v-col> -->
			<v-toolbar flat>
				<!-- <v-avatar>
					<v-icon>{{ icon }}</v-icon>
				</v-avatar> -->
				<!-- <v-toolbar-title>{{ title }}</v-toolbar-title>
				<v-toolbar-subtitle>{{ title }}</v-toolbar-subtitle> -->
				<!-- <v-toolbar-title>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>{{ title }}</v-list-item-title>
							<v-list-item-subtitle>№{{ item.id }} {{ item.datecreate | formatDateTime }}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-toolbar-title> -->
				

				<v-spacer></v-spacer>

				<!-- <z-detail-script v-if="permitionScript" :item="item"></z-detail-script> -->

				<!-- <v-btn icon @click="dialog=false" >
					<v-icon>mdi-close</v-icon>
				</v-btn> -->

				<v-menu
					v-model="menu"
					:close-on-content-click="false"
					:nudge-width="600"
					offset-x
				>
					<template v-slot:activator="{ on, attrs }">
						<!-- <v-btn icon v-bind="attrs" v-on="on">
							<v-icon>mdi-plus</v-icon>
						</v-btn> -->
						<v-icon v-bind="attrs" v-on="on">mdi-plus</v-icon>
					</template>

					<v-card>
						<!-- <v-card-title>Пошук лікарського засобу</v-card-title> -->
						<v-toolbar flat>
							<!-- <v-avatar>
								<v-icon>{{ icon }}</v-icon>
							</v-avatar> -->
							<v-toolbar-title>Пошук лікарського засобу</v-toolbar-title>

							<v-spacer></v-spacer>

							<v-btn icon @click="menu=false" >
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</v-toolbar>

						<v-card-text>
							<z-autocomplete-drug
								v-model="product"
								outlined
								dense
							></z-autocomplete-drug>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="primary" text @click="addDrug(product)">OK</v-btn>
						</v-card-actions>
					</v-card>
				</v-menu>
			</v-toolbar>
		</v-row>
	</v-form>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import zAutocompleteDrug from '@/views/components/control/AutocompleteDrug'


export default {
	name: 'drug.parameters.main',
	components: {
		zAutocompleteDrug,
	},
	data () {
		return {
			menu: false,
			product: {},
			selected: [],

			periods: [
				{ name: 'Останній місяць', value: '30'},
				{ name: '3 місяці', value: '90'},
				{ name: 'Останні півроку', value: '180'},
				{ name: 'Останній рік', value: '365'},
			],

			loading: {
				category: false,
				brand: false,
				model: false,
				generation: false,
				modification: false,
				yearProd: false,
				driver: false,
				option: false,
				fuel: false,
				gearbox: false,
				color: false,
				country: false,
				state: false,
				city: false,
			},
		}
	},
	props: {
		//value: 		{ type: Object, default: () => {} },
		module:			{ type: String, default: 'Estimate'}, // check | market | metodica | exploration | averageprice
		yearProdPeriod:	{ type: Boolean, default: false},
	},

	computed: {
		...mapGetters(['roles']),

		//...mapGetters('Estimate',['params']),

		//...mapGetters('app', ['profile']),

		item: function () {
			return this.$store.getters[`${this.module}/params`]
		},
	},

	watch: {
		/* 'item.brand': function (val) {
			if(val) {
				this.Models({brand: val.id})
			}
		}, */
		/* item: function (val) {
			if(val) {
				console.log('vehicle.estimate.detail', 'watch', 'item', val)
			}
		}, */
	},

	created () {
		//console.log(this.$options.name, 'created')

		this.item.period = this.periods[3];
	},
	methods: {
		/* ...mapActions([
			'setCategories', 'setFuels', 'setColors', 'setCountries', 'setStates'
		]), */

		/* ...mapActions('VehicleEstimate', [
			'setBrands', 'setModels', 'setGenerations', 'setModifications', 'setBodystyles', 'setDrivers',
			'setGearboxes','setOptions','setCities',
		]), */


		/* clear () {
			this.$refs.form.reset()
			//this.$set(this.vehicle,'racenorm', 19000)
		}, */

		addDrug(item) {
			console.log(this.$options.name, 'addDrug','item', item)

			this.menu = false
			this.item.objects.push(item)
		},

		// Завантаження категорій
		loadCategories(e) {
			if(this.categories.length == 0) {
				this.loading.category = true
				var that = this
				axios.get('/vehicle/category').then(response => {
					that.setCategories(response.data.filter((element) => ['CAR','TRUCK'].includes(element.code)))
				}, error => {
					console.log('error', error)
				}).finally(() => (this.loading.category = false));
			}
		},

		// Завантаження брендів
		loadBrands() {
			if(this.item?.category?.id && this.brands.length == 0) {
				this.loading.brand = true
				var that = this
				axios.get(`/vehicle/category/${this.item.category.id}/brand`).then(response => {
					//console.log(that.$options.name, 'GetBrands','response.data', response.data)
					that.setBrands(response.data)
				}, error => {
					console.log('error', error)
				}).finally(() => (this.loading.brand = false));
			}
		},

		// Завантаження моделей
		loadModels() {
			if(this.item?.category?.id && this.item?.brand?.id && this.models.length == 0) {
				this.loading.model = true
				var that = this
				axios.get(`/vehicle/category/${this.item.category.id}/brand/${this.item.brand.id}/models`).then(response => {
					//console.log(that.$options.name, 'GetdModels','response.data', response.data)
					that.setModels(response.data)
				}, error => {
					console.log('error', error)
				}).finally(() => (this.loading.model = false));
			}
		},

		// Завантаження поколінь
		loadGenerations() {
			if(this.item?.model?.id && this.generations.length == 0) {
				this.loading.generation = true
				var that = this
				axios.get(`/vehicle/model/${this.item.model.id}/generation`).then(response => {
					//console.log(that.$options.name, 'GetBrands','response.data', response.data)
					that.setGenerations(response.data)
				}, error => {
					console.log('error', error)
				}).finally(() => (this.loading.generation = false));
			}
		},

		// Завантаження років виробництва
		loadYearProds() {
			//console.log(this.$options.name, 'loadYearProds','generation', this.item.generation)

			this.minYear = this.item?.generation?.yearFrom ? this.item.generation.yearFrom : 1900
			this.maxYear = this.item?.generation?.yearTo ? this.item.generation.yearTo : (new Date()).getFullYear()
		},

		// Завантаження модифікацій
		loadModifications() {
			if(this.item?.generation?.id && this.modifications.length == 0) {
				this.loading.modification = true
				var that = this
				axios.get(`/vehicle/generation/${this.item.generation.id}/modification`).then(response => {
					//console.log(that.$options.name, 'GetBrands','response.data', response.data)
					that.setModifications(response.data)
				}, error => {
					console.log('error', error)
				}).finally(() => (this.loading.modification = false));
			}
		},

		// Завантаження типів кузова
		loadBodystyles() {
			if(this.item?.category?.id && this.bodystyles.length == 0) {
				this.loading.bodystyle = true
				var that = this
				axios.get(`/vehicle/category/${this.item.category.id}/bodystyle`).then(response => {
					//console.log(that.$options.name, 'GetBrands','response.data', response.data)
					that.setBodystyles(response.data)
				}, error => {
					console.log('error', error)
				}).finally(() => (this.loading.bodystyle = false));
			}
		},

		// Завантаження типів приводу
		loadDrivers() {
			if(this.item?.category?.id && this.drivers.length == 0) {
				this.loading.driver = true
				var that = this
				axios.get(`/vehicle/category/${this.item.category.id}/driver`).then(response => {
					//console.log(that.$options.name, 'GetBrands','response.data', response.data)
					that.setDrivers(response.data)
				}, error => {
					console.log('error', error)
				}).finally(() => (this.loading.driver = false));
			}
		},

		// Завантаження типів коробок передач
		loadGearboxes() {
			if(this.item?.category?.id && this.gearboxes.length == 0) {
				this.loading.gearbox = true
				var that = this
				axios.get(`/vehicle/category/${this.item.category.id}/gearbox`).then(response => {
					//console.log(that.$options.name, 'GetBrands','response.data', response.data)
					that.setGearboxes(response.data)
				}, error => {
					console.log('error', error)
				}).finally(() => (this.loading.gearbox = false));
			}
		},

		// Завантаження опцій
		loadOptions() {
			if(this.item?.category?.id && this.options.length == 0) {
				this.loading.option = true
				var that = this
				axios.get(`/vehicle/category/${this.item.category.id}/option`).then(response => {
					//console.log(that.$options.name, 'GetBrands','response.data', response.data)
					that.setOptions(response.data)
				}, error => {
					console.log('error', error)
				}).finally(() => (this.loading.option = false));
			}
		},

		// Завантаження типів палива
		loadFuels() {
			if(this.fuels.length == 0) {
				this.loading.fuel = true
				var that = this
				axios.get('/vehicle/fuel').then(response => {
					//console.log(that.$options.name, 'created','response.data', response.data)
					that.setFuels(response.data)
				}, error => {
					console.log('error', error)
				}).finally(() => (this.loading.fuel = false));
			}
		},

		// Завантаження кольорів
		loadColors() {
			if(this.colors.length == 0) {
				this.loading.color = true
				var that = this
				axios.get('/vehicle/color').then(response => {
					//console.log(that.$options.name, 'created','response.data', response.data)
					that.setColors(response.data)
				}, error => {
					console.log('error', error)
				}).finally(() => (this.loading.color = false));
			}
		},

		// Завантаження країн виробників
		loadCountries() {
			if(this.countries.length == 0) {
				this.loading.country = true
				var that = this
				axios.get('/vehicle/country').then(response => {
					//console.log(that.$options.name, 'created','response.data', response.data)
					that.setCountries(response.data)
				}, error => {
					console.log('error', error)
				}).finally(() => (this.loading.country = false));
			}
		},

		// Завантаження областей
		loadStates() {
			if(this.states.length == 0) {
				this.loading.state = true
				var that = this
				axios.get('/state').then(response => {
					//console.log(that.$options.name, 'created','response.data', response.data)
					that.setStates(response.data)
				}, error => {
					console.log('error', error)
				}).finally(() => (this.loading.state = false));
			}
		},

		// Завантаження міст
		loadCities() {
			if(this.item?.state?.id && this.states.length == 0) {
				this.loading.city = true
				var that = this
				axios.get(`/state/${this.item.state.id}/city`).then(response => {
					//console.log(that.$options.name, 'created','response.data', response.data)
					that.setCities(response.data)
				}, error => {
					console.log('error', error)
				}).finally(() => (this.loading.city = false));
			}
		},

		onCategory (item) {
			this.clearBrand()
			this.clearBodystyle()
			this.clearDriver()
			this.clearGearbox()
			this.clearOption()
		},

		onBrand (item) {
			this.clearModel()
		},

		onModel (item) {
			this.clearGeneration()
		},

		onGeneration (item) {
			this.clearYearProd()
			this.clearModification()
		},

		onState (item) {
			this.clearCity()
		},

		onDeleteModel (item) {
			//console.log(that.$options.name, 'onDeleteModel', 'item', item)
			//console.log(that.$options.name, 'onDeleteModel', 'profile', this.profile)

			if(item?.id) {
				var that = this
				axios.put(`/vehicle/models/${item.id}/datedelete`).then(response => {
					//console.log(that.$options.name, 'onDelete','response.data', response.data)
					const index = that.models.indexOf(item)
					that.models.splice(index, 1);
					that.item.model = null
				}, error => {
					console.log('error', error)
				});
			}
		},

		onUpgrateModel() {
			if(this.item?.category?.id && this.item?.brand?.id) {
				console.log(that.$options.name, 'onUpgrateModel', 'category', this.item.category.id, 'brand', this.item.brand.id)

				var that = this
				axios.post(`/vehicle/category/${this.item.category.id}/brand/${this.item.brand.id}/models/upgrate`).then(response => {
					//console.log(that.$options.name, 'onUpgrateModel','response.data', response.data)
					that.setModels(response.data)
				}, error => {
					console.log('error', error)
				});
			}
		},

		/*
		langId	Ідентифікатор мови	integer	Required
		period	Період	integer	Required
		params		Collection of objects	Required
			categoryId	Ідентифікатор типу транспорту	integer	Required
			stateId	Ідентифікатор області продажу авто	integer	Optional
			brandId	Ідентифікатор марки авто	integer	Required
			modelId	Ідентифікатор моделі авто	integer	Required
			generationId	Ідентифікатор покоління авто	integer	Optional
			modificationId	Ідентифікатор модифікації авто	integer	Optional
			bodyId	Ідентифікатор типу кузову авто	integer	Optional
			year	Рік випуску авто	Collection of integer	Optional
				gte	Значення більше рівно (greater than equal to)	integer	Optional
				lte	Значення меньше рівно (less than equal to)	integer	Optional
			fuelId	Ідентифікатор типу палива	integer	Optional
			mileage	Пробіг авто	Collection of integer	Optional
				gte	Значення більше рівно (greater than equal to)	integer	Optional
				lte	Значення меньше рівно (less than equal to)	integer	Optional
			engineVolume	Об'єм двигуна	Collection of integer	Optional
				gte	Значення більше рівно (greater than equal to)	integer	Optional
				lte	Значення меньше рівно (less than equal to)	integer	Optional
			gearBoxId	Ідентифікатор коробки передач авто	integer	Optional
			driveId	Ідентифікатор типу двигуна авто	integer	Optional
			colorId	Ідентифікатор кольору авто	integer	Optional
		*/
	},
}
</script>