<template>
	<v-list-item v-bind="attrs" v-on="{...dialog }"
		:color="color"
		:dark="dark"
		:dense="dense"
		:disabled="disabled"
	>
		<v-list-item-icon>
			<v-icon>{{ iconName }}</v-icon>
		</v-list-item-icon>
		<v-list-item-title>{{ title | capitalize }}</v-list-item-title>
	</v-list-item>
</template>



<script>
export default {
	data: () => ({

	}),
	props: {
		//value:				{ type: Boolean, default: false }, //dialog
		value:				{ type: Object, default: () => {} }, //dialog
		title:				{ type: String, default: 'delete' },
        color:				{ type: String, default: null },
        dark:     			{ type: Boolean, default: false },
        dense:     			{ type: Boolean, default: false },
		disabled:  			{ type: Boolean, default: false }, //Ознака неактивного елементу

		iconName:			{ type: String, default: 'mdi-delete' },
	},
	computed: {
		dialog: {
			get() 		{ return this.value },
			set(val) 	{ this.$emit('input', val) }
		},
	},
}

</script>