<template>
	<v-form ref="form">
		<v-row dense>
			<v-col cols="12">
				<v-combobox
					v-model="item.category"
					:items="categories"
					label="Категорія"
					outlined
					dense
					item-text="name"
					item-value="id"
					hide-details="auto"
					@change="onCategory"
				></v-combobox>
			</v-col>
			<v-col cols="12">
				<v-combobox
					v-model="item.brand"
					:items="brands"
					label="Марка"
					outlined
					dense
					item-text="name"
					item-value="id"
					hide-details="auto"
					@change="onBrand"
				></v-combobox>
			</v-col>
			<v-col cols="12">
				<v-combobox
					v-model="item.model"
					:items="models"
					label="Модель"
					outlined
					dense
					item-text="name"
					item-value="id"
					hide-details="auto"
					@change="onModel"
				>
					<template v-slot:append-outer v-if="visibleDeleteModel">
						<!-- <v-icon @click="onDeleteModel(item.model)">mdi-delete</v-icon> -->
						<v-menu offset-y>
							<template v-slot:activator="{ on, attrs }">
								<v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
							</template>
							<v-list>
								<v-list-item @click="onDeleteModel(item.model)" :disabled="!item.model">
									<v-list-item-icon>
										<v-icon>mdi-delete</v-icon>
									</v-list-item-icon>
									<v-list-item-title>Видалити модель</v-list-item-title>
								</v-list-item>
								<v-list-item @click="onUpgrateModel" :disabled="item.category==null || item.brand==null">
									<v-list-item-icon>
										<v-icon>mdi-autorenew</v-icon>
									</v-list-item-icon>
									<v-list-item-title>Актуалізувати довідник</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
					</template>
				</v-combobox>
			</v-col>
		
			<!-- Рік випуску авто -->
			<v-col cols="12" v-if="yearProdPeriod">
				<v-system-bar>
					<span>Рік випуску авто</span>
					<v-spacer></v-spacer>
				</v-system-bar>
			</v-col>
			<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" v-if="yearProdPeriod">
				<v-text-field
					v-model="item.yearProdFrom"
					label="Від"
					outlined
					dense
					type="number"
					hide-details="auto"
					:min="minYear"
					:max="maxYear"
				></v-text-field>
			</v-col>
			<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" v-if="yearProdPeriod">
				<v-text-field
					v-model="item.yearProdTo"
					label="До"
					outlined
					dense
					type="number"
					hide-details="auto"
					:min="minYear"
					:max="maxYear"
				></v-text-field>
			</v-col>
			<v-col cols="12"  v-if="!yearProdPeriod">
				<v-combobox
					v-model="item.year"
					:items="years"
					label="Рік випуску авто"
					outlined
					dense
					hide-details="auto"
				></v-combobox>
			</v-col>
			<v-col cols="12">
				<v-combobox
					v-model="item.generation"
					:items="generations"
					label="Покоління"
					outlined
					dense
					item-text="name"
					item-value="id"
					hide-details="auto"
					@change="onGeneration"
				>
					<template v-slot:item="{ on, item }">
						<v-list-item v-on="on">
							<v-list-item-content>
								<v-list-item-title>{{ item.name }}</v-list-item-title>
								<v-list-item-subtitle>{{ item.yearFrom }} - {{ item.yearTo }}</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
					</template>
				</v-combobox>
			</v-col>
			<v-col cols="12">
				<v-combobox
					v-model="item.modification"
					:items="modifications"
					label="Модифікація"
					outlined
					dense
					item-text="name"
					item-value="id"
					hide-details="auto"
				></v-combobox>
			</v-col>
			<v-col cols="12">
				<v-combobox
					v-model="item.bodystyle"
					:items="bodystyles"
					label="Тип кузова"
					outlined
					dense
					item-text="name"
					item-value="id"
					hide-details="auto"
				></v-combobox>
			</v-col>
			<v-col cols="12">
				<v-combobox
					v-model="item.driver"
					:items="drivers"
					label="Тип приводу"
					outlined
					dense
					item-text="name"
					item-value="id"
					hide-details="auto"
				></v-combobox>
			</v-col>

			<!-- Об'єм двигуна -->
			<v-col cols="12">
				<v-system-bar>
					<span>Об'єм двигуна, л.</span>
					<v-spacer></v-spacer>
				</v-system-bar>
			</v-col>
			<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
				<v-text-field
					v-model="item.engineVolumeFrom"
					label="Від"
					outlined
					dense
					type="number"
					hide-details="auto"
					:min="minEngineVolume"
					:max="maxEngineVolume"
				></v-text-field>
			</v-col>
			<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
				<v-text-field
					v-model="item.engineVolumeTo"
					label="До"
					outlined
					dense
					type="number"
					hide-details="auto"
					:min="minEngineVolume"
					:max="maxEngineVolume"
				></v-text-field>
			</v-col>
			<v-col cols="12">
				<v-combobox
					v-model="item.fuel"
					:items="fuels"
					label="Тип палива"
					outlined
					dense
					item-text="name"
					item-value="id"
					hide-details="auto"
				></v-combobox>
			</v-col>
			<v-col cols="12">
				<v-combobox
					v-model="item.gearbox"
					:items="gearboxes"
					label="Коробка передач"
					outlined
					dense
					item-text="name"
					item-value="id"
					hide-details="auto"
				></v-combobox>
			</v-col>
			<v-col cols="12">
				<v-combobox
					v-model="item.options"
					:items="options"
					label="Опції"
					outlined
					dense
					item-text="name"
					item-value="id"
					hide-details="auto"
					multiple
				></v-combobox>
			</v-col>
			<v-col cols="12">
				<v-combobox
					v-model="item.color"
					:items="colors"
					label="Колір"
					outlined
					dense
					item-text="name"
					item-value="id"
					hide-details="auto"
				></v-combobox>
			</v-col>
			<v-col cols="12">
				<v-combobox
					v-model="item.country"
					:items="countries"
					label="Країна виробник"
					outlined
					dense
					item-text="name"
					item-value="id"
					hide-details="auto"
				></v-combobox>
			</v-col>
			<v-col cols="12">
				<v-combobox
					v-model="item.state"
					:items="states"
					label="Область"
					outlined
					dense
					item-text="name"
					item-value="id"
					hide-details="auto"
					@change="onState"
				></v-combobox>
			</v-col>
			<v-col cols="12">
				<v-combobox
					v-model="item.city"
					:items="cities"
					label="Місто"
					outlined
					dense
					item-text="name"
					item-value="id"
					hide-details="auto"
				></v-combobox>
			</v-col>

			<!-- Пробіг авто -->
			<v-col cols="12">
				<v-system-bar>
					<span>Пробіг, тис.км</span>
					<v-spacer></v-spacer>
				</v-system-bar>
			</v-col>
			<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
				<v-text-field
					v-model="item.mileageFrom"
					label="Від"
					outlined
					dense
					type="number"
					hide-details="auto"
					:min="minMileage"
					:max="maxMileage"
				></v-text-field>
			</v-col>
			<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
				<v-text-field
					v-model="item.mileageTo"
					label="До"
					outlined
					dense
					type="number"
					hide-details="auto"
					:min="minMileage"
					:max="maxMileage"
				></v-text-field>
			</v-col>
			<!-- <v-col cols="12">
				<v-range-slider class="align-center"
					v-model="item.mileage"
					:hint="'Пробіг, тис.км: '+ item.mileage[0] + ' - ' + item.mileage[1]"
					persistent-hint
					:min="minMileage"
					:max="maxMileage"
					hide-details
				></v-range-slider>
			</v-col> -->
			
			<!-- Об'єм двигуна -->
			<!-- <v-col cols="12">
				<v-system-bar>
					<span>Об'єм двигуна, л.</span>
					<v-spacer></v-spacer>
				</v-system-bar>
			</v-col>
			<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
				<v-text-field
					v-model="item.engineVolume[0]"
					label="Від"
					outlined
					dense
					type="number"
					hide-details="auto"
					:min="minEngineVolume"
					:max="maxEngineVolume"
				></v-text-field>
			</v-col>
			<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
				<v-text-field
					v-model="item.engineVolume[1]"
					label="До"
					outlined
					dense
					type="number"
					hide-details="auto"
					:min="minEngineVolume"
					:max="maxEngineVolume"
				></v-text-field>
			</v-col> -->
			<!-- <v-col cols="12">
				<v-range-slider class="align-center"
					v-model="item.engineVolume"
					:hint="'Об`єм двигуна: '+ item.engineVolume[0] + ' - ' + item.engineVolume[1]"
					persistent-hint
					:min="minEngineVolume"
					:max="maxEngineVolume"
					hide-details
				></v-range-slider>
			</v-col> -->

			<v-col cols="12">
				<v-combobox
					v-model="item.damage"
					:items="damages"
					label="ДТП"
					outlined
					dense
					item-text="name"
					item-value="value"
					hide-details="auto"
				></v-combobox>
			</v-col>

			<v-col cols="12">
				<!-- <v-text-field
					v-model="item.period"
					label="Період оцінки, днів"
					outlined
					dense
					type="number"
					hide-details="auto"
					:min="1"
					:max="365"
				></v-text-field> -->

				<v-combobox
					v-model="item.period"
					:items="periods"
					label="Період оцінки"
					outlined
					dense
					item-text="name"
					item-value="value"
					hide-details="auto"
				></v-combobox>
			</v-col>
		</v-row>
	</v-form>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
export default {
	data () {
		return {
			minYear: 1900,
			maxYear: (new Date()).getFullYear(),
			minMileage: 1,
			maxMileage: 1000,
			minEngineVolume: 0,
			maxEngineVolume: 10,

			damages: [
				{ id: 1, name: 'Всі', value: null},
				{ id: 2, name: 'Без ДТП', value: 0},
				{ id: 3, name: 'Після ДТП', value: 1},
			],
			
			periods: [
				{ name: 'Останній місяць', value: '30'},
				{ name: '3 місяці', value: '90'},
				{ name: 'Останні півроку', value: '180'},
				{ name: 'Останній рік', value: '365'},
			],
		}
	},
	props: {
		//value: 		{ type: Object, default: () => {} },
		module:			{ type: String, default: 'VehicleEstimate'}, // check | market | metodica | exploration | averageprice
		yearProdPeriod:	{ type: Boolean, default: false},
	},

	computed: {
		...mapGetters([
			'categories', 'fuels', 'colors', 'countries', 'states'
		]),

		...mapGetters('VehicleEstimate',[
			'brands', 'models', 'generations', 'modifications', 'bodystyles', 'drivers', 'gearboxes', 'options', 'cities'
		]),

		...mapGetters('app', ['profile']),


		/* item: {
			get() 		{ return this.value },
			set(val) 	{ this.$emit('input', val) }
		}, */
		item: function () {
			return this.$store.getters[`${this.module}/params`]
		},

		years() {
			const arrayRange = (start, stop, step) =>
			Array.from(
				{ length: (stop - start) / step + 1 },
				(value, index) => start + index * step
			);
			return arrayRange(this.minYear, this.maxYear, 1).reverse();
		},

		visibleDeleteModel() {
			return ['qwins@i.ua','olek061970@gmail.com'].includes(this.profile.email)
		},
		
	},

	watch: {
		/* 'item.brand': function (val) {
			if(val) {
				this.Models({brand: val.id})
			}
		}, */
		/* item: function (val) {
			if(val) {
				console.log('vehicle.estimate.detail', 'watch', 'item', val)
			}
		}, */
	},

	created () {
		//this.item.year 			= [this.minYear, this.maxYear]
		//this.item.mileage		= [this.minMileage, this.maxMileage]
		//this.item.engineVolume	= [this.minEngineVolume, this.maxEngineVolume]
		//this.item.period		= 365
		this.item.period = this.periods[3];

		var that = this

		// Завантаження категорій
		if(this.categories.length == 0) {
			axios.get('/vehicle/categories').then(response => {
				//console.log('\\forms\\vehicle.parameters.vue', 'created','response.data', response.data)
				//that.setCategories(response.data.filter((element) => element.riaCode == 1))
				that.setCategories(response.data.filter((element) => element?.brands?.length))
			}, error => {
				console.log('error', error)
			});
		}

		// Завантаження типів палива
		if(this.fuels.length == 0) {
			axios.get('/vehicle/fuel').then(response => {
				//console.log('\\forms\\vehicle.parameters.vue', 'created','response.data', response.data)
				that.setFuels(response.data)
			}, error => {
				console.log('error', error)
			});
		}

		// Завантаження кольорів
		if(this.colors.length == 0) {
			axios.get('/vehicle/color').then(response => {
				//console.log('\\forms\\vehicle.parameters.vue', 'created','response.data', response.data)
				that.setColors(response.data)
			}, error => {
				console.log('error', error)
			});
		}

		// Завантаження країн виробників
		if(this.countries.length == 0) {
			axios.get('/vehicle/country').then(response => {
				//console.log('\\forms\\vehicle.parameters.vue', 'created','response.data', response.data)
				that.setCountries(response.data)
			}, error => {
				console.log('error', error)
			});
		}

		// Завантаження областей
		if(this.states.length == 0) {
			axios.get('/state').then(response => {
				//console.log('\\forms\\vehicle.parameters.vue', 'created','response.data', response.data)
				that.setStates(response.data)
			}, error => {
				console.log('error', error)
			});
		}
	},
	methods: {
		...mapActions([
			'setCategories', 'setFuels', 'setColors', 'setCountries', 'setStates'
		]),

		...mapActions('VehicleEstimate', [
			'setBrands', 'setModels', 'setGenerations', 'setModifications', 'setBodystyles', 'setDrivers',
			'setGearboxes','setOptions','setCities',
		]),


		/* clear () {
			this.$refs.form.reset()
			//this.$set(this.vehicle,'racenorm', 19000)
		}, */

		onCategory (item) {
			//console.log('\\forms\\vehicle.parameters.vue', 'onCategory','item', item)

			if(item) {
				var that = this

				this.$set(this.item, 'brand', null)
				this.$set(this.item, 'model', null)
				this.setBrands()
				this.setModels()

				// Завантаження брендів
				/* axios.get('/vehicle/brands').then(response => {
					//console.log('\\forms\\vehicle.parameters.vue', 'GetBrands','response.data', response.data)
					that.setBrands(response.data)
				}, error => {
					console.log('error', error)
				}); */

				if(item?.brands) {
					this.setBrands(item.brands)
				}

				// Завантаження типів кузова
				axios.get(`/vehicle/bodystyle/category/${item.id}`).then(response => {
					//console.log('\\forms\\vehicle.parameters.vue', 'GetBrands','response.data', response.data)
					that.setBodystyles(response.data)
				}, error => {
					console.log('error', error)
				});

				// Завантаження типів приводу
				axios.get(`/vehicle/driver/category/${item.id}`).then(response => {
					//console.log('\\forms\\vehicle.parameters.vue', 'GetBrands','response.data', response.data)
					that.setDrivers(response.data)
				}, error => {
					console.log('error', error)
				});

				// Завантаження типів коробок передач
				axios.get(`/vehicle/gearbox/category/${item.id}`).then(response => {
					//console.log('\\forms\\vehicle.parameters.vue', 'GetBrands','response.data', response.data)
					that.setGearboxes(response.data)
				}, error => {
					console.log('error', error)
				});

				// Завантаження опцій
				axios.get(`/vehicle/option/category/${item.id}`).then(response => {
					//console.log('\\forms\\vehicle.parameters.vue', 'GetBrands','response.data', response.data)
					that.setOptions(response.data)
				}, error => {
					console.log('error', error)
				});

				//console.log('\\forms\\vehicle.parameters.vue', 'onCategory','item', this.item)
			}
		},

		onBrand (item) {
			var that = this

			if(item) {
				// Завантаження моделей
				axios.get(`/vehicle/category/${this.item.category.id}/brand/${item.id}/models`).then(response => {
					//console.log('\\forms\\vehicle.parameters.vue', 'GetdModels','response.data', response.data)
					that.setModels(response.data)
				}, error => {
					console.log('error', error)
				});
			}
		},

		onModel (item) {
			var that = this

			if(item) {
				// Завантаження поколінь
				axios.get(`/vehicle/generation/model/${item.id}`).then(response => {
					//console.log('\\forms\\vehicle.parameters.vue', 'onModel','response.data', response.data)
					that.setGenerations(response.data)
				}, error => {
					console.log('error', error)
				});
			}
		},

		onGeneration (item) {
			var that = this

			if(item) {
				// Завантаження модифікацій
				axios.get(`/vehicle/modification/generation/${item.id}`).then(response => {
					//console.log('\\forms\\vehicle.parameters.vue', 'GetdModels','response.data', response.data)
					that.setModifications(response.data)
				}, error => {
					console.log('error', error)
				});
			}
		},

		onState (item) {
			var that = this

			if(item) {
				// Завантаження міст
				axios.get(`/city/state/${item.id}`).then(response => {
					//console.log('\\forms\\vehicle.parameters.vue', 'GetdModels','response.data', response.data)
					that.setCities(response.data)
				}, error => {
					console.log('error', error)
				});
			}
		},

		//onDeleteModel('models', item.model)

		onDeleteModel (item) {
			//console.log('\\forms\\vehicle.parameters.vue', 'onDeleteModel', 'item', item)
			//console.log('\\forms\\vehicle.parameters.vue', 'onDeleteModel', 'profile', this.profile)

			if(item?.id) {
				var that = this
				axios.put(`/vehicle/models/${item.id}/datedelete`).then(response => {
					//console.log('\\forms\\vehicle.parameters.vue', 'onDelete','response.data', response.data)
					const index = that.models.indexOf(item)
					that.models.splice(index, 1);
					that.item.model = null
				}, error => {
					console.log('error', error)
				});
			}
		},

		onUpgrateModel() {
			if(this.item?.category?.id && this.item?.brand?.id) {
				console.log('\\forms\\vehicle.parameters.vue', 'onUpgrateModel', 'category', this.item.category.id, 'brand', this.item.brand.id)

				var that = this
				axios.post(`/vehicle/category/${this.item.category.id}/brand/${this.item.brand.id}/models/upgrate`).then(response => {
					//console.log('\\forms\\vehicle.parameters.vue', 'onUpgrateModel','response.data', response.data)
					that.setModels(response.data)
				}, error => {
					console.log('error', error)
				});
			}
		},

		/*
		langId	Ідентифікатор мови	integer	Required
		period	Період	integer	Required
		params		Collection of objects	Required
			categoryId	Ідентифікатор типу транспорту	integer	Required
			stateId	Ідентифікатор області продажу авто	integer	Optional
			brandId	Ідентифікатор марки авто	integer	Required
			modelId	Ідентифікатор моделі авто	integer	Required
			generationId	Ідентифікатор покоління авто	integer	Optional
			modificationId	Ідентифікатор модифікації авто	integer	Optional
			bodyId	Ідентифікатор типу кузову авто	integer	Optional
			year	Рік випуску авто	Collection of integer	Optional
				gte	Значення більше рівно (greater than equal to)	integer	Optional
				lte	Значення меньше рівно (less than equal to)	integer	Optional
			fuelId	Ідентифікатор типу палива	integer	Optional
			mileage	Пробіг авто	Collection of integer	Optional
				gte	Значення більше рівно (greater than equal to)	integer	Optional
				lte	Значення меньше рівно (less than equal to)	integer	Optional
			engineVolume	Об'єм двигуна	Collection of integer	Optional
				gte	Значення більше рівно (greater than equal to)	integer	Optional
				lte	Значення меньше рівно (less than equal to)	integer	Optional
			gearBoxId	Ідентифікатор коробки передач авто	integer	Optional
			driveId	Ідентифікатор типу двигуна авто	integer	Optional
			colorId	Ідентифікатор кольору авто	integer	Optional
		*/
	},
}
</script>