import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VEditDialog } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VEditDialog,{attrs:{"return-value":_vm.item.status,"large":"","persistent":"","save-text":"Зберегти","cancel-text":"Відмінити"},on:{"update:returnValue":function($event){return _vm.$set(_vm.item, "status", $event)},"update:return-value":function($event){return _vm.$set(_vm.item, "status", $event)},"save":_vm.onOK},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4 text-h6"},[_vm._v(_vm._s(_vm.title))]),_c(VCombobox,{attrs:{"items":_vm.items,"label":"Статус","item-text":"name","item-value":"id"},model:{value:(_vm.item.status),callback:function ($$v) {_vm.$set(_vm.item, "status", $$v)},expression:"item.status"}})]},proxy:true}])},[_c('div',[_vm._v(_vm._s(_vm.text))])]),_c(VSnackbar,{attrs:{"timeout":_vm.snack.timeout,"color":_vm.snack.color},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.snack.visible = false}}},'v-btn',attrs,false),[_c(VIcon,[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.snack.visible),callback:function ($$v) {_vm.$set(_vm.snack, "visible", $$v)},expression:"snack.visible"}},[(_vm.snack.subtitle)?_c(VListItem,{attrs:{"three-line":""}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.snack.title))]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.snack.subtitle))])],1)],1):_c('div',[_vm._v(_vm._s(_vm.snack.title))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }