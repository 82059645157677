//import axios from 'axios'
export default {
    namespaced: true,
    state: {
		endpoint: 'estimate',
		filter: {
        	page: 1,
			itemsPerPage: 15,
			onlymy: 0
		},

		item: {
			type: {},
			objects: [],
			user: {}
		},

		items: [],
    },
    actions: {
		clearItem(context) {
			context.commit('item', {
				type: {},
				objects: [],
				user: {}
			})
		},
	},
}