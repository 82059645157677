<template>
	 <v-main>
        <v-container>
            <v-toolbar elevation="0">
				<v-btn icon color="primary" @click="$router.push('/')">
					<v-icon>mdi-home</v-icon>
				</v-btn>
				<v-toolbar-title class="font-weight-light">{{ $vuetify.lang.t('$vuetify.' + title.plural) | capitalize }}</v-toolbar-title>
				<v-spacer></v-spacer>
				
				<!-- <template v-slot:extension>
					<v-tabs v-model="tab" right>
						<v-tabs-slider color="yellow"></v-tabs-slider>
						<v-tab v-for="item in tabs" :key="item">
							{{ item }}
						</v-tab>
					</v-tabs>
				</template> -->
			</v-toolbar>

<!-- <v-btn outlined color="primary" v-bind="attrs" v-on="{ ...tooltip, ...dialog }">
						<v-icon left dark color="primary">{{ activatorIcon }}</v-icon>
						{{ activatorName }}
					</v-btn> -->

			<v-data-table
				v-model="selected"
				:headers="headers"
				:items="items"
				item-key="id"
				show-select
				:loading="loading"
				loading-text="Завантаження даних... зачекайте, будь ласка." 
				class="mt-16"
			>
				<template v-slot:top>
					<v-toolbar flat>
						<!-- <v-toolbar-title>My CRUD</v-toolbar-title>
						<v-divider class="mx-4" inset vertical ></v-divider> -->
						<v-spacer></v-spacer>

						<!-- Пошук -->
						<v-btn icon @click="OnSearch">
							<v-icon>mdi-magnify</v-icon>
						</v-btn>

						<!-- Створити -->
						<z-dialog-detail
							:module="module"
							mode="create"
							:title="title"
							:icon="icon"
						></z-dialog-detail>

					</v-toolbar>
				</template>

				<!-- Вормат дати в таблиці -->
				<!-- <template v-slot:[`item.expiration`]="{ item }">
					<span>{{ new Date(item.expiration).toLocaleString() }}</span>
				</template>
				<template v-slot:[`item.dateactive`]="{ item }">
					<span>{{ new Date(item.dateactive).toLocaleString() }}</span>
				</template>
				<template v-slot:[`item.datecreate`]="{ item }">
					<span>{{ new Date(item.datecreate).toLocaleString() }}</span>
				</template> -->

				<!-- <span>{{ new Date(item.createdOn).toLocaleString() }}</span> -->
					<!-- <span v-if="!isNaN(value)">--</span>
					<span v-else-if="header.type=='datetime'">{{ new Date(value).toLocaleString() }}</span>
					<span v-else>{{ value }}</span> -->

				<!-- <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ value }">
					{{ header.formatter(value) }} 
				</template> -->

				<template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('type'))" v-slot:[`item.${header.value}`]="{ value }">
					<!-- {{ header.formatter(value) }}  -->
					<span v-if="header.type =='datetime'" :key="index">{{  DateFormat(value) }}</span>
				</template>

				<template v-slot:[`item.actions`]="{ item }">
					<!-- Редагувати -->
					<z-dialog-detail
						:module="module"
						mode="update"
						:title="title"
						:icon="icon"
						:item="item"
						activator="icon"
						
					></z-dialog-detail>

					<v-icon color="primary"  @click="$emit('delete', item)">mdi-trash-can-outline</v-icon>
					<!-- <ProductsView :selected="item" activator="icon" small/>
					<v-icon  v-if="!iscart && exists(item)" @click="RemoveCart(item)" color="blue" small class="mr-2">mdi-cart-remove</v-icon>
					<v-icon  v-else-if="!iscart && !exists(item)" @click="AddCart(item)" color="zcolor" small class="mr-2">mdi-cart-plus</v-icon>
					<v-icon @click="OnRemove(item)" color="zcolor" small>mdi-trash-can-outline</v-icon> -->
				</template>
				<!-- <template v-slot:expanded-item="{ headers, item }">
					<td :colspan="headers.length">
						<v-row dense v-for="(prop, i) in properties(item)" :key="i">
							<v-col cols="12" sm="6" md="6" lg="3" xl="3">
								<strong class="d-flex justify-end">{{prop.title}}:</strong>
							</v-col>
							<v-col cols="12" sm="6" md="6" lg="9" xl="9">
								{{ prop.value }}
							</v-col>
						</v-row>
					</td>
				</template> -->
			</v-data-table>
		</v-container>
	 </v-main>
</template>

<script>
import moment from "moment";
import zDialogDetail from '@/views/components/dialog.detail'
//import mixin from '@/views/mixins/dialogs'

export default {
	//mixins: [mixin],
	components: {
		zDialogDetail
	},
	data: () => ({
		//modulename: 'dialogs.dialog',

		//dialog: false,
		loading: false,

		selected: [],

		//snackbar: false,
		//message: null,
		//messagetype: null, // success | info | warning | error
	}),
	props: {
		module:       	{ type: String, default: null },
		/* title:      	{ type: Object, default: () => {} },
		icon:       	{ type: String, default: null },
		items: 			{ type: Array, default: () => [] },
		headers: 		{ type: Array, default: () => [] }, */

		/* mode: 			{ type: String, default: 'edit' }, // create | edit | preview
		
		
		hint:       	{ type: String, default: null },
		icon:       	{ type: String, default: null },
		fullscreen: 	{ type: Boolean, default: false },
		scrollable: 	{ type: Boolean, default: false },
		hideOverlay: 	{ type: Boolean, default: false },
		width:      	{ type: Number, default: 1024 },
		height:     	{ type: Number, default: 800 },
		transition: 	{ type: String, default: 'dialog-bottom-transition' },
		color:			{ type: String, default: null }, 		// Колір фону (card)
		

		activator:			{ type: Object, default: () => {} },	// Параметри активатора */

		//small:      	{ type: Boolean, default: false },
		//disabled:   	{ type: Boolean, default: false }, //Ознака неактивного елементу
		//activator:  	{ type: String, default: 'button'}, // Тип активатора вікна: button | icon | menu-item | list-item

	},
	computed: {
		title: function () {
            return this.$store.getters[this.module + '/title']
        },
		icon: function () {
            return this.$store.getters[this.module + '/icon']
        },
		items: function () {
            return this.$store.getters[this.module + '/items']
        },
		headers: function () {
            return this.$store.getters[this.module + '/headers']
        },
		

		/* activatorIcon: function () {
			let result = 'mdi-plus'
			switch(this.mode) {
				case 'edit': result = 'mdi-pencil'; break;
				case 'preview': result = 'mdi-printer-eye'; break;
			}
			return result
		}, */

		/* tooltip: function () {
			let result = 'Створити'
			switch(this.mode) {
				case 'edit': result = 'Редагувати'; break;
				case 'preview': result = 'Перегляд'; break;
			}
			return result
		}, */
	},
	created () {
		console.log('dialog.table','created')
		/* if(this.brands.length == 0) 	this.Brands({});
		if(this.engines.length == 0) 	this.Engines({});
		if(this.gearboxes.length == 0) 	this.Gearboxes({}); */
	},
	mounted () {
		console.log('dialog.table','mounted')
	},
	methods: {
		DateFormat(value) {
			return (value ? moment(value).format('YYYY-MM-DD') : null);
		},

		OnSearch (data) {
            var that = this
            this.loading = true;
            this.$store.dispatch(`${this.module}/read`, {
                //params: { name: this.searchtext },
                success: function(response) {
                    that.loading = false;

					//console.log(that.module,'OnSearch', 'items', that.items)
                },
                error: function(error) {
                    that.loading = false;
                }
            })
        },
        OnDelete (item) {
            if(item) {
                var that = this
                this.loading = true;
                this.$store.dispatch(`${this.module}/delete`, {
                    item: item,
                    success: function(response) {
                        that.loading = false;
                    },
                    error: function(error) {
                        that.loading = false;
                    }
                })
            }
        },
        OnDeleteSelected () {
            for(let i in this.selected) {
                this.OnDelete(this.selected[i])
            }
            data.selected = []
        },

		OnRead (e) {
			//this.dialog = true
		},
		/* OnOpen (e) {
			this.dialog = true
		},
		OnClose (e) {
			this.dialog = false
			this.$emit('close', e)
		},
		OnCancel (e) {
			this.OnClose()
			this.$emit('cancel', e)
		},
		OnOK (e) {
			this.OnClose()
			this.$emit('ok', e)
		},

		Message (text, type) {
			//console.log(this.modulename, 'Message', 'text', text)

			this.message = text
			this.snackbar = true
			this.messagetype = type
		}, */
	},
}
</script>