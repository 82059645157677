<template>
	
	<v-form ref="form">
		<v-row>
			<v-col cols="12" sm="4" md="4" align="center">
				<!-- <v-btn icon @click="OnImageClick">
					<v-avatar size="128">
						<v-img :src="image"></v-img>
					</v-avatar>
				</v-btn> -->
				<v-avatar size="128" @click="OnImageClick">
					<v-img :src="image"></v-img>
				</v-avatar>
				<input
					ref="uploader"
					class="d-none"
					type="file"
					accept="image/*"
					@change="OnImageChanged"
				>
			</v-col>
			<v-col cols="12" sm="8" md="8">
				<v-row>
					<v-col cols="12">
						<v-text-field
							v-model="item.name"
							:label="$vuetify.lang.t('$vuetify.firstname') | capitalize"
							required
							:rules="[rules.required, rules.name]"
							@keyup.ctrl.90="OnDefault"
						></v-text-field>
					</v-col>
				</v-row>
			</v-col>

			<!-- <v-col cols="12">
				<v-text-field
					v-model="item.name"
					:label="$vuetify.lang.t('$vuetify.firstname') | capitalize"
					required
					:rules="[rules.required, rules.name]"
					@keyup.ctrl.90="OnDefault"
				></v-text-field>
			</v-col> -->                              
			<v-col cols="12" sm="6" md="6">
				<v-text-field
					v-model="item.email"
					:label="$vuetify.lang.t('$vuetify.email') | capitalize"
					prepend-icon="mdi-email"
					required
					:rules="[rules.required, rules.email]"
					@keyup.ctrl.90="OnDefault"
				></v-text-field>
			</v-col>
			<v-col cols="12" sm="6" md="6">
				<v-text-field 
					v-model="item.phone"
					:label="$vuetify.lang.t('$vuetify.telephone') | capitalize"
					prepend-icon="mdi-phone"
					:rules="[rules.phone]"
					@keyup.ctrl.90="OnDefault"
				></v-text-field>
			</v-col>
			<v-col cols="12">
				<v-text-field 
					v-model="item.company"
					:label="$vuetify.lang.t('$vuetify.company') | capitalize"
					prepend-icon="mdi-domain"
					@keyup.ctrl.90="OnDefault"
				></v-text-field>
			</v-col>
		</v-row>
		<z-alert ref="alert"></z-alert>
	</v-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import zAlert from '@/views/components/alert'

export default {
	components: {
		zAlert
	},
	data: () => ({
		rules: {
			required: (value) => !!value || 'Поле обов\'язкове для заповнення',
			name: (value) => {
				const pattern = /^([a-zA-Zа-яА-ЯІіЇї'\-]+)/
				return (value && value.length > 10 && pattern.test(value)) || 'І\'мя некоректне'
			},
			email: (value) => {
				const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				return pattern.test(value) || 'Некоректний e-mail.'
			},
			phone: (value) => {
				const pattern = /^\+?3?8?(0\d{9})$/
				return !value || pattern.test(value) || 'Номер телефону не вірний (+380961112233)'
			},
		},

		/* module: 'profile',

		panel: {
			items: [0,1,2,3,4,5,6,7,8],
			disabled: false,
			readonly: false,
		}, */

		//dateofissue: false,

	}),
	props: {
		item: 			{ type: Object, default: () => {} },
		/* value: {
			type: Object,
			default() {
				return {}
			}
		}, */
	},
	watch: {
		/* dialog: function (val) {
			if(val) {
				//this.OnLoad()
				//console.log('profile.vue', 'watch', 'dialog', val)
			}
		}, */
	},
	computed: {
		/* ...mapGetters('profile',{
			item: 'item',
		}), */
		/* ...mapGetters('app',[
			'profile',
		]), */

		/* item() {
			return this.$store.getters[`${this.module}/item`]
		}, */
		image() {
			if(this.item) {
				if(this.item.hasOwnProperty('image') ) {
					return this.item.image;

					return process.env.VUE_APP_URL + process.env.VUE_APP_PROFILES_UPLOADS+'/'+this.item.image;
				} else {
					return require('@/assets/no-image.png')
					//'https://cdn.vuetifyjs.com/images/profiles/marcus.jpg'
				}
			}
		},
	},
	methods: {
		/* ...mapActions('profiles', [
			'Save', 'Upload'
		]),
		...mapActions('app', [
			'Profile'
		]), */

		

		/* OnOK () {
			var that = this;

			this.$refs.alert.Close();

			if (this.$refs.form.validate()) {
				this.loading = true;
				this.Profile({
					item: this.item,
					success: function(response) {
						that.loading = false;
						that.OnClose();
					},
					error: function(response) {
						that.loading = false
						that.$refs.alert.Open(response.data, 'error')
					}
				});
			}
		}, */

		

		OnImageClick() {
			window.addEventListener('focus', () => {
				//console.log('OnImageClick: focus')
			}, { once: true })

			this.$refs.uploader.click()
		},
		
		OnImageChanged(e) {
			var that = this;

			//console.log('item', this.item)
			var file = e.target.files[0]
			//console.log('file', file)

			let formData = new FormData();
			formData.append('file', file);

			this.loading = true;
			this.Upload({
				id: this.item.id,
				params: formData,
				success: function(response) {
					that.SetImage(response)
					that.loading = false;
					that.alert.text = null;

					//console.log('OnImageChanged.item', that.item)
				},
				error: function(response) {
					that.loading = false
					that.alert.text = response.data;
				},
			});
		},


		/* OnAddBankAccount () {
			this.item.bankaccounts.push({
				account: null,
				name: null,
				mfo: null,
				depart: null,
				currency: 'UAH'
			})
		},
		OnRemoveBankAccount (item) {
			const index = this.item.bankaccounts.indexOf(item)
			this.item.bankaccounts.splice(index, 1);
		}, */
	},
}
</script>