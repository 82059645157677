import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFabTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VContainer,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","show-select":"","loading":_vm.loading,"search":_vm.search,"loading-text":"Завантаження даних... зачекайте, будь ласка.","class_":"mt-16","items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":""}},[_c(VAppBarNavIcon,[_c(VIcon,[_vm._v(_vm._s(_vm.icon))])],1),_c(VToolbarTitle,[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t(("$vuetify." + _vm.title)))))]),_c(VSpacer),_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Пошук","single-line":"","hide-details":"","outlined":"","rounded":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.onRefresh}},[_c(VIcon,[_vm._v("mdi-refresh")])],1)],1)]},proxy:true},{key:"item.active",fn:function(ref){
var item = ref.item;
return [(item.active)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"success"}},'v-icon',attrs,false),on),[_vm._v("mdi-circle")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("formatDateTime")(item.dateactive)))])]):_vm._e()]}},{key:"item.disabled",fn:function(ref){
var item = ref.item;
return [(item.disabled)?_c(VIcon,{attrs:{"color":"error"}},[_vm._v("mdi-cancel")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VToolbar,{attrs:{"elevation":"0","dense":"","color":"transparent"}},[(_vm.permitionUpdate)?_c('z-detail',{attrs:{"selected":item},on:{"update":function($event){return _vm.edit(item, $event)}}}):_vm._e(),_c('z-statistic',{attrs:{"selected":item}}),(_vm.permitionLock)?_c(VMenu,{ref:"menu",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[(_vm.permitionLock)?_c(VList,[(item.disabled)?_c(VListItem,{on:{"click":function($event){return _vm.onUnlock(item)}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-account-check")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Розблокувати")])],1)],1):_c(VListItem,{on:{"click":function($event){return _vm.onLock(item)}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-account-cancel")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Заблокувати")])],1)],1)],1):_vm._e()],1):_vm._e()],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),(_vm.permitionCreate)?_c('z-detail',{attrs:{"tooltip":"create"},scopedSlots:_vm._u([{key:"activator",fn:function(props){return [_c(VFabTransition,[_c(VBtn,_vm._g(_vm._b({staticClass:"mb-8 mr-8",attrs:{"color":"primary","dark":"","fixed":"","bottom":"","right":"","fab":""},on:{"create":_vm.add}},'v-btn',props.attrs,false),Object.assign({}, props.dialog, props.tooltip)),[_c(VIcon,[_vm._v("mdi-plus")])],1)],1)]}}],null,false,2119364552)}):_vm._e(),_c(VOverlay,{attrs:{"value":_vm.loading}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }