export default {
    namespaced: true,
    state: {
		params: {
			regnum: null,
        	vin: null,
		},
		items: [],
    },
    getters: {
		params(state) {
			return state.params
		},
		items(state) {
			return state.items
		},
		isset(state) {
			return state.params.regnum || state.params.vin
		},
    },
    mutations: {
		params (state, data) {
			state.params = data
		},
		items (state, data) {
			if(data) {
				state.items = []
				let items = data.vehicle
				for (let key in items) {
					state.items.push(items[key])
				}
			} else {
				state.items = []
			}
			//state.items = data?data:[]
		},
    },
    actions: {
		clear (context) {
			context.commit('params', {
				regnum: null,
				vin: null,
			})
			context.commit('items', [])
		},
    }
   
}