<template>
	<v-main>
		<v-navigation-drawer fixed clipped permanent style="padding: 64px 0px 0px 0px;">
			<v-card elevation="0">
				<v-card-text>
					<z-parameters></z-parameters>
				</v-card-text>
			</v-card>
			<template v-slot:append>
				<v-card-actions class="px-4">
					<v-spacer></v-spacer>
					<v-btn text color="primary" @click="onClear">{{ $vuetify.lang.t('$vuetify.clear') }}</v-btn>
					<v-btn color="primary" @click="onOK">{{ $vuetify.lang.t('$vuetify.ok') }}</v-btn>
				</v-card-actions>
			</template>
		</v-navigation-drawer>

		<v-navigation-drawer fixed clipped permanent right
			mini-variant
			mini-variant-width="56"
			style="padding: 64px 0px 0px 0px;"
        >
			<v-list dense nav>
				<v-list-item>
					<v-list-item-action>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<download-excel 
									:data="excel.data"
									:name="excel.name"
									:type="excel.type"
								>
									<v-icon large color="green darken-2" :disabled="excel.disabled" v-bind="attrs" v-on="on">mdi-microsoft-excel</v-icon>
								</download-excel>
							</template>
							<span>Експорт Excel</span>
						</v-tooltip>
					</v-list-item-action>
				</v-list-item>
			</v-list>
        </v-navigation-drawer>

		<v-container style="padding: 64px 0px 0px 256px;">
			<v-card elevation="0">
				<v-card-title class="text-h5">{{ title }} {{ id ? '№' + id: '' }}</v-card-title>
				<v-card-subtitle class="font-weight-light">{{ subtitle }}</v-card-subtitle>
				<v-card-text v-for="(estimate, index) in estimates" :key="index">
					<v-card elevation="0">
						<v-card-title>Розрахунок середньої ціни на нерухомість</v-card-title>
						<v-simple-table dense>
							<template v-slot:default>
								<tbody>
									<tr>
										<td>Середнє арифметичне</td>
										<td>{{ parseInt(estimate.arithmeticMean) | toCurrencyUAH }}</td>
									</tr>
									<tr>
										<td>Загальна кількість оголошень, що беруть участь у підрахунку</td>
										<td>{{ estimate.total }}</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>

						<v-expansion-panels v-model="panel" multiple style="position: static;">
							<v-expansion-panel>
								<v-expansion-panel-header class="text-subtitle-2 font-weight-bold">Ціни</v-expansion-panel-header>
								<v-expansion-panel-content>
									<v-simple-table dense>
										<template v-slot:default>
											<thead>
												<tr>
													<th>Кількість об'яв</th>
													<th>Вартість авто в UAH</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(item, index) in estimate.prices" :key="index">
													<td >{{ item.cnt }}</td>
													<td >{{ item.priceUAH  | toCurrencyUAH }}</td>
												</tr>
											</tbody>
										</template>
									</v-simple-table>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</v-card>
				</v-card-text>
			</v-card>
		</v-container>
		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64" ></v-progress-circular>
		</v-overlay>
		<v-snackbar v-model="snackbar.visible" :color="snackbar.color">
			{{ snackbar.text }}
			<template v-slot:action="{ attrs }">
			<v-btn icon v-bind="attrs" @click="snackbar.visible = false" >
				<v-icon>mdi-close</v-icon>
			</v-btn>
			</template>
		</v-snackbar>
	</v-main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
import zParameters from '@/views/forms/RealtyAveragePrice'
import zPdf from '@/views/pdf/RealtyEstimate'
import downloadExcel from "vue-json-excel";

export default {
	name: 'RealtyAveragePrice',
	components: {
		zParameters,
		zPdf,
		downloadExcel,
	},
	data: () => ({
		title: 'Середня ціна нерухомості',
		subtitle: 'Визначення середньої ціни нерухомості',
		loading: false,

		panel: [0],
		graph: [],

		excel: {
			name: '',
			type: 'xlsx',
			disabled: true,
			data: []
		},

		snackbar: {
			visible: false,
			text: null,
			color: null
		},
	}),

	computed: {
		...mapGetters('RealtyEstimate', [
			'params', 'item', 'isset'
		]),

		excelName() {
			//return this.title + (this.params.number ? ' ' + this.params.number : (this.params.vin ? ' ' + this.params.vin : '') + '.' +  this.excel.type)
			return this.title + '.' +  this.excel.type
		},

		id() {
			return this.item.hasOwnProperty('id') ? this.item.id: null;
		},
		
		estimates() {
			return this.item.hasOwnProperty('estimates') ? this.item.estimates: [];
		},
	},

	methods: {
		...mapActions('RealtyEstimate', [
			'setItem', 'clear'
		]),

		onClear() {
			//this.$refs.parameters.clear()
			//this.clear()
		},

		validate() {
			//return true
			return this.params.category 
				|| this.params.type
		},

		onOK() {
			//console.log(this.$options.name, 'onOK','params', this.params)

			if(this.validate()) {
				this.loading = true
				var that = this
				axios.post('/realty/estimate/averageprice', this.params).then(response => {
					//console.log(that.$options.name, 'onOK','response.data', response.data)
					that.setItem(response.data)
				}, error => {
					console.log('error', error)

					that.snackbar.visible 	= true;
					that.snackbar.text 		= error.response.data.message;
					that.snackbar.color 	= null;
				}).finally(() => {
					this.loading = false
				});
			} else {
				this.snackbar.visible 	= true;
				this.snackbar.text 		= 'Відсутні дані для пошуку';
				this.snackbar.color 	= null;
			}
		},
	},
}
</script>