<template>
	 <v-dialog  
	 	v-model="dialog"
	 	:fullscreen="fullscreen"
		:width="width"
		:height="height"
		scrollable
	 >

		<!-- :fullscreen="fullscreen"
		:hide-overlay=""
		:transition="transition"
		:scrollable="scrollable"
		:width="width"
		:height="height" -->

		<!-- Актіватор -->
		<template v-slot:activator="{ on: dialog, attrs }">
			<!-- activator - icon -->
            <v-tooltip bottom v-if="activator=='icon'" disabled>
                <template v-slot:activator="{ on: tooltip }">
                    <v-icon :small="small" v-bind="attrs" v-on="{ ...tooltip, ...dialog }" :disabled="disabled">{{activatorIcon}}</v-icon>
                </template>
                <span>{{ $vuetify.lang.t('$vuetify.' + mode) | capitalize }}</span>
            </v-tooltip>
            
            <!-- activator - list-item -->
            <!-- <v-list-item v-else-if="activator=='list-item'" v-bind="attrs" v-on="{...dialog }" :disabled="disabled"> 
                <v-list-item-icon>
                    <v-icon color="primary" :disabled="disabled">{{activatorIcon}}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{title}}</v-list-item-title>
            </v-list-item> -->

            <!-- activator - button -->
            <v-tooltip bottom v-else disabled>
                <template v-slot:activator="{ on: tooltip }">
                    <!-- <v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...dialog }" :disabled="disabled" v-text="activatorName">
                        <v-icon color="primary">{{acvicon}}</v-icon>
                    </v-btn> -->
					<v-btn outlined color="primary" v-bind="attrs" v-on="{ ...tooltip, ...dialog }">
						<v-icon left dark color="primary">{{ activatorIcon }}</v-icon>
						{{ $vuetify.lang.t('$vuetify.' + mode)  | capitalize }}
					</v-btn>
                </template>
                <span>{{ title | capitalize}}</span>
            </v-tooltip>
		</template>

		<v-card elevation="4" :color="color">
			<!-- Ttoolbar -->
			<v-toolbar elevation="0" dark color="primary">
				<v-avatar>
					<v-icon>{{ icon }}</v-icon>
				</v-avatar>
				<v-toolbar-title>{{ $vuetify.lang.t('$vuetify.' + title.plural) | capitalize }}</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-btn icon @click="OnClose" >
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>

			<v-card-text>
				<!-- Table -->
				<v-data-table
					v-model="selected"
					:headers="headers"
					:items="items"
					item-key="id"
					show-select
					:loading="loading"
					loading-text="Завантаження даних... зачекайте, будь ласка." 
					
				>
					<template v-slot:top>
						<v-toolbar flat>
							<!-- Пошук -->
							<v-text-field
								v-model="search"
								append-icon="mdi-magnify"
								@click:append="OnSearch"
								@keydown.enter="OnSearch"
								single-line
								hide-details
								filled
								rounded
								dense
								placeholder="Пошук по назві..."
								@keyup.ctrl.90="search='нурофен'"
							></v-text-field>
						</v-toolbar>
					</template>
					
				</v-data-table>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn text @click="OnCancel">{{ $vuetify.lang.t('$vuetify.cancel') }}</v-btn>
				<v-btn color="success" dark @click.native="OnOK" :disabled="loading">{{ $vuetify.lang.t('$vuetify.ok') }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>

export default {
	components: {

	},
	data: () => ({
		dialog: false,
		loading: false,

		search: null,

		selected: [],
		headers: [
            //{ text: 'ID', value: 'id', align: ' d-none' },
            { text: 'ID', value: 'id', sortable: true },
            { text: 'Назва', value: 'name', sortable: true},
        ],
	}),
	props: {
		module: 		{ type: String, default: null }, // users | roles | ....
		mode: 			{ type: String, default: 'add' }, // create | update

		//item: 			{ type: Object, default: () => {} },
		//selected: 			{ type: Object, default: () => {} },

		
		

		//title:      	{ type: String, default: null },
		//title:      	{ type: Object, default: () => {} },
		//icon:       	{ type: String, default: null },
		hint:       	{ type: String, default: null },
		fullscreen: 	{ type: Boolean, default: false },
		scrollable: 	{ type: Boolean, default: false },
		hideOverlay: 	{ type: Boolean, default: false },
		width:      	{ type: Number, default: 800 },
		height:     	{ type: Number, default: 800 },
		transition: 	{ type: String, default: 'dialog-bottom-transition' },
		color:			{ type: String, default: null }, 		// Колір фону (card)
		

		//activator:			{ type: Object, default: () => {} },	// Параметри активатора

		small:      	{ type: Boolean, default: false },
		disabled:   	{ type: Boolean, default: false }, //Ознака неактивного елементу
		activator:  	{ type: String, default: 'button'}, // Тип активатора вікна: button | icon | menu-item | list-item

	},
	computed: {
		title: function () {
            return this.$store.getters[this.module + '/title']
        },
		icon: function () {
            return this.$store.getters[this.module + '/icon']
        },
		items: function () {
            return this.$store.getters[this.module + '/items']
        },
		/* form: function () {
            return this.$store.getters[this.module + '/form']
        }, */

		
		activatorIcon: function () {
			let result = 'mdi-plus'
			switch(this.mode) {
				case 'update': result = 'mdi-pencil'; break;
				case 'preview': result = 'mdi-printer-eye'; break;
			}
			return result
		},
	},
	methods: {
		Open (e) {
			this.dialog = true
		},
		OnClose (e) {
			this.dialog = false
			this.$emit('close', e)
		},
		OnCancel (e) {
			this.$emit('cancel', e)
			this.OnClose()
		},
		OnOK (e) {
			this.$emit(this.mode, e)
			this.OnClose()
		},
		OnSearch (e) {
			//this.$emit(this.mode, e)
			//this.OnClose()
		},
	},
}
</script>