<template>
	<v-card v-if="item.hasOwnProperty('costAmvUAH')"
		elevation="0">
		<v-card-title class="text-button">{{ title }}</v-card-title>
		<!-- <v-card-subtitle class="text-h6">{{ subtitle }}</v-card-subtitle> -->

		<v-card-text>
			<v-simple-table>
				<template v-slot:default>
					<tbody>
						<tr>
							<td width="70%">Середньоринкова вартість ТЗ становить:</td>
							<td class="d-flex justify-end align-center">{{ item.costAmvUAH }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	data: () => ({
		title: 'Висновок',
		subtitle: '',
	}),

	props: {
		item: { type: Object, default: () => {}},
	},

	computed: {
		/* factors() {
			return this.item.hasOwnProperty('factors') ? this.item.factors: [];
		}, */
	},

	methods: {
		
	},
}
</script>