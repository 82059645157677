import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VNavigationDrawer,{staticStyle:{"padding":"64px 0px 0px 0px"},attrs:{"fixed":"","clipped":"","permanent":"","width":"300"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c(VCardActions,{staticClass:"px-4"},[_c('z-drug-search-dialog',{attrs:{"multiple":""},on:{"add":_vm.add}}),_c(VSpacer),_c(VMenu,{attrs:{"close-on-content-click":false,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-cog")])],1)]}}]),model:{value:(_vm.menu.settings),callback:function ($$v) {_vm.$set(_vm.menu, "settings", $$v)},expression:"menu.settings"}},[_c(VCard,[_c(VToolbar,{attrs:{"flat":""}},[_c(VAvatar,[_c(VIcon,[_vm._v("mdi-cog")])],1),_c(VToolbarTitle,[_vm._v("Парамери")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.menu.settings=false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VCombobox,{attrs:{"items":_vm.periods,"label":"Період оцінки","outlined":"","dense":"","item-text":"name","item-value":"value","hide-details":"auto"},model:{value:(_vm.params.period),callback:function ($$v) {_vm.$set(_vm.params, "period", $$v)},expression:"params.period"}})],1)],1)],1)],1),_c(VDivider)]},proxy:true},{key:"append",fn:function(){return [_c(VCardActions,{staticClass:"px-4"},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":_vm.onClear}},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.clear')))]),_c(VBtn,{attrs:{"color":"primary","disabled":_vm.disabledOk},on:{"click":_vm.onOK}},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.ok')))])],1)]},proxy:true}])},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c(VList,{attrs:{"three-line":""}},[_c(VListItemGroup,{attrs:{"active-class":"success--text"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_vm._l((_vm.params.objects),function(item,index){return [_c(VListItem,{key:index,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-wrap",domProps:{"textContent":_vm._s(item.name)}})],1),_c(VListItemAction,[_c(VIcon,{on:{"click":function($event){$event.stopPropagation();return _vm.remove(item)}}},[_vm._v("mdi-delete")])],1)]}}],null,true)}),(index < _vm.params.objects.length - 1)?_c(VDivider,{key:item.name}):_vm._e()]})],2)],1)],1)],1)],1),_c(VNavigationDrawer,{staticStyle:{"padding":"64px 0px 0px 0px"},attrs:{"fixed":"","clipped":"","permanent":"","right":"","mini-variant":"","mini-variant-width":"56"}},[_c(VList,{attrs:{"dense":"","nav":""}},[_c(VListItem,[_c(VListItemAction,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"#f40f02"},on:{"click":_vm.onPDF}},'v-icon',attrs,false),Object.assign({}, tooltip)),[_vm._v("mdi-file-pdf-box")])]}}])},[_c('span',[_vm._v("Експорт PDF")])])],1)],1),_c('z-excel-parameter',{attrs:{"data":_vm.WorkbookParameters,"name":_vm.$options.name},on:{"import":_vm.WorkbookParametersImport}})],1)],1),_c(VContainer,{staticStyle:{"padding":"64px 0px 0px 256px"}},[_c(VCard,{attrs:{"elevation":"0"}},[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.title)+" "+_vm._s(_vm.id ? '№' + _vm.id: ''))]),_c(VCardSubtitle,[_vm._v(_vm._s(_vm.subtitle))]),_c(VDivider),_c(VCardText,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.item.objects,"item-key":"id","loading-text":"Завантаження даних... зачекайте, будь ласка.","items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.priceMin",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toCurrencyUAH")(item.priceMin)))])]}},{key:"item.priceMax",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toCurrencyUAH")(item.priceMax)))])]}},{key:"item.priceAvg",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toCurrencyUAH")(item.priceAvg)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VToolbar,{attrs:{"flat":"","dense":"","color":"transparent"}},[(item.priceCount)?_c('z-statistic',{attrs:{"item":item}}):_vm._e()],1)]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',{staticClass:"title"}),_c('th',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm._f("toCurrencyUAH")(_vm.sumField('priceMin'))))]),_c('th',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm._f("toCurrencyUAH")(_vm.sumField('priceAvg'))))]),_c('th',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm._f("toCurrencyUAH")(_vm.sumField('priceMax'))))]),_c('th',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.sumField('priceCount')))])])])],2)],1)],1)],1),_c(VOverlay,{attrs:{"value":_vm.loading}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1),_c(VSnackbar,{attrs:{"color":_vm.snackbar.color},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.snackbar.visible = false}}},'v-btn',attrs,false),[_c(VIcon,[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.snackbar.visible),callback:function ($$v) {_vm.$set(_vm.snackbar, "visible", $$v)},expression:"snackbar.visible"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")]),_c('z-pdf',{ref:"pdf",attrs:{"item":_vm.item}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }