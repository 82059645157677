<template>
	<v-form ref="form">
		<v-row dense>
			<v-col cols="12">
				<v-text-field
					label="Реєстраційний номер"
					v-model="item.number"
					outlined
					dense
					clearable
					@keyup.ctrl.90="item.number='ab9999it'"
					hide-details="auto"
				></v-text-field>
			</v-col>
			<v-col cols="12">
				<v-text-field
					label="VIN-код"
					v-model="item.vin"
					outlined
					dense
					clearable
					@keyup.ctrl.90="item.vin='JT153EEA100276750'"
					hide-details="auto"
				></v-text-field>
			</v-col>
		</v-row>
	</v-form>
</template>

<script>
export default {
	data () {
		return {
			
		}
	},
	props: {
		module:	{ type: String, default: 'VehicleEstimate'}, // check | market | metodica | exploration | averageprice
	},

	computed: {
		item: function () {
			return this.$store.getters[`${this.module}/params`]
		},
	},
}
</script>