<template>
	<v-main>
		<v-container :fluid="multi">
			<v-toolbar elevation="0">
				<v-btn icon color="primary" @click="$router.push('/')">
					<v-icon >mdi-home</v-icon>
				</v-btn>
				<v-toolbar-title class="font-weight-light">{{ title }}</v-toolbar-title>
				<v-spacer></v-spacer>
				
				<template v-slot:extension>
					<v-tabs v-model="tab" right>
						<v-tabs-slider color="yellow"></v-tabs-slider>
						<v-tab v-for="item in tabs" :key="item">
							{{ item }}
						</v-tab>
					</v-tabs>
				</template>
			</v-toolbar>
			<v-tabs-items v-model="tab">
				<!-- Dialog -->
				<!-- <v-tab-item>
					<FormList ref="result" :type="type" :items="items" v-if="multi" @execute="OnExecute"></FormList>

					<v-row class="justify-center" v-else>
						<v-col cols="12" xs="5" sm="5" md="4" lg="4" xl="4">
							<v-card class="mx-auto" elevation="0">
								<v-card-text>
									<FormDetail ref="detail" :type="type" :item="item"></FormDetail>
								</v-card-text>
								<v-card-actions>
									<v-row class="mx-2" dense no-gutters>
										<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
											<v-btn text color="primary" @click="OnClear">Очистити</v-btn>
										</v-col>
										<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
											<v-btn block color="primary" @click="OnExecute(item)" :loading="loading">ОК</v-btn>
										</v-col>
									</v-row>
								</v-card-actions>
							</v-card>
						</v-col>
						<v-col cols="12"  xs="7" sm="7" md="8" lg="8" xl="8">
							<FormResult ref="result" :type="type" :item="item.estimate" @setparams="OnSetParams"></FormResult>
						</v-col>
					</v-row>
				</v-tab-item> -->
				<v-tab-item>
					<v-row class="justify-center">
						<v-col cols="12" xs="5" sm="5" md="4" lg="4" xl="4">
							<v-card class="mx-auto" elevation="0">
								<v-card-text>
									<FormDetail ref="detail" :type="type" :item="item"></FormDetail>
								</v-card-text>
								<v-card-actions>
									<v-row class="mx-2" dense no-gutters>
										<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
											<v-btn text color="primary" @click="OnClear">Очистити</v-btn>
										</v-col>
										<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
											<v-btn block color="primary" @click="OnExecute(item)" :loading="loading">ОК</v-btn>
										</v-col>
									</v-row>
								</v-card-actions>
							</v-card>
						</v-col>
						<v-col cols="12" xs="7" sm="7" md="8" lg="8" xl="8">
							<FormResult ref="result" :type="type" :item="item.estimate" @setparams="OnSetParams"></FormResult>
						</v-col>
					</v-row>
				</v-tab-item>
				<v-tab-item>
					<FormList ref="result" :type="type" :items="items" @execute="OnExecute"></FormList>
				</v-tab-item>
				<!-- API -->
				<v-tab-item>
					<ApiDoc></ApiDoc>
				</v-tab-item>
				<!-- Price -->
				<v-tab-item>
					
				</v-tab-item>
				<!-- <v-tab-item
					v-for="i in 3"
					:key="i"
					:value="'tab-' + i"
				>
					<v-card flat>
					<v-card-text>{{ text }}</v-card-text>
					</v-card>
				</v-tab-item> -->
			</v-tabs-items>
		</v-container>
	</v-main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FormDetail from '@/views/forms/vehicle.estimate.detail'
import FormResult from '@/views/forms/vehicle.estimate.result'
import FormList from '@/views/forms/vehicle.estimate.list'
//import ApiDoc from '@/views/components/apidoc/vehicle.estimate.mono'

export default {
	components: {
		FormDetail, FormResult, FormList,
		//ApiDoc
	},
	data: () => ({
		loading: false,

		tab: null,
        tabs: [
			'Mini',
			'Multi',
		  //'Форма',
		  //'Документація',
		  //'Ціна'
        ],

		item: { 
			use: { 
				increas: {},
				reduce: {
					elements: {}
				},
			}
		},
		items: [],

		ExecuteCount: 0,
	}),
	props: {
		type: {
			type: String,
			default: 'metodica' // check | market | metodica | exploration | averageprice
		},
		title: {
			type: String,
			default: 'Оцінка ринкової вартості транспортного засобу'
		},
		multi: {
			type: Boolean,
			default: false
		},
	},
	computed: {
		
	},
	methods: {
		...mapActions('vehicles', ['Estimate','VehicleAveragePrice']),

		OnClear () {
			//this.$refs.detail.Clear()
			if(this.multi) {
				this.items = []
			} else {
				this.item = {}
				//this.detail = {}
				//this.result = null
			}
			
		},

		OnAdd (item) {
			this.items.push(item)
		},

		OnExecute(data) {
			//console.log('vehicle.estimate', 'OnExecute', 'data', data)

			if(data) {
				this.loading = true
				this.ExecuteCount = 0

				if(this.type == 'averageprice') {
					if(Array.isArray(data)) {	
						for(let i in data) {
							this.OnAveragePrice(data[i])
						}
					} else {
						this.OnAveragePrice(data)
					}
				} else {
					if(Array.isArray(data)) {	
						for(let i in data) {
							this.OnEstimate(data[i])
						}
					} else {
						this.OnEstimate(data)
					}
				}
			}
		},

		OnEstimate(item) {
			//console.log('vehicle.estimate', 'OnEstimate', 'item', item)
			var that = this
			this.ExecuteCount++
			this.Estimate({
				type: this.type,
				params: item,
				success: function(respond) {
					that.$set(item, 'estimate', respond)

					if(respond && respond.hasOwnProperty('market')) {
						item['total'] = respond['market']['total']['value']
						item['arithmeticMean'] = respond['market']['arithmeticMean']['value']
						item['arithmeticMeanQuartile'] = respond['market']['arithmeticMeanQuartile']['value']	
					}
					that.loading = --this.ExecuteCount == 0//?false: true
				},
				error: function(respond) {
					that.loading = --this.ExecuteCount == 0//?false: true
				},
			});
		},

		OnAveragePrice(item) {
			console.log('vehicle.estimate', 'OnAveragePrice', 'item', item)

			var params = {};

			if(item.hasOwnProperty('regnum')) params['regnum'] 						= item.regnum;
			if(item.hasOwnProperty('vin')) params['vin'] 							= item.vin;
			if(item.hasOwnProperty('brand')) params['brandId'] 						= item.brand.id;
			if(item.hasOwnProperty('model')) params['modelId'] 						= item.model.id;
			if(item.hasOwnProperty('body')) params['bodyId'] 						= item.body.id;
			if(item.hasOwnProperty('gearBox')) params['gearBoxId'] 					= item.gearBox.id;
			if(item.hasOwnProperty('fuel')) params['fuelId'] 						= item.fuel.id;
			if(item.hasOwnProperty('drive')) params['driveId'] 						= item.drive.id;
			if(item.hasOwnProperty('color')) params['colorId'] 						= item.color.id;
			if(item.hasOwnProperty('state')) params['stateId'] 						= item.state.id;
			if(item.hasOwnProperty('generation')) params['generationId'] 			= item.generation.id;
			if(item.hasOwnProperty('modification')) params['modificationId'] 		= item.modification.id;
			if(item.hasOwnProperty('yearGTE')) params['yearGTE'] 					= item.yearGTE;
			if(item.hasOwnProperty('yearLTE')) params['yearLTE'] 					= item.yearLTE;
			if(item.hasOwnProperty('mileageGTE')) params['mileageGTE'] 				= item.mileageGTE;
			if(item.hasOwnProperty('mileageLTE')) params['mileageLTE'] 				= item.mileageLTE;
			if(item.hasOwnProperty('engineVolumeGTE')) params['engineVolumeGTE'] 	= item.engineVolumeGTE;
			if(item.hasOwnProperty('engineVolumeLTE')) params['engineVolumeLTE'] 	= item.engineVolumeLTE;

			console.log('vehicle.estimate', 'OnAveragePrice', 'params', params)

			var that = this
			this.ExecuteCount++
			this.VehicleAveragePrice({
				type: this.type,
				params: params,
				success: function(respond) {
					console.log('vehicle.estimate', 'OnAveragePrice', 'respond', respond)
					if(respond) {
						if(respond.hasOwnProperty('value')) {
							//value
						}
						if(respond.hasOwnProperty('points')) {
							that.$set(item, 'points', respond.points)
						}
					}

					//that.$set(item, 'estimate', respond)

					/* if(respond && respond.hasOwnProperty('market')) {
						item['total'] = respond['market']['total']['value']
						item['arithmeticMean'] = respond['market']['arithmeticMean']['value']
						item['arithmeticMeanQuartile'] = respond['market']['arithmeticMeanQuartile']['value']	
					} */
					that.loading = --this.ExecuteCount == 0//?false: true
				},
				error: function(respond) {
					that.loading = --this.ExecuteCount == 0//?false: true
				},
			});
		},

		//Встановлення параметрів як в транспортному засобі
		OnSetParams (item) {
			//console.log('vehicle.estimate', 'this.item', this.item)
			//console.log('vehicle.estimate', 'item', item)

			var fields = ['yearfrom', 'yearto','racefrom','raceto','brand','model','gearbox','engine','capacityfrom','capacityto']
			for(let i in fields) {
				let key = fields[i]
				if(item[key]) {
					this.$set(this.item, key, item[key])
				}

			}
		},
	},
}
</script>