export default {
    //...uk,
    langname: "Українська",
    language: "мова",
    homepage: "Головна",
    profile: "Профіль",

    commonAttributes: "загальні атрибути",
    type: "тип",
    name: "назва",
    namePersonOrganisation: "П.І.Б. /Назва організації",
    number: "номер",
    date: "дата",
    from: "з",
    to: "по",
    yes: "так",
    not: "ні",

    commonActions: "загальні дії",
    actions: "Дії",
    enter: "Увійти",
    exit: "Вихід",
    ok: "OK",
    cancel: "Скасувати",
    add: "Додати",
    remove: "Видалити",
    clear: "Очистити",
    new: "Створити",
    edit: "Редагувати",
    create: "Створити",
    update: "Редагувати",
    delete: "Видалити",
    search: "Знайти",
    login: "Вхід",
    signin: "Вхід",
    signup: "Реєстрація",
    save: "Зберегти",
    open: "відкрити",
    close: "закрити",
    upload: "вивантажити",
    download: "завантажити",
    import: "імпорт",
    export: "експорт",
    authorization: "авторизація",
    registration: "реєстрація",
    createaccount: "Створити Аккаунт",
    password: "пароль",
    passwordold: "старий пароль",
    passwordnew: "новий пароль",
    passwordconfirm: "підтвердити пароль",
    passwordchange: "змінити пароль",
    passwordreset: "Оновлення паролю",
    passwordrecovery: "Відновлення паролю",
    passwordforgot: "Забули пароль?",
    donthaveaccount: "Створити Аккаунт",
    orderconnection: "Заявка на підключення",

    // Objects
    users: "користувачі",
    user: "користувач",
    roles: "Ролі",
    role: "Роль",
    services: "сервіси",
    service: "сервіс",
    organizations: "організації",
    organization: "організація",
    statistics: "статистика",
	statistic: "статистика",

    //homepage.banner
    cameraoff: "На вашому пристрої доступ до камери заблоковано. В такому режимі ви можете перевіряти поліси, шляхом введення вручну номеру полісу або цифр зі штрих-коду.",
    search: {
        placeholder: "Інформація з QR-коду або зі штрих-коду"
    },

    stopscan: "зупинити сканування",
    startscan: "відновити сканування",

    settings: "налаштування",
    video: "відео",
    frame: "рамка",
    laser: "лазер",
    sound: "звук",
    soundeditor: "редактор звукових сигналів",
    volume: "гучність",
    frequency: "частота",
    duration: "тривалість",
    beeps: {
        scan: "зчитування коду",
        success: "поліс ідентифіковано",
        failure: "поліс не ідентифіковано",
        error: "помилка",
    },

    //Attributes
    pib: "прізвице, ім'я, по батькові",
    telephone: "телефон",
    email: "email",
    comment: "коментар",

persons: "фізичні особи",
person: "фізична особа",
firstname: "ім’я",
surname: "прізвище",
patronymic: "по батькові",
birthDate: "дата народження",
birthPlace: "місце народження",
deathDate: "дата смерті",
deathPlace: "місце смерті",
nationality: "громадянство",
post: "посада",
personsname: "ПІБ",
organization: "організація",

sex: "стать",
male: "чоловіча",
female: "жіноча",

persons_roles: "ролі осіб",
claimant: "заявник",
owner: "власник",
driver: "водій",
previous_owners: "попередні власники",

identification: "документ, який посвідчує особу",
seria: "серія",
number: "номер",
issuedby: "ким виданий",
dateofissue: "дата видачі",
recordno: "запис №",
dateofexpiry: "дійсний до",
documentno: "документ №",

identificationstype: "тип документа",
identificationstypes: "типи посвідченнь особи",
passport: "паспорт",
passportCitizen: "паспорт громадянина",
passportForeign: "паспорт громадянина для виїзду за кордон",
passportDiplomatic: "дипломатичний паспорт",
passportService: "службовий паспорт",
passportSeamen: "посвідчення особи моряка",
passportCrew: "посвідчення члена екіпажу",
passportReturn: "посвідчення особи на повернення",
passportTemporary: "тимчасове посвідчення громадянина",
driverLicense: "посвідчення водія",
identityCardTravel: "посвідчення особи без громадянства для виїзду за кордон",
identityCardPermanent: "посвідка на постійне проживання",
identityCardTemporary: "посвідка на тимчасове проживання",
identityCardMigrant: "картка мігранта",
refugeeCertificate: "посвідчення біженця",
refugeeTravelDocument: "проїзний документ біженця",
identityCardAdditionalProtection: "посвідчення особи, яка потребує додаткового захисту",
identityCardGrantedAdditionalProtection: "проїзний документ особи, якій надано додатковий захист",

addresses: "адреси",
address: "адреса",
postalCode: "індекс",
сountry: "країна",
locality: "населений пункт",
district: "район",
street: "вулиця",
house: "будинок",
apartment: "квартира",
latitude: "latitude",
longitude: "longitude",

addressestypes: "типи адрес",
addresstype: "тип адреси",
addressResidence: "адреса проживання",
addressRegistration: "адреса реєстрації",

documents: "документи",
document: "документ",
templates: "Шаблони",
template: "Шаблон",
constructors: "Коструктори",
constructor: "Коструктор",
//number: "номер",
//date: "дата",

policies: "договори страхування",
policy: "договір страхування",
//number: "номер",
//date: "дата",
insurer: "страхувальник",
periodValidity: "строк дії",

notifications: "повідомлення",
notification: "повідомлення",
//number: "номер",
//date: "дата",

cases: "справи",
case: "справа",
//number: "номер",
//date: "дата",

payments: "виплати",
payment: "виплата",
//number: "номер",
//date: "дата",

calendars: "календарі",
calendar: "календар",
//number: "номер",
//date: "дата",

expiration: "закінчення терміну дії",
datecreate: "дата створення",
    //---------------------------
    company: "компанія",
    inn: "ІПН",

    //Rules
    rule: {
        required: "Значення поля не може бути пустим",
        lenless: "Name must be less than {0} characters",
        code: "Code must be less than {0} characters",
        personsname: "Ім'я особи містить недопустимі символи",
        email: "E-mail вказано невірно",
        phone: "Номер телефону не вірний (+380961112233)",
    },
    
    carousel: {
        ariaLabel: { 
            delimiter: ''
        }
    },

    noDataText: '',

    datePicker: { 
        prevMonthAriaLabel: '',
        nextMonthAriaLabel: '',
    },

    carousel: {
        prev: 'попередній',
        next: 'наступний',
        ariaLabel: {
            delimiter: ''
        }
    },

    input: {
        clear: 'очистити',
        appendAction: '',
        prependAction: '',
    },

    badge: '',

    dataTable: {
		ariaLabel: {
			sortAscending: '',
			activateNone: '',
			activateAscending: '',
			activateDescending: '',
			sortNone: '',
			sortAscending: '',
			sortDescending: '',
		},
		itemsPerPageText: 'Рядків на сторінку:',
		loadingText: 'Завантаження... Зачекайте, будь ласка.',
	},

	dataFooter: {
		prevPage: '',
		nextPage: '',
		itemsPerPageAll: '',
		pageText: '{0}-{1} з {2}'
	},

    pagination: {
		ariaLabel: {
			previous: '',
			currentPage: '',
			page: '',
			next: '',
			wrapper: '',
		}
	},
}