import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VContainer,[_c(VToolbar,{attrs:{"elevation":"0"}},[_c(VBtn,{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.$router.push('/')}}},[_c(VIcon,[_vm._v("mdi-home")])],1),_c(VToolbarTitle,{staticClass:"font-weight-light"},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.' + _vm.title.plural))))]),_c(VSpacer)],1),_c(VDataTable,{staticClass:"mt-16",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","show-select":"","loading":_vm.loading,"loading-text":"Завантаження даних... зачекайте, будь ласка."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":""}},[_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.OnSearch}},[_c(VIcon,[_vm._v("mdi-magnify")])],1),_c('z-dialog-detail',{attrs:{"module":_vm.module,"mode":"create","title":_vm.title,"icon":_vm.icon}})],1)]},proxy:true},_vm._l((_vm.headers.filter(function (header) { return header.hasOwnProperty('type'); })),function(header,index){return {key:("item." + (header.value)),fn:function(ref){
var value = ref.value;
return [(header.type =='datetime')?_c('span',{key:index},[_vm._v(_vm._s(_vm.DateFormat(value)))]):_vm._e()]}}}),{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('z-dialog-detail',{attrs:{"module":_vm.module,"mode":"update","title":_vm.title,"icon":_vm.icon,"item":item,"activator":"icon"}}),_c(VIcon,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('delete', item)}}},[_vm._v("mdi-trash-can-outline")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }