<template>
	<v-dialog  
	 	v-model="dialog"
	 	:fullscreen="fullscreen"
		scrollable
		transition="dialog-bottom-transition"
		width="500"
	>
		<template v-slot:activator="{ on: dialog, attrs }">
			<v-tooltip bottom>
				<template v-slot:activator="{ on: tooltip, attrs }">
					<slot name="activator" :attrs="attrs" :dialog="dialog" :tooltip="tooltip">
						<v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...dialog }">
							<v-icon>mdi-pencil</v-icon>
						</v-btn>
					</slot>
				</template>
				<span>{{ $vuetify.lang.t(`$vuetify.${tooltip}`) | capitalize }}</span>
			</v-tooltip>
		</template>

		<v-card>
			<v-toolbar flat color="#f5f5f5" style="flex: 0 1 auto;">
				<v-avatar>
					<v-icon>{{ icon }}</v-icon>
				</v-avatar>
				<v-toolbar-title v-if="item.id">{{ title.single }} № {{ item.id }}</v-toolbar-title>
				<v-toolbar-title v-else>{{ title.single }}</v-toolbar-title>
				
				<v-spacer></v-spacer>

				<!-- <v-btn icon @click="onSave" >
					<v-icon>mdi-content-save</v-icon>
				</v-btn> -->

				<v-btn icon @click="dialog=false" >
					<v-icon>mdi-close</v-icon>
				</v-btn>

				<!-- <template v-slot:extension>
					<v-tabs v-model="tab" align-with-title>
						<v-tabs-slider color="yellow"></v-tabs-slider>
						<v-tab v-for="item in tabs" :key="item">
							{{ item }}
						</v-tab>
					</v-tabs>
				</template> -->
			</v-toolbar>

			<v-card-text>
				<v-container>
					<v-form ref="form">
						<v-row dense>
							<v-col cols="12">
								<v-text-field
									v-model="item.name"
									label="Назва"
									:rules="[rules.required]"
								></v-text-field>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="12">
								<v-combobox
									v-model="item.type"
									:items="types"
									label="Тип"
									item-text="name"
									item-value="id"
								></v-combobox>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="12">
								<v-text-field
									v-model="item.value"
									label="Значення"
									:rules="[rules.required]"
								></v-text-field>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="12">
								<v-textarea
									v-model="item.description"
									label="Опис"
								></v-textarea>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="12" md="4">
								<v-text-field
									v-model="item.code"
									label="Код"
								></v-text-field>
							</v-col>
						</v-row>
					</v-form>
				</v-container>					
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<!-- <v-btn text @click.native="onCancel">{{ $vuetify.lang.t('$vuetify.cancel') }}</v-btn> -->
				<v-btn color="success" dark @click.native="onOK" :disabled="loading">{{ $vuetify.lang.t('$vuetify.ok') }}</v-btn>
			</v-card-actions>
		</v-card>

		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
	</v-dialog>
</template>

<script>
import mixin from '@/views/mixin/edit'
import { mapGetters, mapActions } from 'vuex'
import zDate from '@/views/components/control/Date'

export default {
	name: 'DocumentPropertyEdit',
	description: 'Форма для реєстрації учасника документу',
	mixins: [mixin],
	components: {
		zDate,
	},
	data: () => ({
		fullscreen: false,
	}),

	computed: {
		...mapGetters('DocumentProperty', [ 'icon', 'title', 'item' ] ),
		...mapGetters('DocumentPropertyType', { 'types': 'items' } ),
	},

	created() {
		//console.log(this.$options.name, 'created')
		this.loadDocumentPropertyType()
	},

	methods: {
		...mapActions('DocumentProperty', [ 'find', 'create', 'update', 'setItem' ]),
		...mapActions('DocumentPropertyType', { 'loadDocumentPropertyType': 'load' }),

		init() {
			//console.log(this.$options.name, 'init', 'item', this.item)
			if(!this.item?.type?.id) {
				this.item.type = this.types[0]
			}
		},

		onOpen() {
			//console.log(this.$options.name, 'onOpen', 'selected', this.selected)
			//console.log(this.$options.name, 'onOpen', 'items', this.items)

			this.setItem(Object.assign({}, this.selected))
			this.init()
		},

		onOK() {
			//console.log(this.$options.name, 'onOK', 'items', this.items, 'selected', this.selected, 'item', this.item)

			this.loading = true
			return this.validate().then(() => {
				//console.log(this.$options.name, 'onOK', 'item', this.item)
				this.$emit('ok', this.item)
			}).then(() => {
				this.onClose()
			}).catch((error) => {
				console.log(this.$options.name, 'onOK', 'error', error)
				//this.showMessage(error)
			}).finally(() => {
				this.loading = false
			});
		},
	},
}
</script>