<template>
    <v-menu>
		<template v-slot:activator="{ on: dialog, attrs }">
			<v-tooltip bottom>
				<template v-slot:activator="{ on: tooltip, attrs }">
					<slot name="activator" :attrs="attrs" :dialog="dialog" :tooltip="tooltip">
						<v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...dialog }">
							<v-icon color="#1d6f42">{{ icon }}</v-icon>
						</v-btn>
					</slot>
				</template>
				<span>{{ tooltip }}</span>
			</v-tooltip>
		</template>

		<v-file-input class="d-none" ref="fileinput" v-model="file" @change="changeImport"></v-file-input>

		<v-list>
			<v-list-item @click="onImport">
				<v-list-item-icon>
					<!-- <v-icon>mdi-import</v-icon> -->
					<!-- <v-icon color="yellow darken-2">mdi-folder</v-icon> -->
					<!-- <v-icon color="light-blue darken-4">mdi-upload</v-icon> -->
					<v-icon color="light-blue darken-4">mdi-import</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>{{ $vuetify.lang.t('$vuetify.import') | capitalize }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-list-item @click="onExport">
				<v-list-item-icon>
					<!-- <v-icon>mdi-export</v-icon> -->
					<!-- <v-icon color="light-blue darken-4">mdi-download</v-icon> -->
					<v-icon color="light-blue darken-4">mdi-export</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>{{ $vuetify.lang.t('$vuetify.export') | capitalize }} </v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</v-menu>
</template>

<script>
import * as XLSX from "xlsx"; //https://www.npmjs.com/package/xlsx

export default {
	name: 'excel2',
	components: {
		
	},

	data: () => ({
		text: null,
		type: 'success',
		visible: false,
		file: null,
	}),

	props: {
		icon:   		{ type: String, default: 'mdi-microsoft-excel' },
		title:   		{ type: String, default: 'Excel' },
		tooltip:  		{ type: String, default: 'Excel' },
		disabled:   	{ type: Boolean, default: false }, //Ознака неактивного елементу
		//fields:  		{ type: Object, default: () => {} },
		//data:  			{ type: Object, default: () => {} },
		//worksheet:  	{ type: String, default: 'My Worksheet' },
		name:  			{ type: String, default: 'Книга 1' },
		accept:			{ type: String, default: '.xlsx' },

		workbook:		{ type: Object, default: () => {} }, 	// Опис структури 
		data:  			{ type: [Array, Object] },				// Дані
	},

	methods: {
		getValue(obj, path, def) {

			/**
			 * If the path is a string, convert it to an array
			 * @param  {String|Array} path The path
			 * @return {Array}             The path array
			 */
			var stringToPath = function (path) {

				// If the path isn't a string, return it
				if (typeof path !== 'string') return path;

				// Create new array
				var output = [];

				// Split to an array with dot notation
				path.split('.').forEach(function (item, index) {

					// Split to an array with bracket notation
					item.split(/\[([^}]+)\]/g).forEach(function (key) {

						// Push to the new array
						if (key.length > 0) {
							output.push(key);
						}

					});

				});

				return output;

			};

			// Get the path as an array
			path = stringToPath(path);

			// Cache the current object
			var current = obj;

			// For each item in the path, dig into the object
			for (var i = 0; i < path.length; i++) {

				// If the item isn't found, return the default (or null)
				if (!current[path[i]]) return def;

				// Otherwise, update the current  value
				current = current[path[i]];

			}

			return current;

		},

		setValue(obj, path, value) {
			var paths = path.split('.')

			paths.reduce((obj, path, i) => {
				return obj[path] = paths.length-1 === i ? value : {}
			}, obj)
		},

		onImport() {
			this.$refs.fileinput.$refs.input.accept = this.accept;
			this.$refs.fileinput.$refs.input.click()
		},

		onExport() {
			//console.log(this.$options.name, 'onExport', 'data', this.data)
			console.log(this.$options.name, 'onExport', 'workbook', this.workbook)
			//console.log(this.$options.name, 'onExport', 'XLSX', XLSX)

			if(this.data) {
				let filename = (this.workbook?.name ? this.workbook.name : this.name ) + '.xlsx'
				
				var workbook = XLSX.utils.book_new();

				if(this.workbook.hasOwnProperty('sheets')) {
					this.workbook.sheets.forEach((sheet, s) => {
						let name = sheet?.name ? sheet.name : `Лист ${s+1}`, worksheet = null

						//console.log(this.$options.name, 'onExport', 'name', name)

						let data_array = []

						if(sheet.hasOwnProperty('headers')) {
							//Формування заголовку таблиці
							let row = []
							sheet.headers.forEach((header) => {
								row.push(header.text)
							})
							data_array.push(row)

							//Формування даних таблиці

							let items = sheet.hasOwnProperty('value') ? this.getValue(this.data, sheet.value) : this.data;

							console.log(this.$options.name, 'onExport', name, 'items', items)

							if(Array.isArray(items)) {
								items.forEach((item) => {
									row = []
									sheet.headers.forEach((header) => {
										let value = this.getValue(item, header.value)
										row.push(value)
									})
									data_array.push(row)
								})
							} else {
								row = []
								sheet.headers.forEach((header) => {
									let value = this.getValue(items, header.value)
									row.push(value)
								})
								data_array.push(row)
								/* for (const [key, value] of Object.entries(items)) {
									console.log(`${key}: ${value}`);
								} */
							}

							worksheet = XLSX.utils.aoa_to_sheet(data_array);
						} else if(sheet.hasOwnProperty('jsa')) {
							worksheet = XLSX.utils.json_to_sheet(jsa);
						} else if(sheet.hasOwnProperty('aoa')) {
							worksheet = XLSX.utils.aoa_to_sheet(aoa);
						}

						//console.log(this.$options.name, 'onExport', name, 'data_array', data_array)


						XLSX.utils.book_append_sheet(workbook, worksheet, name);
					})
				}

				XLSX.writeFileXLSX(workbook, filename);
			}
		},

		changeImport(files) {
			//console.log(this.$options.name, 'changeImport', 'files', files)

			let file = Array.isArray(files) ? files[0] : files;
			if (file) {
				const reader = new FileReader();

				reader.onload = (e) => {
					//console.log(this.$options.name, 'changeImport', 'XLSX', XLSX)

					let result = {}

					const bstr = e.target.result;
					const wb = XLSX.read(bstr, { type: 'binary' });
					
					// Перетворення в об'єкт для відповіді
					if(this.workbook.hasOwnProperty('sheets')) {
						this.workbook.sheets.forEach((sheet, s) => {
							let type = sheet?.type ? sheet.type : 'Object'
								,sheetName = sheet?.name
							;

							//console.log(this.$options.name, 'changeImport', 'type', type)
							//console.log(this.$options.name, 'changeImport', 'sheetName', sheetName)

							let element = null
							switch(type){
								case 'Array': 	element = []; break;
								case 'Object': 	element = {}; break;
							}

							if(sheet?.element) {
								result[sheet.element] = element
							} else {
								element = result
							}

							const ws = sheetName ? wb.Sheets[sheetName] : wb.Sheets[wb.SheetNames[s]]

							const data_array 	= XLSX.utils.sheet_to_json(ws, { header: 1 });
							//const data_json 	= XLSX.utils.sheet_to_row_object_array(ws)

							//console.log(this.$options.name, 'changeImport', 'data_array', data_array)

							if(Array.isArray(element)) {
								data_array.forEach((row, r) => {
									if(r > 0) {
										let obj = {}
										sheet.headers.forEach((header, h) => {
											this.setValue(obj, header.value, row[h])
										})

										element.push(obj)
									}
								})
							} else {
								data_array.forEach((row, r) => {
									if(r > 0) {
										sheet.headers.forEach((header, h) => {
											this.setValue(element, header.value, row[h])
										})
									}
								})
							}
						})
					}

					//console.log(this.$options.name, 'changeImport', 'result', result)

					this.$emit('import', result)
				}

				reader.readAsBinaryString(this.file);
			}
		},
    },
}
</script>