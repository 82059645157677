export default {
    namespaced: true,
    state: {
		params: {
			regnum: null,
        	vin: null,
			category: null,
        	brand: null,
        	model: null,
        	yearGTE: null,
        	yearLTE: null,
        	mileageGTE: null,
        	mileageLTE: null,
        	engineVolumeGTE: null,
        	engineVolumeLTE: null,
        	engine: null,
        	body: null,
        	gearBox: null,
		},

		item: {
			avgValue: null,
			value: null,
			quantityUnits: null,
			points: [],
		}
    },
    getters: {
		params(state) {
			return state.params
		},
		isset(state) {
			return state.params.regnum 
				|| state.params.vin
				|| state.params.brand
				|| state.params.model
		},

		item(state) {
			return state.item
		},
    },
    mutations: {
		params (state, data) {
			state.params = data
		},
		item (state, data) {
			state.item = data
		},
    },
    actions: {
		clear (context) {
			context.commit('params', {
				regnum: null,
				vin: null,
				category: null,
				brand: null,
				model: null,
				yearGTE: null,
				yearLTE: null,
				mileageGTE: null,
				mileageLTE: null,
				engineVolumeGTE: null,
				engineVolumeLTE: null,
				engine: null,
				body: null,
				gearBox: null,
			})

			context.commit('item', {
				avgValue: null,
				value: null,
				quantityUnits: null,
				points: [],
			})
			//context.commit('value', null)
			//context.commit('points', [])
		},
    }
}