<template>
	<v-main>
		<v-navigation-drawer fixed clipped permanent style="padding: 64px 0px 0px 0px;">
			<v-card elevation="0">
				<v-card-text>
					<!-- <z-vehicle-parameters-number></z-vehicle-parameters-number> -->
					<z-vehicle-parameters></z-vehicle-parameters>
				</v-card-text>
			</v-card>
			<template v-slot:append>
				<v-card-actions class="px-4">
					<v-spacer></v-spacer>
					<v-btn text color="primary" @click="onClear">{{ $vuetify.lang.t('$vuetify.clear') }}</v-btn>
					<v-btn color="primary" @click="onOK">{{ $vuetify.lang.t('$vuetify.ok') }}</v-btn>
				</v-card-actions>
			</template>
		</v-navigation-drawer>

		<v-navigation-drawer fixed clipped permanent right
			mini-variant
			mini-variant-width="56"
			style="padding: 64px 0px 0px 0px;"
        >
			<v-list dense nav>
				<!-- PDF -->
				<v-list-item>
					<v-list-item-action>
						<v-tooltip bottom>
							<template v-slot:activator="{ on: tooltip, attrs }">
								<v-icon large color="red" v-bind="attrs" v-on="{ ...tooltip }" @click="onPDF">mdi-file-pdf-box</v-icon>
							</template>
							<span>Експорт PDF</span>
						</v-tooltip>
					</v-list-item-action>
				</v-list-item>

				<!-- Excel -->
				<v-list-item>
					<v-list-item-action>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<download-excel 
									:data="excel.data"
									:name="excel.name"
									:type="excel.type"
								>
									<v-icon large color="green darken-2" :disabled="excel.disabled" v-bind="attrs" v-on="on">mdi-microsoft-excel</v-icon>
								</download-excel>
							</template>
							<span>Експорт Excel</span>
						</v-tooltip>
					</v-list-item-action>
				</v-list-item>
			</v-list>
        </v-navigation-drawer>

		<v-container style="padding: 64px 0px 0px 256px;">
			<v-card elevation="0">
				<v-card-title class="text-h5">{{ title }} {{ id ? '№' + id: '' }}</v-card-title>
				<v-card-subtitle class="font-weight-light">{{ subtitle }}</v-card-subtitle>

				<v-divider></v-divider>

				<v-card-text v-for="(estimate, index) in estimates" :key="index">
					<z-form-average-price-mediana :estimate="estimate"></z-form-average-price-mediana>
					<v-divider></v-divider>
					<z-form-average-price-ai :estimate="estimate"></z-form-average-price-ai>
					<z-form-average-price-statistic :estimate="estimate"></z-form-average-price-statistic>
				</v-card-text>
			</v-card>
		</v-container>
		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64" ></v-progress-circular>
		</v-overlay>
		<v-snackbar v-model="snackbar.visible" :color="snackbar.color">
			{{ snackbar.text }}
			<template v-slot:action="{ attrs }">
			<v-btn icon v-bind="attrs" @click="snackbar.visible = false" >
				<v-icon>mdi-close</v-icon>
			</v-btn>
			</template>
		</v-snackbar>

		<z-pdf ref="pdf" :item="item"></z-pdf>
	</v-main>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import zVehicleParametersNumber from '@/views/forms/vehicle.parameters.number'
import zVehicleParameters from '@/views/forms/vehicle.parameters.ai'
import zPdf from '@/views/pdf/VehicleEstimate'
import downloadExcel from "vue-json-excel";

import zFormAveragePriceMediana from '@/views/forms/VehicleAveragePriceMediana'
import zFormAveragePriceAi from '@/views/forms/VehicleAveragePriceAI'
import zFormAveragePriceStatistic from '@/views/forms/VehicleAveragePriceStatistic'

export default {
	name: 'VehicleAveragePriceCombo',
	components: {
		zVehicleParametersNumber,
		zVehicleParameters,
		zPdf,
		downloadExcel,
		zFormAveragePriceMediana,
		zFormAveragePriceAi,
		zFormAveragePriceStatistic,
	},
	data: () => ({
		title: 'Середня ціна транспортного засобу комбінована',
		subtitle: 'Визначення середньої ціни за методом медіани та з використанням AI з наведенням статистики за період',
		loading: false,

		panel: [],
		panelCount: 2,

		excel: {
			name: '',
			type: 'xlsx',
			disabled: true,
			data: []
		},

		snackbar: {
			visible: false,
			text: null,
			color: null
		},
	}),
	
	computed: {
		...mapGetters('VehicleEstimate', [
			'params', 'item', 'isset'
		]),

		excelName() {
			return this.title + (this.params.number ? ' ' + this.params.number : (this.params.vin ? ' ' + this.params.vin : '') + '.' +  this.excel.type)
		},

		id() {
			return this.item.hasOwnProperty('id') ? this.item.id: null;
		},

		estimates() {
			return this.item.hasOwnProperty('estimates') ? this.item.estimates: [];
		},
	},

	methods: {
		...mapActions('VehicleEstimate', [
			'setItem', 'clear'
		]),

		onClear() {
			//this.$refs.parameters.clear()
			this.clear()
		},

		validate() {
			//return true
			return this.params.number 
				|| this.params.vin
				|| this.params.brand
				|| this.params.model
		},

		onOK() {
			if(this.validate()) {
				this.loading = true
				var that = this
				axios.post('/vehicle/estimate/averageprice/combo', this.params).then(response => {
					//console.log(that.$options.name, 'onOK','response.data', response.data)
					that.setItem(response.data)
				}, error => {
					console.log('error', error)

					that.snackbar.visible 	= true;
					that.snackbar.text 		= error.response.data.message;
					that.snackbar.color 	= null;
				}).finally(() => {
					this.loading = false
				});
			} else {
				this.snackbar.visible 	= true;
				this.snackbar.text 		= 'Відсутні дані для пошуку';
				this.snackbar.color 	= null;
			}
		},

		onPDF () {
			this.$refs.pdf.generate()
		},
	},
}
</script>