<template>
	<v-hover v-slot:default="{ hover }">
		<v-card 
			:elevation="hover ? 3 : 1"
			:class="{ 'on-hover': hover }"
			class="mx-auto"
			outlined
			:to="to"
			shaped
		>

		<!-- :to="{name: router}" -->
		<!-- height="100%" -->
		<!-- class="ma-2 d-flex flex-column" -->
			<v-list-item three-line>
				<v-list-item-content>
					<div class="text-overline mb-4">
						{{ overline }}
					</div>
					<v-list-item-title class="text-h5 mb-1">
						{{ title }}
					</v-list-item-title>
					<v-list-item-subtitle>{{ subtitle }}</v-list-item-subtitle>
				</v-list-item-content>

				<v-list-item-avatar
					tile_
					size="80"
					:color="color"
				>
					<v-icon dark x-large>{{ icon }}</v-icon>
				</v-list-item-avatar>
			</v-list-item>

			<!-- <v-card-actions>
				<v-spacer></v-spacer>
				<v-btn text>
					Калькулятор
				</v-btn>
			</v-card-actions> -->
		</v-card>
	</v-hover>
</template>

<script>

export default {
	data: () => ({
		
	}),
	props: {
		title: 			{ type: String, default: null },
		subtitle: 		{ type: String, default: null },
		description: 	{ type: String, default: null },
		overline: 		{ type: String, default: null },
		img: {
			type: String,
			default: 'https://cdn.vuetifyjs.com/images/cards/docks.jpg'
		},
		icon: 			{ type: String, default: 'icon' },
		color: 			{ type: String, default: 'primary' },
		router: 		{ type: String, default: null },
	},
	computed: {
		
		to() {
			//console.log(this.router)

			return {name: this.router}
		}
	},
	watch: {
		
	},
	methods: {
	
	},
}
</script>