<template>
	<v-form ref="form">
		<!-- Фактори, що впливають на збільшення вартості КТЗ -->
		<v-card elevation="0">
			<v-card-title class="text-subtitle-2 font-weight-bold">Фактори, що впливають на збільшення вартості КТЗ</v-card-title>
			<v-card-text>
				<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.no_corrosion"
					hint="Для строку експлуатації не менше 7 - 12 років"
				>
					<template v-slot:label>
						<span class="text-caption">Відсутність корозійних пошкоджень складових частин кузова</span>
					</template>
				</v-checkbox>
				<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.no_damage_paint"
					hint="Для строку експлуатації не менше 7 - 12 років"
				>
					<template v-slot:label>
						<span class="text-caption">Відсутність пошкоджень лакофарбового покриття за умови, що відновлювальний ремонт кузова не виконували</span>
					</template>
				</v-checkbox>
				<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.engine_repair"
					hint="Для строку експлуатації не менше 7 - 12 років"
				>
					<template v-slot:label>
						<span class="text-caption">Виконано капітальний ремонт двигуна не більше як за 1 рік до дати оцінки</span>
					</template>
				</v-checkbox>
				<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.body_repair"
					hint="Для строку експлуатації не менше 7 - 12 років"
				>
					<template v-slot:label>
						<span class="text-caption">Виконано капітальний ремонт кузова з повним пофарбуванням не більше як за 3 роки до дати оцінки</span>
					</template>
				</v-checkbox>
				<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.body_replacement"
					hint="Для строку експлуатації не менше 7 - 12 років"
				>
					<template v-slot:label>
						<span class="text-caption">Виконано заміну кузова на новий не більше як за 5 років до дати оцінки</span>
					</template>
				</v-checkbox>
			</v-card-text>
		</v-card>

		<!-- Фактори, що впливають на зменшення вартості КТЗ -->
		<v-card elevation="0">
			<v-card-title class="text-subtitle-2 font-weight-bold">Фактори, що впливають на зменшення вартості КТЗ</v-card-title>
			<v-card-text>
				<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.taxi">
					<template v-slot:label>
						<span class="text-caption">КТЗ експлуатувався в режимі таксі</span>
					</template>
				</v-checkbox>
				<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.specialized" >
					<template v-slot:label>
						<span class="text-caption">КТЗ спеціалізованого призначення</span>
					</template>
				</v-checkbox>
				<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.outside_public_roads">
					<template v-slot:label>
						<span class="text-caption">КТЗ застосовувався поза дорогами загального користування</span>
					</template>
				</v-checkbox>

				<v-radio-group v-model="item.use.body_parts_repair">
					<template v-slot:label>
						<div>Кузовні складові КТЗ відновлювалися ремонтом</div>
					</template>
					<v-radio value="3">
						<template v-slot:label>
							<div class="text-caption">У разі відновлення трьох і більше складових кузова</div>
						</template>
					</v-radio>
					<v-radio value="2">
						<template v-slot:label>
							<div class="text-caption">У разі відновлення не більше двох складових кузова</div>
						</template>
					</v-radio>
					<v-radio value="0">
						<template v-slot:label>
							<div class="text-caption">Не відновлювалися</div>
						</template>
					</v-radio>
				</v-radio-group>

				<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.parts_need_repair"
					hint="Сліди підтікання олив та пального; підвищена димність і шумність двигуна, порушення функціональних можливостей тощо"
				>
					<template v-slot:label>
						<span class="text-caption">КТЗ має складові частини, які потребують ремонту (окрім заміни чи капітального ремонту складової)</span>
					</template>
				</v-checkbox>

				<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.color_not_demand" >
					<template v-slot:label>
						<span class="text-caption">КТЗ пофарбовано в колір, який не користується попитом</span>
					</template>
				</v-checkbox>
			</v-card-text>
		</v-card>

		<!-- Елементи додаткового зменшення вартості КТЗ з дефектами кузова, кабіни, рами(1)  -->

		<v-card elevation="0">
			<v-card-title class="text-subtitle-2 font-weight-bold">Елементи додаткового зменшення вартості КТЗ з дефектами кузова, кабіни, рами</v-card-title>
			<!-- Вибирається з таблиці 4.2 -->
			<v-card elevation="0">
				<v-card-title class="text-subtitle-2">ЕЛЕМЕНТИ КУЗОВА (пошкодження корозією)</v-card-title>
				<v-card-text>
					<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.body_floor_panel" >
						<template v-slot:label>
							<span class="text-caption">Панель підлоги кузова, кабіни</span>
						</template>
					</v-checkbox>
					<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.box_elements" >
						<template v-slot:label>
							<span class="text-caption">Коробчасті елементи збільшення жорсткості (лонжерони, поперечини, підсилювачі, підмоторна рама)</span>
						</template>
					</v-checkbox>
					<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.body_sills" >
						<template v-slot:label>
							<span class="text-caption">Пороги кузова</span>
						</template>
					</v-checkbox>

					<!-- 4 -->
					<!-- <v-subheader>Передок кузова, кабіни:</v-subheader> -->
					<v-card elevation="0">
						<v-card-title class="text-subtitle-2">Передок кузова, кабіни:</v-card-title> <!-- body_front -->
						<v-card-text>
							<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.fenders_front" >
								<template v-slot:label>
									<span class="text-caption">бризковики передніх крил</span>
								</template>
							</v-checkbox>
							<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.shield_front" >
								<template v-slot:label>
									<span class="text-caption">щиток передка</span>
								</template>
							</v-checkbox>
							<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.panels_front" >
								<template v-slot:label>
									<span class="text-caption">панелі передка (полиці щитків радіатора)</span>
								</template>
							</v-checkbox>
							<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.fenders_radiator" >
								<template v-slot:label>
									<span class="text-caption">бризковик облицювання радіатора</span>
								</template>
							</v-checkbox>
						</v-card-text>
					</v-card>

					<!-- 5 -->
					<v-card elevation="0">
						<v-card-title class="text-subtitle-2">Боковина кузова, кабіни:</v-card-title>
						<v-card-text>
							<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.risers_sides" >
								<template v-slot:label>
									<span class="text-caption">стояки боковин</span>
								</template>
							</v-checkbox>
							<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.arches_sides" >
								<template v-slot:label>
									<span class="text-caption">арки боковин (бризковики боковин)</span>
								</template>
							</v-checkbox>
							<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.panels_sides" >
								<template v-slot:label>
									<span class="text-caption">панелі боковин</span>
								</template>
							</v-checkbox>
							<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.doors" >
								<template v-slot:label>
									<span class="text-caption">Двері</span>
								</template>
							</v-checkbox>
						</v-card-text>
					</v-card>

					<!-- 6 -->
					<v-card elevation="0">
						<v-card-title class="text-subtitle-2">Задок кузова, кабіни:</v-card-title>
						<v-card-text>
							<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.panels_back" >
								<template v-slot:label>
									<span class="text-caption">панелі задка</span>
								</template>
							</v-checkbox>
							<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.shelves_back" >
								<template v-slot:label>
									<span class="text-caption">полиці задка з перегородкою (стінка моторного відсіку)</span>
								</template>
							</v-checkbox>
						</v-card-text>
					</v-card>

					<!-- 7 -->
					<v-card elevation="0">
						<v-card-title class="text-subtitle-2">Дах кузова, кабіни:</v-card-title>
						<v-card-text>
							<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.panel_roof" >
								<template v-slot:label>
									<span class="text-caption">панель даху</span>
								</template>
							</v-checkbox>
							<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.panel_side_back" >
								<template v-slot:label>
									<span class="text-caption">панель бокова задня</span>
								</template>
							</v-checkbox>
						</v-card-text>
					</v-card>

					<!-- 8 -->
					<v-card elevation="0">
						<v-card-title class="text-subtitle-2">Оперення:</v-card-title>
						<v-card-text>
							<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.wing_removable" >
								<template v-slot:label>
									<span class="text-caption">крило знімне</span>
								</template>
							</v-checkbox>
							<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.wing_unremovable" >
								<template v-slot:label>
									<span class="text-caption">крило незнімне</span>
								</template>
							</v-checkbox>
							<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.bonnet" >
								<template v-slot:label>
									<span class="text-caption">капот</span>
								</template>
							</v-checkbox>
							<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.boot_lid" >
								<template v-slot:label>
									<span class="text-caption">кришка багажника</span>
								</template>
							</v-checkbox>
						</v-card-text>
					</v-card>
				</v-card-text>
			</v-card>

			<v-card elevation="0">
				<v-card-title class="text-subtitle-2">ЕЛЕМЕНТИ КУЗОВА, КАБІНИ, РАМИ (деформація)</v-card-title>
				<v-card-text>
					<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.deformations_no_paint" >
						<template v-slot:label>
							<span class="text-caption">Деформації без пошкодження лакофарбового покриття</span>
						</template>
					</v-checkbox>
					<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.body_skew" >
						<template v-slot:label>
							<span class="text-caption">Інформативні ознаки наявності перекосу кузова чи необхідності правки рами КТЗ (окрім випадків, що передбачають складання калькуляції відновлювального ремонту аварійно пошкодженого КТЗ)</span>
						</template>
					</v-checkbox>
				</v-card-text>
			</v-card>

			<v-card elevation="0">
				<v-card-title class="text-subtitle-2">ОББИВКА КУЗОВА, КАБІНИ (забруднення, пошкодження, потертості)</v-card-title>
				<v-card-text>
					<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.upholstery_saloon" >
						<template v-slot:label>
							<span class="text-caption">Оббивка салону (даху, стояків боковин, полиць, дверей)</span>
						</template>
					</v-checkbox>
					<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.upholstery_sits" >
						<template v-slot:label>
							<span class="text-caption">Оббивка сидінь</span>
						</template>
					</v-checkbox>
				</v-card-text>
			</v-card>

			<v-card elevation="0">
				<v-card-title class="text-subtitle-2">ПОФАРБУВАННЯ КУЗОВА, КАБІНИ (дефекти лакофарбового покриття)</v-card-title>
				<v-card-text>
					<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.body_painting" >
						<template v-slot:label>
							<span class="text-caption">Пофарбування кузова</span>
						</template>
					</v-checkbox>
				</v-card-text>
			</v-card>

			<v-card elevation="0">
				<v-card-title class="text-subtitle-2">ХРОМОВАНІ ДЕТАЛІ (корозія, потьмяніння, відшарування)</v-card-title>
				<v-card-text>
					<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.chrome_plating" >
						<template v-slot:label>
							<span class="text-caption">Хромовані покриття</span>
						</template>
					</v-checkbox>
				</v-card-text>
			</v-card>

			<v-card elevation="0">
				<v-card-title class="text-subtitle-2">СКЛО (потертості, пошкодження)</v-card-title>
				<v-card-text>
					<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.glass" >
						<template v-slot:label>
							<span class="text-caption">Скло</span>
						</template>
					</v-checkbox>
				</v-card-text>
			</v-card>
		</v-card>
		<!-- ------------------------------------------------------------------
		| N  |   Найменування елементів,   |Допустиме найбільше зниження |
		|з/п |    складових частин КТЗ(2)  |     вартості(3), Дз, %      |
		|    |                             |                             |
		|----------------------------------------------------------------|
		|            ЕЛЕМЕНТИ КУЗОВА(4) (пошкодження корозією)           |
		|----------------------------------------------------------------|
		|  1 |Панель підлоги кузова, кабіни|             4,0             |
		|----+-----------------------------+-----------------------------|
		|  2 |Коробчасті елементи          |             6,0             |
		|    |збільшення жорсткості        |                             |
		|    |(лонжерони, поперечини,      |                             |
		|    |підсилювачі, підмоторна      |                             |
		|    |рама)                        |                             |
		|----+-----------------------------+-----------------------------|
		|  3 |Пороги кузова                |             1,0             |
		|----+-----------------------------+-----------------------------|
		|  4 |Передок кузова, кабіни:      |                             |
		|----|                             |                             |
		|4.1 |бризковики передніх крил     |             2,0             |
		|----+-----------------------------+-----------------------------|
		|4.2 |щиток передка                |             3,0             |
		|----+-----------------------------+-----------------------------|
		|4.3 |панелі передка (полиці       |             1,0             |
		|    |щитків радіатора)            |                             |
		|----+-----------------------------+-----------------------------|
		|4.4 |бризковик облицювання        |             0,3             |
		|    |радіатора                    |                             |
		|----+-----------------------------+-----------------------------|
		|  5 |Боковина кузова, кабіни:     |                             |
		|----|                             |                             |
		|5.1 |стояки боковин               |             4,0             |
		|----+-----------------------------+-----------------------------|
		|5.2 |арки боковин (бризковики     |             2,0             |
		|    |боковин)                     |                             |
		|----+-----------------------------+-----------------------------|
		|5.3 |панелі боковин               |             2,0             |
		|----+-----------------------------+-----------------------------|
		|5.4 |Двері                        |             0,5             |
		|----+-----------------------------+-----------------------------|
		|  6 |Задок кузова, кабіни:        |                             |
		|----|                             |                             |
		|6.1 |панелі задка                 |             0,5             |
		|----+-----------------------------+-----------------------------|
		|6.2 |полиці задка з перегородкою  |             0,5             |
		|    |(стінка моторного відсіку)   |                             |
		|----+-----------------------------+-----------------------------|
		|  7 |Дах кузова, кабіни:          |                             |
		|----|                             |                             |
		|7.1 |панель даху                  |             1,0             |
		|----+-----------------------------+-----------------------------|
		|7.2 |панель бокова задня          |             0,3             |
		------------------------------------------------------------------ 


		------------------------------------------------------------------
		| N  |Найменування елементів,      |Допустиме найбільше зниження |
		|з/п |складових частин КТЗ(2)      |вартості(3), Дз, %           |
		|----+-----------------------------+-----------------------------|
		|  8 |Оперення:                    |                             |
		|----|                             |                             |
		|8.1 |крило знімне                 |             0,5             |
		|----+-----------------------------+-----------------------------|
		|8.2 |крило незнімне               |             1,0             |
		|----+-----------------------------+-----------------------------|
		|8.3 |капот                        |             0,5             |
		|----+-----------------------------+-----------------------------|
		|8.4 |кришка багажника             |             0,5             |
		|----------------------------------------------------------------|
		|          ЕЛЕМЕНТИ КУЗОВА, КАБІНИ, РАМИ(5) (деформація)         |
		|----------------------------------------------------------------|
		|  9 |Деформації без пошкодження   |              5              |
		|    |лакофарбового покриття       |                             |
		|----+-----------------------------+-----------------------------|
		| 10 |Інформативні ознаки наявності|              15             |
		|    |перекосу кузова чи           |                             |
		|    |необхідності правки рами КТЗ |                             |
		|    |(окрім випадків, що          |                             |
		|    |передбачають складання       |                             |
		|    |калькуляції відновлювального |                             |
		|    |ремонту аварійно пошкодженого|                             |
		|    |КТЗ)                         |                             |
		|----------------------------------------------------------------|
		|ОББИВКА КУЗОВА, КАБІНИ(5) (забруднення, пошкодження, потертості)|
		|----------------------------------------------------------------|
		| 11 |Оббивка салону (даху, стояків|             1,0             |
		|    |боковин, полиць, дверей)     |                             |
		|----+-----------------------------+-----------------------------|
		| 12 |Оббивка сидінь               |             2,0             |
		|----------------------------------------------------------------|
		|ПОФАРБУВАННЯ КУЗОВА, КАБІНИ(5) (дефекти лакофарбового покриття) |
		|----------------------------------------------------------------|
		| 13 |Пофарбування кузова          |             3,0             |
		|----------------------------------------------------------------|
		|     ХРОМОВАНІ ДЕТАЛІ(5) (корозія, потьмяніння, відшарування)   |
		|----------------------------------------------------------------|
		| 14 |Хромовані покриття           |             3,0             |
		|----------------------------------------------------------------|
		|                СКЛО(5) (потертості, пошкодження)               |
		|----------------------------------------------------------------|
		| 15 |Скло                         |             0,5             |
		------------------------------------------------------------------  -->

	</v-form>
</template>

<script>
export default {
	data () {
		return {
			
		}
	},
	props: {
		module:	{ type: String, default: 'VehicleEstimate'}, // check | market | metodica | exploration | averageprice
	},

	computed: {
		item: function () {
			return this.$store.getters[`${this.module}/params`]
		},
	},
}
</script>