<template>
	<v-main>
        <v-container>
			<v-data-table
				v-model="selected"
				:headers="headers"
				:items="items"
				item-key="id"
				show-select
				:loading="loading"
				loading-text="Завантаження даних... зачекайте, будь ласка." 
				class="mt-16"
			>
				<template v-slot:top>
					<v-toolbar flat>
						<v-toolbar-title>{{ $vuetify.lang.t('$vuetify.' + title) | capitalize }}</v-toolbar-title>
						<v-spacer></v-spacer>

						<!-- Оновити -->
						<v-btn icon @click="onRefresh">
							<v-icon>mdi-refresh</v-icon>
						</v-btn>

						<!-- Пошук -->
						<!-- <v-btn icon @click="OnSearch">
							<v-icon>mdi-magnify</v-icon>
						</v-btn> -->

						<!-- Створити -->
						<z-detail
							activator-type="button"
							activator-outlined
							:activator-title="$vuetify.lang.t('$vuetify.create')"
							activator-tooltip-disabled
						></z-detail>

					</v-toolbar>
				</template>

				<template v-slot:[`item.actions`]="{ item }">
					<v-toolbar elevation="0" dense color="transparent">
						<z-detail
							mode="update"
							:selected="item"
							activator-type="icon"
							activator-icon="mdi-pencil"
							:activator-tooltip-text="$vuetify.lang.t('$vuetify.update')"
						></z-detail>
						<z-confirm-delete 
							:activator-tooltip-text="$vuetify.lang.t('$vuetify.delete')"
							@ok="onDelete(item)"
						></z-confirm-delete>
					</v-toolbar>

				</template>
			</v-data-table>
		</v-container>
		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
    </v-main>
</template>

<script>
import mixin from './mixin/main'
import { mapGetters, mapActions } from 'vuex'
import downloadExcel from "vue-json-excel"
import zDetail from '@/views/details/Services'
import zConfirmDelete from '@/views/components/confirm-delete'

export default {
	mixins: [mixin],
	name: 'Services',
	description: 'Сервіси',
	components: {
		downloadExcel,
		zDetail,
		zConfirmDelete
	},
	data: () => ({
		title: 'services',

		headers: [
			//{ text: 'ID', value: 'id', align: ' d-none' },
			{ text: 'ID', value: 'id', sortable: true},
			{ text: 'Назва', value: 'name', sortable: true},
			{ text: 'Actions', value: 'actions', sortable: false },
		],
	}),
	
	computed: {
		...mapGetters('Services', [ 'endpoint', 'items' ]),
	},

	methods: {
		...mapActions('Services', [ 'setItem', 'setItems', 'createItem', 'updateItem', 'deleteItem' ]),
	},
}
</script>