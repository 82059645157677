<template>
	<v-dialog  
	 	v-model="dialog"
	 	fullscreen
		transition_="dialog-bottom-transition"
		scrollable
	>
		<!-- Актіватор -->
		<template v-slot:activator="{  on, attrs }">
			<slot name="activator">
				<v-btn icon v-bind="attrs" v-on="on">
					<v-icon>mdi-eye</v-icon>
				</v-btn>
			</slot>
		</template>

		<v-card color="#EEEEEE">
			<!-- Ttoolbar -->
			<v-toolbar flat color="#f5f5f5" style="flex: 0 1 auto;">
				<v-avatar>
					<v-icon>{{ icon }}</v-icon>
				</v-avatar>
				<v-toolbar-title>{{ title }}</v-toolbar-title>
				<!-- <v-toolbar-subtitle>{{ title }}</v-toolbar-subtitle> -->
				<!-- <v-toolbar-title>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>{{ title }}</v-list-item-title>
							<v-list-item-subtitle>№{{ item.id }} {{ item.datecreate | formatDateTime }}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-toolbar-title> -->

				<v-spacer></v-spacer>

				<!-- <z-detail-script v-if="permitionScript" :item="item"></z-detail-script> -->

				<v-btn icon @click="dialog=false" >
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>

			<v-card-text>
				<v-container>
					<v-sheet color="white" elevation="1">
						<v-toolbar flat>
							<v-toolbar-title>{{ item.type.name }}</v-toolbar-title>
							<v-spacer></v-spacer>
							<v-toolbar-title>{{ item.id ? '№ ' + item.id: '' }}</v-toolbar-title>
						</v-toolbar>
						<v-card flat>
							<v-card-text>
								<v-simple-table dense>
									<template v-slot:default>
										<tbody>
											<tr>
												<td>Тип</td>
												<td>{{ item.type.name }}</td>
											</tr>
											<tr>
												<td>Дата</td>
												<td>{{ item.date | formatDate }}</td>
											</tr>
											<tr>
												<td>Дата початку періоду</td>
												<td>{{ item.datebegin | formatDate }}</td>
											</tr>
											<tr>
												<td>Дата завершення періоду</td>
												<td>{{ item.dateend | formatDate }}</td>
											</tr>
											<tr>
												<td>Користувач</td>
												<td>{{ item.user.name }}</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-card-text>
						</v-card>

						<v-card flat>
							<v-card-title>Об'єкти оцінки</v-card-title>
							<!-- class="text-h5" -->
							<!-- <v-card-subtitle>{{ subtitle }}</v-card-subtitle> -->

							<!-- <v-divider></v-divider> -->

							<v-card-text>
								<v-data-table
									:headers="headers"
									:items="item.objects"
									item-key="id"
									loading-text="Завантаження даних... зачекайте, будь ласка."
									hide-default-footer
								>
									<template v-slot:[`item.priceMin`]="{ item }">
										<span>{{ item.priceMin | toCurrencyUAH }}</span>
									</template>

									<template v-slot:[`item.priceMax`]="{ item }">
										<span>{{ item.priceMax | toCurrencyUAH }}</span>
									</template>

									<template v-slot:[`item.priceAvg`]="{ item }">
										<span>{{ item.priceAvg | toCurrencyUAH }}</span>
									</template>

									<template v-slot:[`item.actions`]="{ item }">
										<v-toolbar elevation="0" dense color="transparent">
											<!-- <z-statistic v-if="item.priceCount" :item="item"></z-statistic> -->
										</v-toolbar>
									</template>

									<template slot="body.append">
										<!-- <tr class="pink--text"> -->
										<tr>
											<th class="title"></th>
											<th class="font-weight-bold">{{ sumField('priceMin') | toCurrencyUAH }}</th>
											<th class="font-weight-bold">{{ sumField('priceAvg') | toCurrencyUAH }}</th>
											<th class="font-weight-bold">{{ sumField('priceMax') | toCurrencyUAH }}</th>
											<th class="font-weight-bold">{{ sumField('priceCount') }}</th>
										</tr>
									</template>
								</v-data-table>
							</v-card-text>
						</v-card>
					</v-sheet>
				</v-container>
				
			</v-card-text>
		</v-card>

		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
	</v-dialog>
</template>

<script>
import axios from 'axios'
//import zStatistic from '@/views/DocumentObjectStatistic'

export default {
	name: 'DocumentDetailView',
	description: 'Форма для перегляду інформації оцінки без можливості редагування',
	components: {
		//zStatistic,
	},
	data: () => ({
		dialog: false,
		loading: false,
	
		item: {
			type: {},
			objects: [],
			user: {}
		},

		headers: [
			//{ text: 'ID', value: 'id', sortable: true, width: '50' },
			{ text: 'Назва', value: 'name', sortable: true },
			{ text: 'Мінімальна ціна', value: 'priceMin', sortable: true },
			{ text: 'Середня ціна', value: 'priceAvg', sortable: true },
			{ text: 'Максимальна ціна', value: 'priceMax', sortable: true },
			{ text: 'Кількість цін в оцінці', value: 'priceCount', sortable: true },
			{ text: '', value: 'actions', sortable: false },
		],
	}),

	props: {
		icon:   		{ type: String, default: 'mdi-home-analytics' },
		title:   		{ type: String, default: 'Оцінка' },
		//item: 					{ type: Object, default: {} },
		selected: 					{ type: Object, default: {} },
	},

	computed: {
		//...mapGetters(['roles']),
		//...mapGetters('Roles', [ 'endpoint', 'item' ]),
		
		/* filteredUsers() {
			return this.item.users.filter(item => {
				return item.id==this.searchUsers
					|| item.name.toLowerCase().includes(this.searchUsers.toLowerCase())
			})
		}, */

		/* filteredServices() {
			return this.item.services.filter(item => {
				return item.id==this.searchServices
					|| item.name.toLowerCase().includes(this.searchServices.toLowerCase())
			})
		}, */

		/* item() {
			return this.selected
		},

		item() {
			return this.selected
		},

		this.title */

		/* title() {
			return this.item?.type?.name
		}, */

		estimate() {
			return this.item ? this.item: {};
		},

		/* objects() {
			return this.item.hasOwnProperty('objects') ? this.item.objects: [];
		}, */		
	},

	watch: {
		dialog: function (val) {
			if(val) {
				this.find()
			}
		},
	},

	methods: {
		/**
		 * Пошук
		 */
		 find () {
			if(this.selected?.id) {
				this.loading = true;
				var that = this
				axios.get(`/estimate/${this.selected.id}`).then(response => {
					//console.log(that.$options.name, 'find','response.data', response.data)
					that.item = Object.assign({}, response.data)
				}, error => {
					console.log(error)
				}).finally(() => {
					this.loading = false
				});
			}
		},

		sumField(key) {
			return this.item.objects.reduce((a, b) => a + (b[key] || 0), 0)
		},
	},
}
</script>