<template>
	<v-form ref="form">
		<v-row dense>
			<v-col cols="12">
				<v-combobox
					v-model="item.category"
					:items="categories"
					label="Категорія"
					outlined
					dense
					item-text="name"
					item-value="id"
					hide-details="auto"
					:loading="loading.category"
					@focus="loadCategories"
				></v-combobox>
			</v-col>
			<v-col cols="12">
				<v-combobox
					v-model="item.type"
					:items="types"
					label="Тип"
					outlined
					dense
					item-text="name"
					item-value="id"
					hide-details="auto"
					:loading="loading.type"
					@focus="loadTypes"
				></v-combobox>
			</v-col>
			<v-col cols="12">
				<v-combobox
					v-model="item.operation"
					:items="operations"
					label="Тип операції"
					outlined
					dense
					item-text="name"
					item-value="id"
					hide-details="auto"
					:loading="loading.operation"
					@focus="loadOperations"
				></v-combobox>
			</v-col>
			<v-col cols="12">
				<v-combobox
					v-model="item.state"
					:items="states"
					label="Область"
					outlined
					dense
					item-text="name"
					item-value="id"
					hide-details="auto"
					:loading="loading.state"
					@change="onState"
					@focus="loadStates"
				></v-combobox>
			</v-col>
			<v-col cols="12">
				<v-combobox
					v-model="item.city"
					:items="cities"
					label="Місто"
					outlined
					dense
					item-text="name"
					item-value="id"
					hide-details="auto"
					:loading="loading.city"
					@focus="loadCities"
				></v-combobox>
			</v-col>
			<v-col cols="12">
				<v-combobox
					v-model="item.district"
					:items="districts"
					label="Район"
					outlined
					dense
					item-text="name"
					item-value="id"
					hide-details="auto"
					:loading="loading.district"
					@focus="loadDistricts"
				></v-combobox>
			</v-col>

			<v-col cols="12">
				<z-date 
					v-model="item.dateFrom"
					label="Дата видачі від"
					outlined
					dense
					clirable
					hide-details
				></z-date>
			</v-col>
			<v-col cols="12">
				<z-date
					v-model="item.dateTo"
					label="Дата видачі до"
					outlined
					dense
					clirable
					hide-details
				></z-date>
			</v-col>

			<v-col cols="12">
				<v-text-field
					v-model="item.area"
					label="Площа"
					outlined
					dense
					type="number"
					hide-details="auto"
					:min="minArea"
					:max="maxArea"
				></v-text-field>
			</v-col>
			<v-col cols="12">
				<v-text-field
					v-model="item.roomsCount" 
					label="Кількість кімнат"
					outlined
					dense
					type="number"
					hide-details="auto"
					:min="minRoomsСount"
					:max="maxRoomsСount"
				></v-text-field>
			</v-col>
		</v-row>
	</v-form>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import zDate from '@/views/components/control/Date'

export default {
	name: 'RealtyAveragePrice',
	components: {
		zDate,
	},
	data: () => ({
		minArea: 0,
		maxArea: 1000,
		minRoomsСount: 1,
		maxRoomsСount: 10,

		loading: {
			category: false,
			type: false,
			operation: false,
			state: false,
			city: false,
			district: false,
		},
	}),

	props: {
		module:			{ type: String, default: 'RealtyEstimate'}, // check | market | metodica | exploration | averageprice
	},

	computed: {
		...mapGetters('app', ['profile']),
		...mapGetters(['roles','countries', 'states']),
		...mapGetters('State', { 'states': 'items'}),
		...mapGetters('City', { 'cities': 'items'}),
		...mapGetters('CityDistrict', { 'districts': 'items'}),
		...mapGetters('RealtyCategory', { 'categories': 'items'}),
		...mapGetters('RealtyOperationType', { 'operations': 'items'}),
		...mapGetters('RealtyType', { 'types': 'items'}),
		//...mapGetters('RealtyEstimate', ['item']),
		...mapGetters('RealtyEstimate', {'item': 'params'}),
	},

	watch: {
		/* 'item.brand': function (val) {
			if(val) {
				this.Models({brand: val.id})
			}
		}, */
		/* item: function (val) {
			if(val) {
				console.log('Realty.estimate.detail', 'watch', 'item', val)
			}
		}, */
	},

	created () {
		//console.log(this.$options.name, 'created')
		//this.item.year 			= [this.minYear, this.maxYear]
		//this.item.mileage		= [this.minMileage, this.maxMileage]
		//this.item.engineVolume	= [this.minEngineVolume, this.maxEngineVolume]
		//this.item.period		= 365

		//this.item.damage = this.damages[0];
		//this.item.period = this.periods[3];

		
		//this.loadStates()
		//this.loadCategories()
		//this.loadOperations()
		//this.loadTypes()
	},
	methods: {
		...mapActions(['setCountries', 'setStates']),
		...mapActions('State', { loadStates: 'load', setStats: 'setItems'} ),
		...mapActions('City', { loadCities: 'load', setCities: 'setItems'} ),
		...mapActions('CityDistrict', { loadDistricts: 'load', setDistricts: 'setItems'} ),
		...mapActions('RealtyCategory', { loadCategories: 'load', setCategories: 'setItems'} ),
		...mapActions('RealtyOperationType', { loadOperations: 'load', setOperations: 'setItems'} ),
		...mapActions('RealtyType', { loadTypes: 'load', setTypes: 'setItems'} ),
		
		clearOperation () {
			this.$set(this.item, 'operation', null)
			this.setOperations()
		},

		clearCity () {
			this.$set(this.item, 'city', null)
			this.setCities()
		},

		clearDistrict () {
			this.$set(this.item, 'district', null)
			this.setDistricts()
		},

		// Завантаження міст
		loadCities() {
			if(this.item?.state?.id && this.cities.length == 0) {
				this.loading.city = true
				var that = this
				axios.get(`/state/${this.item.state.id}/city`).then(response => {
					//console.log(that.$options.name, 'created','response.data', response.data)
					that.setCities(response.data)
				}, error => {
					console.log('error', error)
				}).finally(() => (this.loading.city = false));
			}
		},

		// Завантаження районів
		loadDistricts() {
			if(this.item?.city?.id && this.districts.length == 0) {
				this.loading.district = true
				var that = this
				axios.get(`/city/${this.item.city.id}/district`).then(response => {
					//console.log(that.$options.name, 'created','response.data', response.data)
					that.setDistricts(response.data)
				}, error => {
					console.log('error', error)
				}).finally(() => (this.loading.district = false));
			}
		},

		onState (item) {
			this.clearCity()
			this.clearDistrict()
		},

		onCity (item) {
			this.clearDistrict()
		},

		onUpgrateModel() {
			if(this.item?.city?.id) {
				//console.log(that.$options.name, 'onUpgrateModel', 'category', this.item.category.id, 'brand', this.item.brand.id)
				var that = this
				axios.post(`/city/${this.item.city.id}/district/upgrate`).then(response => {
					//console.log(that.$options.name, 'onUpgrateModel','response.data', response.data)
					that.setDistricts(response.data)
				}, error => {
					console.log('error', error)
				});
			}
		},
	},
}
</script>