import mixin from '@/views/mixin/api'
export default {
    mixins: [mixin],
	data: () => ({
		title: 'services',
		icon: 'mdi-account-tie',
		dialog: false,
		fullscreen: false,
		loading: false,
		tooltipDisabled: false,

		selected: [],
		search: '',

		sortAlphabeticalAscending: true,
		viewList: true,
	}),
	props: {
		disabled:   	{ type: Boolean, default: false }, //Ознака неактивного елементу
		activatorType:  { type: String, default: 'button-icon'}, // Тип активатора вікна: button-icon | button | icon | menu-item | list-item
		activatorIcon:  { type: String, default: 'mdi-plus'},
		multiple:   	{ type: Boolean, default: false }, //
		excluded:		{ type: Array, default: () => [] }, // виключчити з переліку
	},

	watch: {
		dialog: function (val) {
            if(val) {
				this.onOpen()
            } else {
				this.onClose()
			}
        },
	},

	computed: {
		filtered() {
			this.selected = []
			let result = this.items.filter(item => {
				return (item.id==this.search
					|| item.name.toLowerCase().includes(this.search.toLowerCase())
					) && !this.excluded.some(t => {
						return item.id==t.id
					}) 
			}).sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0))

			//console.log('Services', 'filtered','result', result)

			return this.sortAlphabeticalAscending ? result : result.reverse()
		},
	},

	methods: {
        onRefresh() {
			this.findAll()
		},

		onOpen () {
			if(this.items.length == 0) {
				this.onRefresh()
			}
		},

		onClose () {
			this.selected = []
			this.search = ''
		},

		onCancel () {
			this.dialog = false
		},

		onOK () {
			//console.log('Services', 'onOK', 'selected', this.selected)

			let result = []
			if(Array.isArray(this.selected)) {
                for(var i in this.selected) {
                    result.push(this.filtered[this.selected[i]]);
                }
            } else {
                result.push(this.filtered[this.selected]);
            }

			//console.log('Services', 'onOK', 'result', result)

			this.dialog = false
			this.$emit('ok', result)
		},

		onSelectAll(data) {
			//console.log('Services', 'onSelectAll', 'data', data)
			if(data) {
				this.selected = Array.from(Array(this.filtered.length).keys())
			} else {
				this.selected = []
			}
		},
	},
}