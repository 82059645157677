<template>
	<v-main>
        <v-container>
            <v-toolbar elevation="0">
				<!-- <v-btn icon color="primary" @click="$router.push('/')">
					<v-icon>mdi-home</v-icon>
				</v-btn> -->
				<!-- <v-toolbar-title class="font-weight-light">{{ $vuetify.lang.t('$vuetify.' + title) | capitalize }}</v-toolbar-title> -->
				<v-toolbar-title>{{ title | capitalize }}</v-toolbar-title>
				<v-spacer></v-spacer>
			</v-toolbar>

			<v-data-table
				:headers="headers"
				:items="items"
				item-key="id"
				:loading="loading"
				loading-text="Завантаження даних... зачекайте, будь ласка." 
			>
			<!-- :search="search"
				:custom-filter="customFilter" -->
				<template v-slot:top>
					<v-toolbar flat>
						<!-- <v-toolbar-title>
							{{ (title in $vuetify.lang.locales.en ? $vuetify.lang.t('$vuetify.' + title) : title ) | capitalize }}
						</v-toolbar-title> -->

						<!-- <v-divider class="mx-4" inset vertical ></v-divider> -->

						<v-spacer></v-spacer>

						<!-- <z-dialog-compare
							:selected="selected"
						></z-dialog-compare> -->
						<v-btn icon @click.stop="onRefresh">
							<v-icon>mdi-refresh</v-icon>
						</v-btn>

						<!-- Пошук -->
						<!-- <z-search v-model="search"></z-search> -->
					</v-toolbar>
				</template>

				<template v-slot:[`item.actions`]="{ item }">
					<v-toolbar elevation="0" dense color="transparent">
						<!-- PDF -->
						<!-- <v-icon color="red" @click="onPDF(item)">mdi-file-pdf-box</v-icon> -->

						<z-detail :selected="item" ></z-detail>

						<!-- activator-type="button"
							activator-outlined
							:activator-title="$vuetify.lang.t('$vuetify.create')"
							activator-tooltip-disabled -->
						<!-- Видалити -->
						<!-- <z-confirm 
							activator-tooltip-text="delete"
							@ok="onDelete(item)"
						></z-confirm> -->
					</v-toolbar>
				</template>
			</v-data-table>
		</v-container>
		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
    </v-main>
</template>

<script>
import axios from 'axios'
//import mixin from './mixin/main'
//import { mapGetters, mapActions } from 'vuex'
//import downloadExcel from "vue-json-excel"
import zDetail from '@/views/OrganizationStatisticDetail'
//import zPdf from '@/views/pdf/VehicleEstimate'

export default {
	name: 'OrganizationStatistic',
	//mixins: [mixin],
	components: {
		//zPdf,
		//downloadExcel,
		zDetail,
		//zDialogCompare,
		//zConfirmDelete
	},
	data: () => ({
		title: 'Статистика запитів по організації',
		loading: 	false,

		headers: [
			{ text: 'ID', value: 'id', sortable: true, width: '50'},
			{ text: 'Назва', value: 'name', sortable: true},
			{ text: 'Тип', value: 'type', sortable: true},
			{ text: 'ЄДРПОУ', value: 'taxID', sortable: true},
			{ text: 'Кількість запитів', value: 'cnt', sortable: true},
			{ text: 'Actions', value: 'actions', sortable: false, width: '10%' },
		],

		items: 		[],
		selected: 	[],
	}),

	props: {
		own: { type: Boolean, default: false },
	},
	
	created () {
		this.onRefresh()
	},

	methods: {
		/**
		 * Оновити
		 */
		onRefresh () {
			this.search()	
		},

		/**
		 * Пошук
		 */
		search () {
			var that = this;
			this.loading = true;
			axios.get('/organization/statistic').then(response => {
				//console.log(that.$options.name, 'search','response.data', response.data)
				that.items = response.data
			}, error => {
				console.log(error)
			}).finally(() => {
				this.loading = false
			});
		},
	},
}
</script>