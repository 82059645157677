import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var attrs = ref.attrs;
return [_vm._t("activator",function(){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),Object.assign({}, tooltip, dialog)),[_c(VIcon,{attrs:{"color":"#1d6f42"}},[_vm._v(_vm._s(_vm.icon))])],1)]},{"attrs":attrs,"dialog":dialog,"tooltip":tooltip})]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.tooltip))])])]}}])},[_c(VFileInput,{ref:"fileinput",staticClass:"d-none",on:{"change":_vm.changeImport},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c(VList,[_c(VListItem,{on:{"click":_vm.onImport}},[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"light-blue darken-4"}},[_vm._v("mdi-import")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.import'))))])],1)],1),_c(VListItem,{on:{"click":_vm.onExport}},[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"light-blue darken-4"}},[_vm._v("mdi-export")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.export')))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }