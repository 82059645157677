<template>
	<div>
		<VueHtml2pdf
			:show-layout="false"
			:float-layout="true"
			:enable-download="false"
			:preview-modal="true"
			:paginate-elements-by-height="1400"
			:filename="filename"
			:pdf-quality="2"
			:manual-pagination="false"
			pdf-format="a4"
			pdf-orientation="portrait"
			pdf-content-width="800px"
			ref="html2Pdf"
		>

		<!-- 
			:html-to-pdf-options="options"
			pdf-content-width="800px"
			:paginate-elements-by-height="1400" -->

			<template slot="pdf-content">
				<v-app>
					<v-main>
						<div class="mx-8">
							<v-avatar size="48px">
								<img :src="logo">
							</v-avatar>
							<span class="ml-4 text-h4">{{ logoTitle }}</span>

							<h2 class="text-center">{{ title }}</h2>

							<v-row no-gutters>
								<v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
									№ {{ id }}
								</v-col>
								<v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6" class="text-right">
									{{ item.datecreate | formatDateTime }}
								</v-col>
								<v-col cols="12" class="text-right">
									{{ username }}
								</v-col>
							</v-row>
							<br>
							<hr>
							<br>
						</div>

						<div class="mx-8" v-if="parameters.length">
							<h3>Параметри запиту</h3><br>
							<v-simple-table dense>
								<template v-slot:default>
									<tbody>
										<tr v-for="(item, index) in parameters" :key="index">
											<td>{{ item.name }}</td>
											<td>{{ item.value }}</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</div>

						<div class="html2pdf__page-break" v-if="estimates.length"/>

						<div class="my-8 mx-8" v-if="estimates.length">
							<h3>Оцінка ринку</h3><br>
							<div v-for="estimate in estimates" :key="estimate.id">
								<v-simple-table dense v-if="estimate.total">
									<template v-slot:default>
										<tbody>
											<tr>
												<td>Загальна кількість оголошень</td>
												<td class="text-right">{{ estimate.total }}</td>
											</tr>
											<tr>
												<td>Середнє арифметичне</td>
												<td class="text-right">{{ estimate.arithmeticMean | toCurrencyUAH }}</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>

								<h5 v-if="estimate.prices.length">Ціни</h5>
								<v-simple-table dense>
									<template v-slot:default>
										<thead>
											<tr>
												<th>Кількість об'яв</th>
												<th>Вартість авто в UAH</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(item, index) in estimate.prices" :key="index">
												<td >{{ item.cnt }}</td>
												<td >{{ item.priceUAH  | toCurrencyUAH }}</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</div>
						</div>
					</v-main>	
				</v-app>
			</template>
		</VueHtml2pdf>
	</div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
/*
https://www.npmjs.com/package/vue-html2pdf
*/
export default {
	name: 'PdfRealtyEstimate',
	components: {
        VueHtml2pdf
    },
	data () {
		return {
			logo: require('../../assets/logo.svg'),
			logoTitle: 'Ortica Systems',
			title: "Середня ціна на нерухомість",
			
			options: {
				//margin: [10, 10],
			
				//filename: `OrticaSystems.pdf`,
			
				image: {
					type: 'jpeg', 
					quality: 0.98
				},
			
				enableLinks: false,
			
				html2canvas: {
					scale: 1,
					useCORS: true
				},
			
				jsPDF: {
					unit: 'mm',
					format: 'a4',
					orientation: 'portrait'
				}
			}
		}
	},
	props: {
		item: 		{ type: Object, default: () => {} },
	},

	computed: {
		id() {
			return this.item.hasOwnProperty('id') ? this.item.id: null;
		},

		/* title() {
			return this.item?.type?.name ? this.item.type.name: '';
		}, */

		filename() {
			return this.title + ' ' + this.id
		},

		username() {
			return this.item?.user?.name ? this.item.user.name: '';
		},

		parameters() {
			//console.log('RealtyEstimate.vue', 'parameters','item', this.item)

			let result = []
			//------------------------------------------
			if(this.item?.category?.name) result.push({name: "Категорія", value: this.item.category.name})
			if(this.item?.type?.name) result.push({name: "Тип", value: this.item.type.name})
			if(this.item?.operation?.name) result.push({name: "Тип операції", value: this.item.operation.name})
			if(this.item?.state?.name) result.push({name: "Область", value: this.item.state.name})
			if(this.item?.city?.name) result.push({name: "Місто", value: this.item.city.name})
			if(this.item?.district?.name) result.push({name: "Район", value: this.item.district.name})
			if(this.item?.dateFrom) result.push({name: "Дата видачі від", value: this.item.dateFrom})
			if(this.item?.dateTo) result.push({name: "Дата видачі до", value: this.item.dateTo})
			if(this.item?.area) result.push({name: "Площа", value: this.item.area})
			if(this.item?.roomsCount) result.push({name: "Кількість кімнат", value: this.item.roomsCount})

			return result
		},

		estimates() {
			return this.item.hasOwnProperty('estimates') ? this.item.estimates: [];
		},
	},

	methods: { 
        generate () {
			//console.log(this.$options.name, 'generate', 'item', this.item)

            this.$refs.html2Pdf.generatePdf()
        },

		valueUAH(items) {
			return items.map(element=>element.priceUAH);
		},

		valueUSD(items) {
			return items.map(element=>element.priceUSD);
		},

		avgUAH(items) {
			const arr = this.valueUAH(items);
			const average = arr.reduce((a, b) => a + b, 0) / arr.length;
			return average
		},

		avgUSD(items) {
			const arr = this.valueUSD(items);
			const average = arr.reduce((a, b) => a + b, 0) / arr.length;
			return average
		},

		minUAH(items) {
			const arr = this.valueUAH(items);
			return Math.min(...arr)
		},
		minUSD(items) {
			const arr = this.valueUSD(items);
			return Math.min(...arr)
		},

		maxUAH(items) {
			const arr = this.valueUAH(items);
			return Math.max(...arr)
		},
		maxUSD(items) {
			const arr = this.valueUSD(items);
			return Math.max(...arr)
		},
    },

}

</script>

<style>

</style>