import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.estimate.avgPriceUAH >= 0 || _vm.estimate.avgPriceUSD >= 0)?_c(VCard,{attrs:{"elevation":"0"}},[_c(VCardTitle,{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.title))]),_c(VCardText,[_c(VList,{attrs:{"dense":""}},[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("UAH")])],1),_c(VListItemAction,[_c(VListItemActionText,{staticClass:"font-weight-bold text-subtitle-2"},[_vm._v(_vm._s(_vm._f("toCurrencyUAH")(_vm.estimate.avgPriceUAH)))])],1)],1),_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("USD")])],1),_c(VListItemAction,[_c(VListItemActionText,{staticClass:"font-weight-bold text-subtitle-2"},[_vm._v(_vm._s(_vm._f("toCurrencyUSD")(_vm.estimate.avgPriceUSD)))])],1)],1)],1)],1),_c(VDivider),(_vm.visibleAdverts && _vm.adverts.length)?_c(VCard,{staticClass:"mx-auto",attrs:{"elevation":"0"}},[_c(VCardTitle,{staticClass:"text-h6"},[_vm._v("Об'яви")]),_c(VCardText,[_c(VChipGroup,{attrs:{"active-class":"primary--text","column":""}},_vm._l((_vm.adverts),function(advert,index){return _c(VChip,{key:index,attrs:{"href":_vm.getAdvertUrl(advert),"target":"_blank"}},[_vm._v(" "+_vm._s(parseInt(advert.price))+" ")])}),1)],1)],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }