<template>
    <v-navigation-drawer fixed clipped permanent right
		mini-variant
		mini-variant-width="56"
		style="padding: 64px 0px 0px 0px;"
	>
		<v-list dense nav>
			<!-- PDF -->
			<v-list-item>
				<v-list-item-action>
					<v-tooltip bottom>
						<template v-slot:activator="{ on: tooltip, attrs }">
							<v-icon large color="red" v-bind="attrs" v-on="{ ...tooltip }" @click="onPDF">mdi-file-pdf-box</v-icon>
						</template>
						<span>Експорт PDF</span>
					</v-tooltip>
				</v-list-item-action>
			</v-list-item>

			<!-- Excel -->
			<v-list-item>
				<v-list-item-action>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<download-excel 
								:data="excel.data"
								:name="excel.name"
								:type="excel.type"
							>
								<v-icon large color="green darken-2" :disabled="excel.disabled" v-bind="attrs" v-on="on">mdi-microsoft-excel</v-icon>
							</download-excel>
						</template>
						<span>Експорт Excel</span>
					</v-tooltip>
				</v-list-item-action>
			</v-list-item>
			<v-divider></v-divider>
		</v-list>
	</v-navigation-drawer>
</template>

<script>

export default {
	data () {
		return {
            text: null,
            type: 'success',
            visible: false,
		}
	},
	props: {
        outlined:	    { type: Boolean, default: true},
        dismissible:	{ type: Boolean, default: true},
	},
	methods: {
        Open (text, type) {
            this.text = text
            this.type = type
            this.visible = true
        },
        Close () {
            this.text = null
            this.type = 'success'
            this.visible = false
        },
    },
}
</script>