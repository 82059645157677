<template>
	<v-snackbar 
		v-model="dialog"
		:color="color" 
		:timeout="timeout"
		multi-line
	>
		<template v-slot>
			<v-layout align-center pr-4>
				<v-icon class="pr-3" dark large>{{ icon }}</v-icon>
				<v-layout column>
					<!-- <div>
						<strong>{{ title }}</strong>
					</div> -->
					<div>{{ text }}</div>
				</v-layout>
			</v-layout>
		</template>
		<template v-slot:action="{ attrs }">
			<!-- <z-app-login v-if="status==401"></z-app-login> -->
			<v-btn icon v-bind="attrs" @click.stop="onClose">
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</template>
    </v-snackbar>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import zAppLogin from '@/views/app.login.vue'

export default {
	components: { zAppLogin },
	data: () => ({

	}),
	
	computed: {
        ...mapGetters({
            visible: 	'messageVisibe',
            type: 		'messageType',
            title: 		'messageTitle',
            text: 		'messageText',
            status: 	'messageStatus',
        }),

		dialog: {
			get() 		{ return this.visible },
			set(val) 	{ this.$emit('input', val) }
		},

		color() {
			let result
			switch (this.type) {
				case "error": 	result = "error"; break;
				case "info": 	result = "info"; break;
				case "success": result = "success"; break;
				case "warning": result = "warning"; break;
				default:  		result = null;
			}
			return result
		},

		icon() {
			let result
			switch (this.type) {
				case "error": 	result = "mdi-alert-circle"; break;
				case "info": 	result = "mdi-information"; break;
				case "success": result = "mdi-check-circle"; break;
				case "warning": result = "mdi-alert"; break;
				default:  		result = null;
			}
			return result
		},

		timeout() {
			let result
			switch (this.type) {
				case "error": 	result = 7500; break;
				case "info": 	result = 0; break;
				case "success": result = 3000; break;
				case "warning": result = 5000; break;
				default:  		result = null;
			}
			return result
		},
    },

	methods: {
		...mapActions([ 'closeMessage' ]),

		onClose() {
			this.closeMessage()
		},
	},
}
</script>