export default {
    namespaced: true,
    state: {
		params: {
			regnum: null,
        	vin: null,
		},

		categories: [],
        brands: [],
        models: [],
        engines: [],
        gearboxes: [],
        bodies: [],
    },
    getters: {
		params(state) {
			return state.params
		},
		isset(state) {
			return state.params.regnum || state.params.vin
		},

		minYear(state) {
			return 1900
		},
		maxYear(state) {
			let d = new Date()
			return d.getFullYear()
		},

		years(state) {
            var d = new Date()
            var items = []
            for(var i = d.getFullYear(); i > 1900; i-- ){
                items.push(i)
            }
            return items;
        },

		categories(state) {
            return state.categories;
        },
        brands(state) {
            return state.brands;
        },
        models(state) {
            return state.models;
        },
        engines(state) {
            return state.engines;
        },
        gearboxes(state) {
            return state.gearboxes;
        },
        bodies(state) {
            return state.bodies;
        },
    },
    mutations: {
		params (state, data) {
			state.params = data
		},

		categories (state, data) {
            state.categories = data?data:[];
        },
        brands (state, data) {
            state.brands = data?data:[];
        },
        models (state, data) {
            state.models = data?data:[];
        },
        engines (state, data) {
            state.engines = data?data:[];
        },
        gearboxes (state, data) {
            state.gearboxes = data?data:[];
        },
        bodies (state, data) {
            state.bodies = data?data:[];
        },
    },
    actions: {
		clear (context) {
			context.commit('params', {
				regnum: null,
				vin: null,
			})
			context.commit('items', [])
		},
    }
   
}