import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VForm,{ref:"form"},[_c(VList,{attrs:{"three-line":""}},[_c(VListItemGroup,{attrs:{"active-class":"success--text","multiple_":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_vm._l((_vm.item.objects),function(item,index){return [_c(VListItem,{key:index,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-wrap",domProps:{"textContent":_vm._s(item.name)}}),_c(VListItemSubtitle,{staticClass:"text-wrap",domProps:{"textContent":_vm._s(item.maker.name)}})],1),_c(VListItemAction,[_c(VIcon,{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-delete")])],1)]}}],null,true)}),(index < item.objects.length - 1)?_c(VDivider,{key:index}):_vm._e()]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }