<template>
	<v-menu
		ref="menu"
		v-model="menu"
		:close-on-content-click="false"
		transition="scale-transition"
		offset-y
		max-width="290px"
		min-width="auto"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-text-field v-bind="attrs" v-on="on"
				v-model="dateFormatted"
				:label="(label in $vuetify.lang.locales.en ? $vuetify.lang.t(`$vuetify.${label}`) : label ) | capitalize"
				:hint="hint"
				:persistent-hint="persistentHint"
				:prepend-icon="prependIcon"
				:outlined="outlined"
				:dense="dense"
				:clearable="clirable"
				:hide-details="hideDetails"
				@blur="date = parseDate(dateFormatted)"
				maxlength="10"
				:rules="rules"
			></v-text-field>
		</template>
		<v-date-picker
			v-model="date"
			no-title
			@input="menu = false"
		></v-date-picker>
	</v-menu>
</template>

<script>
import moment from 'moment'
export default {
	data: vm => ({
		menu: false,
		dateFormatted: null
		//date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
		//dateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
		//dateFormatted: null
	}),

	props: {
		value: 			{ type: String, default: ''},
		label:  		{ type: String, default: null},
		placeholder:  	{ type: String, default: null},
		hint:  			{ type: String, default: null},
		persistentHint:	{ type: Boolean, default: false },
		readonly:		{ type: Boolean, default: false },
		clirable:		{ type: Boolean, default: false },
		outlined: 		{ type: Boolean, default: false },
		dense: 			{ type: Boolean, default: false },
		prependIcon:  	{ type: String, default: null}, //"mdi-calendar
		hideDetails: 	{ type: [Boolean,String], default: false },
		rules:			{ type: Array, default: () => [] },
	},

	computed: {
		date: {
			//date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			//get() 		{ return this.value ? this.value.substring(0, 10) : null },
			//get() 		{ this.formatDate(this.value); return this.value },
			get() 		{ return this.value },
			set(val) 	{ this.$emit('input', val) }
		},
	},

	watch: {
		date (val) {
			this.dateFormatted = this.formatDate(this.date)
			//this.dateFormatted = this.formatDate(val)
		},
    },
	
	methods: {
		formatDate (date) {
			if (!date) return null
			//console.log('\\components\\control\\date.vue', 'formatDate', 'date', date, date.substring(0,10), date.substring(0,10).split('-'))

			const [year, month, day] = date.substring(0,10).split('-')
			return `${day}.${month}.${year}`
		},
		parseDate (date) {
			if (!date) return null

			//console.log('\\components\\control\\date.vue', 'parseDate', 'date', date, moment(date, "MM.DD.YYYY", true).isValid());

			const [day, month, year] = date.split('.')
			return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
		},
	},
}
</script>