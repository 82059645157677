<template>
	<v-dialog 
		v-model="dialog" 
		max-width="500px"
	>
		<!-- Актіватор -->
		<template v-slot:activator="{ on: dialog, attrs }">
			<!-- activator - icon -->
            <v-tooltip bottom v-if="activatorType=='icon'" :disabled="tooltipDisabled" >
                <template v-slot:activator="{ on: tooltip }">
                    <v-icon v-bind="attrs" v-on="{ ...tooltip, ...dialog }" :disabled="disabled">{{ activatorIcon }}</v-icon>
                </template>
                <span>{{ $vuetify.lang.t('$vuetify.delete') | capitalize }}</span>
            </v-tooltip>

			<!-- activator - button-icon -->
			<v-tooltip bottom v-else-if="activatorType=='button-icon'" :disabled="tooltipDisabled" >
                <template v-slot:activator="{ on: tooltip }">
					<v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...dialog }" :disabled="disabled">
						<v-icon>{{ activatorIcon }}</v-icon>
					</v-btn>
                </template>
                <span>{{ $vuetify.lang.t('$vuetify.add') | capitalize }}</span>
            </v-tooltip>
            
            <!-- activator - list-item -->
            <v-list-item v-else-if="activatorType=='list-item'" v-bind="attrs" v-on="{...dialog }" :disabled="disabled"> 
                <v-list-item-icon>
                    <v-icon :disabled="disabled">{{ activatorIcon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $vuetify.lang.t('$vuetify.delete') | capitalize }}</v-list-item-title>
            </v-list-item>

            <!-- activator - button -->
            <v-tooltip bottom v-else :disabled="tooltipDisabled">
                <template v-slot:activator="{ on: tooltip }">
					<v-btn outlined color="primary" v-bind="attrs" v-on="{ ...tooltip, ...dialog }">
						<v-icon left dark color="primary">{{ activatorIcon }}</v-icon>
						{{ $vuetify.lang.t('$vuetify.delete') | capitalize }}
					</v-btn>
                </template>
                <span>{{ $vuetify.lang.t('$vuetify.delete') | capitalize }}</span>
            </v-tooltip>
		</template>

		<v-card>
			<v-card-title class="text-h5">
				{{ title }}
				<v-spacer></v-spacer>
				<v-btn icon @click="dialog=false" >
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<!-- <v-divider></v-divider> -->
			<v-card-text class="text-center">
				<p class="text-subtitle-1 font-weight-black">Ви дійсно бажаєте здійснити видалення?</p>
				<p class="text-subtitle-1 font-weight-black">Дані будуть видалені без можливості їх подальшого відновлення!</p>
			</v-card-text>
			<!-- <v-divider></v-divider> -->
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn text @click="onCancel">Відхилити</v-btn>
				<v-btn outlined color="success" @click="onOK">Підтверджую</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>

export default {
	components: {

	},
	data: () => ({
		//title: 'Are you sure you want to delete this item?',
		//title: 'Ви дійсно бажаєте видалити цей запис?',
		title: 'Видалення',
		icon: 'mdi-account-tie',
		dialog: false,
		tooltipDisabled: false,
	}),
	props: {
		selected: 		{ type: Object, default: () => {} },
		disabled:   	{ type: Boolean, default: false }, //Ознака неактивного елементу
		activatorType:  { type: String, default: 'icon'}, // Тип активатора вікна: button | icon | menu-item | list-item
		activatorIcon:  { type: String, default: 'mdi-trash-can'},
	},

	watch: {
		
	},
	
	computed: {

	},

	methods: {
		onCancel () {
			this.dialog = false
		},

		onOK () {
			this.dialog = false
			this.$emit('ok')
		},
	},
}
</script>