import {HTTP} from '../http'

export default {
    namespaced: true,
    state: {
        name: 'profiles',
        title: {
            single: 'profile',
            plural: 'profiles'
        },
        icon: 'mdi-account',

        /* item: {
            id: 1,
            name:   null,
            firstname: null,
            middlename: null,
            lastname: null,
            password: null,
            nicname: null,
            company: null,
            email: null,
            phone: null,
            image: null,
            description: null,
            password: null,
            confirm: null,
        }, */

        item: {},
    },
    getters: {        
        name(state) {
            return state.name;
        },
        module(state) {
            return state.name;
        },
        path(state) {
            return '/'+state.name;
        },
        icon(state) {
            return state.icon;
        },
        title(state) {
            return state.title;
        },
        item(state) {
            return state.item;
        },
    },
    mutations: {
        item (state, data) {
            state.item = data ? data : Object.assign({}, state.default);
        },
        
        insert (state, data) {
            if(Array.isArray(data)) {
                for(var i in data) {
                    state.items.push(data[i]);
                }
            } else {
                state.items.push(data);
            }
        },
        update (state, data) {
            if(data) {
                if(data.hasOwnProperty('old') && data.hasOwnProperty('new')) {
                    const index = state.items.indexOf(data.old)
                    state.items.splice(index, 1, data.new)
                }
            }
        },
        delete (state, data) {
            if(Array.isArray(data)) {
                for(let i in data) {
                    const index = state.items.indexOf(data[i])
                    state.items.splice(index, 1);
                }
            } else {
                const index = state.items.indexOf(data)
                state.items.splice(index, 1);
            }
        },
    },
    actions: {
        /**
         * Обнулення даних
         * @param {*} context 
         */
        Clear (context){
            //console.log(context.getters.name,'Clear')
            context.commit('item')

            context.commit('item', {
                firstname: null
                ,middlename: null
                ,lastname: null
                ,password: null
                ,nicname: null
                ,company: null
                ,email: null
                ,phone: null
                ,image: null
                ,description: null
                ,password: null
                ,confirm: null
            })
        },

        // Ініціалізація
        async Init (context, data) {
            return new Promise((resolve, reject) => {
                 // Очищення даних
                context.dispatch('Clear');

                setTimeout(() => {
                    var response = {
                        data: {
                            name: 'Іваненко Іван Іванович',
                            email: 'qwins4@i.ua',
                            phone: '+380971112233',
                        }
                    }
    
                    context.commit('item', response.data)
    
                    //console.log(context.getters.name, 'item', context.getters.item)
                    
                    //if(data.hasOwnProperty('success')) data.success(response.data);
                    
                    resolve()
                }, 1000)
            })
        },

        /**
         * Пошук одного об'єкту по параметрам
         * 
         * @param {*} context 
         * @param {*} data 
         * @returns 
         */
        Find(context, data) {
            //console.log(context.getters.name, 'Find','data', data)

            // Обнулення даних
            context.dispatch('Clear');

            let url = context.getters.path;

            if(data.hasOwnProperty('item')) {
                if(data.item.hasOwnProperty('id')) {
                    url += '/' + data.item.id
                }
            }

            HTTP.get(url).then(response => {
                context.commit('item', response.data);
                if(data.hasOwnProperty('success')) data.success(response);
            }, error => {
                //context.dispatch('Message', error, {root: true});
                if(data.hasOwnProperty('error')) data.error(error.response);
            });
        },

        Search(context, data) {
            //console.log(context.getters.name, 'Search','data', data)
            HTTP.get(context.getters.path).then(response => {
                context.commit('items');
                context.commit('insert', response.data);
                if(data && data.hasOwnProperty('success')) data.success(response.data);
            }, error => {
                context.dispatch('Message', error, {root: true});
                if(data && data.hasOwnProperty('error')) data.error(error);
            });
        },

        Create(context, data) {
            //console.log(context.getters.name, 'Create', 'data', data)
            HTTP.post(context.getters.path, data.params, data.config).then(response => {
                context.commit('insert', response.data);
                if(data.hasOwnProperty('success')) data.success(response.data);
            }, error => {
                context.dispatch('Message', error, {root: true});
                if(data.hasOwnProperty('error')) data.error(error);
            });
        },

        Update(context, data) {
            //console.log(context.getters.name, 'Update','data', data)

            if(JSON.stringify(data.item) === JSON.stringify(data.params)) {
                if(data.hasOwnProperty('success')) data.success();
                return
            }

            HTTP.put(context.getters.path + '/' + data.params.id, data.params).then(response => {
                context.commit('update', {
                    new: response.data,
                    old: data.item
                })
                if(data.hasOwnProperty('success')) data.success(response);
            }, error => {
                context.dispatch('Message', error, {root: true});
                if(data.hasOwnProperty('error')) data.error(error);
            });
        },

        Delete(context, data) {
            //console.log(context.getters.name, 'Delete','data', data)
            HTTP.delete(context.getters.path + '/' + data.params.id).then(response => {
                context.commit('delete', data);
                if(data.hasOwnProperty('success')) data.success(response);
            }, error => {
                context.dispatch('Message', error, {root: true});
                if(data.hasOwnProperty('error')) data.error(error);
            })
        },

        //---------------------------------------------------
        /* Clear(context, data) {
            context.commit('item', {
                firstname: null
                ,middlename: null
                ,lastname: null
                ,password: null
                ,nicname: null
                ,company: null
                ,email: null
                ,phone: null
                ,image: null
                ,description: null
                ,password: null
                ,confirm: null
            })
        }, */

        Load(context, data) {
            //context.dispatch('Find', data)
            //context.dispatch('Search', data)
            //console.log(context.getters.name, 'Load','data', data)

            context.commit('item', {
                surname: 'Іваненко', 
                firstname: 'Іван',
                patronymic: 'Іванович',

                name:   "Іваненко Іван Іванович",
                /* firstname: "John",
                middlename: null,
                lastname: "Leider", */
                company: "Firma",
                post: null,
                address: null,
                telephone: "0971112233",
                email: "qwins4@i.ua",
                inn: "1234567890",
                description: "Тестовий аккаунт",
                image: 'https://cdn.vuetifyjs.com/images/john.png',

                passport: {
                    seria: 'ВМ',
                    number: '112233',
                    issuedby: 'Київським РВ УМВС',
                    dateofissue: '1988-05-08',
                },

                bankaccounts: [
                    {
                        account: 'UA533057490000026008000000737',
                        name: 'АО "БАНК КРЕДИТ ДНІПРО"',
                        mfo: '305749',
                        depart: 'Маяковська',
                        currency: 'UAH'
                    },
                    {
                        account: 'UA183282090000026000010049162',
                        name: 'ПАО АБ «Південний»',
                        mfo: '328209',
                        depart: null,
                        currency: 'UAH'
                    },
                ],
            });
        },

        Save(context, data) {
            /* if(data.params.id) {
                context.dispatch('Update', data)
            } else {
                context.dispatch('Create', data)
            } */

            HTTP.put(context.getters.path + '/' + data.item.id, data.item).then(response => {
                context.commit('item', response.data);
                if(data.hasOwnProperty('success')) data.success(response);
            }, error => {
                //context.dispatch('Message', error, {root: true});
                if(data.hasOwnProperty('error')) data.error(error.response);
            });
           
        },
    }
}