<template>
	<v-main>
        <!-- <v-container> -->
			<v-data-table
				v-model="selected"
				:headers="headers"
				:items="items"
				item-key="id"
				show-select
				:search="searchText"
				:loading="loading"
				loading-text="Завантаження даних... зачекайте, будь ласка."
				sort-by="id"
				sort-desc
				:items-per-page="filter.itemsPerPage"
				hide-default-footer
			>
				<template v-slot:top>
					<v-toolbar flat>
						<v-avatar>
							<v-icon>{{ icon }}</v-icon>
						</v-avatar>
						<v-toolbar-title>
							{{ (title.plural in $vuetify.lang.locales.en ? $vuetify.lang.t(`$vuetify.${title.plural}`) : title.plural ) | capitalize }}
						</v-toolbar-title>

						<v-spacer></v-spacer>

						<v-menu offset-y>
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon v-bind="attrs" v-on="on">
									<v-icon>mdi-text-box-plus</v-icon>
								</v-btn>
							</template>

							<v-list>
								<!-- <z-document-edit-pricelist @ok="item.members.push($event)" tooltip="create"> -->
								<z-document-edit-pricelist>
									<template v-slot:activator="props">
										<v-list-item v-bind="props.attrs" v-on="{ ...props.dialog }">
											<v-list-item-icon>
												<v-icon v-text="props.icon"></v-icon>
											</v-list-item-icon>
											<v-list-item-content>
												<v-list-item-title>{{ props.title }}</v-list-item-title>
											</v-list-item-content>
										</v-list-item>
									</template>
								</z-document-edit-pricelist>
							</v-list>
						</v-menu>

						<z-filter @ok="onSearch"></z-filter>

						<v-text-field
							v-model="searchText"
							append-icon="mdi-magnify"
							label="Пошук"
							dense
							single-line
							hide-details
							outlined
							rounded
						></v-text-field>

						<v-btn icon @click.stop="onRefresh">
							<v-icon>mdi-refresh</v-icon>
						</v-btn>

						<!-- Пошук -->
						<!-- <z-search v-model="search"></z-search> -->
					</v-toolbar>
				</template>

				<template v-slot:[`item.actions`]="{ item }">
					<v-toolbar flat dense color="transparent">
						<!-- PDF -->
						<!-- <v-icon color="red" @click="onPDF(item)">mdi-file-pdf-box</v-icon> -->

						<!-- Перегляд -->
						<!-- <z-view :selected="item"></z-view> -->
						<!-- Редагувати -->
						<z-edit :selected="item"></z-edit>
						<component
							v-bind:is="componentDialog(item)"
							:selected="item"
						></component>
						
						<!-- Видалити -->
						<!-- <z-confirm 
							activator-tooltip-text="delete"
							@ok="onDelete(item)"
						></z-confirm> -->
					</v-toolbar>
				</template>

				<template v-for="(item, index) in headers" v-slot:[`header.${item.value}`]="{  }">
					<span :key="index">{{ (item.text in $vuetify.lang.locales.en ? $vuetify.lang.t('$vuetify.' + item.text) : item.text ) | capitalize }}</span>
				</template>

				<template v-slot:[`item.date`]="{ item }">
					<span>{{ item.date | formatDateTime }}</span>
				</template>
				<template v-slot:[`item.datebegin`]="{ item }">
					<span>{{ item.datebegin | formatDateTime }}</span>
				</template>
				<template v-slot:[`item.dateend`]="{ item }">
					<span>{{ item.dateend | formatDateTime }}</span>
				</template>
				
				<template v-slot:[`item.icon`]="{ item }">
					<v-icon>{{ item.icon }}</v-icon>
				</template>

				<template v-slot:[`item.status.name`]="{ item }">
					<z-document-status-change
						v-model="item"
					></z-document-status-change>
				</template>

				<!-- <template v-slot:[`item.code`]="{ item }">
					<span>{{ item.objects.reduce((a, b) => a + (b['priceAvg'] || 0), 0) | toCurrencyUAH }}</span>
				</template> -->
			</v-data-table>

			<z-edit tooltip="create">
				<template v-slot:activator="props">
					<v-fab-transition>
						<v-btn
							v-bind="props.attrs" v-on="{ ...props.dialog, ...props.tooltip}"
							color="primary" dark fixed bottom right fab
							class="mb-8 mr-8"
						>
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</v-fab-transition>
				</template>
			</z-edit>

			<v-footer app padless>
				<v-toolbar flat>
					<v-spacer></v-spacer>

					<v-pagination
						v-model="filter.page"
						:length="pageCount"
						:total-visible="7"
					></v-pagination>

					<v-spacer></v-spacer>

					<!-- <z-edit tooltip="create">
						<template v-slot:activator="props">
							<v-btn icon fab v-bind="props.attrs" v-on="{ ...props.dialog, ...props.tooltip}">
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</template>
					</z-edit> -->
				</v-toolbar>
				<!-- <v-card flat tile width="100%" class="text-center">
					<v-card-text>
						<v-pagination
							v-model="filter.page"
							:length="pageCount"
							:total-visible="7"
						></v-pagination>
					</v-card-text>
      			</v-card> -->
			</v-footer>

			<!-- <z-pdf ref="pdf" :item="pdfItem"></z-pdf> -->
		<!-- </v-container> -->
		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
    </v-main>
</template>

<script>
//import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import downloadExcel from "vue-json-excel"
//import zPdf from '@/views/pdf/VehicleDocument'
import zFilter from '@/views/DocumentFilter'
import zEdit from '@/views/DocumentEdit'
import zView from '@/views/DocumentView'
import zDocumentEdit from '@/views/DocumentEdit'
import zDocumentEditPricelist from '@/views/DocumentEditPricelist'

import zDocumentStatusChange from '@/views/DocumentStatusChange'

//import zDialogCompare from '@/views/VehicleRequestCompare'
//import zDialogStatistic from '@/views/VehicleRequestStatistic'

//import zConfirmDelete from '@/views/components/confirm-delete'

export default {
	name: 'Document',
	components: {
		//zPdf,
		downloadExcel,
		zFilter,
		zEdit,
		zView,
		zDocumentEdit,
		zDocumentEditPricelist,

		zDocumentStatusChange,
		//zDialogCompare,
		//zDialogStatistic
	},

	data: () => ({
		loading: false,
		searchText: '',
		pageCount: 2,

		selected: [],

		pdfItem: {},

		templates: [
			{ code: 'pricelist', name: 'Прайс-лист' }
		],
	}),

	/* props: {
		icon:   		{ type: String, default: 'mdi-file-document' },
		title:   		{ type: String, default: 'Документи' },
	}, */
	
	computed: {
		...mapGetters('Document', [ 'icon', 'title', 'headers', 'items', 'filter' ]),
		...mapGetters('DocumentType', { 'types': 'items' }),
		...mapGetters('DocumentStatusType', { 'statuses': 'items' }),
	},

	watch: {
		'filter.page': function (val) {
			this.onSearch()
		},
	},

	created() {
		this.onSearch()
	},

	methods: {
		//...mapActions('Document', [ 'setItem', 'setItems' ]),
		...mapActions('Document', [ 'search' ]),

		onRefresh () {
			this.onSearch()
		},

		onPDF (item) {
			this.pdfItem = item
			this.$refs.pdf.generate()
		},

		onNext() {
			//console.log(this.$options.name, 'onNext','page', this.page)
			this.onSearch()
		},

		onPrevious() {
			//console.log(this.$options.name, 'onPrevious','page', this.page)
			this.onSearch()
		},

		/**
		 * Пошук
		 */
		onSearch () {
			//console.log(that.$options.name, 'search')
			//console.log(this.$options.name, 'search','filter', this.filter)
			this.loading = true
			this.search(this.filter).then(() => {
				if(this.items.length > 0 && this.pageCount < this.filter.page + 1) {
					this.pageCount = this.filter.page + 1
				}
			}).finally(() => {
				this.loading = false
			})
		},

		componentDialog(item) {
			let typeCode =  item?.type?.code ? item.type.code : ''
			const name = `z${this.$options.name}Edit${typeCode.charAt(0).toUpperCase() + typeCode.slice(1)}`
			//console.log(this.$options.name, 'componentDialog','name', name)
			return this.$options.components[name] ? name : `z${this.$options.name}Edit`
		},
	},
}
</script>