<template>
	<v-main>
		<v-navigation-drawer fixed clipped permanent style="padding: 64px 0px 0px 0px;" width="300">
			<template v-slot:prepend>
				<v-card-actions class="px-4">
					<z-drug-search-dialog
						multiple
						@add="add"
					></z-drug-search-dialog>

					<v-spacer></v-spacer>
					
					<!-- <z-excel-parameter
						:data="WorkbookParameters"
						@import="WorkbookParametersImport"
					></z-excel-parameter> -->
					
					<v-menu
						v-model="menu.settings"
						:close-on-content-click="false"
						offset-x
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon v-bind="attrs" v-on="on">
								<v-icon>mdi-cog</v-icon>
							</v-btn>
						</template>
						<!-- <template v-slot:activator="{ on: dialog, attrs }">
							<v-tooltip bottom>
								<template v-slot:activator="{ on: tooltip, attrs }">
									<slot name="activator" :attrs="attrs" :dialog="menu.settings" :tooltip="tooltip">
										<v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...dialog }">
											<v-icon>mdi-cog</v-icon>
										</v-btn>
									</slot>
								</template>
								<span>Парамери</span>
							</v-tooltip>
						</template> -->

						<v-card>
							<v-toolbar flat>
								<v-avatar>
									<v-icon>mdi-cog</v-icon>
								</v-avatar>
								<v-toolbar-title>Парамери</v-toolbar-title>
								<v-spacer></v-spacer>
								<v-btn icon @click="menu.settings=false" >
									<v-icon>mdi-close</v-icon>
								</v-btn>
							</v-toolbar>

							<v-card-text>
								<v-combobox
									v-model="params.period"
									:items="periods"
									label="Період оцінки"
									outlined
									dense
									item-text="name"
									item-value="value"
									hide-details="auto"
								></v-combobox>
							</v-card-text>
						</v-card>
					</v-menu>
					
				</v-card-actions>
				<v-divider></v-divider>
			</template>
			<v-card flat>
				<v-card-text>
					<v-list three-line>
						<v-list-item-group
							v-model="selected"
							active-class="success--text"
						>
							<template v-for="(item, index) in params.objects">
								<v-list-item :key="index">
									<template v-slot:default="{ active }">
									<v-list-item-content>
										<v-list-item-title v-text="item.name" class="text-wrap"></v-list-item-title>
									</v-list-item-content>

									<v-list-item-action>
										<v-icon @click.stop="remove(item)">mdi-delete</v-icon>
									</v-list-item-action>
									</template>
								</v-list-item>

								<v-divider v-if="index < params.objects.length - 1" :key="item.name"></v-divider>
							</template>
						</v-list-item-group>
					</v-list>
				</v-card-text>
			</v-card>
			<template v-slot:append>
				<v-card-actions class="px-4">
					<v-spacer></v-spacer>
					<v-btn text color="primary" @click="onClear">{{ $vuetify.lang.t('$vuetify.clear') }}</v-btn>
					<v-btn color="primary" :disabled="disabledOk" @click="onOK">{{ $vuetify.lang.t('$vuetify.ok') }}</v-btn>
				</v-card-actions>
			</template>
		</v-navigation-drawer>

		<v-navigation-drawer fixed clipped permanent right
			mini-variant
			mini-variant-width="56"
			style="padding: 64px 0px 0px 0px;"
        >
			<v-list dense nav>
				<!-- PDF -->
				<v-list-item>
					<v-list-item-action>
						<v-tooltip bottom>
							<template v-slot:activator="{ on: tooltip, attrs }">
								<v-icon color="#f40f02" v-bind="attrs" v-on="{ ...tooltip }" @click="onPDF">mdi-file-pdf-box</v-icon>
							</template>
							<span>Експорт PDF</span>
						</v-tooltip>
					</v-list-item-action>
				</v-list-item>

				<!-- Excel -->
				<z-excel-parameter
					:data="WorkbookParameters"
					:name="$options.name"
					@import="WorkbookParametersImport"
				></z-excel-parameter>

			</v-list>
        </v-navigation-drawer>

		<v-container style="padding: 64px 0px 0px 256px;">
			<v-card elevation="0">
				<v-card-title class="text-h5">{{ title }} {{ id ? '№' + id: '' }}</v-card-title>
				<v-card-subtitle>{{ subtitle }}</v-card-subtitle>

				<v-divider></v-divider>

				<v-card-text>
					<v-data-table
						:headers="headers"
						:items="item.objects"
						item-key="id"
						loading-text="Завантаження даних... зачекайте, будь ласка."
						:items-per-page="-1"
						hide-default-footer
					>
						<template v-slot:[`item.priceMin`]="{ item }">
							<span>{{ item.priceMin | toCurrencyUAH }}</span>
						</template>

						<template v-slot:[`item.priceMax`]="{ item }">
							<span>{{ item.priceMax | toCurrencyUAH }}</span>
						</template>

						<template v-slot:[`item.priceAvg`]="{ item }">
							<span>{{ item.priceAvg | toCurrencyUAH }}</span>
						</template>

						<template v-slot:[`item.actions`]="{ item }">
							<v-toolbar flat dense color="transparent">
								<z-statistic v-if="item.priceCount"
									:item="item"
								></z-statistic>
							</v-toolbar>
						</template>

						<template slot="body.append">
							<tr>
								<th class="title"></th>
								<th class="font-weight-bold">{{ sumField('priceMin') | toCurrencyUAH }}</th>
								<th class="font-weight-bold">{{ sumField('priceAvg') | toCurrencyUAH }}</th>
								<th class="font-weight-bold">{{ sumField('priceMax') | toCurrencyUAH }}</th>
								<th class="font-weight-bold">{{ sumField('priceCount') }}</th>
							</tr>
						</template>
					</v-data-table>
				</v-card-text>
			</v-card>
		</v-container>

		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64" ></v-progress-circular>
		</v-overlay>
		<v-snackbar v-model="snackbar.visible" :color="snackbar.color">
			{{ snackbar.text }}
			<template v-slot:action="{ attrs }">
			<v-btn icon v-bind="attrs" @click="snackbar.visible = false" >
				<v-icon>mdi-close</v-icon>
			</v-btn>
			</template>
		</v-snackbar>

		<z-pdf ref="pdf" :item="item"></z-pdf>
	</v-main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
import zPdf from '@/views/pdf/VehicleEstimate'
import zExcelParameter from '@/views/components/menu/excel'
//import downloadExcel from "vue-json-excel";
import zDrugSearchDialog from '@/views/DrugSearchDialog'
import zStatistic from '@/views/EstimateObjectStatistic'

export default {
	name: 'EstimateAveragePrice',
	components: {
		zExcelParameter,
		zPdf,
		//downloadExcel,
		zDrugSearchDialog,
		zStatistic,
	},
	data: () => ({
		loading: false,
		selected: [],

		menu: {},

		product: {},
		
		periods: [
			{ code: 'LAST_MONTH', name: 'Останній місяць', value: '30'},
			{ code: 'LAST_THREE_MONTH', name: '3 місяці', value: '90'},
			{ code: 'LAST_HALF_YEAR', name: 'Останні півроку', value: '180'},
			{ code: 'LAST_YEAR', name: 'Останній рік', value: '365'},
			{ code: 'ALL_TIME', name: 'За весь час', value: null},
		],

		headers: [
			//{ text: 'ID', value: 'id', sortable: true, width: '50' },
			{ text: 'Назва', value: 'name', sortable: true },
			{ text: 'Мінімальна ціна', value: 'priceMin', sortable: true },
			{ text: 'Середня ціна', value: 'priceAvg', sortable: true },
			{ text: 'Максимальна ціна', value: 'priceMax', sortable: true },
			{ text: 'Кількість цін в оцінці', value: 'priceCount', sortable: true },
			{ text: '', value: 'actions', sortable: false },
		],

		/* excel: {
			name: '',
			type: 'xlsx',
			disabled: true,
			data: []
		}, */

		snackbar: {
			visible: false,
			text: null,
			color: null
		},
	}),

	props: {
		icon:   		{ type: String, default: 'mdi-google-analytics' },
		title:   		{ type: String, default: 'Середня ціна лікарських засобів' },
		subtitle:   	{ type: String, default: 'Визначення середньої ціни лікарських засобів' },
	},

	computed: {
		...mapGetters('EstimateAveragePrice', ['params', 'item', 'isset']),

		excelName() {
			return this.title + (this.params.number ? ' ' + this.params.number : (this.params.vin ? ' ' + this.params.vin : '') + '.' +  this.excel.type)
		},

		id() {
			return this.item.hasOwnProperty('id') ? this.item.id: null;
		},

		estimate() {
			return this.item ? this.item: {};
		},

		disabledOk() {
			return !this.params.objects.length
		},

		WorkbookParameters() {
			let result = {
				name: this.$options.name,
				//name: 'Книга 1',
				sheets: []
			}
			
			//-- Об'єкти --------------------------------------------
			let sheet = {
				name: "Об'єкти",
				data_json: []
			}

			this.params.objects.forEach((object) => {
				//console.log(this.$options.name, 'WorkbookParameters', 'object', object)
				sheet.data_json.push({
					"Ідентифікатор": object.id,
					"Код Моріон": object.idm,
					"Назва": object.name
				})
			})

			result.sheets.push(sheet)

			//-- Результат --------------------------------------------
			sheet = {
				name: "Результат",
				data_json: []
			}

			this.item.objects.forEach((object) => {
				//console.log(this.$options.name, 'WorkbookParameters', 'object', object)

				let product = object?.product ? object.product : {};
				let drug 	= product?.drugs ? product.drugs[0]: {};

				sheet.data_json.push({
					"Ідентифікатор об'єкту": object.id,
					"Назва об'єкту": object.name,
					"ID типу об'єкту": object.type.id,
					"Назва типу об'єкту": object.type.name,

					"ID продукту": product.id,
					"Код Моріон": product.idm,
					"Номер державної реєстрації": product.number,
					"Дата початку дії державної реєстрації": product.datebegin,
					"Дата завершення дії державної реєстрації": product.dateend,

					"ID типу продукту":  product.type.id,
					"Назва типу продукту":  product.type.name,
					"ID виробника":  product.maker.id,
					"Назва виробника":  product.maker.name,

					"ID лікарського засобу": drug.id,
					//"Код Моріон": object.product.drugs.idm,
					"Назва лікарського засобу": drug.name,
					"Номер лікарського засобу": drug.num,
					"Медична форма": drug.medform,

					"Мінімальна ціна": object.priceMin,
					"Середня ціна": object.priceAvg,
					"Максимальна ціна": object.priceMax,
					"Кількість цін в оцінці": object.priceCount,
				})
			})

			result.sheets.push(sheet)

			//console.log(this.$options.name, 'WorkbookParameters', 'result', result)

			return result
		},
	},

	created(){
		this.params.period = this.periods[0]
	},

	methods: {
		...mapActions('EstimateAveragePrice', [ 'setItem' ]),

		onClear() {
			this.params.period = this.periods[0]
			this.params.objects = []
			this.setItem()
			//this.$refs.parameters.clear()
			//this.clear()
		},

		validate() {
			return this.params.objects.length > 0 
				/* || this.params.vin
				|| this.params.brand
				|| this.params.model */
		},

		add(item) {
			//console.log(this.$options.name, 'add','item', item)
			if(Array.isArray(item)) {
				item.forEach((item) => {
					this.params.objects.push(item)
				})
			} else {
				this.params.objects.push(item)
			}
		},

		remove(item) {
			//console.log(this.$options.name, 'remove','item', item)
			if(Array.isArray(item)) {
				item.forEach((itm) => {
					const index = this.params.objects.indexOf(itm)
					this.params.objects.splice(index, 1);
				})
			} else {
				const index = this.params.objects.indexOf(item)
				this.params.objects.splice(index, 1);
			}
		},

		onOK() {
			//console.log(this.$options.name, 'onOK','params', this.params)

			if(this.validate()) {
				this.loading = true
				var that = this
				axios.post('/estimate', this.params).then(response => {
					//console.log(that.$options.name, 'onOK','response.data', response.data)
					that.setItem(response.data)
				}, error => {
					console.log('error', error)

					that.snackbar.visible 	= true;
					that.snackbar.text 		= error.response.data.message;
					that.snackbar.color 	= null;
				}).finally(() => {
					this.loading = false
				});
			} else {
				this.snackbar.visible 	= true;
				this.snackbar.text 		= 'Відсутні дані для пошуку';
				this.snackbar.color 	= null;
			}
		},

		sumField(key) {
			return this.item.objects.reduce((a, b) => a + (b[key] || 0), 0)
		},

		onPDF () {
			this.$refs.pdf.generate()
		},

		WorkbookParametersImport(data) {
			//console.log(this.$options.name, 'changeImport', 'data', data)

			this.onClear()

			if(data) {
				//var WorkbookName = data.name;
				
				if(data.hasOwnProperty('sheets')) {
					data.sheets.forEach((sheet) => {
						//let name = sheet.name

						if(sheet.name == "Об'єкти") {
							if(sheet.hasOwnProperty('data_json')) {
								let data_json = sheet.data_json
								data_json.forEach((item) => {
									let id = item['Ідентифікатор'], idm = item['Код Моріон'], name = item['Назва'];

									this.add({
										id: id,
										idm: idm,
										name: name,
										product: {
											id: id,
											idm: idm,
										}
									})
								})
							}
						} else if(sheet.name == "Результат") {
							this.item['objects'] = []

							if(sheet.hasOwnProperty('data_json')) {
								let data_json = sheet.data_json
								data_json.forEach((item) => {
									this.item.objects.push({
										id: item["Ідентифікатор об'єкту"],
										name: item["Назва об'єкту"],
										type: {
											id: item["ID типу об'єкту"],
											name: item["Назва типу об'єкту"],
										},
										product: {
											id: item['ID продукту'],
											idm: item['Код Моріон'],
											name: item["Назва об'єкту"],
											number: item['Номер державної реєстрації'],
											datebegin: item['Дата початку дії державної реєстрації'],
											dateend: item['Дата завершення дії державної реєстрації'],
											type: {
												id: item['ID типу продукту'],
												name: item['Назва типу продукту'],
											},
											maker: {
												id: item['ID виробника'],
												name: item['Назва виробника'],
											},
											drugs: [
												{
													id: item['ID лікарського засобу'],
													idm: item['Код Моріон'],
													name: item['Назва лікарського засобу'],
													num: item['Номер лікарського засобу'],
													medform: item['Медична форма'],
												}
											],
										},
										priceMin: item['Мінімальна ціна'],
										priceAvg: item['Середня ціна'],
										priceMax: item['Максимальна ціна'],
										priceCount: item['Кількість цін в оцінці'],
									})
								})
							}
						}

						//console.log(this.$options.name, 'changeImport', 'item', this.item)
					})
				}
			}
		},
	},
}
</script>