export default {
	namespaced: true,
	state: {
		endpoint: '/document/object/category',
		name: 'DocumentObjectCategory',
		title: {
			single: 'DocumentObjectCategory',
			plural: 'DocumentObjectCategorys'
		},
		icon: 'mdi-format-list-bulleted-type',
		items: [],
		headers: [
			{ text: 'id', value: 'id', sortable: true, width: '10%'},
			{ text: 'icon', value: 'icon', sortable: true, width: '10%'},
			{ text: 'name', value: 'name', sortable: true},
			{ text: 'category', value: 'category.name', sortable: true},
			{ text: 'description', value: 'description', sortable: true},
			{ text: 'ordinal', value: 'ordinal', sortable: true, width: '10%'},
			{ text: 'actions', value: 'actions', sortable: false, width: '10%' },
		],
	},
}
