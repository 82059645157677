<template>
	<v-dialog  
	 	v-model="dialog"
	 	:fullscreen="fullscreen"
		transition="dialog-bottom-transition"
		width="800"
		height="1024"
		max-width="1024"
		scrollable
		persistent
	>
		<!-- Актіватор -->
		<template v-slot:activator="{ on: dialog, attrs }">
			<v-tooltip bottom>
				<template v-slot:activator="{ on: tooltip, attrs }">
					<slot name="activator" :attrs="attrs" :dialog="dialog" :tooltip="tooltip">
						<v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...dialog }">
							<v-icon>mdi-pencil</v-icon>
						</v-btn>
					</slot>
				</template>
				<span>{{ $vuetify.lang.t(`$vuetify.${tooltip}`) | capitalize }}</span>
			</v-tooltip>
		</template>

		<v-card elevation="4">
			<!-- Ttoolbar -->
			<v-toolbar elevation="0" dark color="primary" style="flex: 0 1 auto;">
				<v-avatar>
					<v-icon>{{ icon }}</v-icon>
				</v-avatar>
				<v-toolbar-title>{{ $vuetify.lang.t(`$vuetify.${title}`) | capitalize }}</v-toolbar-title>
				
				<v-spacer></v-spacer>
				
				<!-- <v-chip> {{ item.id }}</v-chip> -->
				<span> {{ item.id }}</span>

				<v-spacer></v-spacer>

				<v-btn icon @click="fullscreen = !fullscreen" >
					<v-icon v-if="fullscreen">mdi-arrow-collapse</v-icon>
					<v-icon v-else>mdi-arrow-expand</v-icon>
				</v-btn>

				<v-btn icon @click="dialog=false" >
					<v-icon>mdi-close</v-icon>
				</v-btn>

				<!-- <template v-slot:extension>
					Tabs
					<v-tabs v-model="tab" align-with-title>
						<v-tabs-slider color="yellow"></v-tabs-slider>
						<v-tab v-for="item in tabs" :key="item">
							{{ item }}
						</v-tab>
					</v-tabs>

					<v-toolbar v-if="tab==1" dense color="primary" elevation="0">
						<z-field-search v-model="searchRoles"></z-field-search>
						<z-confirm-delete @ok="onRemoveRoles()" activator-type="button-icon" :disabled="roles.length==0"></z-confirm-delete>
						<z-roles @ok="onAddRoles($event)" activator-icon="mdi-plus" multiple :excluded="item.usersroles"></z-roles>
					</v-toolbar>

				</template> -->
			</v-toolbar>

			<!-- <v-card-text style="height: 380px;"> -->
			<v-card-text class="mt-6">
				<v-form ref="form">
					<v-row v-if="item.profile">
						<v-col cols="12">
							<v-text-field
								v-model="item.profile.name"
								:label="$vuetify.lang.t('$vuetify.pib') | capitalize"
								prepend-icon="mdi-account"
								required
								:rules="[rules.required]"
								@keyup.ctrl.90="OnDefault"
							></v-text-field>
						</v-col>                                  
						<v-col cols="12" sm="6" md="6">
							<v-text-field
								v-model="item.profile.email"
								:label="$vuetify.lang.t('$vuetify.email') | capitalize"
								prepend-icon="mdi-email"
								required
								:rules="[rules.required, rules.email]"
								@keyup.ctrl.90="OnDefault"
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="6" md="6">
							<v-text-field 
								v-model="item.profile.phone"
								:label="$vuetify.lang.t('$vuetify.telephone') | capitalize"
								prepend-icon="mdi-phone"
								:rules="[rules.phone]"
								@keyup.ctrl.90="OnDefault"
							></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-combobox
								v-model="item.profile.organization"
								:label="$vuetify.lang.t('$vuetify.company') | capitalize"
								:items="organizations"
								label="Організація"
								item-text="name"
								item-value="id"
								hide-details="auto"
								prepend-icon="mdi-domain"
								@focus="loadOrganization"
								:rules="[required]"
							></v-combobox>
							<!-- <v-text-field 
								v-model="item.profile.company"
								:label="$vuetify.lang.t('$vuetify.company') | capitalize"
								prepend-icon="mdi-domain"
								required
								:rules="[rules.required]"
								@keyup.ctrl.90="OnDefault"
							></v-text-field> -->
						</v-col>
						<v-col cols="12">
							<v-textarea
								v-model="item.profile.description"
								:label="$vuetify.lang.t('$vuetify.comment') | capitalize"
								prepend-icon="mdi-comment"
								hint="Вкажіть, будь ласка, з якими сервісами ви бажаєте працювати"
								rows="1"
							></v-textarea>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<v-combobox
								v-model="item.usersroles"
								:label="$vuetify.lang.t('$vuetify.roles') | capitalize"
								:items="roles"
								item-text="name"
								multiple
								chips
								:loading="loadings.roles"
								@focus="loadRoles"
								:rules="[required]"
							></v-combobox>
							<!-- :rules="[rules.required]" -->
						</v-col>
					</v-row>
					<!-- <v-row>
						<v-col cols="12" md="3" class="d-flex align-center">
							<v-list-item-subtitle class="text-wrap">{{ $vuetify.lang.t('$vuetify.name') | capitalize }}</v-list-item-subtitle>
						</v-col>
						<v-col cols="12" md="9">
							<v-text-field
								v-model="item.name"
								outlined
								clearable
								hide-details="auto"
								:rules="[rules.required]"
							></v-text-field>

						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="3" class="d-flex align-center">
							<v-list-item-subtitle class="text-wrap">{{ 'Логін' | capitalize }}</v-list-item-subtitle>
						</v-col>
						<v-col cols="12" md="9">
							<v-text-field
								v-model="item.login"
								outlined
								clearable
								hide-details="auto"
								:rules="[rules.required]"
							></v-text-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="3" class="d-flex align-center">
							<v-list-item-subtitle class="text-wrap">{{ $vuetify.lang.t('$vuetify.comment') | capitalize }}</v-list-item-subtitle>
						</v-col>
						<v-col cols="12" md="9">
							<v-textarea
								v-model="item.description"
								outlined
								rows="2"
								hide-details="auto"
							></v-textarea>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="3" class="d-flex align-center">
							<v-list-item-subtitle class="text-wrap">{{ $vuetify.lang.t('$vuetify.roles') | capitalize }}</v-list-item-subtitle>
						</v-col>
						<v-col cols="12" md="9">
							<v-combobox
								v-model="item.usersroles"
								:items="roles"
								item-text="name"
								multiple
								chips
								:loading="loadings.roles"
								@focus="loadRoles"
							></v-combobox>
						</v-col>
					</v-row> -->
				</v-form>

				<!-- <v-tabs-items v-model="tab">
					<v-tab-item>
						<v-card flat>
							<v-card-text>
								<v-row>
									<v-col cols="12" sm="6" md="6">
										<v-text-field
											:label="$vuetify.lang.t('$vuetify.name') | capitalize"
											v-model="item.name"
											dense
											clearable
											hide-details="auto"
										></v-text-field>
									</v-col>
									<v-col cols="12" sm="6" md="6">
										<v-text-field
											:label="$vuetify.lang.t('$vuetify.login') | capitalize"
											v-model="item.login"
											dense
											clearable
											hide-details="auto"
										></v-text-field>
									</v-col>
									<v-col cols="12" sm="6" md="6">
										<v-text-field
											:label="$vuetify.lang.t('$vuetify.expiration') | capitalize"
											v-model="item.expiration"
											dense
											clearable
											hide-details="auto"
										></v-text-field>
									</v-col>
									<v-col cols="12" sm="6" md="6">
										<v-text-field
											:label="$vuetify.lang.t('$vuetify.datecreate') | capitalize"
											v-model="item.datecreate"
											dense
											clearable
											hide-details="auto"
										></v-text-field>
									</v-col>

									<v-col cols="12" sm="6" md="6">
										<v-checkbox
											v-model="item.enable"
											label="enable"
											hide-details
										></v-checkbox>
									</v-col>
									<v-col cols="12" sm="6" md="6">
										<v-checkbox
											v-model="item.active"
											label="active"
											hide-details
										></v-checkbox>
									</v-col>

									<v-col cols="12">
										<v-textarea
											v-model="item.description"
											:label="$vuetify.lang.t('$vuetify.comment') | capitalize"
											hint=""
											rows="2"
										></v-textarea>
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
					</v-tab-item>
					<v-tab-item>
						<v-list dense>
							<v-list-item-group v-model="roles" multiple>
								<template v-for="(itm, index) in filteredRoles">
									<v-list-item >
											<template v-slot:default="{ active }">
												<v-list-item-action>
													<v-checkbox :input-value="active"></v-checkbox>
												</v-list-item-action>
												<v-list-item-content>
													<v-list-item-title v-text="itm.name"></v-list-item-title>
												</v-list-item-content>

												<v-list-item-action>
													<z-confirm-delete @ok="onRemoveRole(itm)"></z-confirm-delete>
												</v-list-item-action>
											</template>
									</v-list-item>
									<v-divider v-if="index < filteredRoles.length - 1" :key="index"></v-divider>
								</template>
							</v-list-item-group>
						</v-list>
					</v-tab-item>
				</v-tabs-items> -->

				<v-alert v-model="alert" dense outlined type="error" dismissible>{{ alertText }}</v-alert>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn text @click.native="onCancel">{{ $vuetify.lang.t('$vuetify.cancel') }}</v-btn>
				<v-btn color="success" dark @click.native="onOK" :disabled="loading">{{ $vuetify.lang.t('$vuetify.ok') }}</v-btn>
			</v-card-actions>
		</v-card>

		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
	</v-dialog>
</template>

<script>
import axios from 'axios'
//import mixin from './details/mixin/main'
import mixin from './mixin/detail'
import { mapGetters, mapActions } from 'vuex'
import zActivator from '@/views/components/activator/activator'
import zFieldSearch from '@/views/components/field.search'
import zConfirmDelete from '@/views/components/confirm-delete'
import zRoles from '@/views/dictionaries/Roles'

export default {
	name: 'UsersDetail',
	mixins: [mixin],
	components: {
		zActivator,
		zFieldSearch,
		zConfirmDelete,
		zRoles,
	},
	data: () => ({
		title: 'user',
		icon: 'mdi-account-tie',


        //tabs: ['Головна', 'Ролі'],

		//roles: [],
		//searchRoles: '',

		loadings: {
			roles: false,
			organizations: false,
		},

		item: {
			profile: {}
		},

		alert: false,
		alertText: null
	}),

	props: {
		selected: 					{ type: Object, default: null },
		disabled:   				{ type: Boolean, default: false }, //Ознака неактивного елементу
		tooltip:  					{ type: String, default: 'edit' },
		
		/* activatorType:  			{ type: String, default: 'button-icon' }, // Тип активатора вікна: button | icon | menu-item | list-item
		activatorIcon:  			{ type: String, default: 'mdi-plus' },
		activatorTitle:  			{ type: String, default: null },
		activatorOutlined:     		{ type: Boolean, default: false },
		activatorTooltipText:  		{ type: String, default: null },
		activatorTooltipDisabled:	{ type: Boolean, default: false }, */
	},

	computed: {
		//...mapGetters('Users', [ 'endpoint', 'item' ]),
		...mapGetters('Roles', {  'userroles': 'items' }),
		...mapGetters('Organization', {  'userorganizations': 'items' }),

		filteredRoles() {
			return this.item.usersroles.filter(item => {
				return item.id==this.searchRoles
					|| item.name.toLowerCase().includes(this.searchRoles.toLowerCase())
			})
		},

		roles() {
			let result = this.userroles.map( element => ({id:element.id, name : element.name}))

			return result
		},

		organizations() {
			let result = this.userorganizations.map( element => ({id:element.id, name : element.name}))

			return result
		},
	},

	watch: {
		dialog: function (val) {
			//console.log(this.$options.name, 'dialog','val', val)
			if(val) {
				this.$emit('open')
			} else {
				//this.item.id = null
				this.$refs.form.reset()
				this.$emit('close')
			}
		},
	},

	methods: {
		//...mapActions('Users', [ 'setItem', 'setItems', 'createItem', 'updateItem', 'deleteItem' ]),
		...mapActions('Roles', { 'setRoles': 'setItems' }),
		...mapActions('Organization', { 'setOrganizations': 'setItems' }),

		find(item) {
			if(item?.id) {
				this.loading = true
				var that = this
				axios.get(`/users/${item.id}`).then(response => {
					//console.log(this.$options.name, 'find','response.data', response.data)
					that.item = response.data
				}, error => {
					console.log(error)
					that.showAlert(error)
				}).finally(() => (this.loading = false));
			}
		},

		save() {
			//console.log(this.$options.name, 'save','item', this.item)

			var that = this
			if (this.$refs.form.validate()) {
				if(this.item?.id) {
					axios.put(`/users/${this.item.id}`, this.item).then(response => {
						console.log(that.$options.name, 'save::update','response.data', response.data)
						//console.log(this.$options.name, 'create','response.data', response.data)
						//that.createItem(response.data)
						
						that.item = response.data
						that.$emit('update', that.item)
						that.dialog = false
					}, error => {
						console.log(error)
						that.showAlert(error)
					}).finally(() => (this.loading = false));
				} else {
					axios.post(`/users`, this.item).then(response => {
						console.log(that.$options.name, 'save::create','response.data', response.data)
						//console.log(this.$options.name, 'create','response.data', response.data)
						//that.createItem(response.data)
						
						that.item = response.data
						that.$emit('create', that.item)
						that.dialog = false
					}, error => {
						console.log(error)
						that.showAlert(error)
					}).finally(() => (this.loading = false));
				}
			}
		},

		loadOrganization(e) {
			if(this.organizations.length == 0) {
				this.loadings.organizations = true
				var that = this
				axios.get('/organization').then(response => {
					//console.log(this.$options.name, 'loadOrganization','data', response.data)
					that.setOrganizations(response.data)
				}, error => {
					console.log('error', error)
					that.showAlert(error)
				}).finally(() => (this.loadings.organizations = false));
			}
		},

		// Завантаження довідника ролей
		loadRoles(e) {
			if(this.roles.length == 0) {
				this.loadings.roles = true
				var that = this
				axios.get('/roles').then(response => {
					//console.log(this.$options.name, 'loadRoles','data', response.data)
					that.setRoles(response.data)
				}, error => {
					console.log('error', error)
					that.showAlert(error)
				}).finally(() => (this.loadings.roles = false));
			}
		},

		onOK () {
			console.log(this.$options.name, 'onOK', 'item', this.item)

			this.save()
		},

		showAlert (text) {
			this.alert = true
			this.alertText = text
		},


		OnDefault() {
			this.item.profile.name = 'Іваненко Іван Іванович'
			this.item.profile.email = 'bot@ortica-systems.com'
			this.item.profile.phone = '+380971112233'
		},

		/* required(value) {
			if (value instanceof Array && value.length == 0) {
				return 'Required.';
			}
			return !!value || 'Required.';
		}, */

		/* onAddRoles (items) {
			this.addItems(this.item.usersroles, items)
		},

		onRemoveRole (item) {
			this.removeItem(this.item.usersroles, item)
		},

		onRemoveRoles() {
			this.removeItems(this.item.usersroles, this.filteredRoles, this.roles)
			this.roles = []
		}, */
	},
}
</script>