<template>
	<v-card v-if="registrations.length"
		elevation="0">
		<v-card-title class="text-h6">{{ title }}</v-card-title>

		<v-card-text>
			<v-simple-table dense>
				<template v-slot:default>
					<thead>
						<tr>
							<th class="text-left">Назва</th>
							<th class="text-left">Значення</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in registrations" :key="item.code">
							<td>{{ item.name }}</td>
							<td>{{ item.val }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	data: () => ({
		title: 'Дані про державну реєстрацію транспортного засобу',
	}),

	props: {
		item: { type: Object, default: () => {}},
	},

	computed: {
		//Дані про державну реєстрацію транспортного засобу
		registrations() {
			return this.item.hasOwnProperty('registrations') ? this.item.registrations: [];
		},
	},

	methods: {
		
	},
}
</script>