<template>
    <v-card elevation="0">

        <v-card-text class="text--primary">
            <v-toolbar flat >
                <v-spacer></v-spacer>
                <!-- Створити -->
                <Dialog :type="type" mode="add" :item="item" @add="OnAdd" @close="OnClose"></Dialog>
                
                <v-divider class="mx-4" inset vertical></v-divider>
                <!-- Оцінка -->
                <!-- <v-btn color="primary" @click="$emit('execute', items)" :disabled="items.length<1"> -->
                <v-btn color="primary" @click="OnExecute(items)" :disabled="items.length<1">
                    <v-icon left dark>mdi-play-box-multiple</v-icon>
                    Оцінка
                </v-btn>
            </v-toolbar>

            <v-data-table :headers="headers" :items="items" :loading="loading">
                <template v-slot:[`item.actions`]="{ item }">
                    <!-- <v-icon @click="$emit('execute', item)" color="primary" small>mdi-play-box</v-icon>
                    <ResultDialog small activator="icon" v-if="item.hasOwnProperty('estimate')" :item="item.estimate"></ResultDialog>
                    <Dialog :type="type" mode="edit" small activator="icon" :item="item"></Dialog>
                    <v-icon @click="OnRemove(item)" color="primary" small>mdi-trash-can-outline</v-icon> -->

                    <!-- Меню -->
                    <v-menu>
                        <template v-slot:activator="{ on: menu, attrs }">
                            <v-tooltip bottom disabled>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                                        <v-icon color="primary">mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <span>Меню</span>
                            </v-tooltip>
                        </template>

                        <v-list>
                            <!-- Редагувати -->
                            <Dialog :type="type" mode="edit" small activator="list-item" :item="item"></Dialog>

                            <!-- Видалити -->
                            <v-list-item @click="OnRemove(item)">
                                <v-list-item-icon>
                                    <v-icon color="primary">mdi-trash-can-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Видалити</v-list-item-title>
                            </v-list-item>

                            <v-divider></v-divider>

                            <!-- Оцінка -->
                            <!-- <v-list-item @click="$emit('execute', item)"> -->
                            <v-list-item @click="OnExecute(item)">
                                <v-list-item-icon>
                                    <v-icon color="primary">mdi-play-box</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Оцінка</v-list-item-title>
                            </v-list-item>

                            <ResultDialog small activator="list-item" v-if="item.hasOwnProperty('estimate')" :item="item.estimate"></ResultDialog>

                        </v-list>
                    </v-menu>

                </template>
            </v-data-table>

        
            <!-- <v-list two-line>
                <v-list-item-group
                    v-model="selected"
                    active-class="pink--text"
                    multiple
                >
                    <template v-for="(item, index) in items">
                        <v-list-item :key="index">
                            <template v-slot:default="{ }">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <span v-if="item.hasOwnProperty('regnum')"  v-text="item.regnum"></span>
                                        <span v-if="item.hasOwnProperty('vin')" v-text="item.vin"></span>
                                        <span v-if="item.hasOwnProperty('brand') && item.brand" v-text="item.brand.name"></span>
                                        <span v-if="item.hasOwnProperty('model') && item.model" v-text="item.model.name"></span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="text--primary">
                                        <span v-if="(item.hasOwnProperty('yearfrom') || item.hasOwnProperty('yearto')) && (item.yearfrom || item.yearto)">
                                            Рік виробництва
                                            <span v-if="item.yearfrom" v-text="' з '+item.yearfrom"></span>
                                            <span v-if="item.yearto" v-text="' по '+item.yearto"></span>
                                        </span>
                                        <span v-if="(item.hasOwnProperty('racefrom') || item.hasOwnProperty('raceto')) && (item.racefrom || item.raceto)">
                                            Пробіг
                                            <span v-if="item.racefrom" v-text="' від '+item.racefrom"></span>
                                            <span v-if="item.raceto" v-text="' до '+item.raceto"></span>
                                        </span>
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        <span v-if="item.hasOwnProperty('engine') && item.engine" v-text="'Двигун:' + item.engine.name"></span>
                                        <span v-if="(item.hasOwnProperty('capacityfrom') || item.hasOwnProperty('capacityto')) && (item.capacityfrom || item.capacityto)">
                                            Oб'єм
                                            <span v-if="item.capacityfrom" v-text="' від '+item.capacityfrom"></span>
                                            <span v-if="item.capacityto" v-text="' до '+item.capacityto"></span>
                                        </span>
                                        <span v-if="item.hasOwnProperty('gearbox') && item.gearbox" v-text="'Коробка передач:' + item.gearbox.name"></span>

                                    </v-list-item-subtitle>

                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-list-item-action-text v-text="item.total"></v-list-item-action-text>
                                    <v-list-item-action-text v-text="item.arithmeticMean"></v-list-item-action-text>
                                    <v-list-item-action-text v-text="item.arithmeticMeanQuartile"></v-list-item-action-text>
                                </v-list-item-action>
                            </template>
                        </v-list-item>
                        <v-divider v-if="index < items.length - 1" :key="index"></v-divider>
                    </template>
                </v-list-item-group>
            </v-list> -->
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Dialog from './vehicle.estimate.dialog.vue'
import ResultDialog from './vehicle.estimate.result.dialog.vue'

export default {
    components: {
		Dialog, ResultDialog
	},
    data () {
        return {
            loading: false,

            item: { 
                use: { 
                    increas: {},
                    reduce: {
                        elements: {}
                    },
                }
            },
			items: [],
			selected: [],
			/* headers: [
				//{ text: 'Марка/Модель', value: 'markName', sortable: true},
				{ text: 'Реєстраційний номер', value: 'regnum', sortable: true},
				//{ text: 'VIN-код', value: 'vin', sortable: true},
				{ text: 'Марка', value: 'brand.name', sortable: true},
				{ text: 'Модель', value: 'model.name', sortable: true},
				{ text: 'Рік виробництва', value: 'year', sortable: true},
				//{ text: 'Рік, від', value: 'yearfrom', sortable: true},
				//{ text: 'Рік, по', value: 'yearto', sortable: true},
				//{ text: 'Пробіг', value: 'race', sortable: true},
				//{ text: 'Пробіг від', value: 'racefrom', sortable: true},
				//{ text: 'Пробіг по', value: 'raceto', sortable: true},
				//{ text: 'Двигун', value: 'engine.name', sortable: true}, 
				//{ text: "Об'єм двигуна", value: 'capacity', sortable: true}, 
				//{ text: "Об'єм двигуна від", value: 'capacityfrom', sortable: true}, 
				//{ text: "Об'єм двигуна по", value: 'capacityto', sortable: true}, 
				//{ text: 'Коробка передач', value: 'gearbox.name', sortable: true},
				{ text: 'Кількість цін', value: 'total', sortable: true},
				//{ text: 'Середня арифметична', value: 'arithmeticMean', sortable: true},
				{ text: 'Середня ціна', value: 'arithmeticMeanQuartile', sortable: true}, //Середня арифметична міжквантильна
				//{ text: 'Вартість', value: 'conclusion.value', sortable: true},
				{ text: 'Вартість', value: 'conclusionValue', sortable: true},
                { text: 'Actions', value: 'actions', sortable: false },
			], */
        }
    },
    props: {
        type: {
			type: String,
			default: 'metodica' // check | market | metodica | exploration
		},
    },

    watch: {
		/* 'item.brand': function (val) {
			if(val) {
				this.Models({brand: val.id})
			}
		}, */
	},
    computed: {
        headers () {
            if(['check'].includes(this.type)) {
                return [
                    { text: 'Реєстраційний номер', value: 'regnum', sortable: true},
                    //{ text: 'VIN-код', value: 'vin', sortable: true},
                    { text: 'Марка', value: 'estimate.vehicle.brand.value', sortable: true},
                    { text: 'Модель', value: 'estimate.vehicle.model.value', sortable: true},
                    { text: 'Рік виробництва', value: 'estimate.vehicle.year.value', sortable: true},
                    { text: 'Тип кузова', value: 'estimate.vehicle.body.value', sortable: true},
                    { text: 'Двигун', value: 'estimate.vehicle.engine.value', sortable: true}, 
                    { text: "Об'єм двигуна", value: 'estimate.vehicle.capacity.value', sortable: true},
                    { text: "Колір", value: 'estimate.vehicle.color.value', sortable: true},
                    { text: 'Actions', value: 'actions', sortable: false },
                ]
            }
            
            return [
				//{ text: 'Марка/Модель', value: 'markName', sortable: true},
				{ text: 'Реєстраційний номер', value: 'regnum', sortable: true},
				//{ text: 'VIN-код', value: 'vin', sortable: true},
				{ text: 'Марка', value: 'brand.name', sortable: true},
				{ text: 'Модель', value: 'model.name', sortable: true},
				{ text: 'Рік виробництва', value: 'year', sortable: true},
				//{ text: 'Рік, від', value: 'yearfrom', sortable: true},
				//{ text: 'Рік, по', value: 'yearto', sortable: true},
				//{ text: 'Пробіг', value: 'race', sortable: true},
				//{ text: 'Пробіг від', value: 'racefrom', sortable: true},
				//{ text: 'Пробіг по', value: 'raceto', sortable: true},
				//{ text: 'Двигун', value: 'engine.name', sortable: true}, 
				//{ text: "Об'єм двигуна", value: 'capacity', sortable: true}, 
				//{ text: "Об'єм двигуна від", value: 'capacityfrom', sortable: true}, 
				//{ text: "Об'єм двигуна по", value: 'capacityto', sortable: true}, 
				//{ text: 'Коробка передач', value: 'gearbox.name', sortable: true},
				{ text: 'Кількість цін', value: 'total', sortable: true},
				//{ text: 'Середня арифметична', value: 'arithmeticMean', sortable: true},
				{ text: 'Середня ціна', value: 'arithmeticMeanQuartile', sortable: true}, //Середня арифметична міжквантильна
				//{ text: 'Вартість', value: 'conclusion.value', sortable: true},
				{ text: 'Вартість', value: 'conclusionValue', sortable: true},
                { text: 'Actions', value: 'actions', sortable: false },
			]

        }
    },
    
    methods: {
        ...mapActions('vehicles', [
			'Estimate'
		]),

        OnAdd (item) {
			//this.items.push(Object.assign({}, item))
			this.items.push(JSON.parse(JSON.stringify(item)));

            //console.log('vehicle.estimate.list', 'methods.OnAdd', 'item', this.items)
		},
        OnRemove (item) {
            const index = this.items.indexOf(item)
            this.items.splice(index, 1);
        },

        OnClose () {
			this.item = { 
                use: { 
                    increas: {},
                    reduce: {
                        elements: {}
                    },
                }
            }
		},


        OnExecute(data) {
			console.log('vehicle.estimate.list', 'OnExecute', 'data', data)

			if(data) {
				this.loading = true
				this.ExecuteCount = 0

				if(Array.isArray(data)) {	
					for(let i in data) {
						this.OnEstimate(data[i])
					}
				} else {
					this.OnEstimate(data)
				}
			}
		},

		OnEstimate(item) {
			//console.log('vehicle.estimate.list', 'OnEstimate', 'item', item)
			var that = this
			this.ExecuteCount++
			this.Estimate({
				type: this.type,
				params: item,
				success: function(respond) {
                    if(respond) {
                        //console.log('vehicle.estimate.list', 'methods.OnEstimate', 'respond', respond)
                        that.$set(item, 'estimate', respond)

                        if(respond.hasOwnProperty('market')) {
                            item['total'] = respond['market']['total']['value']
                            item['arithmeticMean'] = respond['market']['arithmeticMean']['value']
                            item['arithmeticMeanQuartile'] = respond['market']['arithmeticMeanQuartile']['value']
                        }

                        if(respond.hasOwnProperty('conclusion')) {
                            item['conclusionValue'] = respond['conclusion']['value']
                            //console.log('vehicle.estimate.list', 'methods.OnEstimate', 'conclusion', conclusion)
                        }

                        if(respond.hasOwnProperty('params')) {
                            if(respond.params.hasOwnProperty('brand')) {
                                that.$set(item, 'brand', respond.params.brand)
                            }
                            if(respond.params.hasOwnProperty('model')) {
                                that.$set(item, 'model', respond.params.model)
                            }
                            if(respond.params.hasOwnProperty('yearfrom')) {
                                that.$set(item, 'year', respond.params.yearfrom)
                            }
                        }

                        //console.log('vehicle.estimate.list', 'methods.OnEstimate', 'item', item)
                    }

					that.loading = --this.ExecuteCount == 0//?false: true
				},
				error: function(respond) {
					that.loading = --this.ExecuteCount == 0//?false: true
				},
			});
		},


		/* OnEstimate (item) {
			var that = this
			this.loading = true

			let count = 0
			for(let i in this.items) {
				var item = this.items[i]
				count++

				this.Estimate({
                    type: this.type,
					params: item,
					success: function(respond, item) {
						
						//console.log(count, 'item', item)
						console.log('vehicle.estimate.list', 'methods.OnEstimate', 'respond', respond)

						if(respond.hasOwnProperty('market')){
							item['total'] = respond['market']['total']['value']
							item['arithmeticMean'] = respond['market']['arithmeticMean']['value']
							item['arithmeticMeanQuartile'] = respond['market']['arithmeticMeanQuartile']['value']
							item['estimate'] = respond
						}

                        if(respond.hasOwnProperty('conclusion')){
                            //item['conclusionValue'] = respond['conclusion']['value']
                            console.log('vehicle.estimate.list', 'methods.OnEstimate', 'conclusion', conclusion)

                            that.$set(item,'conclusionValue',respond['conclusion']['value'])
                        }

						that.loading = --count==0?false: true
					},
					error: function(respond, item) {
						that.loading = --count==0?false: true
					},
				});
			}
		}, */

    },
}
</script>