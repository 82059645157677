<template>
	<v-menu
		v-model="dialog"
		:close-on-content-click="false"
		:nudge-width="200"
		offset-x
	>
		<!-- <template v-slot:activator="{ on, attrs }">
			<v-btn v-bind="attrs" v-on="on" icon :disabled="disabled">
				<v-icon>mdi-filter-variant</v-icon>
			</v-btn>
		</template> -->
		<template v-slot:activator="{ on: dialog, attrs }">
			<v-tooltip bottom>
				<template v-slot:activator="{ on: tooltip, attrs }">
					<slot name="activator" :attrs="attrs" :dialog="dialog" :tooltip="tooltip">
						<v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...dialog }">
							<v-icon>mdi-filter-variant<</v-icon>
						</v-btn>
					</slot>
				</template>
				<span>{{ $vuetify.lang.t(`$vuetify.${tooltip}`) | capitalize }}</span>
			</v-tooltip>
		</template>

		<v-card>
			<!-- <v-card-title>Фільтр</v-card-title> -->
			<v-toolbar flat>
				<v-avatar>
					<v-icon>mdi-filter-variant</v-icon>
				</v-avatar>
				<v-toolbar-title>Фільтр</v-toolbar-title>

				<v-spacer></v-spacer>

				<v-btn icon @click="dialog=false" >
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>
			<v-card-text>
				<v-text-field
					v-model.number="item.id"
					label="Ідентифікатор"
					dense
					hide-details="auto"
					outlined
					type="number"
					:min="1"
					clearable
				></v-text-field>

				<v-divider class="mt-6"></v-divider>

				<v-switch 
					v-model="item.onlymy"
					false-value="0"
					true-value="1"
					label="Тільки мої запити"
				></v-switch>

				<v-text-field
					v-model.number="item.itemsPerPage"
					label="Кількість рядків на сторінку"
					dense
					hide-details="auto"
					outlined
					type="number"
					:min="10"
					:max="100"
				></v-text-field>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary" text @click="onOK">OK</v-btn>
			</v-card-actions>
		</v-card>
	</v-menu>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'DocumentFilter',
	data: () => ({
		dialog: false,
		item: {},
	}),
	props: {
		icon:   		{ type: String, default: 'mdi-magnify' },
		title:   		{ type: String, default: 'Пошук лікарського засобу' },
		tooltip:  		{ type: String, default: 'add' },
		disabled:   	{ type: Boolean, default: false }, //Ознака неактивного елементу
	},
	
	watch: {
		dialog(val) {
			if(val) {
				this.item = Object.assign({}, this.filter)
			}
		},
	},

	computed: {
		...mapGetters('Document', [ 'filter' ]),
	},

	methods: {
		...mapActions('Document', [ 'setFilter' ]),

		onOK () {
			//console.log(this.$options.name, 'onOK','selected', this.selected)
			this.setFilter(this.item)
			this.$emit('ok')
			this.dialog = false
		},
	},
}
</script>