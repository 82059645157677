import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VContainer,[_c(VToolbar,{attrs:{"elevation":"0"}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm._f("capitalize")(_vm.title)))]),_c(VSpacer)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","loading":_vm.loading,"loading-text":"Завантаження даних... зачекайте, будь ласка."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":""}},[_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onRefresh.apply(null, arguments)}}},[_c(VIcon,[_vm._v("mdi-refresh")])],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VToolbar,{attrs:{"elevation":"0","dense":"","color":"transparent"}},[_c('z-detail',{attrs:{"selected":item}})],1)]}}],null,true)})],1),_c(VOverlay,{attrs:{"value":_vm.loading}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }