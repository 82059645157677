import { mapActions } from 'vuex'
import mixin from '@/views/mixin/api'

export default {
	mixins: [mixin],
	data: () => ({
		title: 'role',
		icon: 'mdi-account-tie',
		dialog: false,
		fullscreen: false,
		loading: false,

		tab: null,
        tabs: ['Головна'],
	}),
	props: {
		selected: 					{ type: Object, default: null },
		disabled:   				{ type: Boolean, default: false }, //Ознака неактивного елементу
		activatorType:  			{ type: String, default: 'button-icon' }, // Тип активатора вікна: button | icon | menu-item | list-item
		activatorIcon:  			{ type: String, default: 'mdi-plus' },
		activatorTitle:  			{ type: String, default: null },
		activatorOutlined:     		{ type: Boolean, default: false },
		activatorTooltipText:  		{ type: String, default: null },
		activatorTooltipDisabled:	{ type: Boolean, default: false },
	},

	watch: {
		dialog: function (val) {
			if(val) {
				this.onOpen()
			} else {
				this.onClose()
			}
		},
	},

	methods: {
		...mapActions([ 'showMessage' ]),

		addItems (items, data) {
			for(let i in data) {
				items.push(data[i]);
			}
		},

		removeItem (items, item) {
			const index = items.indexOf(item)
			items.splice(index, 1);
		},

		removeItems(items, filtered, indexes) {
			let itms = []
			indexes.forEach((i) => itms.push(filtered[i]));
			itms.forEach((item) => this.removeItem(items, item));
		},

		onOpen () {
			//console.log('details.mixin.main', 'onOpen','selected', this.selected)

			this.find(this.selected)
		},

		onClose () {
			this.setItem()
		},

		onCancel () {
			this.dialog = false
		},

		onOK () {
			//var that = this
			//this.loading = true

			/* let promise = this.item.id ? this.update() : this.create()
			promise.then(response => {
				//console.log('Roles', 'load','response.data', response.data)
				that.item = response.data

				that.loading 	= false
				this.dialog 	= false

				//that.showMessage({text: 'OK', status: '200'})
			}, error => {
				//console.log(error)
				that.loading = false
				that.showMessage(error)
			}) */
		},
	},
}