import {HTTP} from '../http'
import axios from 'axios'

export default {
    namespaced: true,
    state: {
        name: 'apikey',
        title: {
            single: 'apikey',
            plural: 'apikeys'
        },
        icon: 'mdi-key',

        apikey: null,
    },
    getters: {        
        name(state) {
            return state.name;
        },
        module(state) {
            return state.name;
        },
        path(state) {
            return '/'+state.name;
        },
        icon(state) {
            return state.icon;
        },
        title(state) {
            return state.title;
        },
        apikey(state) {
            return state.apikey;
        },
    },
    mutations: {
        apikey (state, data) {
            state.apikey = data;
        },
    },
    actions: {
        
        /**
         * Отримати
         * 
         * @param {*} context 
         * @param {*} data 
         * @returns 
         */
        Get(context, data) {
            //console.log(context.getters.name, 'Get','data', data)

            // Обнулення даних
            //context.commit('apikey')
            
            let url = context.getters.path + (data.hasOwnProperty('id')?'/' + data.id:'');

            console.log(context.getters.name, 'Get','url', url)

            /* 
            HTTP.get(url).then(response => {
                context.commit('item', response.data);
                if(data.hasOwnProperty('success')) data.success(response.data);
            }, error => {
                //context.dispatch('Message', error, {root: true});
                if(data.hasOwnProperty('error')) data.error(error.response);
            });
 */
            //console.log('app.apikey', 'Open','Authorization', axios.defaults.headers.common['Authorization'])

            /* let url = context.getters.path + (data.hasOwnProperty('id')?'/' + data.id:'');

            console.log(context.getters.name, 'Get','url', url) */
            
            axios.get(url).then(response => {
                context.commit('item', response.data);
                if(data.hasOwnProperty('success')) data.success(response.data);
            }, error => {
                //context.dispatch('Message', error, {root: true});
                if(data.hasOwnProperty('error')) data.error(error.response);
            });
        },

        /**
         * Змінити
         * 
         * @param {*} context 
         * @param {*} data 
         */
        Change(context, data) {
            //console.log(context.getters.name, 'Create', 'data', data)

            let url = context.getters.path + (data.hasOwnProperty('id')?'/' + data.id:'');
            HTTP.put(url, data.params, data.config).then(response => {
                context.commit('item', response.data);
                if(data.hasOwnProperty('success')) data.success(response.data);
            }, error => {
                context.dispatch('Message', error, {root: true});
                if(data.hasOwnProperty('error')) data.error(error);
            });
        },

        
        /**
         * Видалити 
         * 
         * @param {*} context 
         * @param {*} data 
         */
        Delete(context, data) {
            //console.log(context.getters.name, 'Delete','data', data)
            let url = context.getters.path + (data.hasOwnProperty('id')?'/' + data.id:'');
            HTTP.delete(context.getters.path + '/' + data.params.id).then(response => {
                context.commit('item', response.data);
                if(data.hasOwnProperty('success')) data.success(response.data);
            }, error => {
                context.dispatch('Message', error, {root: true});
                if(data.hasOwnProperty('error')) data.error(error);
            })
        },

        //---------------------------------------------------
        /* Clear(context, data) {
            context.commit('item', {
                firstname: null
                ,middlename: null
                ,lastname: null
                ,password: null
                ,nicname: null
                ,company: null
                ,email: null
                ,phone: null
                ,image: null
                ,description: null
                ,password: null
                ,confirm: null
            })
        }, */

        Load(context, data) {
            //context.dispatch('Find', data)
            //context.dispatch('Search', data)
            //console.log(context.getters.name, 'Load','data', data)

            context.commit('item', {
                surname: 'Іваненко', 
                firstname: 'Іван',
                patronymic: 'Іванович',

                name:   "Іваненко Іван Іванович",
                /* firstname: "John",
                middlename: null,
                lastname: "Leider", */
                company: "Firma",
                post: null,
                address: null,
                telephone: "0971112233",
                email: "qwins4@i.ua",
                inn: "1234567890",
                description: "Тестовий аккаунт",
                image: 'https://cdn.vuetifyjs.com/images/john.png',

                passport: {
                    seria: 'ВМ',
                    number: '112233',
                    issuedby: 'Київським РВ УМВС',
                    dateofissue: '1988-05-08',
                },

                bankaccounts: [
                    {
                        account: 'UA533057490000026008000000737',
                        name: 'АО "БАНК КРЕДИТ ДНІПРО"',
                        mfo: '305749',
                        depart: 'Маяковська',
                        currency: 'UAH'
                    },
                    {
                        account: 'UA183282090000026000010049162',
                        name: 'ПАО АБ «Південний»',
                        mfo: '328209',
                        depart: null,
                        currency: 'UAH'
                    },
                ],
            });
        },

        Save(context, data) {
            /* if(data.params.id) {
                context.dispatch('Update', data)
            } else {
                context.dispatch('Create', data)
            } */

            HTTP.put(context.getters.path + '/' + data.item.id, data.item).then(response => {
                context.commit('item', response.data);
                if(data.hasOwnProperty('success')) data.success(response);
            }, error => {
                //context.dispatch('Message', error, {root: true});
                if(data.hasOwnProperty('error')) data.error(error.response);
            });
           
        },
    }
}