import axios from 'axios'
export default {
	namespaced: true,
	state: {
		endpoint: '/document',
		name: 'Document',
		title: {
			single: 'Document',
			plural: 'Documents'
		},
		icon: 'mdi-file-document',
		item: {
			type: {},
			status: {},
			user: {},
			members: [],
			objects: [],
			properties: [],
			statuses: [],
		},
		items: [],
		headers: [
			{ text: 'id', value: 'id', sortable: true},
			//{ text: 'icon', value: 'icon', sortable: true, width: '10%'},
			{ text: 'name', value: 'name', sortable: true},
			{ text: 'number', value: 'number', sortable: true},
			{ text: 'type', value: 'type.name', sortable: true},
			{ text: 'date', value: 'date', sortable: true},
			{ text: 'datebegin', value: 'datebegin', sortable: true},
			{ text: 'dateend', value: 'dateend', sortable: true},
			{ text: 'members', value: 'members.length', sortable: true},
			{ text: 'objects', value: 'objects.length', sortable: true},
			{ text: 'properties', value: 'properties.length', sortable: true},
			{ text: 'status', value: 'status.name', sortable: true},
			{ text: 'description', value: 'description', sortable: true},
			{ text: 'user', value: 'user.name', sortable: true},
			{ text: 'actions', value: 'actions', sortable: false},
		],
		filter: {
        	page: 1,
			itemsPerPage: 15,
			onlymy: 0
		},
	},
	actions: {
		clearItem(context) {
			context.commit('item', {
				type: {},
				status: {},
				user: {},
				members: [],
				objects: [],
				properties: [],
				statuses: [],
			})
		},

		setStatus (context, item) {
			return axios.put(`${context.getters.endpoint}/${item.id}/status/${item.status.id}`)
			/* return axios.put(`${context.getters.endpoint}/${item.id}/status/${item.status.id}`).then(response => {
				//console.log(this.$options.name, 'onSearch','response.data', response.data)
				//context.commit('item', response.data)

				//const index = context.getters.items.indexOf(selected)
				//context.getters.items.splice(index, 1, context.getters.item)
			}, error => {
				console.log(error)
			}) */
		},
	},
}
