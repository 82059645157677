<template>
	<div>
		<!-- activator - icon -->
		<z-activator-icon v-if="type=='icon'" v-bind="$attrs" v-model="dialog"
			:icon-name="iconName"
			:tooltip-text="tooltipText"
			:disabled="disabled"
		></z-activator-icon>
		<!-- activator - button-icon -->
		<z-activator-button v-else-if="type=='button-icon'" v-bind="$attrs" v-model="dialog"
			icon
			:icon-name="iconName"
			:tooltip-text="tooltipText"
			:disabled="disabled"
		></z-activator-button>

		<!-- activator - list-item -->
		<z-activator-list-item v-else-if="type=='list-item'" v-bind="$attrs" v-model="dialog"
			:icon-name="iconName"
			:title="title"
			:disabled="disabled"
		></z-activator-list-item>

		<z-activator-button v-else v-bind="$attrs" v-model="dialog"
			:title="title"
			:icon-name="iconName"
			:outlined="outlined"
			:disabled="disabled"
			:tooltip-text="tooltipText"
			:tooltip-disabled="tooltipDisabled"
		></z-activator-button>
	</div>
</template>

<script>
import zActivatorIcon from './icon'
import zActivatorButton from './button'
import zActivatorListItem from './list-item'

export default {
	components: {
		zActivatorIcon, zActivatorButton, zActivatorListItem
	},
	data: () => ({

	}),
	props: {
		//value:				{ type: Boolean, default: false }, //dialog
		value:				{ type: Object, default: () => {} }, //dialog
		type:  				{ type: String, default: 'button-icon'}, // Тип активатора вікна: button | icon | menu-item | list-item

		title:				{ type: String, default: null },
        icon:				{ type: Boolean, default: false },
        text:				{ type: Boolean, default: false },
		iconName:			{ type: String, default: 'mdi-delete' },
        color:				{ type: String, default: null },
		large:     			{ type: Boolean, default: false },
        small:     			{ type: Boolean, default: false },
        dark:     			{ type: Boolean, default: false },
        block:     			{ type: Boolean, default: false },
        outlined:     		{ type: Boolean, default: false },
		disabled:  			{ type: Boolean, default: false }, //Ознака неактивного елементу

		dense:     			{ type: Boolean, default: false },

        tooltipText:		{ type: String, default: null },
		tooltipDisabled:	{ type: Boolean, default: false },
	},
	computed: {
		dialog: {
			get() 		{ return this.value },
			set(val) 	{ this.$emit('input', val) }
		},
	},
}

</script>