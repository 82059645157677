<template>
	<v-dialog  
	 	v-model="dialog"
	 	fullscreen
		scrollable
		transition_="dialog-bottom-transition"
	>
		<template v-slot:activator="{ on: dialog, attrs }">
			<v-tooltip bottom>
				<template v-slot:activator="{ on: tooltip, attrs }">
					<slot name="activator" :attrs="attrs" :dialog="dialog" :tooltip="tooltip" :icon="icon" :title="title.single">
						<v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...dialog }">
							<v-icon>mdi-pencil</v-icon>
						</v-btn>
					</slot>
				</template>
				<span>{{ $vuetify.lang.t(`$vuetify.${tooltip}`) | capitalize }}</span>
			</v-tooltip>
		</template>

		<v-card color="#EEEEEE">
			<v-toolbar flat color="#f5f5f5" style="flex: 0 1 auto;">
				<v-avatar>
					<v-icon>{{ iconDocument }}</v-icon>
				</v-avatar>
				<v-toolbar-title>{{ titleDocument.single }}</v-toolbar-title>
				<!-- <v-toolbar-title v-if="item.id">{{ title.single }} № {{ item.id }}</v-toolbar-title>
				<v-toolbar-title v-else>{{ title.single }}</v-toolbar-title> -->
				
				<v-spacer></v-spacer>

				<!-- Excel -->
				<z-excel
					:data="item"
					:workbook="workbook"
					:name="$options.name"
					@import="onImport"
				></z-excel>

				<v-divider vertical inset></v-divider>

				<v-btn icon @click="onSave" >
					<v-icon>mdi-content-save</v-icon>
				</v-btn>

				<v-btn icon @click="dialog=false" >
					<v-icon>mdi-close</v-icon>
				</v-btn>

				<!-- <template v-slot:extension>
					<v-tabs v-model="tab" align-with-title>
						<v-tabs-slider color="yellow"></v-tabs-slider>
						<v-tab v-for="item in tabs" :key="item">
							{{ item }}
						</v-tab>
					</v-tabs>
				</template> -->
			</v-toolbar>

			<v-card-text>
				<v-container>
					<v-sheet color="white" elevation="1">
						<v-card flat>
							<v-toolbar flat>
								<v-avatar>
									<v-icon>{{ icon }}</v-icon>
								</v-avatar>
								<!-- <v-toolbar-title>{{ title.single }}</v-toolbar-title> -->
								<v-toolbar-title v-if="item.id">{{ title.single }} № {{ item.id }}</v-toolbar-title>
								<v-toolbar-title v-else>{{ title.single }}</v-toolbar-title>

								<v-spacer></v-spacer>
								<!-- <v-toolbar-title>{{ item.id ? '№ ' + item.id: '' }}</v-toolbar-title> -->
							</v-toolbar>
							<v-card-text>
								<v-form ref="form">
									<v-row dense>
										<v-col cols="12" md="8">
											<v-card flat>
												<v-card-text>
													<v-text-field
														v-model="item.name"
														label="Назва"
														placeholder="Прайс-лист на лікарські засоби"
														:rules="[rules.required]"
													></v-text-field>
													<v-combobox
														v-model="item.objecttype"
														:items="objecttypes"
														label="Категорії товарів або послуг"
														item-text="name"
														item-value="id"
													></v-combobox>
													<v-combobox
														v-model="item.pricetype"
														:items="pricetypes"
														label="Тип ціни"
														item-text="name"
														item-value="id"
													></v-combobox>
													<v-textarea
														v-model="item.description"
														label="Опис"
														rows="1"
													></v-textarea>

													

													<!-- <v-text-field
														v-model="item.provider.name"
														label="Постачальник"
														:rules="[rules.required]"
													></v-text-field> -->

													
												</v-card-text>
											</v-card>

										</v-col>
										<v-col cols="12" md="4">
											<!-- <v-text-field
												v-model="item.code"
												label="Код"
											></v-text-field> -->
											<v-card flat>
												<v-card-text>
													<z-date 
														v-model="item.date"
														label="Дата складання"
													></z-date>
													<z-date
														v-model="item.datebegin"
														label="Дата початку періоду"
														clirable
													></z-date>
													<z-date
														v-model="item.dateend"
														label="Дата завершення періоду"
														clirable
													></z-date>
													<v-combobox
														v-model="item.currency"
														:items="currencies"
														label="Валюта"
														item-text="name"
														:prepend-inner-icon="item.currency.icon"
													></v-combobox>
												</v-card-text>
											</v-card>
										</v-col>
									</v-row>
									
									<v-divider></v-divider>

									<!-- <v-subheader>Постачальник</v-subheader>
									<v-container fluid>
										<v-row dense>
											<v-col cols="12">
												<v-text-field
													v-model="item.provider.name"
													label="Назва"
													:rules="[rules.required]"
												></v-text-field>
											</v-col>
										</v-row>
									</v-container> -->
									<v-card flat>
										<v-card-title class="text-caption">Постачальник</v-card-title>
										<v-card-text>
											<v-text-field
												v-model="item.provider.name"
												label="Назва"
												:rules="[rules.required]"
											></v-text-field>
										</v-card-text>
									</v-card>
								</v-form>
							</v-card-text>
						</v-card>

						<v-divider></v-divider>

						<v-card flat>
							<!-- <v-card-title>Учасники</v-card-title> -->
							<v-card-text>
								<v-data-table
									:headers="headers"
									:items="item.products"
									item-key="id"
									loading-text="Завантаження даних... зачекайте, будь ласка."
									:search="search"
									:custom-filter="filterOnlyCapsText"
								>
									<!-- 
										dense
										hide-default-footer
										:items-per-page="-1" 
									-->
									<template v-slot:top>
										<v-toolbar flat>
											<v-toolbar-title>Ціни</v-toolbar-title>
											<v-spacer></v-spacer>
											<v-text-field
												v-model="search"
												label="Пошук"
												class="mx-4"
											></v-text-field>
											<v-btn icon @click="clearPrices">
												<v-icon>mdi-delete-sweep</v-icon>
											</v-btn>
											<!-- Імпорт цін-->
											<z-excel-import
												@add="addPrices"
												@replace="replacePrices"
											></z-excel-import>

											<v-btn icon @click="addProduct">
												<v-icon>mdi-plus</v-icon>
											</v-btn>
										</v-toolbar>
									</template>

									<template v-slot:[`item.code`]="{ item }">
										<v-text-field
											v-model="item.code"
											:rules="[rules.required]"
											dense
										></v-text-field>
									</template>
									<template v-slot:[`item.name`]="{ item }">
										<v-text-field
											v-model="item.name"
											:rules="[rules.required]"
											dense
										></v-text-field>
									</template>
									<template v-slot:[`item.price`]="{ item }">
										<v-text-field
											v-model="item.price"
											type="number"
											hide-spin-buttons
											:rules="[rules.required]"
											dense
										>
											<!-- <template v-slot:append>
												<v-menu offset-y>
													<template v-slot:activator="{ on, attrs }">
														<v-btn text v-bind="attrs" v-on="on" :color="item.currency.color">
															{{ item.currency.code }}
														</v-btn>
													</template>
													<v-list>
														<v-list-item v-for="(itm, index) in currencies" :key="index" @click="item.currency=itm">
															<v-list-item-icon>
																<v-icon v-text="itm.icon" :color="itm.color"></v-icon>
															</v-list-item-icon>
															<v-list-item-title>{{ itm.name }}</v-list-item-title>
														</v-list-item>
													</v-list>
												</v-menu>
											</template> -->
										</v-text-field>
									</template>

									<template v-slot:[`item.actions`]="{ item, index }">
										<v-toolbar flat dense color="transparent">
											<v-spacer></v-spacer>
											<z-confirm-delete 
												:activator-tooltip-text="$vuetify.lang.t('$vuetify.delete')"
												@ok="removePrice(index)"
											></z-confirm-delete>
										</v-toolbar>
									</template>
								</v-data-table>
							</v-card-text>
						</v-card>

						<!-- return ['Головна', 'Учасники', "Об'єкти", 'Властивості', 'Статуси' ] -->

					</v-sheet>
				</v-container>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<!-- <v-btn text @click.native="onCancel">{{ $vuetify.lang.t('$vuetify.cancel') }}</v-btn> -->
				<v-btn color="success" dark @click.native="onOK" :disabled="loading">{{ $vuetify.lang.t('$vuetify.ok') }}</v-btn>
			</v-card-actions>
		</v-card>

		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
	</v-dialog>
</template>

<script>
import mixin from '@/views/mixin/edit'
import { mapGetters, mapActions } from 'vuex'
import zDate from '@/views/components/control/Date2'
import zConfirmDelete from '@/views/components/confirm-delete'
import zExcel from '@/views/components/menu/excel2'
import zExcelImport from '@/views/ExcelImport'

export default {
	name: 'DocumentEditPricelist',
	description: 'Форма для реєстрації прайс-листа',
	mixins: [mixin],
	components: {
		zDate,
		zConfirmDelete,
		zExcel,
		zExcelImport,
	},
	data: () => ({
		search: '',
		item: {
			name: 'Прайс-лист на лікарські засоби',
			type: {},
			objecttype: {},
			status: {},
			pricetype: {},
			date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			//date: new Date(),
			currency: {
				id: 980,
				code: "UAH",
				name: "Гривня",
				icon: "mdi-currency-uah",
				color: "#001489"
			},
			provider: {
				code: null,
				name: null,
			},
			products: [],
		},

		headers: [
			//{ text: 'id', value: 'id', sortable: true},
			{ text: 'Код', value: 'code', sortable: true, width: '20%'},
			{ text: 'Назва товару або послуги', value: 'name', sortable: true},
			//{ text: 'Одиниця виміру', value: 'unit', sortable: true}, //"кг", "шт", "м²", "послуга".
			{ text: 'Ціна за одиницю', value: 'price', sortable: true, width: '20%'},
			{ text: '', value: 'actions', sortable: false, width: '5%'},
		],

		workbook: {
			name: '',
			sheets: [
				{
					name: 'Основна інформація',
					type: 'Object',
					headers: [
						{ text: 'Назва', value: 'name' },
						{ text: 'Тип', value: 'type.name' },
						{ text: 'Категорія товарів або послуг', value: 'objecttype.name' },
						{ text: 'Тип ціни', value: 'pricetype.name' },
						{ text: 'Опис', value: 'description' },
						{ text: 'Постачальник', value: 'provider.name' },
						{ text: 'Дата складання', value: 'date' },
						{ text: 'Дата початку періоду', value: 'datebegin' },
						{ text: 'Дата завершення періоду', value: 'dateend' },
					],
				},
				{
					name: 'Таблиця',
					type: 'Array',
					value: 'products',
					element: 'products',
					headers: [
						{ text: 'Код', value: 'code' },
						{ text: 'Назва товару або послуги', value: 'name' },
						//{ text: 'Одиниця виміру', value: 'unit' },
						{ text: 'Ціна за одиницю', value: 'price' },
						{ text: 'Валюта', value: 'currency.code' },
					],
				},
			],
		}, 
	}),

	computed: {
		//...mapGetters('Document', [ 'icon', 'title', 'item' ] ),
		//...mapGetters('Document', [ 'item' ] ),
		...mapGetters('Document', { 'iconDocument':'icon', 'titleDocument': 'title', 'document': 'item' } ),
		...mapGetters('DocumentType', { 'types': 'items' } ),
		...mapGetters('DocumentStatusType', { 'statuses': 'items' } ),
		...mapGetters('DocumentMember', { 'iconMember':'icon', 'titleMember': 'title', 'headersMember': 'headers' } ),
		...mapGetters('DocumentMemberType', { 'membertypes': 'items' } ),
		...mapGetters('DocumentObject', { 'iconObject':'icon', 'titleObject': 'title', 'headersObject': 'headers' } ),
		...mapGetters('DocumentProperty', { 'iconProperty':'icon', 'titleProperty': 'title', 'headersProperty': 'headers' } ),
		...mapGetters('DocumentStatus', { 'iconStatus':'icon', 'titleStatus': 'title', 'headersStatus': 'headers' } ),

		...mapGetters('CurrencyType', { 'currencies': 'items' } ),
		...mapGetters('DocumentObjectType', { 'objecttypes': 'items' } ),
		...mapGetters('DocumentObjectPriceType', { 'pricetypes': 'items' } ),

		tabs() {
			//return ['Головна', this.titleMember.plural, this.titleObject.plural, this.titleProperty.plural, this.titleStatus.plural ]
			return ['Головна', 'Учасники', "Об'єкти", 'Властивості', 'Статуси' ]
		},

		data() {
			return this.item ? this.item: {};
		},

		icon() {
			return this?.item?.type?.icon ? this.item.type.icon : this.iconDocument;
		},

		title() {
			return this?.item?.type?.name ? { single: this.item.type.name } : this.titleDocument;
		},
	},

	created() {
		//console.log(this.$options.name, 'created')
		this.loadDocumentType()
		this.loadDocumentStatusType()
		this.loadCurrencyType()
		this.loadDocumentObjectType()
		this.loadDocumentObjectPriceType()

		this.$set(this.item, 'type', this.types.find(e => e.code === 'pricelist'))
		this.$set(this.item, 'pricetype', this.pricetypes.find(e => e.code === 'wholesale'))
	},

	methods: {
		...mapActions('Document', [ 'find', 'create', 'update', 'setItem' ]),
		...mapActions('DocumentType', { 'loadDocumentType': 'load' }),
		...mapActions('DocumentStatusType', { 'loadDocumentStatusType': 'load' }),
		...mapActions('DocumentMemberType', { 'loadDocumentMemberType': 'load' }),
		...mapActions('CurrencyType', { 'loadCurrencyType': 'load' }),
		...mapActions('DocumentObjectType', { 'loadDocumentObjectType': 'load' }),
		...mapActions('DocumentObjectPriceType', { 'loadDocumentObjectPriceType': 'load' }),

		filterOnlyCapsText (value, search, item) {
			return value != null &&
			search != null &&
			typeof value === 'string' &&
			//value.toString().toLocaleUpperCase().indexOf(search) !== -1
			value.toString().indexOf(search) !== -1
		},

		load() {
			if(this.id) {
				this.loading = true
				this.find(this.id).then(()=>{
					this.item = this.fromDocument()
				}).finally(() => {
					this.loading = false
				})
			}
		},

		init() {
			this.$set(this.item, 'type', this.types.find(e => e.code === 'pricelist'))
			this.$set(this.item, 'objecttype', this.objecttypes.find(e => e.code === 'drug'))
			this.$set(this.item, 'pricetype', this.pricetypes.find(e => e.code === 'wholesale'))
			this.$set(this.item, 'status', this.statuses.find(e => e.code === 'acting'))
			this.$set(this.item, 'date', (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10))
		},

		initObject(item, items, defaultCode) {
			let result = {}
			if(item) {
				if(!item?.id) {
					if(item?.code) {
						result = items.find(e => e.code === item.code)
					} else if(item?.name) {
						result = items.find(e => e.name === item.name)
					} else {
						result = items.find(e => e.code === defaultCode)
					}
				}
			}

			return result
		},

		addProduct() {
			this.item.products.push({
				code: null,
				name: null,
				price: null,
				currency: this.currencies.find(e => e.code === 'UAH')
			})
		},

		onImport(data) {
			//console.log(this.$options.name, 'onImport', 'data', data)
			this.item = data

			//Ініціалізація об'єктів з довідників
			this.$set(this.item, 'type', this.initObject(this.item?.type, this.types, 'pricelist'))
			this.$set(this.item, 'objecttype', this.initObject(this.item?.objecttype, this.objecttypes, 'drug'))
			this.$set(this.item, 'pricetype', this.initObject(this.item?.pricetype, this.pricetypes, 'wholesale'))
			this.$set(this.item, 'status', this.initObject(this.item?.status, this.statuses, 'acting'))

			this.item.products.forEach((item) => {
				this.$set(item, 'currency', this.initObject(item?.currency, this.currencies, 'UAH'))
			})
		},

		/* onImportPrice(data) {
			//console.log(this.$options.name, 'onImportPrice', 'data', data)
			//this.item.products = data
			data.forEach((item) => {
				this.item.products.push(item)
			})
		}, */

		clearPrices() {
			this.item.products = []
		},

		addPrices(data) {
			//console.log(this.$options.name, 'addPrices', 'data', data)

			data.forEach((item,index) => {
				//console.log(this.$options.name, 'addPrices', data.length, 'index', index)
				if(item.hasOwnProperty('code') && item.code > 0) {
					this.item.products.push(item)
				}
			})

			//console.log(this.$options.name, 'addPrices', 'products', this.item.products)
		},

		replacePrices(data) {
			//console.log(this.$options.name, 'replacePrices', 'data', data)

			this.clearPrices()
			this.addPrices(data)
		},

		removePrice(data) {
			//console.log(this.$options.name, 'removePrice', 'data', data)

			const index = Number.isInteger(data) ? data : this.item.products.indexOf(data);
			this.item.products.splice(index, 1);
		},

		toDocument() {
			//console.log(this.$options.name, 'toDocument', 'item', this.item)

			let document = {
				code: this.item.code,
				name: this.item.name,
				description: this.item.description,
				date: this.item.date,
				datebegin: this.item.datebegin,
				dateend: this.item.dateend,
				type: { id: this.item.type.id, name: this.item.type.name} ,
				status: {},
				members: [],
				objects: [],
			}

			let provider = this.item.provider
			document.members.push({
				code: provider?.code,
				name: provider?.name,
				type: { code: 'mainperson' },
			})

			this.item.products.forEach((item) => {
				//this.$set(item, 'currency', this.initObject(item?.currency, this.currencies, 'UAH'))
				let object = {
					code: item.code,
					name: item.name,
					type: { code: 'drug' },
					category: { code: 'goods'},
					//product: {},
					members: [],
					prices: [],
				}

				object.members.push({
					code: provider?.code,
					name: provider?.name,
					type: { code: 'provider' },
				})

				object.prices.push({
					//type: this.item.pricetype,
					//currency: item.currency,
					type: { id: this.item.pricetype.id, name: this.item.pricetype.name},
					currency: { id: item.currency.id, name: item.currency.name},
					value: item.price,
					datebegin: this.datebegin,
					dateend: this.dateend,
				})

				document.objects.push(object)
			})

			return document
		},

		fromDocument() {
			//console.log(this.$options.name, 'fromDocument', 'document', this.document)

			let provider = {}
			this.document.members.forEach((member) => {
				provider = {
					code: member.code,
					name: member.name,
				}
			})

			let products = [], pricetype = {}, objecttype = {}
			this.document.objects.forEach((object) => {
				objecttype = object.type

				let product = {
					code: object.code,
					name: object.name,
					price: null,
					currency: {},
				}
				
				object.prices.forEach((price) => {
					pricetype = price.type
					product.price = price.value
					product.currency = price.currency
				})

				products.push(product)
			})

			let result = {
				id: this.document.id,
				code: this.document.code,
				name: this.document.name,
				description: this.document.description,
				type: this.document.type,
				objecttype: objecttype,
				status: this.document.status,
				pricetype: pricetype,
				date: this.document.date.substr(0, 10),
				datebegin: this.document.datebegin.substr(0, 10),
				dateend: this.document.dateend.substr(0, 10),
				provider: provider,
				products: products,
			}

			//console.log(this.$options.name, 'fromDocument', 'result', result)

			return result
		},

		/* onSave() {
			//console.log(this.$options.name, 'changeImport', 'item', this.item)
			//console.log(this.$options.name, 'changeImport', 'document', this.toDocument())
		}, */
	},
}
</script>