import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [(_vm.activatorType=='icon')?_c(VTooltip,{attrs:{"bottom":"","disabled":_vm.tooltipDisabled},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"disabled":_vm.disabled}},'v-icon',attrs,false),Object.assign({}, tooltip, dialog)),[_vm._v(_vm._s(_vm.activatorIcon))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.delete'))))])]):(_vm.activatorType=='button-icon')?_c(VTooltip,{attrs:{"bottom":"","disabled":_vm.tooltipDisabled},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.disabled}},'v-btn',attrs,false),Object.assign({}, tooltip, dialog)),[_c(VIcon,[_vm._v(_vm._s(_vm.activatorIcon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.add'))))])]):(_vm.activatorType=='list-item')?_c(VListItem,_vm._g(_vm._b({attrs:{"disabled":_vm.disabled}},'v-list-item',attrs,false),Object.assign({}, dialog)),[_c(VListItemIcon,[_c(VIcon,{attrs:{"disabled":_vm.disabled}},[_vm._v(_vm._s(_vm.activatorIcon))])],1),_c(VListItemTitle,[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.delete'))))])],1):_c(VTooltip,{attrs:{"bottom":"","disabled":_vm.tooltipDisabled},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"outlined":"","color":"primary"}},'v-btn',attrs,false),Object.assign({}, tooltip, dialog)),[_c(VIcon,{attrs:{"left":"","dark":"","color":"primary"}},[_vm._v(_vm._s(_vm.activatorIcon))]),_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.delete')))+" ")],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$vuetify.lang.t('$vuetify.delete'))))])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.title)+" "),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.dialog=false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"text-center"},[_c('p',{staticClass:"text-subtitle-1 font-weight-black"},[_vm._v("Ви дійсно бажаєте здійснити видалення?")]),_c('p',{staticClass:"text-subtitle-1 font-weight-black"},[_vm._v("Дані будуть видалені без можливості їх подальшого відновлення!")])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":_vm.onCancel}},[_vm._v("Відхилити")]),_c(VBtn,{attrs:{"outlined":"","color":"success"},on:{"click":_vm.onOK}},[_vm._v("Підтверджую")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }