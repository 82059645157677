import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VNavigationDrawer,{staticStyle:{"padding":"64px 0px 0px 0px"},attrs:{"fixed":"","clipped":"","permanent":"","right":"","mini-variant":"","mini-variant-width":"56"}},[_c(VList,{attrs:{"dense":"","nav":""}},[_c(VListItem,[_c(VListItemAction,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"large":"","color":"red"},on:{"click":_vm.onPDF}},'v-icon',attrs,false),Object.assign({}, tooltip)),[_vm._v("mdi-file-pdf-box")])]}}])},[_c('span',[_vm._v("Експорт PDF")])])],1)],1),_c(VListItem,[_c(VListItemAction,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('download-excel',{attrs:{"data":_vm.excel.data,"name":_vm.excel.name,"type":_vm.excel.type}},[_c(VIcon,_vm._g(_vm._b({attrs:{"large":"","color":"green darken-2","disabled":_vm.excel.disabled}},'v-icon',attrs,false),on),[_vm._v("mdi-microsoft-excel")])],1)]}}])},[_c('span',[_vm._v("Експорт Excel")])])],1)],1),_c(VDivider)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }