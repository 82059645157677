<template>
	<v-form ref="form">
		<v-card  elevation="0">
			<v-system-bar class="my-2">
				<span>Параметри для ринку</span>
				<v-spacer></v-spacer>
			</v-system-bar>

			<v-card  elevation="0">
				<v-row dense>
					<v-col cols="12" sm="6" md="6">
						<v-text-field
							label="Реєстраційний номер"
							v-model="item.regnum"
							outlined
							dense
							clearable
							@keyup.ctrl.90="$set(item,'regnum', 'ab9999it')"
							hide-details="auto"
						></v-text-field>
						<!-- @keyup.ctrl.90="$set(item,'regnum', 'ВЕ9387ВХ')" -->
					</v-col>
					<v-col cols="12" sm="6" md="6">
						<v-text-field
							label="VIN-код"
							v-model="item.vin"
							outlined
							dense
							clearable
							@keyup.ctrl.90="$set(item,'vin', 'JT153EEA100276750')"
							hide-details="auto"
						></v-text-field>
					</v-col>
				</v-row>
			</v-card>

			<v-card  elevation="0" v-if="['market','metodica','exploration'].includes(type)">
				<v-row dense>
					<!-- <v-col cols="12" sm="6" md="6">
						<v-text-field
							label="Реєстраційний номер"
							v-model="item.regnum"
							outlined
							dense
							clearable
							@keyup.ctrl.90="$set(item,'regnum', 'ВЕ9387ВХ')"
							hide-details="auto"
						></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" md="6">
						<v-text-field
							label="VIN-код"
							v-model="item.vin"
							outlined
							dense
							clearable
							@keyup.ctrl.90="$set(item,'vin', 'JT153EEA100276750')"
							hide-details="auto"
						></v-text-field>
					</v-col> -->
					<v-col cols="12" sm="6" md="6">
						<v-combobox
							v-model="item.brand"
							:items="brands"
							label="Марка"
							outlined
							dense
							item-text="name"
							item-value="value"
							hide-details="auto"
						></v-combobox>
					</v-col>
					<v-col cols="12" sm="6" md="6">
						<v-combobox
							v-model="item.model"
							:items="models"
							label="Модель"
							outlined
							dense
							item-text="name"
							item-value="value"
							hide-details="auto"
						></v-combobox>
					</v-col>
					<!-- <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="3">
						<v-combobox
							v-model="item.year"
							:items="years"
							label="Рік"
							outlined
							dense
						></v-combobox>
					</v-col> -->
					<v-col cols="12" sm="6" md="6" lg="6" xl="6">
						<v-combobox
							v-model="item.yearfrom"
							:items="years"
							label="Рік, від"
							outlined
							dense
							hide-details="auto"
						></v-combobox>
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="6" xl="6">
						<v-combobox
							v-model="item.yearto"
							:items="years"
							label="Рік, по"
							outlined
							dense
							hide-details="auto"
						></v-combobox>
					</v-col>
					<!-- <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="3">
						<v-text-field
							label="Пробіг, тис.км"
							v-model="item.race"
							outlined
							dense
							type="number"
						></v-text-field>
					</v-col> -->
					<!-- <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
						<v-text-field
							label="Показник одометра, км"
							v-model="item.odometer"
							outlined
							dense
							type="number"
						></v-text-field>
					</v-col> -->
					<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
						<v-text-field
							label="Пробіг від, тис.км"
							v-model="item.racefrom"
							outlined
							dense
							type="number"
							hide-details="auto"
						></v-text-field>
					</v-col>
					<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
						<v-text-field
							label="Пробіг по, тис.км"
							v-model="item.raceto"
							outlined
							dense
							type="number"
							hide-details="auto"
						></v-text-field>
					</v-col>

					<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
						<v-combobox
							v-model="item.engine"
							:items="engines"
							label="Двигун"
							outlined
							dense
							item-text="name"
							item-value="value"
							hide-details="auto"
						></v-combobox>
					</v-col>
					<!-- <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="3">
						<v-text-field
							label="Об'єм двигуна"
							v-model="item.engineVolume"
							outlined
							dense
							type="number"
						></v-text-field>
					</v-col> -->
					<!-- <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
						<v-text-field
							label="Об'єм від"
							v-model="item.capacityfrom"
							outlined
							dense
							type="number"
							hide-details="auto"
						></v-text-field>
					</v-col>
					<v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
						<v-text-field
							label="Об'єм по"
							v-model="item.capacityto"
							outlined
							dense
							type="number"
							hide-details="auto"
						></v-text-field>
					</v-col> -->
					
					<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
					<!-- <v-col cols="12"> -->
						<v-combobox
							v-model="item.gearbox"
							:items="gearboxes"
							label="Коробка передач"
							outlined
							dense
							item-text="name"
							item-value="value"
							hide-details="auto"
						></v-combobox>
					</v-col>
					<!-- <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
						<v-combobox
							v-model="item.currency"
							:items="currencies"
							item-value="id"
							item-text="name"
							label="Валюта"
							outlined 
							dense
							hide-details="auto"
						></v-combobox>
					</v-col> -->
				</v-row>
			</v-card>

			<!-- Параметри для середньої ціни -->
			<v-card  elevation="0" v-if="['averageprice'].includes(type)">
				<v-row dense>
					<v-col cols="12" sm="6" md="6">
						<v-combobox
							v-model="item.brand"
							:items="brands"
							label="Марка"
							outlined
							dense
							item-text="name"
							item-value="id"
							hide-details="auto"
						></v-combobox>
					</v-col>
					<v-col cols="12" sm="6" md="6">
						<v-combobox
							v-model="item.model"
							:items="models"
							label="Модель"
							outlined
							dense
							item-text="name"
							item-value="id"
							hide-details="auto"
						></v-combobox>
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="6" xl="6">
						<v-combobox
							v-model="item.yearGTE"
							:items="years"
							label="Рік, від"
							outlined
							dense
							hide-details="auto"
						></v-combobox>
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="6" xl="6">
						<v-combobox
							v-model="item.yearLTE"
							:items="years"
							label="Рік, по"
							outlined
							dense
							hide-details="auto"
						></v-combobox>
					</v-col>
					<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
						<v-text-field
							label="Пробіг від, тис.км"
							v-model="item.mileageGTE"
							outlined
							dense
							type="number"
							hide-details="auto"
						></v-text-field>
					</v-col>
					<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
						<v-text-field
							label="Пробіг по, тис.км"
							v-model="item.mileageLTE"
							outlined
							dense
							type="number"
							hide-details="auto"
						></v-text-field>
					</v-col>
					<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
						<v-text-field
							label="Об'єм двигуна від"
							v-model="item.engineVolumeGTE"
							outlined
							dense
							type="number"
							hide-details="auto"
						></v-text-field>
					</v-col>
					<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
						<v-text-field
							label="Об'єм двигуна до"
							v-model="item.engineVolumeLTE"
							outlined
							dense
							type="number"
							hide-details="auto"
						></v-text-field>
					</v-col>


					<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
						<v-combobox
							v-model="item.engine"
							:items="engines"
							label="Двигун"
							outlined
							dense
							item-text="name"
							item-value="value"
							hide-details="auto"
						></v-combobox>
					</v-col>
					<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
						<v-combobox
							v-model="item.body"
							:items="bodies"
							label="Кузов"
							outlined
							dense
							item-text="name"
							item-value="value"
							hide-details="auto"
						></v-combobox>
					</v-col>
					<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
						<v-combobox
							v-model="item.gearBox"
							:items="gearboxes"
							label="Коробка передач"
							outlined
							dense
							item-text="name"
							item-value="value"
							hide-details="auto"
						></v-combobox>
					</v-col>

					<!-- <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
						<v-combobox
							v-model="item.fuel"
							:items="fuels"
							label="Тип палива"
							outlined
							dense
							item-text="name"
							item-value="value"
							hide-details="auto"
						></v-combobox>
					</v-col>
					<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
						<v-combobox
							v-model="item.drive"
							:items="drives"
							label="Привід"
							outlined
							dense
							item-text="name"
							item-value="value"
							hide-details="auto"
						></v-combobox>
					</v-col>
					<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
						<v-combobox
							v-model="item.color"
							:items="colors"
							label="Колір"
							outlined
							dense
							item-text="name"
							item-value="value"
							hide-details="auto"
						></v-combobox>
					</v-col>
					<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
						<v-combobox
							v-model="item.state"
							:items="states"
							label="Область"
							outlined
							dense
							item-text="name"
							item-value="value"
							hide-details="auto"
						></v-combobox>
					</v-col>
					<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
						<v-combobox
							v-model="item.generation"
							:items="generations"
							label="Покоління"
							outlined
							dense
							item-text="name"
							item-value="value"
							hide-details="auto"
						></v-combobox>
					</v-col>
					<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
						<v-combobox
							v-model="item.modification"
							:items="modifications"
							label="Модифікація"
							outlined
							dense
							item-text="name"
							item-value="value"
							hide-details="auto"
						></v-combobox>
					</v-col> -->
				</v-row>
			</v-card>
		</v-card>

		<v-card v-if="['metodica','exploration'].includes(type)">
			<v-system-bar class="my-2">
				<span>Параметри для методики</span>
				<v-spacer></v-spacer>
			</v-system-bar>

			<v-row dense>
				<v-col cols="12">
					<v-combobox
						v-model="item.year"
						:items="years"
						label="Рік виробництва"
						outlined
						dense
						hide-details="auto"
					></v-combobox>
				</v-col>
				<v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
					<v-text-field
						label="Показник одометра, км"
						v-model="item.odometer"
						outlined
						dense
						type="number"
						hide-details="auto"
					></v-text-field>
				</v-col>
				<v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
					<v-text-field
						label="Нормативний середньорічний пробіг, км"
						v-model="item.racenorm"
						outlined
						dense
						type="number"
						hide-details="auto"
					></v-text-field>
				</v-col>
				<v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
					<v-text-field
						label="Середня ринкова ціна"
						v-model="item.cd"
						outlined
						dense
						type="number"
						hide-details="auto"
					></v-text-field>
				</v-col>
				<v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
					<v-combobox
						label="Ринок регіону"
						v-model="item.marketregion"
						:items="marketregion"
						outlined 
						dense
						item-text="name"
						item-value="value"
						hide-details="auto"
					></v-combobox>
				</v-col>
			</v-row>
		</v-card>


		<v-card  elevation="0" v-if="['exploration'].includes(type)">
			<v-system-bar class="my-2" v-if="['exploration'].includes(type)">
				<span>Умови  експлуатації</span>
				<v-spacer></v-spacer>
			</v-system-bar>

			<!-- Фактори, що впливають на збільшення вартості КТЗ -->
			<v-card elevation="0">
				<v-card-title class="text-subtitle-2 font-weight-bold">Фактори, що впливають на збільшення вартості КТЗ</v-card-title>
				<v-card-text>
					<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.increas.no_corrosion"
						hint="Для строку експлуатації не менше 7 - 12 років"
					>
						<template v-slot:label>
							<span class="text-caption">Відсутність корозійних пошкоджень складових частин кузова</span>
						</template>
					</v-checkbox>
					<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.increas.no_damage_paint"
						hint="Для строку експлуатації не менше 7 - 12 років"
					>
						<template v-slot:label>
							<span class="text-caption">Відсутність пошкоджень лакофарбового покриття за умови, що відновлювальний ремонт кузова не виконували</span>
						</template>
					</v-checkbox>
					<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.increas.engine_repair"
						hint="Для строку експлуатації не менше 7 - 12 років"
					>
						<template v-slot:label>
							<span class="text-caption">Виконано капітальний ремонт двигуна не більше як за 1 рік до дати оцінки</span>
						</template>
					</v-checkbox>
					<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.increas.body_repair"
						hint="Для строку експлуатації не менше 7 - 12 років"
					>
						<template v-slot:label>
							<span class="text-caption">Виконано капітальний ремонт кузова з повним пофарбуванням не більше як за 3 роки до дати оцінки</span>
						</template>
					</v-checkbox>
					<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.increas.body_replacement"
						hint="Для строку експлуатації не менше 7 - 12 років"
					>
						<template v-slot:label>
							<span class="text-caption">Виконано заміну кузова на новий не більше як за 5 років до дати оцінки</span>
						</template>
					</v-checkbox>
				</v-card-text>
			</v-card>

			<!-- Фактори, що впливають на зменшення вартості КТЗ -->
			<v-card elevation="0">
				<v-card-title class="text-subtitle-2 font-weight-bold">Фактори, що впливають на зменшення вартості КТЗ</v-card-title>
				<v-card-text>
					<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.reduce.taxi">
						<template v-slot:label>
							<span class="text-caption">КТЗ експлуатувався в режимі таксі</span>
						</template>
					</v-checkbox>
					<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.reduce.specialized" >
						<template v-slot:label>
							<span class="text-caption">КТЗ спеціалізованого призначення</span>
						</template>
					</v-checkbox>
					<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.reduce.outside_public_roads">
						<template v-slot:label>
							<span class="text-caption">КТЗ застосовувався поза дорогами загального користування</span>
						</template>
					</v-checkbox>

					<v-radio-group v-model="item.use.reduce.body_parts_repair">
						<template v-slot:label>
							<div>Кузовні складові КТЗ відновлювалися ремонтом</div>
						</template>
						<v-radio value="3">
							<template v-slot:label>
								<div class="text-caption">У разі відновлення трьох і більше складових кузова.</div>
							</template>
						</v-radio>
						<v-radio value="2">
							<template v-slot:label>
								<div class="text-caption">У разі відновлення не більше двох складових кузова</div>
							</template>
						</v-radio>
						<v-radio value="0">
							<template v-slot:label>
								<div class="text-caption">Не відновлювалися</div>
							</template>
						</v-radio>
					</v-radio-group>

					<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.reduce.parts_need_repair"
						hint="Сліди підтікання олив та пального; підвищена димність і шумність двигуна, порушення функціональних можливостей тощо"
					>
						<template v-slot:label>
							<span class="text-caption">КТЗ має складові частини, які потребують ремонту (окрім заміни чи капітального ремонту складової)</span>
						</template>
					</v-checkbox>

					<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.reduce.color_not_demand" >
						<template v-slot:label>
							<span class="text-caption">КТЗ пофарбовано в колір, який не користується попитом</span>
						</template>
					</v-checkbox>
				</v-card-text>
			</v-card>

			<!-- Елементи додаткового зменшення вартості КТЗ з дефектами кузова, кабіни, рами(1)  -->

			<v-card elevation="0">
				<v-card-title class="text-subtitle-2 font-weight-bold">Елементи додаткового зменшення вартості КТЗ з дефектами кузова, кабіни, рами</v-card-title>
				<!-- Вибирається з таблиці 4.2 -->
				<v-card elevation="0">
					<v-card-title class="text-subtitle-2">ЕЛЕМЕНТИ КУЗОВА (пошкодження корозією)</v-card-title>
					<v-card-text>
						<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.reduce.elements.body_floor_panel" >
							<template v-slot:label>
								<span class="text-caption">Панель підлоги кузова, кабіни</span>
							</template>
						</v-checkbox>
						<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.reduce.elements.box_elements" >
							<template v-slot:label>
								<span class="text-caption">Коробчасті елементи збільшення жорсткості (лонжерони, поперечини, підсилювачі, підмоторна рама)</span>
							</template>
						</v-checkbox>
						<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.reduce.elements.body_sills" >
							<template v-slot:label>
								<span class="text-caption">Пороги кузова</span>
							</template>
						</v-checkbox>

						<!-- 4 -->
						<!-- <v-subheader>Передок кузова, кабіни:</v-subheader> -->
						<v-card elevation="0">
							<v-card-title class="text-subtitle-2">Передок кузова, кабіни:</v-card-title> <!-- body_front -->
							<v-card-text>
								<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.reduce.elements.fenders_front" >
									<template v-slot:label>
										<span class="text-caption">бризковики передніх крил</span>
									</template>
								</v-checkbox>
								<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.reduce.elements.shield_front" >
									<template v-slot:label>
										<span class="text-caption">щиток передка</span>
									</template>
								</v-checkbox>
								<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.reduce.elements.panels_front" >
									<template v-slot:label>
										<span class="text-caption">панелі передка (полиці щитків радіатора)</span>
									</template>
								</v-checkbox>
								<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.reduce.elements.fenders_radiator" >
									<template v-slot:label>
										<span class="text-caption">бризковик облицювання радіатора</span>
									</template>
								</v-checkbox>
							</v-card-text>
						</v-card>

						<!-- 5 -->
						<v-card elevation="0">
							<v-card-title class="text-subtitle-2">Боковина кузова, кабіни:</v-card-title>
							<v-card-text>
								<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.reduce.elements.risers_sides" >
									<template v-slot:label>
										<span class="text-caption">стояки боковин</span>
									</template>
								</v-checkbox>
								<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.reduce.elements.arches_sides" >
									<template v-slot:label>
										<span class="text-caption">арки боковин (бризковики боковин)</span>
									</template>
								</v-checkbox>
								<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.reduce.elements.panels_sides" >
									<template v-slot:label>
										<span class="text-caption">панелі боковин</span>
									</template>
								</v-checkbox>
								<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.reduce.elements.doors" >
									<template v-slot:label>
										<span class="text-caption">Двері</span>
									</template>
								</v-checkbox>
							</v-card-text>
						</v-card>

						<!-- 6 -->
						<v-card elevation="0">
							<v-card-title class="text-subtitle-2">Задок кузова, кабіни:</v-card-title>
							<v-card-text>
								<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.reduce.elements.panels_back" >
									<template v-slot:label>
										<span class="text-caption">панелі задка</span>
									</template>
								</v-checkbox>
								<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.reduce.elements.shelves_back" >
									<template v-slot:label>
										<span class="text-caption">полиці задка з перегородкою (стінка моторного відсіку)</span>
									</template>
								</v-checkbox>
							</v-card-text>
						</v-card>

						<!-- 7 -->
						<v-card elevation="0">
							<v-card-title class="text-subtitle-2">Дах кузова, кабіни:</v-card-title>
							<v-card-text>
								<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.reduce.elements.panel_roof" >
									<template v-slot:label>
										<span class="text-caption">панель даху</span>
									</template>
								</v-checkbox>
								<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.reduce.elements.panel_side_back" >
									<template v-slot:label>
										<span class="text-caption">панель бокова задня</span>
									</template>
								</v-checkbox>
							</v-card-text>
						</v-card>

						<!-- 8 -->
						<v-card elevation="0">
							<v-card-title class="text-subtitle-2">Оперення:</v-card-title>
							<v-card-text>
								<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.reduce.elements.wing_removable" >
									<template v-slot:label>
										<span class="text-caption">крило знімне</span>
									</template>
								</v-checkbox>
								<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.reduce.elements.wing_unremovable" >
									<template v-slot:label>
										<span class="text-caption">крило незнімне</span>
									</template>
								</v-checkbox>
								<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.reduce.elements.bonnet" >
									<template v-slot:label>
										<span class="text-caption">капот</span>
									</template>
								</v-checkbox>
								<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.reduce.elements.boot_lid" >
									<template v-slot:label>
										<span class="text-caption">кришка багажника</span>
									</template>
								</v-checkbox>
							</v-card-text>
						</v-card>
					</v-card-text>
				</v-card>


				<v-card elevation="0">
					<v-card-title class="text-subtitle-2">ЕЛЕМЕНТИ КУЗОВА, КАБІНИ, РАМИ (деформація)</v-card-title>
					<v-card-text>
						<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.reduce.elements.deformations_no_paint" >
							<template v-slot:label>
								<span class="text-caption">Деформації без пошкодження лакофарбового покриття</span>
							</template>
						</v-checkbox>
						<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.reduce.elements.body_skew" >
							<template v-slot:label>
								<span class="text-caption">Інформативні ознаки наявності перекосу кузова чи необхідності правки рами КТЗ (окрім випадків, що передбачають складання калькуляції відновлювального ремонту аварійно пошкодженого КТЗ)</span>
							</template>
						</v-checkbox>
					</v-card-text>
				</v-card>

				<v-card elevation="0">
					<v-card-title class="text-subtitle-2">ОББИВКА КУЗОВА, КАБІНИ (забруднення, пошкодження, потертості)</v-card-title>
					<v-card-text>
						<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.reduce.elements.upholstery_saloon" >
							<template v-slot:label>
								<span class="text-caption">Оббивка салону (даху, стояків боковин, полиць, дверей)</span>
							</template>
						</v-checkbox>
						<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.reduce.elements.upholstery_sits" >
							<template v-slot:label>
								<span class="text-caption">Оббивка сидінь</span>
							</template>
						</v-checkbox>
					</v-card-text>
				</v-card>

				<v-card elevation="0">
					<v-card-title class="text-subtitle-2">ПОФАРБУВАННЯ КУЗОВА, КАБІНИ (дефекти лакофарбового покриття)</v-card-title>
					<v-card-text>
						<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.reduce.elements.body_painting" >
							<template v-slot:label>
								<span class="text-caption">Пофарбування кузова</span>
							</template>
						</v-checkbox>
					</v-card-text>
				</v-card>

				<v-card elevation="0">
					<v-card-title class="text-subtitle-2">ХРОМОВАНІ ДЕТАЛІ (корозія, потьмяніння, відшарування)</v-card-title>
					<v-card-text>
						<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.reduce.elements.chrome_plating" >
							<template v-slot:label>
								<span class="text-caption">Хромовані покриття</span>
							</template>
						</v-checkbox>
					</v-card-text>
				</v-card>

				<v-card elevation="0">
					<v-card-title class="text-subtitle-2">СКЛО (потертості, пошкодження)</v-card-title>
					<v-card-text>
						<v-checkbox class="text-caption" dense hide-details="auto" v-model="item.use.reduce.elements.glass" >
							<template v-slot:label>
								<span class="text-caption">Скло</span>
							</template>
						</v-checkbox>
					</v-card-text>
				</v-card>
			</v-card>
<!-- ------------------------------------------------------------------
| N  |   Найменування елементів,   |Допустиме найбільше зниження |
|з/п |    складових частин КТЗ(2)  |     вартості(3), Дз, %      |
|    |                             |                             |
|----------------------------------------------------------------|
|            ЕЛЕМЕНТИ КУЗОВА(4) (пошкодження корозією)           |
|----------------------------------------------------------------|
|  1 |Панель підлоги кузова, кабіни|             4,0             |
|----+-----------------------------+-----------------------------|
|  2 |Коробчасті елементи          |             6,0             |
|    |збільшення жорсткості        |                             |
|    |(лонжерони, поперечини,      |                             |
|    |підсилювачі, підмоторна      |                             |
|    |рама)                        |                             |
|----+-----------------------------+-----------------------------|
|  3 |Пороги кузова                |             1,0             |
|----+-----------------------------+-----------------------------|
|  4 |Передок кузова, кабіни:      |                             |
|----|                             |                             |
|4.1 |бризковики передніх крил     |             2,0             |
|----+-----------------------------+-----------------------------|
|4.2 |щиток передка                |             3,0             |
|----+-----------------------------+-----------------------------|
|4.3 |панелі передка (полиці       |             1,0             |
|    |щитків радіатора)            |                             |
|----+-----------------------------+-----------------------------|
|4.4 |бризковик облицювання        |             0,3             |
|    |радіатора                    |                             |
|----+-----------------------------+-----------------------------|
|  5 |Боковина кузова, кабіни:     |                             |
|----|                             |                             |
|5.1 |стояки боковин               |             4,0             |
|----+-----------------------------+-----------------------------|
|5.2 |арки боковин (бризковики     |             2,0             |
|    |боковин)                     |                             |
|----+-----------------------------+-----------------------------|
|5.3 |панелі боковин               |             2,0             |
|----+-----------------------------+-----------------------------|
|5.4 |Двері                        |             0,5             |
|----+-----------------------------+-----------------------------|
|  6 |Задок кузова, кабіни:        |                             |
|----|                             |                             |
|6.1 |панелі задка                 |             0,5             |
|----+-----------------------------+-----------------------------|
|6.2 |полиці задка з перегородкою  |             0,5             |
|    |(стінка моторного відсіку)   |                             |
|----+-----------------------------+-----------------------------|
|  7 |Дах кузова, кабіни:          |                             |
|----|                             |                             |
|7.1 |панель даху                  |             1,0             |
|----+-----------------------------+-----------------------------|
|7.2 |панель бокова задня          |             0,3             |
------------------------------------------------------------------ 


------------------------------------------------------------------
| N  |Найменування елементів,      |Допустиме найбільше зниження |
|з/п |складових частин КТЗ(2)      |вартості(3), Дз, %           |
|----+-----------------------------+-----------------------------|
|  8 |Оперення:                    |                             |
|----|                             |                             |
|8.1 |крило знімне                 |             0,5             |
|----+-----------------------------+-----------------------------|
|8.2 |крило незнімне               |             1,0             |
|----+-----------------------------+-----------------------------|
|8.3 |капот                        |             0,5             |
|----+-----------------------------+-----------------------------|
|8.4 |кришка багажника             |             0,5             |
|----------------------------------------------------------------|
|          ЕЛЕМЕНТИ КУЗОВА, КАБІНИ, РАМИ(5) (деформація)         |
|----------------------------------------------------------------|
|  9 |Деформації без пошкодження   |              5              |
|    |лакофарбового покриття       |                             |
|----+-----------------------------+-----------------------------|
| 10 |Інформативні ознаки наявності|              15             |
|    |перекосу кузова чи           |                             |
|    |необхідності правки рами КТЗ |                             |
|    |(окрім випадків, що          |                             |
|    |передбачають складання       |                             |
|    |калькуляції відновлювального |                             |
|    |ремонту аварійно пошкодженого|                             |
|    |КТЗ)                         |                             |
|----------------------------------------------------------------|
|ОББИВКА КУЗОВА, КАБІНИ(5) (забруднення, пошкодження, потертості)|
|----------------------------------------------------------------|
| 11 |Оббивка салону (даху, стояків|             1,0             |
|    |боковин, полиць, дверей)     |                             |
|----+-----------------------------+-----------------------------|
| 12 |Оббивка сидінь               |             2,0             |
|----------------------------------------------------------------|
|ПОФАРБУВАННЯ КУЗОВА, КАБІНИ(5) (дефекти лакофарбового покриття) |
|----------------------------------------------------------------|
| 13 |Пофарбування кузова          |             3,0             |
|----------------------------------------------------------------|
|     ХРОМОВАНІ ДЕТАЛІ(5) (корозія, потьмяніння, відшарування)   |
|----------------------------------------------------------------|
| 14 |Хромовані покриття           |             3,0             |
|----------------------------------------------------------------|
|                СКЛО(5) (потертості, пошкодження)               |
|----------------------------------------------------------------|
| 15 |Скло                         |             0,5             |
------------------------------------------------------------------  -->

		</v-card>
	</v-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
	data () {
		return {

			// Значення коефіцієнта К ринку регіону
			marketregion: [
				{ id: 1, name: 'Адміністративні райони і районні центри з низькою інвестиційною привабливістю', k: 0.97 },
				{ id: 2, name: 'Обласні центри, районні центри з високою інвестиційною привабливістю', k: 1.03 },
				{ id: 3, name: 'Інші райони', k: 1.0 },
			],
		}
	},
	props: {
		type: {
			type: String,
			default: 'metodica' // check | market | metodica | exploration
		},
		item: {
			type: Object,
			default() {
				return { racenorm: 19000 }
			}
		},
	},

	watch: {
		'item.brand': function (val) {
			if(val) {
				this.Models({brand: val.id})
			}
		},
		/* item: function (val) {
			if(val) {
				console.log('vehicle.estimate.detail', 'watch', 'item', val)
			}
		}, */
	},
	computed: {
		...mapGetters('vehicles',[
			'categories', 'brands', 'models', 'engines', 'gearboxes', 'bodies', 'years',
		]),
	},
	created () {
		if(this.brands.length == 0) 	this.Brands({});
		if(this.engines.length == 0) 	this.Engines({});
		if(this.gearboxes.length == 0) 	this.Gearboxes({});
		//if(this.bodies.length == 0) 	this.Bodies({});
	},
	methods: {
		...mapActions('vehicles', [
			'Categories', 'Brands', 'Models', 'Engines', 'Gearboxes', 'Bodies',
		]),

		Clear () {
			this.$refs.form.reset()
			//this.$set(this.vehicle,'racenorm', 19000)
		},

		/* Set (item) {
			if(item) {
				this.item = Object.assign({}, item)
			}
		}, */

	},
}
</script>