export default {
    namespaced: true,
    state: {
		params: {
            type: { code: 'exploration' },
		    number: null,
			vin: null,
			mileage: [],
			engineVolume: [],
            use: {},
			bargain: 0.93,
		},

        item: {
            percentiles: [],
            adverts: [],
            registrations: [],
        },

        //Dictionaries
		brands: [],
		models: [],
		generations: [],
		modifications: [],
		bodystyles: [],
		drivers: [],
		gearboxes: [],
		options: [],
		cities: [],

		vehicle: [],
		market: [],
		percentiles: {},
		offers: [],
    },
    getters: {
		params(state) {
			return state.params
		},
		
		brands(state) {
			return state.brands
		},
		models(state) {
			return state.models
		},
		generations(state) {
			return state.generations
		},
		modifications(state) {
			return state.modifications
		},
		bodystyles(state) {
			return state.bodystyles
		},
		drivers(state) {
			return state.drivers
		},
		gearboxes(state) {
			return state.gearboxes
		},
		options(state) {
			return state.options
		},
		cities(state) {
			return state.cities
		},
		
		isset(state) {
			return state.params.number 
				|| state.params.vin 
				|| state.params.brand
				|| state.params.model
				//|| state.params.engine
				//|| state.params.gearbox
		},
		vehicle(state) {
			return state.vehicle
		},
		market(state) {
			return state.market
		},
		percentiles(state) {
			return state.percentiles
		},
		offers(state) {
			return state.offers
		},
    },
    mutations: {
		params (state, data) {
			state.params = data
		},

		brands (state, data) {
			state.brands = data?data:[]
		},
		models (state, data) {
			state.models = data?data:[]
		},
		generations (state, data) {
			state.generations = data?data:[]
		},
		modifications (state, data) {
			state.modifications = data?data:[]
		},
		bodystyles (state, data) {
			state.bodystyles = data?data:[]
		},
		drivers (state, data) {
			state.drivers = data?data:[]
		},
		gearboxes (state, data) {
			state.gearboxes = data?data:[]
		},
		options (state, data) {
			state.options = data?data:[]
		},
		cities (state, data) {
			state.cities = data?data:[]
		},

		vehicle (state, data) {
			if(data) {
				state.vehicle = []
				for (let key in data) {
					state.vehicle.push(data[key])
				}
			} else {
				state.vehicle = []
			}
			//state.vehicle = data?data:[]
		},
		market (state, data) {
			state.market = data?data:[]
		},
		percentiles (state, data) {
			state.percentiles = data?data:{}
		},
		offers (state, data) {
			let arr = data.slice().sort(function(a, b) {
				return a.price - b.price;
			})

			state.offers = arr?arr:[]
		},
    },
    actions: {
		clear (context) {
			let d = new Date()
			context.commit('params', {
                type: { code: 'market' },
                number: null,
			    vin: null,
				mileage: [],
				engineVolume: [],
                use: {},
				bargain: 0.93,
			})

            context.commit('item', {
				percentiles: [],
                adverts: [],
                registrations: [],
			})

			context.commit('vehicle', [])
			context.commit('market', [])
			context.commit('percentiles', [])
		},

		setBrands (context, data) {
			context.commit('brands', data)
		},
		setModels (context, data) {
			context.commit('models', data)
		},
		setGenerations (context, data) {
			context.commit('generations', data)
		},
		setModifications (context, data) {
			context.commit('modifications', data)
		},
		setBodystyles (context, data) {
			context.commit('bodystyles', data)
		},
		setDrivers (context, data) {
			context.commit('drivers', data)
		},
		setGearboxes (context, data) {
			context.commit('gearboxes', data)
		},
		setOptions (context, data) {
			context.commit('options', data)
		},
		setCities (context, data) {
			context.commit('cities', data)
		},
    },
}