import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VContainer,{staticStyle:{"background":"linear-gradient(45deg,#ffffff,#257047)"},attrs:{"fluid":""}},[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('div',{staticClass:"mt-8 text-h5"},[_vm._v("Сервіси для бізнесу")]),_c('p',{staticClass:"mt-8 mb-16 text-body-1 font-weight-light"},[_vm._v(" Широкий спектр сервісів в різних напрямках для сучасного бізнесу."),_c('br'),_vm._v(" Доступно та зручно. ")])]),_c(VCol,{staticClass:"text-right d-flex align-stert mb-6",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('img',{attrs:{"src":require('../assets/honeycomb.svg')}}),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('img',{attrs:{"src":require('../assets/logo_tinex.jpg')}})]),_c(VCol,{attrs:{"cols":"12"}},[_c('img',{attrs:{"src":require('../assets/logo_pvbki_ukr.png')}})])],1)],1)],1)],1)],1),_c(VMain,{staticStyle:{"padding":"30px 0px 0px"}},[_c(VContainer,[_c(VRow,_vm._l((_vm.services),function(item,index){return _c(VCol,{key:index,attrs:{"cols":"12","md":"6"}},[_c('ShopCard',{attrs:{"overline":item.overline,"title":item.title,"subtitle":item.subtitle,"icon":item.icon,"router":item.router,"color":item.color}})],1)}),1)],1)],1),_c(VOverlay,{attrs:{"value":_vm.loading}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }