<template>
	<v-dialog  
	 	v-model="dialog"
	 	fullscreen
		scrollable
		transition_="dialog-bottom-transition"
	>
		<template v-slot:activator="{ on: dialog, attrs }">
			<v-tooltip bottom>
				<template v-slot:activator="{ on: tooltip, attrs }">
					<slot name="activator" :attrs="attrs" :dialog="dialog" :tooltip="tooltip">
						<v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...dialog }">
							<v-icon>mdi-pencil</v-icon>
						</v-btn>
					</slot>
				</template>
				<span>{{ $vuetify.lang.t(`$vuetify.${tooltip}`) | capitalize }}</span>
			</v-tooltip>
		</template>

		<v-card>
			<v-toolbar flat color="#f5f5f5" style="flex: 0 1 auto;">
				<v-avatar>
					<v-icon>{{ icon }}</v-icon>
				</v-avatar>
				<v-toolbar-title v-if="item.id">{{ title }} № {{ item.id }}</v-toolbar-title>
				<v-toolbar-title v-else>{{ title }}</v-toolbar-title>
				
				<v-spacer></v-spacer>

				<v-btn icon @click="onSave" >
					<v-icon>mdi-content-save</v-icon>
				</v-btn>

				<v-btn icon @click="dialog=false" >
					<v-icon>mdi-close</v-icon>
				</v-btn>

				<template v-slot:extension>
					<v-tabs v-model="tab" align-with-title>
						<v-tabs-slider color="yellow"></v-tabs-slider>
						<v-tab v-for="item in tabs" :key="item">
							{{ item }}
						</v-tab>
					</v-tabs>
				</template>
			</v-toolbar>

			<v-card-text>
				<v-tabs-items v-model="tab">
					<v-tab-item>
						<v-card flat>
							<v-card-text>
								<v-container>
									<v-form ref="form">
										<v-row dense>
											<v-col cols="12" sm="3" md="2">
												<v-text-field
													v-model="item.code"
													label="Код"
												></v-text-field>
											</v-col>
										</v-row>
										<v-row dense>
											<v-col cols="12" sm="6" md="4">
												<v-text-field
													v-model="item.name"
													label="Назва"
													:rules="[rules.required]"
												></v-text-field>
											</v-col>
										</v-row>
										<v-row dense>
											<v-col cols="12" sm="6" md="4">
												<v-combobox
													v-model="item.type"
													:items="types"
													label="Тип"
													item-text="name"
													item-value="id"
												></v-combobox>
											</v-col>
										</v-row>
										<v-row dense>
											<v-col cols="12" sm="6" md="4">
												<z-date 
													v-model="item.date"
													label="Дата"
												></z-date>
											</v-col>
										</v-row>
										<v-row dense>
											<v-col cols="12" sm="6" md="4">
												<z-date
													v-model="item.datebegin"
													label="Дата початку періоду"
												></z-date>
											</v-col>
											<v-col cols="12" sm="6" md="4">
												<z-date
													v-model="item.dateend"
													label="Дата завершення періоду"
												></z-date>
											</v-col>
										</v-row>
										<v-row dense>
											<v-col cols="12">
												<v-textarea
													v-model="item.description"
													label="Опис"
												></v-textarea>
											</v-col>
										</v-row>
									</v-form>
								</v-container>
							</v-card-text>
						</v-card>
					</v-tab-item>
					<v-tab-item>
						<v-card flat>
							<v-card-text>
								<v-data-table
									:headers="headers"
									:items="item.objects"
									item-key="id"
									loading-text="Завантаження даних... зачекайте, будь ласка."
									hide-default-footer
								>
									<template v-slot:top>
										<v-toolbar flat>
											<v-spacer></v-spacer>

										</v-toolbar>
									</template>

									<template v-slot:[`item.priceMin`]="{ item }">
										<span>{{ item.priceMin | toCurrencyUAH }}</span>
									</template>

									<template v-slot:[`item.priceMax`]="{ item }">
										<span>{{ item.priceMax | toCurrencyUAH }}</span>
									</template>

									<template v-slot:[`item.priceAvg`]="{ item }">
										<span>{{ item.priceAvg | toCurrencyUAH }}</span>
									</template>

									<template v-slot:[`item.actions`]="{ item }">
										<v-toolbar flat dense color="transparent">
											<!-- <z-statistic v-if="item.priceCount" :item="item"></z-statistic> -->
										</v-toolbar>
									</template>

									<template slot="body.append">
										<tr>
											<th class="title"></th>
											<th class="font-weight-bold">{{ sumField('priceMin') | toCurrencyUAH }}</th>
											<th class="font-weight-bold">{{ sumField('priceAvg') | toCurrencyUAH }}</th>
											<th class="font-weight-bold">{{ sumField('priceMax') | toCurrencyUAH }}</th>
											<th class="font-weight-bold">{{ sumField('priceCount') }}</th>
										</tr>
									</template>
								</v-data-table>
							</v-card-text>
						</v-card>
					</v-tab-item>
				</v-tabs-items>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<!-- <v-btn text @click.native="onCancel">{{ $vuetify.lang.t('$vuetify.cancel') }}</v-btn> -->
				<v-btn color="success" dark @click.native="onOK" :disabled="loading">{{ $vuetify.lang.t('$vuetify.ok') }}</v-btn>
			</v-card-actions>
		</v-card>

		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
	</v-dialog>
</template>

<script>
import mixin from '@/views/mixin/edit'
import { mapGetters, mapActions } from 'vuex'
import zDate from '@/views/components/control/Date'

export default {
	name: 'EstimateEdit',
	description: 'Форма для редагування інформації оцінки',
	mixins: [mixin],
	components: {
		zDate,
	},
	data: () => ({
		headers: [
			//{ text: 'ID', value: 'id', sortable: true, width: '50' },
			{ text: 'Назва', value: 'name', sortable: true },
			{ text: 'Мінімальна ціна', value: 'priceMin', sortable: true },
			{ text: 'Середня ціна', value: 'priceAvg', sortable: true },
			{ text: 'Максимальна ціна', value: 'priceMax', sortable: true },
			{ text: 'Кількість цін в оцінці', value: 'priceCount', sortable: true },
			{ text: '', value: 'actions', sortable: false },
		],

		tabs: ['Головна', "Об'єкти"],
	}),

	props: {
		icon:   		{ type: String, default: 'mdi-home-analytics' },
		title:   		{ type: String, default: 'Оцінка' },
	},

	computed: {
		...mapGetters('Estimate', [ 'item' ] ),
		...mapGetters('EstimateType',{ 'types': 'items' } ),

		estimate() {
			return this.item ? this.item: {};
		},
	},

	created() {
		//console.log(this.$options.name, 'created')
		this.loadType()
	},

	methods: {
		...mapActions('Estimate', [ 'find', 'create', 'update', 'setItem' ]),
		...mapActions('EstimateType', { 'loadType': 'load' }),

		init() {
			this.item.type = this.types[0]
		},

		sumField(key) {
			return this.item.objects.reduce((a, b) => a + (b[key] || 0), 0)
		},
	},
}
</script>