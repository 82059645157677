<template>
	<v-main>
		<v-navigation-drawer fixed clipped permanent style="padding: 64px 0px 0px 0px;">
			<v-card elevation="0">
				<v-card-text>
					<!-- <z-vehicle-parameters-number></z-vehicle-parameters-number> -->
					<z-vehicle-parameters></z-vehicle-parameters>
				</v-card-text>
			</v-card>
			<template v-slot:append>
				<v-card-actions class="px-4">
					<v-spacer></v-spacer>
					<v-btn text color="primary" @click="onClear">{{ $vuetify.lang.t('$vuetify.clear') }}</v-btn>
					<v-btn color="primary" @click="onOK">{{ $vuetify.lang.t('$vuetify.ok') }}</v-btn>
				</v-card-actions>
			</template>
		</v-navigation-drawer>

		<v-navigation-drawer fixed clipped permanent right
			mini-variant
			mini-variant-width="56"
			style="padding: 64px 0px 0px 0px;"
        >
			<v-list dense nav>
				<!-- PDF -->
				<v-list-item>
					<v-list-item-action>
						<v-tooltip bottom>
							<template v-slot:activator="{ on: tooltip, attrs }">
								<v-icon large color="red" v-bind="attrs" v-on="{ ...tooltip }" @click="onPDF">mdi-file-pdf-box</v-icon>
							</template>
							<span>Експорт PDF</span>
						</v-tooltip>
					</v-list-item-action>
				</v-list-item>

				<!-- Excel -->
				<v-list-item>
					<v-list-item-action>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<download-excel 
									:data="excel.data"
									:name="excel.name"
									:type="excel.type"
								>
									<v-icon large color="green darken-2" :disabled="excel.disabled" v-bind="attrs" v-on="on">mdi-microsoft-excel</v-icon>
								</download-excel>
							</template>
							<span>Експорт Excel</span>
						</v-tooltip>
					</v-list-item-action>
				</v-list-item>
			</v-list>
        </v-navigation-drawer>

		<v-container style="padding: 64px 0px 0px 256px;">
			<v-card elevation="0">
				<v-card-title class="font-weight-light">
					{{ title }} {{ id ? '№' + id: '' }}
				</v-card-title>

				<v-divider></v-divider>

				<v-expansion-panels v-model="panel" multiple style="position: static;">

					<v-expansion-panel v-if="registrations.length">
						<v-expansion-panel-header class="font-weight-bold" color="grey lighten-5">Транспортний засіб</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-simple-table dense>
								<template v-slot:default>
									<thead>
										<tr>
											<th class="text-left">Назва</th>
											<th class="text-left">Значення</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="item in registrations" :key="item.code">
											<td>{{ item.name }}</td>
											<td>{{ item.val }}</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-expansion-panel-content>
					</v-expansion-panel>
					
					<v-expansion-panel v-if="estimates.length">
						<v-expansion-panel-header class="font-weight-bold" color="grey lighten-5">Оцінка ринку</v-expansion-panel-header>
						<v-expansion-panel-content v-for="estimate in estimates" :key="estimate.id">

							<v-simple-table dense>
								<template v-slot:default>
									<tbody>
										<tr>
											<td>Середнє арифметичне</td>
											<td>{{ parseInt(estimate.arithmeticMean) }}</td>
										</tr>
										<tr>
											<td>Середнє арифметичне без урахування 25% найменших і найбільших значень</td>
											<td>{{ parseInt(estimate.interQuartileMean) }}</td>
										</tr>
										<tr>
											<td>Загальна кількість оголошень, що беруть участь у підрахунку</td>
											<td>{{ estimate.total }}</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
							
							<v-card elevation="0">
								<v-card-title class="text-subtitle-2 font-weight-bold">Процентілі</v-card-title>
								<v-simple-table dense>
									<template v-slot:default>
										<thead>
											<tr>
												<th class="text-left" v-for="percentile in estimate.percentiles" :key="percentile.id" >{{ percentile.code }}</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td v-for="percentile in estimate.percentiles" :key="percentile.id">{{ parseInt(percentile.price) }}</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-card>

							<v-expansion-panels v-model="panel2" multiple style="position: static;">
								<v-expansion-panel>
									<v-expansion-panel-header class="text-subtitle-2 font-weight-bold">Ціни</v-expansion-panel-header>
									<v-expansion-panel-content>
										<v-chip-group active-class="primary--text" column>
											<v-chip v-for="advert in adverts(estimate.adverts)" :key="advert.id" :href="getAdvertUrl(advert)"  target="_blank">
												{{ parseInt(advert.price) }}
											</v-chip>
										</v-chip-group>
									</v-expansion-panel-content>
								</v-expansion-panel>
							</v-expansion-panels>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-card>
		</v-container>
		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
		<v-snackbar v-model="snackbar.visible" :color="snackbar.color">
			{{ snackbar.text }}
			<template v-slot:action="{ attrs }">
			<v-btn icon v-bind="attrs" @click="snackbar.visible = false" >
				<v-icon>mdi-close</v-icon>
			</v-btn>
			</template>
		</v-snackbar>

		<z-pdf ref="pdf"
			:item="item"
		></z-pdf>
	</v-main>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import downloadExcel from "vue-json-excel";
import zVehicleParametersNumber from '@/views/forms/vehicle.parameters.number'
import zVehicleParameters from '@/views/forms/vehicle.parameters'
import zPdf from '@/views/pdf/VehicleEstimate'


export default {
	components: {
		zPdf,
		downloadExcel,
		zVehicleParametersNumber,
		zVehicleParameters,
	},
	data: () => ({
		title: 'Аналіз ринкових цін транспортного засобу',
		loading: false,

		panel: [0,1],
		panel2: [0,1],

		excel: {
			name: '',
			type: 'xlsx',
			disabled: true,
			data: []
		},
		//excelType: 'xlsx',

		snackbar: {
			visible: false,
			text: null,
			color: null
		},
	}),
	props: {
		
	},

	watch: {
		
	},
	
	computed: {
		...mapGetters('VehicleEstimate', [
			'params', 'item', 'isset'
		]),

		excelName() {
			return this.title + (this.params.number ? ' ' + this.params.number : (this.params.vin ? ' ' + this.params.vin : '') + '.' +  this.excel.type)
		},


		brand() {
			return this.params.hasOwnProperty('brand') ? this.params.brand.name : '';
		},
		model() {
			return this.params.hasOwnProperty('model') ? this.params.model.name : '';
		},
		estimates() {
			return this.item.hasOwnProperty('estimates') ? this.item.estimates: [];
		},

		id() {
			return this.item.hasOwnProperty('id') ? this.item.id: null;
		},

		registrations() {
			return this.item.hasOwnProperty('registrations') ? this.item.registrations: [];
		},
	},

	created () {
		//this.clear()
		//this.load()
	},
	methods: {
		...mapActions('VehicleEstimate', [
			'setItem', 'clear'
		]),

		adverts(items) {
			const sorted = [...items].sort((a, b) => parseInt(a.price) - parseInt(b.price));
			return sorted
		},

		getAdvertUrl(item) {
			//console.log('VehicleEstimateMarket', 'getAdvertUrl', 'item', item)

			return `https://auto.ria.com/uk/auto_${this.brand}_${this.model}_${item.code}.html`
		},

		onClear() {
			//this.$refs.parameters.clear()
			this.clear()
		},

		validate() {
			return this.params.number 
				|| this.params.vin
				|| this.params.brand
				|| this.params.model
		},

		onOK() {
			var that = this

			//console.log('VehicleEstimateMarket', 'onOK', 'params', this.params)
			//return

			if(this.validate()) {
				this.loading = true
				axios.post('/vehicle/estimate/market', this.params).then(response => {
					//console.log('VehicleEstimateMarket', 'onOK','response.data', response.data)
					
					that.setItem(response.data)
					that.loading = false
				}, error => {
					console.log('error', error)
					that.loading = false

					that.snackbar.visible 	= true;
					that.snackbar.text 		= error.response.data.message;
					that.snackbar.color 	= null;
				});
			} else {
				this.snackbar.visible 	= true;
				this.snackbar.text 		= 'Відсутні дані для пошуку';
				this.snackbar.color 	= null;
			}
		},

		onPDF () {
			this.$refs.pdf.generate()
		}
	},
}
</script>