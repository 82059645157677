import mixin from './api'
export default {
	mixins: [mixin],
	data: () => ({
		title: 'roles',
		loading: false,

		excel: {
			name: '',
			type: 'xlsx',
			disabled: true,
			data: []
		},

		selected: [],
		headers: [
            { text: 'ID', value: 'id', sortable: true},
            { text: 'Назва', value: 'name', sortable: true},
            { text: 'Actions', value: 'actions', sortable: false },
        ],
	}),

	created () {
		this.onRefresh()
	},

	methods: {
		add(data) {
			//console.log(this.$options.name, 'add', 'data', data)

			this.items.push(data)
		},

		edit(item, data) {
			console.log(this.$options.name, 'edit', 'data', data)
			const index = this.items.indexOf(item)
			this.items.splice(index, 1, data)
			console.log(this.$options.name, 'edit', 'items', this.items)
		},

		remove(item, data) {
			/* const index = this.items.indexOf(item)
			this.items.splice(index, 1, data) */
		},

		onRefresh() {
			this.findAll()
		},

		onDelete(item) {
			if(item) {
				this.delete(item)
			} else {
				this.selected.forEach((i) => {
					let item = this.items[i]
					//this.delete(item)
				})
			}
		},
	},
}