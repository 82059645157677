export default {
	namespaced: true,
	state: {
		endpoint: '/document/object/price',
		name: 'DocumentObjectPrice',
		title: {
			single: 'DocumentObjectPrice',
			plural: 'DocumentObjectPrices'
		},
		icon: 'mdi-cash',
		items: [],
		headers: [
			//{ text: 'ID', value: 'id', sortable: true, width: '50' },
			//{ text: 'Код', value: 'code', sortable: true },
			//{ text: 'Назва', value: 'name', sortable: true },
			{ text: 'Сума', value: 'value', sortable: true},
			{ text: 'Тип', value: 'type.name', sortable: true},
			{ text: 'Дата початку дії', value: 'datebegin', sortable: true},
			{ text: 'Дата завершення дії', value: 'dateend', sortable: true},
			//{ text: 'Опис', value: 'description', sortable: true },
			{ text: 'Дії', value: 'actions', sortable: false, width: '10%' },
		],
		item: {
			type: {},
			currency: {},
			user: {},
		},
	},
	actions: {
		clearItem(context) {
			context.commit('item', {
				type: {},
				currency: {},
				user: {},
			})
		},
	},
}
