<template>
    <v-menu>
		<template v-slot:activator="{ on: dialog, attrs }">
			<v-tooltip bottom>
				<template v-slot:activator="{ on: tooltip, attrs }">
					<slot name="activator" :attrs="attrs" :dialog="dialog" :tooltip="tooltip">
						<v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...dialog }">
							<v-icon color="#1d6f42">{{ icon }}</v-icon>
						</v-btn>
					</slot>
				</template>
				<span>{{ tooltip }}</span>
			</v-tooltip>
		</template>

		<v-file-input class="d-none" ref="fileinput" v-model="file" @change="changeImport"></v-file-input>

		<v-list>
			<v-list-item @click="onImport">
				<v-list-item-icon>
					<!-- <v-icon>mdi-import</v-icon> -->
					<v-icon color="yellow darken-2">mdi-folder</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>{{ $vuetify.lang.t('$vuetify.import') | capitalize }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-list-item @click="onExport">
				<v-list-item-icon>
					<!-- <v-icon>mdi-export</v-icon> -->
					<v-icon color="light-blue darken-4">mdi-download</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>{{ $vuetify.lang.t('$vuetify.export') | capitalize }} </v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</v-menu>
</template>

<script>
import * as XLSX from "xlsx"; //https://www.npmjs.com/package/xlsx

export default {
	name: 'MenuExcel',
	components: {
		
	},

	data: () => ({
		text: null,
		type: 'success',
		visible: false,

		file: null,
	}),

	props: {
		icon:   		{ type: String, default: 'mdi-microsoft-excel' },
		title:   		{ type: String, default: 'Excel' },
		tooltip:  		{ type: String, default: 'Excel' },
		disabled:   	{ type: Boolean, default: false }, //Ознака неактивного елементу
		//fields:  		{ type: Object, default: () => {} },
		data:  			{ type: Object, default: () => {} },
		//worksheet:  	{ type: String, default: 'My Worksheet' },
		name:  			{ type: String, default: 'Книга 1' },
		accept:			{ type: String, default: '.xlsx' },
	},

	methods: {
		onImport() {
			this.$refs.fileinput.$refs.input.accept = this.accept;
			this.$refs.fileinput.$refs.input.click()
		},

		/**
		 * let data = {
				name: 'Книга 1',
				sheets: [
					{
						name: 'Лист 1',
						data_json: [
							{
								a:1,
								b:2,
								c:3
							},
							{
								a:4,
								b:5,
								c:6
							},
						],
						data_array: [
							["A1", "B1", "C1"],
							["A2", "B2", "C2"],
							["A3", "B3", "C3"]
						]

					},
				]
			}
		 */

		onExport() {
			//console.log(this.$options.name, 'onExport', 'utils', utils)
			//console.log(this.$options.name, 'onExport', 'XLSX', XLSX)

			let data = this.data
			
			let filename = (data?.name ? data.name : this.name ) + '.xlsx'
			
			var workbook = XLSX.utils.book_new();
			
			if(data.hasOwnProperty('sheets')) {
				data.sheets.forEach((sheet) => {
					let name = sheet.name, worksheet = null

					if(sheet.hasOwnProperty('data_json')) {
						let jsa = sheet.data_json
						worksheet = XLSX.utils.json_to_sheet(jsa);
					}

					if(sheet.hasOwnProperty('data_array')) {
						let aoa = sheet.data_array
						worksheet = XLSX.utils.aoa_to_sheet(aoa);
					}

					//console.log(this.$options.name, 'onExport', 'SheetName', name, 'jsa', jsa)

					XLSX.utils.book_append_sheet(workbook, worksheet, name);
				})
			}

			XLSX.writeFileXLSX(workbook, filename);
		},

		changeImport(files) {
			//console.log(this.$options.name, 'changeImport', 'files', files)

			let file = Array.isArray(files) ? files[0] : files;
			if (file) {
				const reader = new FileReader();

				reader.onload = (e) => {
					//console.log(this.$options.name, 'changeImport', 'XLSX', XLSX)

					const bstr = e.target.result;
					const wb = XLSX.read(bstr, { type: 'binary' });
					
					/* const wsname = wb.SheetNames[0];
					const ws = wb.Sheets[wsname];
					const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

					//console.log(this.$options.name, 'changeImport', 'data', data)

					this.$emit('import', data)
 					*/

					// Перетворення в об'єкт для відповіді
					let result = {
						name: this.name,
						sheets: []
					}
					
					wb.SheetNames.forEach((wsname) => {
						const ws = wb.Sheets[wsname];
						
						const data_array 	= XLSX.utils.sheet_to_json(ws, { header: 1 });
						const data_json 	= XLSX.utils.sheet_to_row_object_array(ws)

						//console.log(this.$options.name, 'WorkbookParameters', 'data_array', data_array)
						//console.log(this.$options.name, 'WorkbookParameters', 'data_json', data_json)

						result.sheets.push({
							name: wsname,
							data_json: data_json,
							data_array: data_array,
						})
					})

					//console.log(this.$options.name, 'changeImport', 'result', result)

					this.$emit('import', result)
				}

				reader.readAsBinaryString(this.file);
			}
		},
    },
}
</script>