import axios from 'axios'
export default {
	namespaced: true,
	state: {
		endpoint: '/document/object/price/type',
		name: 'DocumentObjectPriceType',
		title: {
			single: 'DocumentObjectPriceType',
			plural: 'DocumentObjectPriceTypes'
		},
		icon: 'mdi-format-list-bulleted-type',
		items: [],
	},
	actions: {
		load (context, data) {
			return axios.get(context.getters.endpoint).then(response => {
				context.commit('items', response.data)
			}, error => {
				console.log('error', error)
			})
		},
	},
}
