<template>
	<v-tooltip bottom :disabled="tooltipDisabled">
		<template v-slot:activator="{ on: tooltip, attrs }">
			<v-icon v-bind="attrs" v-on="{ ...tooltip, ...dialog }"
				:large="large" 
				:small="small" 
				:dark="dark" 
				:disabled="disabled"
			>{{ iconName }}</v-icon>
		</template>
		<span>{{ tooltipText | capitalize }}</span>
	</v-tooltip>
</template>

<script>
export default {
	data: () => ({

	}),
	props: {
		//value:				{ type: Boolean, default: false }, //dialog
		value:				{ type: Object, default: () => {} }, //dialog
        iconName:			{ type: String, default: 'mdi-delete' },
        color:				{ type: String, default: null },
		large:     			{ type: Boolean, default: false },
        small:     			{ type: Boolean, default: false },
        dark:     			{ type: Boolean, default: false },
		disabled:  			{ type: Boolean, default: false }, //Ознака неактивного елементу

        tooltipText:		{ type: String, default: null },
		tooltipDisabled:	{ type: Boolean, default: false },
	},
	computed: {
		dialog: {
			get() 		{ return this.value },
			set(val) 	{ this.$emit('input', val) }
		},
	},
}

</script>