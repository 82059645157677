<template>
	<v-card elevation="0">
		<v-card-title class="text-h6">{{ title }}</v-card-title>
		<v-card class="mx-auto" color="grey lighten-4">
			<v-card-title>
				<div class="text-caption grey--text text-uppercase">
					Динаміка середньої ціни
				</div>
				<v-spacer></v-spacer>
				<!-- <strong>UAH</strong> -->
			</v-card-title>

			<v-sheet color="transparent">
				<v-sparkline
					:labels="labels(estimate.prices)"
					:value="valueUAH(estimate.prices)"
					color="blue"
					line-width="1"
					padding="16"
					label-size="3"
				></v-sparkline>
			</v-sheet>
		</v-card>
		<!-- <v-carousel v-if="prices.length"
			height="300"
			hide-delimiters
			show-arrows-on-hover
		>
			<v-carousel-item>
				<v-card class="mx-auto" color="grey lighten-4" height="300">
					<v-card-title>
						<div class="text-caption grey--text text-uppercase">
							Середня ціна за періоди
						</div>
						<v-spacer></v-spacer>
						<strong>UAH</strong>
					</v-card-title>

					<v-sheet color="transparent">
						<v-sparkline
							:labels="labels(estimate.prices)"
							:value="valueUAH(estimate.prices)"
							color="blue"
							line-width="1"
							padding="16"
							label-size="3"
						></v-sparkline>
					</v-sheet>
				</v-card>
			</v-carousel-item>
			<v-carousel-item>
				<v-card class="mx-auto" color="grey lighten-4" height="300">
					<v-card-title>
						<div class="text-caption grey--text text-uppercase">
							Середня ціна за періоди
						</div>
						<v-spacer></v-spacer>
						<strong>USD</strong>
					</v-card-title>

					<v-sheet color="transparent">
						<v-sparkline
							:labels="labels(estimate.prices)"
							:value="valueUSD(estimate.prices)"
							color="blue"
							line-width="1"
							padding="16"
							label-size="3"
						></v-sparkline>
					</v-sheet>
				</v-card>
			</v-carousel-item>
		</v-carousel> -->

		<v-row v-if="prices.length">
			<v-col cols="12">
				
				
			</v-col>

			<!-- <v-col cols="12" md="6">
				<v-card class="mx-auto" color="grey lighten-4">
					<v-card-title>
						<div class="text-caption grey--text text-uppercase">
							Середня ціна за періоди
						</div>
						<v-spacer></v-spacer>
						<strong>USD</strong>
					</v-card-title>

					<v-sheet color="transparent">
						<v-sparkline
							:labels="labels(estimate.prices)"
							:value="valueUSD(estimate.prices)"
							color="blue"
							line-width="1"
							padding="16"
							label-size="3"
						></v-sparkline>
					</v-sheet>
				</v-card>
			</v-col> -->

			<v-col cols="12" md="6">
				<v-card>
					<v-list dense>
						<v-subheader>Середня ціна</v-subheader>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>UAH</v-list-item-title>
							</v-list-item-content>
							<v-list-item-action>
								<v-list-item-action-text>{{ avgUAH(estimate.prices) | toCurrencyUAH }}</v-list-item-action-text>
							</v-list-item-action>
						</v-list-item>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>USD</v-list-item-title>
							</v-list-item-content>
							<v-list-item-action>
								<v-list-item-action-text>{{ avgUSD(estimate.prices) | toCurrencyUSD }}</v-list-item-action-text>
							</v-list-item-action>
						</v-list-item>
					</v-list>
				</v-card>
			</v-col>

			<v-col cols="12" md="3">
				<v-card>
					<v-list dense>
						<v-subheader>Мінімальна ціна</v-subheader>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>UAH</v-list-item-title>
							</v-list-item-content>
							<v-list-item-action>
								<v-list-item-action-text>{{ minUAH(estimate.prices) | toCurrencyUAH }}</v-list-item-action-text>
							</v-list-item-action>
						</v-list-item>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>USD</v-list-item-title>
							</v-list-item-content>
							<v-list-item-action>
								<v-list-item-action-text>{{ minUSD(estimate.prices) | toCurrencyUSD }}</v-list-item-action-text>
							</v-list-item-action>
						</v-list-item>
					</v-list>
				</v-card>
			</v-col>

			<v-col cols="12" md="3">
				<v-card>
					<v-list dense>
						<v-subheader>Максимальна ціна</v-subheader>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>UAH</v-list-item-title>
							</v-list-item-content>
							<v-list-item-action>
								<v-list-item-action-text>{{ maxUAH(estimate.prices) | toCurrencyUAH }}</v-list-item-action-text>
							</v-list-item-action>
						</v-list-item>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>USD</v-list-item-title>
							</v-list-item-content>
							<v-list-item-action>
								<v-list-item-action-text>{{ maxUSD(estimate.prices) | toCurrencyUSD }}</v-list-item-action-text>
							</v-list-item-action>
						</v-list-item>
					</v-list>
				</v-card>
			</v-col>
			<v-col cols="12">
				<v-card elevation="0">
					<v-simple-table dense>
						<template v-slot:default>
							<thead>
								<tr>
									<th>Дата</th>
									<!-- <th>Кількість об'яв</th> -->
									<th>Вартість авто в UAH</th>
									<th>Вартість авто в USD</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item, index) in prices" :key="index">
									<td >{{ item.name }}</td>
									<!-- <td >{{ item.cnt }}</td> -->
									<td >{{ item.priceUAH }}</td>
									<td >{{ item.priceUSD }}</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-card>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
export default {
	data: () => ({
		title: 'Статистика середньої ціни за період',
	}),

	props: {
		estimate: { type: Object, default: () => {}},
	},

	computed: {
		prices() {
			//console.log(this.$options.name, 'computed','prices', this.estimate.prices)
			return this.estimate.hasOwnProperty('prices') ? this.estimate.prices: [];
		},
	},

	methods: {
		labels(items) {
			return items.map(element=>element.name);
		},

		valueUAH(items) {
			return items.map(element=>element.priceUAH);
		},

		valueUSD(items) {
			return items.map(element=>element.priceUSD);
		},

		avgUAH(items) {
			const arr = this.valueUAH(items);
			const average = arr.reduce((a, b) => a + b, 0) / arr.length;
			return average
		},

		avgUSD(items) {
			const arr = this.valueUSD(items);
			const average = arr.reduce((a, b) => a + b, 0) / arr.length;
			return average
		},

		minUAH(items) {
			const arr = this.valueUAH(items);
			return Math.min(...arr)
		},
		minUSD(items) {
			const arr = this.valueUSD(items);
			return Math.min(...arr)
		},

		maxUAH(items) {
			const arr = this.valueUAH(items);
			return Math.max(...arr)
		},
		maxUSD(items) {
			const arr = this.valueUSD(items);
			return Math.max(...arr)
		},
	},
}
</script>