<template>
	 <v-dialog  
	 	v-model="dialog"
	 	:fullscreen="fullscreen"
		:transition="transition"
		:scrollable="scrollable"
		:width="width"
		:height="height"
		:max-width="maxWidth"
	 >

		<!-- :fullscreen="fullscreen"
		:hide-overlay=""
		:max-width="maxWidth"
		:height="height"
		:max-height="maxHeight"
		 -->

		<!-- Актіватор -->
		<template v-slot:activator="{ on: dialog, attrs }">
			<!-- activator - icon -->
            <v-tooltip bottom v-if="activator=='icon'" disabled>
                <template v-slot:activator="{ on: tooltip }">
                    <v-icon :small="small" v-bind="attrs" v-on="{ ...tooltip, ...dialog }" :disabled="disabled">{{activatorIcon}}</v-icon>
                </template>
                <span>{{ $vuetify.lang.t('$vuetify.' + mode) | capitalize }}</span>
            </v-tooltip>
            
            <!-- activator - list-item -->
            <!-- <v-list-item v-else-if="activator=='list-item'" v-bind="attrs" v-on="{...dialog }" :disabled="disabled"> 
                <v-list-item-icon>
                    <v-icon color="primary" :disabled="disabled">{{activatorIcon}}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{title}}</v-list-item-title>
            </v-list-item> -->

            <!-- activator - button -->
            <v-tooltip bottom v-else disabled>
                <template v-slot:activator="{ on: tooltip }">
                    <!-- <v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...dialog }" :disabled="disabled" v-text="activatorName">
                        <v-icon color="primary">{{acvicon}}</v-icon>
                    </v-btn> -->
					<v-btn outlined color="primary" v-bind="attrs" v-on="{ ...tooltip, ...dialog }">
						<v-icon left dark color="primary">{{ activatorIcon }}</v-icon>
						{{ $vuetify.lang.t('$vuetify.' + mode)  | capitalize }}
					</v-btn>
                </template>
                <span>{{ title | capitalize}}</span>
            </v-tooltip>
		</template>

		<v-card elevation="4" :color="color">
			<!-- Ttoolbar -->
			<v-toolbar elevation="0" dark color="primary">
				<v-avatar>
					<v-icon>{{ icon }}</v-icon>
				</v-avatar>
				<v-toolbar-title>{{ $vuetify.lang.t('$vuetify.' + title.single) | capitalize }}</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-btn icon @click="OnClose" >
					<v-icon>mdi-close</v-icon>
				</v-btn>

				<!-- Extension for Tabs -->
				<template v-slot:extension v-if="form.hasOwnProperty('tabs')">
					<!-- Tabs -->
					<v-tabs v-model="form.tab" align-with-title>
						<v-tabs-slider color="yellow"></v-tabs-slider>
						<v-tab v-for="item in form.tabs" :key="item">
							{{ item }}
						</v-tab>
					</v-tabs>
				</template>
			</v-toolbar>

			<v-card-text style="height: 500px;">
				<!-- <slot name="context">


				</slot> -->
				<component ref="component" v-bind:is="currentComponent"
					v-bind="$attrs"
					:module="module"
					:item="item"
					@click="$emit('click', $event)"
					@input="$emit('input', $event)"
				></component>
			</v-card-text>
			<v-card-actions v-if="!fullscreen">
				<v-spacer></v-spacer>
				<v-btn text @click="OnCancel">{{ $vuetify.lang.t('$vuetify.cancel') }}</v-btn>
				<v-btn color="success" dark @click.native="OnOK" :disabled="loading">{{ $vuetify.lang.t('$vuetify.ok') }}</v-btn>
			</v-card-actions>
		</v-card>

		<!-- <v-snackbar 
			v-model="snackbar" 
			vertical
			:timeout="3000"
			shaped
			:color="messagetype"
		>
			{{ message }}
			<template v-slot:action="{ attrs }">
				<v-btn color="indigo" text
					v-bind="attrs"
					@click="snackbar = false"
				>Close</v-btn>
			</template>
		</v-snackbar> -->
	</v-dialog>
</template>

<script>
//import zActivator from '@/views/components/activators/dialog'
//import mixin from '@/views/mixins/dialogs'
import Vue from 'vue'
import upperFirst from 'lodash/upperFirst'

//Глобальна реєстрація компонентів групи forms
//const requireComponent = require.context('./forms',
const requireComponent = require.context('@/views/forms',
	true, // Обрабатывать или нет подкаталоги
	/\.(vue)$/i // Регулярное выражение для определения файлов базовых компонентов /Base[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
	// Получение конфигурации компонента
	const componentConfig 	= requireComponent(fileName)
	const componentName 	= 'z'+upperFirst(fileName.split('/').pop().replace(/\.\w+$/, ''))
	
	//console.log('components registration', 'componentName', componentName)

	// Глобальная регистрация компонента
	Vue.component(
		componentName,
		// Поиск опций компонента в `.default`, который будет существовать,
		// если компонент экспортирован с помощью `export default`,
		// иначе будет использован корневой уровень модуля.
		componentConfig.default || componentConfig
	)
})

export default {
	//mixins: [mixin],
	components: {
		//zActivator
	},
	data: () => ({
		dialog: false,
		loading: false,
		

		/* snackbar: false,
		message: null,
		messagetype: null, // success | info | warning | error */
	}),
	props: {
		module: 		{ type: String, default: null }, // users | roles | ....
		mode: 			{ type: String, default: 'update' }, // create | update

		item: 			{ type: Object, default: () => {} },
		//selected: 			{ type: Object, default: () => {} },

		
		

		//title:      	{ type: String, default: null },
		//title:      	{ type: Object, default: () => {} },
		//icon:       	{ type: String, default: null },
		hint:       	{ type: String, default: null },
		fullscreen: 	{ type: Boolean, default: false },
		scrollable: 	{ type: Boolean, default: true },
		hideOverlay: 	{ type: Boolean, default: false },
		width:      	{ type: Number, default: 800 },
		maxWidth:      	{ type: Number, default: 800 },
		height:     	{ type: Number, default: 1024 },
		maxHeight:     	{ type: Number, default: 1024 },
		transition: 	{ type: String, default: 'dialog-bottom-transition' },
		color:			{ type: String, default: null }, 		// Колір фону (card)

		//activator:			{ type: Object, default: () => {} },	// Параметри активатора

		small:      	{ type: Boolean, default: false },
		disabled:   	{ type: Boolean, default: false }, //Ознака неактивного елементу
		activator:  	{ type: String, default: 'button'}, // Тип активатора вікна: button | icon | menu-item | list-item

	},
	computed: {
		title: function () {
            return this.$store.getters[this.module + '/title']
        },
		icon: function () {
            return this.$store.getters[this.module + '/icon']
        },
		form: function () {
            return this.$store.getters[this.module + '/form']
        },

		currentComponent: function() {
			if(this.module) {
				return "z-" + this.module.toLowerCase();
			}
		},

		activatorIcon: function () {
			let result = 'mdi-plus'
			switch(this.mode) {
				case 'update': result = 'mdi-pencil'; break;
				case 'preview': result = 'mdi-printer-eye'; break;
			}
			return result
		},
	},
	methods: {
		Open (e) {
			this.dialog = true
		},
		OnClose (e) {
			this.dialog = false
			this.$emit('close', e)
		},
		OnCancel (e) {
			this.$emit('cancel', e)
			this.OnClose()
		},
		OnOK (e) {
			this.$emit(this.mode, e)
			this.OnClose()
		},

		/* Message (text, type) {
			//console.log(this.modulename, 'Message', 'text', text)

			this.message = text
			this.snackbar = true
			this.messagetype = type
		}, */
	},
}
</script>