export default {
	namespaced: true,
	state: {
		endpoint: '/document/status',
		name: 'DocumentStatus',
		title: {
			single: 'DocumentStatus',
			plural: 'DocumentStatuss'
		},
		icon: 'mdi-list-status',
		items: [],
		headers: [
			{ text: 'ID', value: 'id', sortable: true, width: '50' },
			//{ text: 'Код', value: 'code', sortable: true },
			//{ text: 'Назва', value: 'name', sortable: true },
			{ text: 'type', value: 'type.name', sortable: true},
			{ text: 'date', value: 'date', sortable: true},
			//{ text: 'Опис', value: 'description', sortable: true },
			{ text: 'actions', value: 'actions', sortable: false, width: '10%' },
		],
		item: {
			type: {},
		},
	},
	actions: {
		clearItem(context) {
			context.commit('item', {
				type: {},
			})
		},
	},
}
